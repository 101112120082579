import React, { useState, } from 'react';
import { useFormik, } from 'formik';
import _ from 'lodash';
import * as Yup from 'yup';

import { Form, Col, } from 'react-bootstrap';
import { Notification, } from 'rsuite';

import { sendDPOContactRequest, } from '../../services/api';

import Button from '../Button';


const INITIAL_VALUES = Object.freeze({
  name: '',
  email: '',
  typeReq: '',
  obs: '',
});

const REQUEST_TYPES = Object.freeze([
  'Consultar',
  'Excluir',
  'Transferir',
]);


export default function DPOContactForm({ company, onContactSent, origin, }) {
  const currentUrl = location.origin;
  const [loading, setLoading] = useState(false);

  const formik = useFormik({
    initialValues: INITIAL_VALUES,
    onSubmit: sendContactRequest,
    validationSchema: Yup.object({
      name: Yup.string().required('Obrigatório'),
      email: Yup.string().email('Deve ser um e-mail válido').required('Obrigatório'),
      typeReq: Yup.string().oneOf(REQUEST_TYPES, 'Deve selecionar um tipo de contato válido').required('Obrigatório'),
      obs: Yup.string().required('Obrigatório').max(1500, 'Deve possuir no máximo 1500 caracteres'),
    }),
  });


  async function sendContactRequest(values) {
    setLoading(true);

    try {
      await sendDPOContactRequest({
        ...values,
        idEmpresa: company._id,
        logo: company.logo,
        site: company.site,
        companyName: company.name,
        emailContato: company.email,
        to: `${currentUrl}/solicitacao/dados?idEmpresa=${company._id}`,
        origin,
      });

      formik.resetForm({
        values: INITIAL_VALUES,
      });

      if (_.isFunction(onContactSent)) {
        onContactSent();
      }
    } catch {
      Notification['error']({
        placement: 'bottomEnd',
        title:
          'Erro ao registrar sua solicitação, tente novamente mais tarde.',
      });
    }

    setLoading(false);
  }

  function __onSubmit(event) {
    event.preventDefault();
    formik.handleSubmit();
  }


  return (
    <Form>
      <Form.Row>
        <Col xs={12}>
          <Form.Group>
            <Form.Label className="inputLabel">
              Nome
            </Form.Label>
            <Form.Control
              className="formInput"
              id="name"
              placeholder='Digite aqui seu Nome'
              isInvalid={formik.touched.name && formik.errors.name}
              {...formik.getFieldProps('name')}
            />

            <Form.Control.Feedback type="invalid">
              {formik.errors.name}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>

        <Col xs={6}>
          <Form.Group>
            <Form.Label className="inputLabel">
              E-mail
            </Form.Label>
            <Form.Control
              className="formInput"
              id="email"
              placeholder='Digite aqui seu E-mail'
              isInvalid={formik.touched.email && formik.errors.email}
              {...formik.getFieldProps('email')}
            />

            <Form.Control.Feedback type="invalid">
              {formik.errors.email}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>

        <Col xs={6}>
          <Form.Group>
            <Form.Label className="inputLabel">
              Tipo de contato
            </Form.Label>
            <Form.Control
              className="selectContent"
              as="select"
              defaultValue="Escolha um valor"
              id="typeReq"
              isInvalid={formik.touched.typeReq && formik.errors.typeReq}
              {...formik.getFieldProps('typeReq')}
            >
              <option>
                Escolha um valor
              </option>
              {
                REQUEST_TYPES.map((typeReq, id) => (
                  <option
                    key={id}
                    value={typeReq}
                  >
                    {typeReq}
                  </option>
                ))
              }
            </Form.Control>

            <Form.Control.Feedback type="invalid">
              {formik.errors.typeReq}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>

        <Col xs={12}>
          <Form.Group>
            <Form.Label className="inputLabel">
              Descreva sua solicitação (<strong>se possível inclua o link de referência</strong>)
            </Form.Label>
            <Form.Control
              className="formInput"
              as="textarea"
              id="obs"
              placeholder='Descreva aqui sua solicitação'
              isInvalid={formik.touched.obs && formik.errors.obs}
              {...formik.getFieldProps('obs')}
              rows={5}
            />

            <Form.Control.Feedback type="invalid">
              {formik.errors.obs}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
      </Form.Row>

      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Button
          onClick={__onSubmit}
          variant="contained"
          color="primary"
          loading={loading}
        >
          Enviar sua solicitação
        </Button>
      </div>
    </Form>
  );
}
