import { useState, } from 'react';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  makeStyles,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { useFormik, } from 'formik';
import * as Yup from 'yup';
import _ from 'lodash';
import { useSelector, } from 'react-redux';

import { Notification, } from 'rsuite';

import Button from '../Button';

import { createImpactReport, } from '../../services/api';

import { selectCompany, } from '../../features/companySlice';


const INITIAL_VALUES = Object.freeze({
  title: '',
});


const useStyles = makeStyles({
  actions: {
    marginTop: '1rem',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  title: {
    color: 'white',
    backgroundColor: '#3B66FF',
  },
});


/**
 * Dialog used to register a impact report template
 *
 * @param {boolean} open - whether the dialog is open or not
 * @param {function} onClose - callback called when dialog is closed
 * @param {number} reportId - report ID for the report to create the template from
 *
 * @returns Rendered dialog
 */
function RegisterImpactReportTemplateDialog({ open, onClose, reportId, }) {
  const classes = useStyles();
  const company = useSelector(selectCompany);
  const [loading, setLoading] = useState(false);

  const formik = useFormik({
    initialValues: INITIAL_VALUES,
    onSubmit: handleRegister,
    validationSchema: Yup.object({
      title: Yup.string().required('Obrigatório'),
    }),
  });


  /**
   * Handle register
   *
   * @param {object} values - new impact report info
   */
  async function handleRegister(values) {
    setLoading(true);

    try {
      const data = _.cloneDeep(values);
      data.templateId = reportId;
      data.isTemplate = true;

      await createImpactReport(company._id, data);
      Notification['success']({
        placement: 'bottomEnd',
        title: 'Template de relatório de impacto criado com sucesso',
      });

      __onClose();
    } catch {
      Notification['error']({
        placement: 'bottomEnd',
        title: 'Falha ao criar novo template de relatório de impacto!',
        description: 'Tente novamente dentro de alguns instantes.'
      });
    }

    setLoading(false);
  }

  /**
   * Submit event handler
   *
   * @param {object} event - event that triggered the handler
   */
  function onSubmit(event) {
    event.preventDefault();
    formik.handleSubmit();
  }

  /**
   * Handle cancel
   */
  function __onClose() {
    formik.resetForm();
    onClose();
  }


  return (
    <Dialog
      open={open}
      onClose={__onClose}
      maxWidth="md"
    >
      <DialogTitle className={classes.title}>
        Criar template de relatório de impacto
      </DialogTitle>

      <DialogContent>
        <form
          onSubmit={onSubmit}
        >
          <Grid
            item
            xs={12}
            container
            direction="row"
          >
            <TextField
              margin="dense"
              variant="filled"
              label="Nome"
              InputProps={{
                disableUnderline: true,
              }}
              fullWidth
              id="title"
              type="text"
              error={formik.errors.title && formik.touched.title}
              helperText={formik.touched.title && formik.errors.title}
              {...formik.getFieldProps('title')}
            />
          </Grid>

          <Grid
            className={classes.actions}
          >
            <Button
              variant="contained"
              loading={loading}
              onClick={__onClose}
            >
              Cancelar
            </Button>

            <Button
              type="submit"
              variant="contained"
              color="primary"
              loading={loading}
            >
              Cadastrar
            </Button>
          </Grid>
        </form>
      </DialogContent>
    </Dialog>
  );
}

RegisterImpactReportTemplateDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  reportId: PropTypes.number.isRequired,
};

export default RegisterImpactReportTemplateDialog;
