import React, { useState, memo, } from 'react';
import _ from 'lodash';
import { TextField, Grid, IconButton, InputAdornment, makeStyles, } from '@material-ui/core';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { useFormik, } from 'formik';
import * as Yup from 'yup';
import { Button, } from 'rsuite';
import clsx from 'clsx';


const INITIAL_VALUES = Object.freeze({
  name: '',
  email: '',
  phoneNumber: '',
  companyName: '',
  password: '',
  confirmPassword: '',
});

const useStyles = makeStyles({
  container: {
    height: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
  },
  actions: {
    marginTop: '1rem',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  button: {
    textTransform: 'none',
    fontFamily: 'Inter',
    fontWeight: 'bold',
    paddingLeft: '3rem',
    paddingRight: '3rem',
  },
  addBtn: {
    background: '#3B66FF',
    color: '#fff !important',
    marginLeft: '1rem',
  },
  cancelBtn: {
    borderColor: '#3B66FF',
    color: '#3B66FF !important',
    background: 'transparent !important',
    boxShadow: 'none',
    borderWidth: 2,
    border: '2px solid #3B66FF !important',
    '&:hover': {
      background: 'transparent !important',
      border: '2px solid #3B66FF !important',
    },
  },
});


function RegisterForm({ handleLogin, handleCancel, }) {
  const classes = useStyles();

  const formik = useFormik({
    initialValues: INITIAL_VALUES,
    onSubmit: __handleLogin,
    validationSchema: Yup.object({
      name: Yup.string().required('Obrigatório'),
      email: Yup.string().lowercase().trim().email('Deve ser um e-mail válido').
        required('Obrigatório'),
      phoneNumber: Yup.string().required('Obrigatório').trim(),
      companyName: Yup.string().required('Obrigatório'),
      password: Yup.string().required('Obrigatório'),
      confirmPassword: Yup.string().oneOf([Yup.ref('password'), ''],
        'Senhas devem ser iguais').required('Obrigatório'),
    }),
  });

  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);

  /**
   * Handle submit
   *
   * @param {object} values - form data
   */
  async function __handleLogin (values) {
    values = _.omit(values, ['confirmPassword']);
    values.email = values.email.trim().toLowerCase();
    values.name = values.name.trim();
    values.companyName = values.companyName.trim();
    values.phoneNumber = values.phoneNumber.trim();

    try {
      setLoading(true);
      await handleLogin(values);
    } catch {
      // ignore error
    }

    setLoading(false);
  }


  function togglePassword() {
    setShowPassword(!showPassword);
  };

  function handleMouseDownPassword(event) {
    event.preventDefault();
  };

  function onSubmit(event) {
    event.preventDefault();
    formik.handleSubmit();
  }


  return (
    <form
      onSubmit={onSubmit}
      className={classes.container}
    >
      <Grid
        item
        xs={12}
        container
        direction="row"
      >
        <TextField
          InputProps={{
            disableUnderline: true,
            autoComplete: 'name',
          }}
          margin="dense"
          variant="filled"
          label="Nome"
          fullWidth
          id="name"
          type="text"
          error={formik.errors.name && formik.touched.name}
          helperText={formik.touched.name && formik.errors.name}
          {...formik.getFieldProps('name')}
        />
      </Grid>

      <Grid
        item
        xs={12}
        container
        direction="row"
        justifyContent="space-between"
      >
        <Grid
          item
          xs={6}
        >
          <TextField
            InputProps={{
              disableUnderline: true,
              autoComplete: "email"
            }}
            style={{
              paddingRight: '0.2rem',
            }}
            margin="dense"
            variant="filled"
            label="E-mail"
            fullWidth
            id="email"
            type="email"
            error={formik.errors.email && formik.touched.email}
            helperText={formik.touched.email && formik.errors.email}
            {...formik.getFieldProps('email')}
          />
        </Grid>
        <Grid
          item
          xs={6}
        >
          <TextField
            InputProps={{
              disableUnderline: true,
            }}
            style={{
              paddingLeft: '0.2rem',
            }}
            margin="dense"
            variant="filled"
            label="Whatsapp"
            fullWidth
            id="phoneNumber"
            type="text"
            error={formik.errors.phoneNumber && formik.touched.phoneNumber}
            helperText={formik.touched.phoneNumber && formik.errors.phoneNumber}
            {...formik.getFieldProps('phoneNumber')}
          />
        </Grid>
      </Grid>

      <Grid
        item
        xs={12}
      >
        <TextField
          InputProps={{
            disableUnderline: true,
            autoComplete: 'companyName',
          }}
          margin="dense"
          variant="filled"
          label="Empresa"
          fullWidth
          id="companyName"
          type="text"
          error={formik.errors.companyName && formik.touched.companyName}
          helperText={formik.touched.companyName && formik.errors.companyName}
          {...formik.getFieldProps('companyName')}
        />
      </Grid>

      <Grid
        item
        xs={12}
        container
        direction="row"
        justifyContent="space-between"
      >
        <Grid
          item
          xs={6}
        >
          <TextField
            InputProps={{
              disableUnderline: true,
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={togglePassword}
                    onMouseDown={handleMouseDownPassword}
                  >
                    { showPassword ? <Visibility /> : <VisibilityOff /> }
                  </IconButton>
                </InputAdornment>
              )
            }}
            style={{
              paddingRight: '0.2rem',
            }}
            margin="dense"
            variant="filled"
            label="Senha"
            fullWidth
            id="password"
            type={ showPassword ? 'text' : 'password' }
            error={formik.errors.password && formik.touched.password}
            helperText={formik.touched.password && formik.errors.password}
            {...formik.getFieldProps('password')}
          />
        </Grid>

        <Grid
          item
          xs={6}
        >
          <TextField
            InputProps={{
              disableUnderline: true,
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={togglePassword}
                    onMouseDown={handleMouseDownPassword}
                  >
                    { showPassword ? <Visibility /> : <VisibilityOff /> }
                  </IconButton>
                </InputAdornment>
              )
            }}
            style={{
              paddingLeft: '0.2rem',
            }}
            margin="dense"
            variant="filled"
            label="Repita a senha"
            fullWidth
            id="confirmPassword"
            type={ showPassword ? 'text' : 'password' }
            error={formik.errors.confirmPassword && formik.touched.confirmPassword}
            helperText={formik.touched.confirmPassword && formik.errors.confirmPassword}
            {...formik.getFieldProps('confirmPassword')}
          />
        </Grid>
      </Grid>

      <Grid
        className={classes.actions}
      >
        <Button
          appearance="primary"
          className={clsx(classes.button, classes.cancelBtn)}
          onClick={handleCancel}
          loading={loading}
        >
          Cancelar
        </Button>

        <Button
          type="submit"
          appearance="primary"
          className={clsx(classes.button, classes.addBtn)}
          loading={loading}
        >
          Cadastrar
        </Button>
      </Grid>
    </form>
  );
}

export default memo(RegisterForm);
