import { createSlice } from '@reduxjs/toolkit';

const planoAcaoStore = createSlice({
    name: 'planAction',
    initialState: {
        statusMenuLeft: false,
        currentMeeting: null
    },

    reducers: {
        setStatusMenuLeft(state, action) {
            state.statusMenuLeft = action.payload;
        },
        setCurrentMeeting(state, action) {
            state.currentMeeting = action.payload;
        },
    }
});

export const { setStatusMenuLeft, setCurrentMeeting } = planoAcaoStore.actions;

export default planoAcaoStore.reducer
