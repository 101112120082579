import React from 'react';
import { useSelector, } from 'react-redux';

import QuestionsCategoriesMaturityLevelChart from '../../QuestionsCategoriesMaturityLevelChart';

import { selectCompany, } from '../../../features/companySlice';
import { getQuestionsCategoriesReport, } from '../../../services/api';
import BaseWidget from './BaseWidget';


function CategoryMaturityLevelReportWidget(props) {
  const company = useSelector(selectCompany);
  const [loading, setLoading] = React.useState(true);
  const [categoriesReport, setCategoriesReport] = React.useState([]);


  async function initialize() {
    setLoading(true);
    try {
      const { data, } = await getQuestionsCategoriesReport(company._id);
      setCategoriesReport(data);
    } catch {
      //TODO: handle error
    }
    setLoading(false);
  }

  React.useEffect(() => {
    initialize();
  }, []);


  return (
    <BaseWidget
      {...props}
      loading={loading}
    >
      <QuestionsCategoriesMaturityLevelChart data={categoriesReport} />
    </BaseWidget>
  );
}

export default CategoryMaturityLevelReportWidget;
