import PropTypes from 'prop-types';
import { Notification, } from 'rsuite';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  makeStyles,
} from '@material-ui/core';

const useStyles = makeStyles({
  actions: {
    marginTop: '1rem',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  title: {
    color: 'white',
    backgroundColor: '#3B66FF',
  },
});


/**
 * Dialog used to register a new site
 *
 * @param {boolean} open - whether the dialog is open or not
 * @param {function} onClose - callback called when dialog is closed
 *
 * @returns Rendered dialog
 */
function ShowCode({ open, onClose, children, }) {
  const classes = useStyles();

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="lg"
      fullWidth={true}
    >
      <DialogTitle className={classes.title}>
        Código
      </DialogTitle>

      <DialogContent>
        {children}
      </DialogContent>
    </Dialog>
  );
}


ShowCode.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onCreated: PropTypes.func.isRequired,
};

export default ShowCode;
