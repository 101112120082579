import React, { useEffect } from 'react';

import Sheet from '../../components/Sheet';
import CoursesPage from './CursosPage';
import TrainingsDashboard from './TraningsDashboard';
import Trainings from '../../components/DPO/ContainerTreinamentos';
import { fetchCourses, } from '../../features/courseSlice';
import { useDispatch, } from 'react-redux';
import { WorkshopGrid } from '../../components/Workshop';

const PAGES = Object.freeze({
  cursos: {
    id: 'cursos',
    titleTab: "Cursos",
    titlePage: "Cursos",
    component: <Trainings />,
  },
  dashboard: {
    id: 'dashboard',
    titleTab: "Dashboard",
    titlePage: "Dashboard",
    component: <TrainingsDashboard />,
  },
  workshop: {
    id: 'workshop',
    titleTab: "Workshop",
    titlePage: "WorkShop",
    component: <WorkshopGrid />,
  },
});

const ARRAY_PAGES = Object.values(PAGES);

export default function MapCursosPage() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchCourses());
  }, []);

  return (
    <Sheet pages={ARRAY_PAGES} />
  );
}
