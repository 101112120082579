import React from 'react';
import _ from 'lodash';
import * as Yup from 'yup';
import { useFormik, } from 'formik';
import { useTranslation, } from 'react-i18next';

import { Button, } from 'rsuite';
import { Container, TextField, makeStyles, Grid, } from '@material-ui/core';


const INITIAL_VALUES = Object.freeze({
  name: '',
  description: '',
});

const useStyles = makeStyles({
  buttonContainer: {
    marginTop: '0.5rem',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  cancelBtn: {
    marginLeft: '1rem',
    borderColor: '#3B66FF',
    color: '#3B66FF !important',
    background: 'transparent !important',
    boxShadow: 'none',
    borderWidth: 2,
    border: '2px solid #3B66FF !important',
    '&:hover': {
      background: 'transparent !important',
      border: '2px solid #3B66FF !important',
    },
  },
});


function DataMappingTemplateForm({ data, onSubmit, onCancel, }) {
  const { t, }= useTranslation();
  const classes = useStyles();
  const formik = useFormik({
    initialValues: INITIAL_VALUES,
    onSubmit: submitCompanySector,
    validationSchema: Yup.object({
      name: Yup.string().required(_.capitalize(t('VALIDATION_MESSAGES.required', { context: 'male' }))),
      description: Yup.string(),
    }),
  });
  const [loading, setLoading] = React.useState(false);


  /**
   * Save company sector in API
   */
  async function submitCompanySector(values) {
    setLoading(true);

    onSubmit({
      ...data,
      ...values,
    })
  }

  React.useEffect(() => {
    if (undefined != data && undefined != data.id) {
      formik.setValues({
        ...data,
        description: data.description || '',
      });
    } else {
      formik.setValues(INITIAL_VALUES);
    }
  }, [data]);


  return (
    <Container
      disableGutters
      fullWidth
      maxWidth="xl"
    >
      <TextField
        margin="dense"
        label={_.capitalize(t('name'))}
        fullWidth
        id="name"
        variant="filled"
        InputProps={{
          disableUnderline: true,
        }}
        type="text"
        error={formik.errors.name && formik.touched.name}
        helperText={formik.touched.name && formik.errors.name}
        {...formik.getFieldProps('name')}
      />

      <TextField
        margin="dense"
        label={_.capitalize(t('description', { count: 1, }))}
        multiline
        fullWidth
        id="description"
        variant="filled"
        InputProps={{
          disableUnderline: true,
        }}
        type="text"
        error={formik.errors.description && formik.touched.description}
        helperText={formik.touched.description && formik.errors.description}
        {...formik.getFieldProps('description')}
      />

      <Grid
        className={classes.buttonContainer}
      >
        <Button
          className={classes.saveButton}
          appearance="primary"
          loading={loading}
          onClick={formik.handleSubmit}
        >
          { _.capitalize(t('save')) }
        </Button>

        <Button
          appearance="primary"
          className={classes.cancelBtn}
          onClick={onCancel}
          loading={loading}
        >
          { _.capitalize(t('cancel')) }
        </Button>
      </Grid>
    </Container>
  );
}

export default React.memo(DataMappingTemplateForm);
