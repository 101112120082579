import { useState, } from 'react';
import {
  Container,
  Grid,
  Button,
  makeStyles,
  Paper,
} from '@material-ui/core';
import AddOutlinedIcon from '@material-ui/icons/AddOutlined';
import CarouselTraining from './CarouselTraining';
import { CourseModal, } from '../CourseModal/index';
import { COURSE_LEVELS_ARRAY, } from '../../utils/constants';
import Dropdown from '../Dropdown';


const courseLevelOptions = Object.freeze(COURSE_LEVELS_ARRAY.map(({ value, label, }) => ({
  id: value,
  name: label,
})));

const useStyles = makeStyles({
  pageActions: {
    marginBottom: '1rem',
  },
  contentContainer: {
    paddingTop: '1rem',
    paddingBottom: '1rem',
  },
});


export default function Trainings() {
  const classes = useStyles();

  const [openModalNewTraining, setOpenModalNewTraining] = useState(false);
  const [filteredCourseLevel, setFilteredCourseLevel] = useState(null);


  /**
   * Close modal to create new course
   */
  function handleCloseModalNewTraining() {
    setOpenModalNewTraining(false);
  }

  /**
   * Handle change event in filtered course level
   *
   * @param {string} level - updated course level filter
   */
  const onSelectCourseLevel = (level) => {
    setFilteredCourseLevel(0 === level.length ? null : level);
  }

  return (
    <Container
      className={classes.contentContainer}
      fullWidth
      maxWidth="xl"
      component={Paper}
    >
      <Grid
        className={classes.pageActions}
        alignItems="center"
        justifyContent="space-between"
        direction="row"
        container
      >
        <Button
          variant="outlined"
          color="primary"
          startIcon={<AddOutlinedIcon />}
          onClick={() => setOpenModalNewTraining(true)}
        >
          Novo curso
        </Button>

        <Dropdown
          defaultItem="Dificuldade do curso"
          items={courseLevelOptions}
          value={filteredCourseLevel}
          setValue={onSelectCourseLevel}
        />
      </Grid>

      <CarouselTraining onlyLevel={filteredCourseLevel} />

      <CourseModal
        open={openModalNewTraining}
        handleClose={handleCloseModalNewTraining}
      />
    </Container>
  )
}
