import React, { useEffect, useState } from "react";
import { useSelector, useDispatch, } from 'react-redux';

import { Grid, IconButton, } from '@material-ui/core';
import { makeStyles, } from '@material-ui/styles';
import Dropdown from '../../components/Dropdown';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import RegisterNewSiteDialog from "./RegisterNewSiteDialog";
import { Notification, } from 'rsuite';
import { getCompanyRegisteredPopups } from "../../services/api";
import { setIndexSelectSite, setSites } from "../../features/gestaoDeCookies";



export default function SelectSite() {
  const { company } = useSelector(state => state.company);
  const { sites, indexSelectSite } = useSelector(state => state.gestaoDeCookies);

  const classes = useStyles();
  const dispatch = useDispatch();

  const [newSiteModalVisible, setNewSiteModalVisible] = useState(false);
  const [loading, setLoading] = useState(true);

  /**
   * Fetch registered sites from API
   */
  async function initialize() {
    setLoading(true);

    try {
      const { data, } = await getCompanyRegisteredPopups(company._id);
      dispatch(setSites(data));
    } catch {
      Notification['error']({
        placement: 'bottomEnd',
        title: 'Falha ao carregar sites registrados, tente novamente dentro de alguns instantes',
      });
    }

    setLoading(false);
  }


  /**
   * Handle change in selected site
   *
   * @param {string} selectedSiteId - selected site ID
   */
  function onSiteChange(selectedSiteId) {
    const idx = sites.findIndex(({ id, }) => id === selectedSiteId);
    dispatch(setIndexSelectSite(Math.max(idx, 0)));
  }

  /**
  * Open modal to register new site
  */
  async function openNewSiteModal() {
    setNewSiteModalVisible(true);
  }

  /**
   * Close modal to register new site
   */
  async function closeNewSiteModal() {
    setNewSiteModalVisible(false);
  }

  /**
   * Callback called when new popup config is created for a site
   * @param {object} popupConfig - new popup config
   */
  async function onNewSitePopupCreated(popupConfig) {
    const updatedSites = [...sites, popupConfig];
    dispatch(setSites(updatedSites));
    dispatch(setIndexSelectSite(updatedSites.length - 1));
  }

  useEffect(async () => {
    await initialize();
  }, []);

  return (
    <Grid
      className={classes.reportHeader}
      container
      alignItems="center"
    >
      <Dropdown
        items={sites}
        value={sites.length > 0 ? sites[indexSelectSite].id : ''}
        setValue={onSiteChange}
        labelKey="host"
      />
      <IconButton
        aria-label="add-site"
        onClick={openNewSiteModal}
      >
        <AddCircleIcon />
      </IconButton>

      <RegisterNewSiteDialog
        open={newSiteModalVisible}
        onClose={closeNewSiteModal}
        onCreated={onNewSitePopupCreated}
      />
    </Grid>


  );
}


const useStyles = makeStyles({
  layout: {
    marginTop: '0.8rem',
    marginBottom: '0.5rem',
  },
  selectorContainer: {
    marginBottom: '1rem',
  },
  noItems: {
    paddingTop: '4rem',
    paddingBottom: '4rem',
    color: '#80808080',
  },
  loading: {
    marginTop: '4rem',
    marginBottom: '4rem',
  },
});
