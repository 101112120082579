export const ImpactReportVersion = Object.freeze({
  VERSION_1: 1,
  VERSION_2: 2,
});

export const WidgetType = Object.freeze({
  TEXT: 'TEXT',
  OBJECTIVE: 'OBJECTIVE',
  SIGNATURES: 'SIGNATURES',
  IMAGE: 'IMAGE',
  REVISION_HISTORY: 'REVISION_HISTORY',
  RISK_IDENTIFICATIONS_AND_ASSESSMENTS: 'RISK_IDENTIFICATIONS_AND_ASSESSMENTS',
  RISK_TREATMENT_MEASURES: 'RISK_TREATMENT_MEASURES',
});

export const WIDGET_TYPE_ARRAY = Object.values(WidgetType);

/**
 * The user cannot add new instances of this widget to a impact report nor delete
 * the instances already present in the report
 */
export const REQUIRED_WIDGET_TYPES = [
  WidgetType.OBJECTIVE,
  WidgetType.REVISION_HISTORY,
];
