import { Icon, } from 'rsuite';
import {
  ListItemIcon,
  ListItem,
  ListItemText,
} from '@material-ui/core';

import history from '../../../history';
import '../../NavBar/styles.css';


export default function SingleItem({ go, icon, description, noBoxIcon, }) {
  return (
    <ListItem
      className="BoxAllListMenew"
      div
      onClick={() => {
        history.push(go)
      }}
    >
      <div className="DivInternaBoxAll">
        <ListItemIcon className="BoxIcon">
          <Icon
            className={`BoxIcon ${
              noBoxIcon ? 'noMarginOnBox' : ''
            }`}
            icon={icon}
            size="lg"
          />
        </ListItemIcon>
        <ListItemText
          className="TitlteMenew"
          primary={
            <small className="TitlteMenew">
              {description}
            </small>
          }
        />
      </div>
    </ListItem>
  );
}
