import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector, } from 'react-redux';
import {
  Typography,
  makeStyles,
  Grid,
  TableCell,
  TableRow,
  Dialog,
  DialogContent,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import Table from '../../components/Table';
import { Notification } from 'rsuite'
import clsx from 'clsx';

import ProcessingAgentForm from '../ProcessingAgentForm/ProcessingAgentForm';
import {
  deleteProcessingAgent,
  selectProcessingAgents,
  selectFeatureStatus,
  listProcessingAgents,
  selectProcessingAgentModalState,
  setProcessingAgentsModal,
} from '../../features/processingAgentsSlice';
import { CRUD_STATUSES } from '../../utils/constants';
import {
  createManyProcessingAgentsFromCSVFile
} from '../../features/processingAgentsSlice';
import ImportExportActions
  from '../../composable/controllers/ProcessingAgents/components/ImportExportTemplate'

const useStyles = makeStyles({
  title: {
    color: '#1C265A',
    fontWeight: 'bolder',
    fontSize: '1.2rem',
    fontFamily: 'Inter',
    marginBottom: '2rem',
  },
  processingAgentForm: {
    paddingRight: '1.0rem',
  },
  action: {
    '&:hover': {
      cursor: 'pointer',
    },
  },
  deleteButton: {
    marginLeft: '0.5rem',
    color: 'red',
  },
  actions: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
  importExportTitle: {
    color: '#1C265A',
    fontWeight: 'bolder',
    fontSize: '1.2rem',
    fontFamily: 'Inter',
    marginBottom: '0.5rem',
  },
  importExportActions: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
  },
  importExportContainer: {
    marginBottom: '1.0rem',
  },
});

const HEADERS = Object.freeze([
  {
    label: 'Nome',
    align: 'center',
  },
  {
    label: 'CNPJ',
    align: 'center',
  },
  {
    label: 'Nome do DPO',
    align: 'center',
  },
  {
    label: 'E-mail',
    align: 'center',
  },
  {
    label: 'Telefone',
    align: 'center',
  },
  // {
  //   label: 'Tipos',
  //   align: 'center',
  // },
  {
    label: '',
    align: 'right',
  },
]);


function ProcessingAgents() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const processingAgents = useSelector(selectProcessingAgents);
  const status = useSelector(selectFeatureStatus);
  const processingAgentsModal = useSelector(selectProcessingAgentModalState);
  const [currProcessingAgent, setCurrProcessingAgent] = useState({});

  /**
   * Handle user request to delete processing agent
   *
   * @param {number} processingAgentId - processing agent ID
   */
  async function onDeleteProcessingAgent(processingAgentId) {
    try {
      await dispatch(deleteProcessingAgent(processingAgentId)).unwrap();

      Notification['success']({
        placement: 'bottomEnd',
        title: 'Terceiro deletado com sucesso!',
      });
    } catch {
      Notification['error']({
        placement: 'bottomEnd',
        title: 'Falha ao deletar terceiro!',
        description: 'Tente novamente dentro de alguns instantes.'
      });
    }
  }

  /**
   * Handle click in button to update processing agent
   *
   * @param {object} processingAgent - processing agent data
   */
  function onEditProcessingAgent(processingAgent) {
    setCurrProcessingAgent(processingAgent);
  }

  /**
   * Handle update in processing agent
   */
  function onUpdateFinished() {
    setCurrProcessingAgent({});
  }

  useEffect(() => {
    if (processingAgentsModal) {
      dispatch(listProcessingAgents());
    }
  }, [processingAgentsModal]);

  function onClose() {
    dispatch(setProcessingAgentsModal(false));
  }

  async function importFile(file) {
    await dispatch(createManyProcessingAgentsFromCSVFile(file)).unwrap();
    dispatch(listProcessingAgents());
  }

  return (
    <Dialog
      open={processingAgentsModal}
      onClose={onClose}
      maxWidth="lg"
    >
      <DialogContent>
        <Grid
          container
          justifyContent="space-around"
        >
          <Grid
            item
            md={5}
            xs={12}
            className={classes.processingAgentForm}
          >
            <Typography
              variant="h6"
              className={classes.title}
            >
              Adicionar novo terceiro
            </Typography>
            <ProcessingAgentForm
              data={currProcessingAgent}
              onUpdateFinished={onUpdateFinished}
            />
          </Grid>

          <Grid
            item
            md={7}
            xs={12}
          >
            <Typography
              variant="h6"
              className={classes.title}
            >
              Todos os terceiros
            </Typography>
            <Table
              headers={HEADERS}
              data={processingAgents}
              loading={(status == CRUD_STATUSES.IDLE) || (status == CRUD_STATUSES.LOADING)}
              disablePagination
              errorMessage="Falha ao carregar terceiros"
              noDataMessage="Nenhum terceiro encontrado"
              maxHeight={410}
            >
              {
                (processingAgent, props) => {
                  return (
                    <TableRow {...props}>
                      <TableCell
                        align="center"
                        style={{
                          minWidth: '10rem',
                        }}
                      >
                        { processingAgent.name }
                      </TableCell>
                      <TableCell align="center">
                        { processingAgent.companyIdentification }
                      </TableCell>
                      <TableCell
                        align="center"
                        style={{
                          minWidth: '10rem',
                        }}
                      >
                        { processingAgent.dpoName }
                      </TableCell>
                      <TableCell align="center">
                        { processingAgent.email }
                      </TableCell>
                      <TableCell align="center">
                        { processingAgent.phoneNumber }
                      </TableCell>
                      {/* <TableCell align="center" >
                        {
                          processingAgent.types.map((value) => (
                            <Chip
                              key={value}
                              color="primary"
                              size="small"
                              label={PROCESSING_AGENTS_TYPES[value].label}
                              style={{
                                marginBottom: '0.2rem',
                              }}
                            />
                          ))
                        }
                      </TableCell> */}
                      <TableCell
                        align="right"
                        size="small"
                      >
                        <div
                          className={classes.actions}
                        >
                          <EditIcon
                            fontSize="small"
                            className={classes.action}
                            onClick={() => onEditProcessingAgent(processingAgent)}
                          />

                          <DeleteIcon
                            fontSize="small"
                            className={clsx(classes.action, classes.deleteButton)}
                            onClick={() => onDeleteProcessingAgent(processingAgent.id)}
                          />
                        </div>
                      </TableCell>
                    </TableRow>
                  );
                }
              }
            </Table>
          </Grid>
          <Grid
            item
            md={12}
            xs={12}
            className={classes.importExportContainer}
          >
            <Typography
              variant="h6"
              className={classes.importExportTitle}
            >
              Adicionar novos terceiros através de um csv

            </Typography>
            <div className={classes.importExportActions}>
              <ImportExportActions
                onChange={importFile}
              />
            </div>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
}

export default ProcessingAgents;
