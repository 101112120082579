import { useState, useEffect } from 'react'
import _ from 'lodash';
import { useSelector, } from 'react-redux';
import { useTranslation, } from 'react-i18next';
import moment from 'moment';
import fileDownload from 'js-file-download';

import { ListGroup, } from 'react-bootstrap';
import { Notification, } from 'rsuite';
import { Collapse, } from '@material-ui/core';

import {
  getHistoricoObservacoes,
  updateImpactOrProbability,
  setQuestionAsAnswered,
  setQuestionAsNotAnswered,
  updateQuestionMaturityLevel,
  createQuestionObservation,
  changeQuestionQuickActionFlag,
  deleteQuestionObservation,
  addQuestionEvidence,
  downloadEvidence,
} from '../../services/api';

import { showToast } from '../../ui-lib/toast'
import 'react-toastify/dist/ReactToastify.css'

import {
  QUESTION_CHANGE_REASONS,
  QUESTION_STATUSES,
} from '../../utils/constants';
import Risk, { RISK_STATUSES, } from '../../core/entities/risk';

import { selectUserById, selectUsers, } from '../../features/usersSlice';
import { selectCompany, } from '../../features/companySlice';
import { selectCollaboratorById, selectCollaborators, } from '../../features/collaboratorsSlice';
import { selectCompanySectorById, } from '../../features/companySectorsSlice';
import { selectCompanyCategoryById, } from '../../features/companyCategoriesSlice';

import QuestionItemHeader from './QuestionItemHeader';
import QuestionItemCard from './QuestionItemCard';

import { getUserId, } from '../../utils';


const CorpoResposta = ({ data: _data, }) => {
  const { _id: companyId, } = useSelector(selectCompany);
  const userId = getUserId();
  const [data, setData] = useState(_data);
  const user = useSelector((state) => selectUserById(state, data.user_id));
  const users = useSelector(selectUsers);
  const sector = useSelector((state) => selectCompanySectorById(state, data.sector_id));
  const category = useSelector((state) => selectCompanyCategoryById(state, data.category_id));
  const collaborator = useSelector((state) => selectCollaboratorById(state, data.collaborator_id));
  const collaborators = useSelector(selectCollaborators);
  const { t, } = useTranslation();
  const [observations, setObservations] = useState([]);
  const [openList, setOpenList] = useState(false);
  const [loadingButton, setLoadingButton] = useState(false);
  const [questionStatusData, setQuestionStatusData] = useState(QUESTION_STATUSES.UNANSWERED);
  const [questionRiskStatus, setQuestionRiskStatus] = useState(RISK_STATUSES.UNKNOWN);
  const [obs, setObs] = useState('');


  async function getData() {
    try {
      const { data: obs, } = await getHistoricoObservacoes(data.id);
      setObservations(obs);
    } catch {
      //? drop error
    }
  }

  function updateData(key, value) {
    setData((data) => ({
      ...data,
      [key]: value,
    }));
  }

  async function updateQuestionIntes(step, value) {
    updateData(step, value);

    try {
      await updateImpactOrProbability(data.id, value, step);
      getData();
      Notification['success']({
        placement: 'bottomEnd',
        title: `${_.capitalize(step)} ${ 'probabilidade' === step ? 'alterada' : 'alterado' } com sucesso!`,
      });
    } catch {
      Notification['error']({
        placement: 'bottomEnd',
        title: `Falha ao alterar ${step}`,
        description: _.capitalize(t('ERRORS.try_again_later')),
      });
    }
  }

  /**
   * Use effect t update question status indicators
   */
  useEffect(() => {
    let statusData = null;
    if (!data.reg_resposta) {
      statusData = QUESTION_STATUSES.UNANSWERED;
    } else if (data.reg_resposta && data.resposta) {
      statusData = QUESTION_STATUSES.COMPLIANT;
    } else if (data.reg_resposta) {
      statusData = QUESTION_STATUSES.ANSWERED_NOT_COMPLIANT;
    }

    setQuestionStatusData(statusData);
  }, [data.reg_resposta, data.resposta]);

  /**
   * Use effect to update probability and impact
   */
  useEffect(() => {
    const updatedData = { ...data, ..._data, };
    setData(updatedData);
  }, [_data]);

  useEffect(() => {
    if ((undefined != data.probabilidade) && (undefined != data.impacto)) {
      setQuestionRiskStatus(Risk.getRisk(data.probabilidade, data.impacto));
    }
  }, [data]);

  const toggleChecked = () => {
    const updated = !data.rapida;
    updateData('rapida', updated);
    updateRapida(updated);
  }

  async function updateRapida(value) {
    let messageType = 'success';
    let message = 'Tarefa marcada como rápida';

    try {
      await changeQuestionQuickActionFlag(companyId, data.id, value);
      getData();
    } catch {
      messageType = 'error';
      message = `Erro ao registrar ação rapida`;
    }

    return showToast({
      type: messageType,
      message,
      pauseOnFocusLoss: true,
    });
  }

  async function updateResult(apiCall, updatedStatus) {
    setLoadingButton(true);

    let messageType = 'success';
    let message = 'Tarefa respondida com sucesso';

    try {
      await apiCall(companyId, data.id, obs);
      setQuestionStatusData(updatedStatus);
      setData((data) => ({
        ...data,
        user_id: userId,
        changed_at: moment.utc(),
        collaborator_id: null,
        change_reason: QUESTION_STATUSES.COMPLIANT.status == updatedStatus.status ?
          QUESTION_CHANGE_REASONS.SET_AS_COMPLIANT : QUESTION_CHANGE_REASONS.SET_AS_IN_REVIEW,
      }));
      getData();
    } catch (err) {
      messageType = 'error';
      message = 'Resposta não gravada';
    }

    showToast({
      type: messageType,
      message,
      pauseOnFocusLoss: true,
    });

    setLoadingButton(false);
  }

  const insertObs = () => {
    createQuestionObservation(companyId, data.id, obs).then(() => {
      Notification['success']({
        placement: 'bottomEnd',
        title: _.capitalize(t('PAGES.QUESTION_ITEM_CARD.observation_successfully_registered')),
      });
      getData();
      setObs('');
    }).catch(() => {
      Notification['error']({
        placement: 'bottomEnd',
        title: _.capitalize(t('ERRORS.QUESTION_ITEM_CARD.failed_to_register_observation')),
        description: _.capitalize(t('ERRORS.try_again_later')),
      });
    });
  }

  function toggleOpenList() {
    const _openList = !openList;
    if (_openList) {
      getData();
    }
    setOpenList(_openList);
  }

  async function updateMaturityLevel(level) {
    try {
      updateData('category_maturity_level', Number(level));
      await updateQuestionMaturityLevel(companyId, data.id, Number(level));
      Notification['success']({
        placement: 'bottomEnd',
        title: _.capitalize(t('PAGES.QUESTION_ITEM_CARD.question_maturity_level_successfully_updated')),
      });
    } catch {
      Notification['error']({
        placement: 'bottomEnd',
        title: _.capitalize(t('ERRORS.QUESTION_ITEM_CARD.failed_to_update_question_maturity_level')),
        description: _.capitalize(t('ERRORS.try_again_later')),
      });
    }
  }

  async function deleteObservation(questionId, obsId) {
    try {
      await deleteQuestionObservation(companyId, questionId, obsId);

      Notification['success']({
        placement: 'bottomEnd',
        title: _.capitalize(t('PAGES.QUESTION_ITEM_CARD.observation_successfully_deleted')),
      });

      setObservations(observations.filter(({ id, }) => id != obsId));
    } catch (err) {
      if ((undefined != err.response) && (403 == err.response.status)) {
        Notification['error']({
          placement: 'bottomEnd',
          title: _.capitalize(t('ERRORS.not_allowed')),
        });
      } else {
        Notification['error']({
          placement: 'bottomEnd',
          title: _.capitalize(t('ERRORS.QUESTION_ITEM_CARD.failed_to_delete_observation')),
          description: _.capitalize(t('ERRORS.try_again_later')),
        });
      }
    }
  }

  async function onAttachEvidence(questionId, file) {
    try {
      const response = await addQuestionEvidence(companyId, questionId, file);
      setData(response.data);

      Notification['success']({
        placement: 'bottomEnd',
        title: _.capitalize(t('PAGES.EVIDENCE_MANAGEMENT.evidence_successfully_attached')),
      });
    } catch {
      Notification['error']({
        placement: 'bottomEnd',
        title: _.capitalize(t('ERRORS.EVIDENCE_MANAGEMENT.failed_to_attach_evidence')),
        description: _.capitalize(t('ERRORS.try_again_later')),
      });
    }
  }

  async function onDownloadEvidence(evidenceId) {
    try {
      const response = await downloadEvidence(evidenceId);

      const headerLine = response.headers['content-disposition'];
      const startIdx = headerLine.indexOf('"') + 1;
      const endIdx = headerLine.lastIndexOf('"');
      const filename = headerLine.substring(startIdx, endIdx);

      fileDownload(response.data, filename);

      Notification['success']({
        placement: 'bottomEnd',
        title: _.capitalize(t('PAGES.EVIDENCE_MANAGEMENT.evidence_successfully_downloaded')),
      });
    } catch {
      Notification['error']({
        placement: 'bottomEnd',
        title: _.capitalize(t('ERRORS.EVIDENCE_MANAGEMENT.failed_to_download_evidence')),
        description: _.capitalize(t('ERRORS.try_again_later')),
      });
    }
  }


  return (
    <ListGroup>
      <ListGroup.Item
        onClick={toggleOpenList}
        active={openList}
      >
        <QuestionItemHeader
          data={data}
          open={openList}
          questionStatus={questionStatusData}
          sector={sector != undefined ? sector.name : 'N/A'}
        />
      </ListGroup.Item>

      <Collapse
        in={openList}
        unmountOnExit
      >
        <QuestionItemCard
          obs={obs}
          data={data}
          user={user}
          users={users}
          loading={loadingButton}
          collaborator={collaborator}
          collaborators={collaborators}
          observations={observations}
          questionStatus={questionStatusData}
          questionRiskStatus={questionRiskStatus}
          category={category != undefined ? category.name : 'N/A'}
          onUpdateProbability={(value) => updateQuestionIntes('probabilidade', value)}
          onUpdateImpact={(value) => updateQuestionIntes('impacto', value)}
          onUpdateMaturityLevel={updateMaturityLevel}
          onSubmitReview={() => updateResult(setQuestionAsNotAnswered, QUESTION_STATUSES.ANSWERED_NOT_COMPLIANT)}
          onSubmitCompliant={() => updateResult(setQuestionAsAnswered, QUESTION_STATUSES.COMPLIANT)}
          onInsertObs={insertObs}
          onToggleFastActionItem={toggleChecked}
          setObs={setObs}
          onDeleteObservation={deleteObservation}
          onAttachEvidence={onAttachEvidence}
          onDownloadEvidence={onDownloadEvidence}
        />
      </Collapse>
      <br />
    </ListGroup>
  );
}

export default CorpoResposta;
