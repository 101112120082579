import { useEffect, useState } from 'react'
import { useDispatch, useSelector, } from 'react-redux';
import { Grid, Typography, Box } from '@material-ui/core'
import { Icon, InputGroup, Input } from 'rsuite';
import _ from 'lodash';

import {
  loadingFalse,
  loadingTrue,
  setItens,
} from '../../features/responderPerguntas';
import {
  getPerguntasOrdem,
  getPerguntasBySearch,
  getPerguntasBySetor,
} from '../../services/api';
import Dropdown from '../Dropdown';
import {
  selectCompanySectors,
} from '../../features/companySectorsSlice';


export default function Header({ showTitle = true }) {
  const dispatch = useDispatch();
  const companySectors = useSelector(selectCompanySectors);
  const [widthSearch, setWidthSearch] = useState('400px');
  const [selectedSectorId, setSelectedSectorId] = useState(null);


  async function fetchQuestions(fetcher, ...args) {
    dispatch(loadingTrue());

    try {
      const { data, } = await fetcher(...args);
      dispatch(setItens(data));
    } catch {
      //TODO: show error
    }

    dispatch(loadingFalse());
  }

  async function getPerguntas() {
    fetchQuestions(getPerguntasOrdem);
  }

  async function searchQuestion(value) {
    fetchQuestions(getPerguntasBySearch, value);
  }

  async function getQuestionsBySector(value) {
    if (_.isString(value) && (0 === value.length)) {
      setSelectedSectorId(null);
    } else {
      setSelectedSectorId(value);
      fetchQuestions(getPerguntasBySetor, value);
      return;
    }

    getPerguntas();
  }

  useEffect(() => {
    if (window.screen.width <= 1370) {
      setWidthSearch('280px');
    } else if (window.screen.width <= 1200) {
      setWidthSearch('180px');
    } else {
      setWidthSearch('400px');
    }
  });


  return (
    <>
      <Grid
        item
        xs={12}
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          marginBottom: 30,
        }}
      >
        {showTitle &&
          <Typography
            variant="h4"
            style={{
              width: '35%',
              color: '#4D5884',
              fontSize: '2rem',
            }}
          >
            Responder checklist
          </Typography>
        }
        <div
          style={{
            display: 'flex',
            width: '100%',
            alignItems: 'flex-end',
            justifyContent: 'space-between',
          }}
        >
          <Grid item style={{ marginRight: 15 }}>
            <InputGroup
              inside
              style={{
                display: 'flex',
                alignItems: 'center',
                width: `${widthSearch}`,
                height: 30,
                padding: '3px',
              }}
              onChange={(e) => {
                searchQuestion(e.target.value)
              }}
            >
              <Input placeholder="Pesquisar por tarefa" />
              <InputGroup.Button loading={false}>
                <Icon icon="search" />
              </InputGroup.Button>
            </InputGroup>
          </Grid>
          <Dropdown
            defaultItem="Escolha um setor"
            items={companySectors}
            value={selectedSectorId}
            setValue={getQuestionsBySector}
          />
        </div>
      </Grid>
    </>
  );
}
