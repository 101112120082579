import React, { useLayoutEffect, useRef, useState, } from 'react';
import { useDispatch, useSelector, } from 'react-redux';
import _ from 'lodash';
import { useTranslation, } from 'react-i18next';

import {
  Dialog,
  DialogTitle,
  DialogContent,
  makeStyles,
  Typography,
  Container,
  Grid,
  TextField,
  // Select,
  // MenuItem,
  // InputLabel,
  // FormControl,
  // FormHelperText,
  CircularProgress,
} from '@material-ui/core';
import { Formik, } from 'formik';
import { Notification, Button, } from 'rsuite';

import QuestionForm from './QuestionsForm';
import DeleteDialog from '../DeleteDialog';
import LimitedBackdrop from '../LimitedBackdrop';

import useModal from '../../hooks/useModal';

import { camelCaseObjectKeys, } from '../../utils';
import { COURSE_LEVELS, CRUD_STATUSES, } from '../../utils/constants';

import {
  createCourse,
  selectCourseById,
  updateCourse,
} from '../../features/courseSlice';
import { createCourseSchema, } from '../../validation-schemas';


const useStyles = makeStyles({
  header: {
    backgroundColor: '#3B66FF',
  },
  headerTitle: {
    color: 'white',
  },
  thumbnail: {
    width: '100%',
    objectFit: 'cover',
    height: '150px',
    display: 'block',
    marginBottom: '1rem',
  },
  formControl: {
    width: '100%',
    minWidth: 120,
  },
  examQuestionsSectionTitle: {
    marginTop: '1rem',
    marginBottom: '0.25rem',
  },
  submitButton: {
    marginLeft: '1rem',
  },
});

const INITIAL_VALUES = Object.freeze({
  title: '',
  duration: '',
  level: '',
  description: '',
  videoUrl: '',
  test: {
    questions: [
      {
        statement: '',
        alternatives: [
          {
            isCorrect: true,
            statement: '',
          },
          {
            isCorrect: false,
            statement: '',
          },
        ],
      },
    ],
  }
});

const EDIT_COURSE_INITIAL_VALUES = Object.freeze({
  test: {
    questions: [],
  }
});

const NOTIFICATIONS = Object.freeze({
  CREATE: {
    success: {
      title: 'Curso criado com sucesso!',
    },
    error: {
      title: 'Falha ao criar o curso!',
      description: 'Tente novamente dentro de alguns instantes.'
    }
  },
  EDIT: {
    success: {
      title: 'Curso atualizado com sucesso!',
    },
    error: {
      title: 'Falha ao editar o curso!',
      description: 'Tente novamente dentro de alguns instantes.'
    }
  },
});


export const CourseModal = ({ courseId, handleClose, open, ...props }) => {
  const { t, } = useTranslation();
  const classes = useStyles();
  const [
    cancelConfirmationModalOpen,
    openCancelConfirmationModal,
    closeCancelConfirmationModal
  ] = useModal();
  const dispatch = useDispatch();
  const course = useSelector((state) => selectCourseById(state, courseId));
  const loadingCourses = useSelector((state) => {
    const { status, } = state.courses;
    return (CRUD_STATUSES.FETCH_SUCCESSFUL !== status) &&
    (CRUD_STATUSES.FETCH_FAILED !== status)
  });

  const [loading, setLoading] = useState(false);
  const [headerText, setHeaderText] = useState('Adicionar novo curso');

  const formikRef = useRef(null);


  /**
   * Save course in API
   */
  const saveCourse = async (data) => {
    setLoading(true);

    const courseData = camelCaseObjectKeys(_.pick(_.cloneDeep(data), [
      'id',
      ...Object.keys(INITIAL_VALUES)
    ]));
    const isEdit = undefined != courseData.id;
    const requestInfo = isEdit ? NOTIFICATIONS.EDIT : NOTIFICATIONS.CREATE;

    if (!isEdit) {
      courseData.level = COURSE_LEVELS.BASIC.value;
      courseData.duration = 0;
    }

    const questions = courseData.test.questions;
    for (let questionIdx = 0; questionIdx < questions.length; questionIdx++) {
      questions[questionIdx] = camelCaseObjectKeys(questions[questionIdx]);
      for (let altIdx = 0; altIdx < questions[questionIdx].alternatives.length; altIdx++) {
        questions[questionIdx].alternatives[altIdx].index = altIdx;
        questions[questionIdx].alternatives[altIdx] =
          camelCaseObjectKeys(questions[questionIdx].alternatives[altIdx]);
      }
    }

    try {
      await dispatch(isEdit ? updateCourse(courseData) : createCourse(courseData)).unwrap();
      Notification['success']({
        placement: 'bottomEnd',
        title: requestInfo.success.title,
      });

      clearModal();
    } catch {
      Notification['error']({
        placement: 'bottomEnd',
        title: requestInfo.error.title,
        description: requestInfo.error.description,
      });
    } finally {
      setLoading(false);
    }
  }

  /**
   * Clear modal
   */
  const clearModal = () => {
    closeCancelConfirmationModal();
    handleClose();
    formikRef.current.resetForm(
      {
        values: INITIAL_VALUES,
      },
    );
  }

  useLayoutEffect(() => {
    if (formikRef.current != null) {
      formikRef.current.resetForm({
        values: course != undefined ? {
          ...EDIT_COURSE_INITIAL_VALUES,
          ...course,
          thumbnailUrl: course.thumbnail_url,
          videoUrl: course.video_url,
        } : INITIAL_VALUES,
      });
    }
  }, [course]);

  useLayoutEffect(() => {
    if (open) {
      setHeaderText(undefined != courseId ? 'Editar curso' : 'Adicionar novo curso');
    }
  }, [courseId]);


  return (
    <Formik
      initialValues={INITIAL_VALUES}
      onSubmit={saveCourse}
      validationSchema={createCourseSchema}
      innerRef={formikRef}
    >
    {
      (formik) => {
        return (
          <Dialog
            open={open}
            scroll="body"
            {...props}
          >
            <DialogTitle
              className={classes.header}
              disableTypography
            >
              <Typography
                variant="h5"
                display="block"
                className={classes.headerTitle}
              >
                { headerText }
              </Typography>
            </DialogTitle>

            <DialogContent>
              <Container disableGutters >
                <LimitedBackdrop
                  open={loadingCourses}
                >
                  <CircularProgress />
                </LimitedBackdrop>

                <TextField
                  margin="dense"
                  label="Nome do curso"
                  fullWidth
                  id="title"
                  type="text"
                  error={formik.errors.title && formik.touched.title}
                  helperText={formik.touched.title && formik.errors.title}
                  {...formik.getFieldProps('title')}
                />
                {/*
                <TextField
                  margin="dense"
                  label="Duração total em horas"
                  fullWidth
                  id="duration"
                  type="number"
                  error={formik.errors.duration && formik.touched.duration}
                  helperText={formik.touched.duration && formik.errors.duration}
                  {...formik.getFieldProps('duration')}
                />

                  <FormControl
                    className={classes.formControl}
                    error={formik.errors.level && formik.touched.level}
                  >
                    <InputLabel
                      id="course-level-select-label"
                    >
                      Dificuldade
                    </InputLabel>
                    <Select
                      labelId="course-level-select-label"
                      id="level"
                      {...formik.getFieldProps('level')}
                    >
                      {
                        COURSE_LEVELS_ARRAY.map(({value, label}) => {
                          return (
                            <MenuItem
                              key={value}
                              value={value}
                            >
                              { label }
                            </MenuItem>
                          );
                        })
                      }
                    </Select>
                    {
                      formik.touched.level && formik.errors.level ?
                      <FormHelperText>
                        { formik.errors.level }
                      </FormHelperText> :
                      null
                    }
                  </FormControl> */}

                <TextField
                  margin="dense"
                  label="Descrição"
                  fullWidth
                  multiline
                  maxRows={5}
                  id="description"
                  type="text"
                  error={formik.errors.description && formik.touched.description}
                  helperText={formik.touched.description && formik.errors.description}
                  {...formik.getFieldProps('description')}
                />

                <TextField
                  margin="dense"
                  label="Link da aula (compatível com incorporação em iframe)"
                  fullWidth
                  id="videoUrl"
                  type="text"
                  error={formik.errors.videoUrl && formik.touched.videoUrl}
                  helperText={formik.touched.videoUrl && formik.errors.videoUrl ? formik.errors.videoUrl : 'https://www.youtube.com/embed/UDIT5WV6abU?si=fAIAkKuqalNre889' }
                  {...formik.getFieldProps('videoUrl')}
                />

                <Typography
                  variant="h6"
                  display="block"
                  className={classes.examQuestionsSectionTitle}
                >
                  Configurar prova final
                </Typography>

                <QuestionForm />

                <Grid
                  container
                  alignItems="center"
                  justifyContent="flex-end"
                >
                  <Button
                    appearance="ghost"
                    loading={loading}
                    onClick={openCancelConfirmationModal}
                  >
                    Cancelar
                  </Button>

                  <Button
                    className={classes.submitButton}
                    appearance="primary"
                    loading={loading}
                    onClick={formik.handleSubmit}
                  >
                    Salvar
                  </Button>
                </Grid>
              </Container>
            </DialogContent>

            <DeleteDialog
              open={cancelConfirmationModalOpen}
              confirmationMessage={t('PAGES.COURSE_MANAGEMENT.cancel_without_saving_confirmation')}
              onClose={closeCancelConfirmationModal}
              onConfirm={clearModal}
            />
          </Dialog>
        );
      }
    }
    </Formik>
  );
}
