import React, { useEffect, useState } from "react";
import { makeStyles, Button, TextField, } from '@material-ui/core';
import { Notification, } from 'rsuite';
import TimeLineCustom from "../../components/TimeLine/TimeLineCustom";
import { getAffiliatesLink } from "../../services/api";

let devLink;

function removeTrailingSlash(devLink) {
  if (devLink.endsWith('/')) {
    return devLink.slice(0, -1);
  }
  return devLink;
}

switch (window.location.host) {
  case 'localhost:3000':
    devLink = process.env.REACT_APP_API_LOCAL

    break
  case 'dev.octo.legal':
    devLink = 'https://apidev.octo.legal/'
    break
  case 'homologacao.octo.legal':
    devLink = 'https://apihomologacao.octo.legal/'
    break
  case 'octo.legal':
    devLink = process.env.REACT_APP_API_PROD;
  default:
    devLink = process.env.REACT_APP_API_PROD;
}

export default function AffiliatesPage() {

  const classes = styles();

  const [link, setLink] = useState();
  const userId = localStorage.getItem('user');

  const Btn = () => {
    return (
      <div className={classes.container}>
        <Button onClick={onCopyScript} className={classes.btnLink} variant="contained" color="primary">
          Copiar link
        </Button>
      </div>
    )
  }

  const Input = () => {
    return (<TextField disabled className={classes.inputLink} defaultValue={link} label="Link afiliado" variant="outlined" size="small" />)
  }

  const onCopyScript = async () => {
    await navigator.clipboard.writeText(link);
    Notification['success']({
      placement: 'bottomEnd',
      title: 'Código copiado com sucesso!',
    });
  }

  const Title = ({ title }) => {
    return (
      <h5 style={{ textAlign: "center" }}>{title}</h5>
    )

  }

  const ITENS = Object.freeze({
    'component1': {
      id: 'component1',
      topComponent: <Title title='Seu link exclusivo' />,
      bootomComponent: <Btn />,
    },
    'component2': {
      id: 'component2',
      topComponent: <Title title='Divulgue a octo usando seu link' />,
      bootomComponent: <Input />,
    },
    'component3': {
      id: 'component3',
      topComponent: <Title title='Acompanhe suas indicações' />,
      bootomComponent: '',
    },
  }
  );

  async function getAffiliatedLink() {
    try {
      const getAffiliatedLinkRes = await getAffiliatesLink(userId);
      setLink(`${removeTrailingSlash(devLink)}/al/${getAffiliatedLinkRes.data.id}`);
    } catch (e) {
      Notification['error']({
        placement: 'bottomEnd',
        title: `Falha ao buscar o link de Afiliado!`,
        description: 'Tente novamente dentro de alguns instantes.'
      });
    }
  }

  const initialize = async () => {
    await getAffiliatedLink();
  }

  useEffect(() => {
    initialize();
  }, []);

  return (
    <div>
      <TimeLineCustom itens={ITENS} />
    </div>
  )
}


const styles = makeStyles(() => ({
  container: {
    display: "flex",
    justifyContent: "center"
  },
  btnLink: {
    marginLeft: '20px'
  }
}));
