import { createSlice, createAsyncThunk, } from '@reduxjs/toolkit';
import _ from 'lodash';
import { Notification, } from 'rsuite';

import * as api from '../services/api';
import { CRUD_STATUSES, } from '../utils/constants';


const initialState = {
  companySectors: [],
  companySectorsMap: {},
  status: CRUD_STATUSES.IDLE,
  managementModalOpen: false,
};


export const listCompanySectors = createAsyncThunk('companySectors/fetch', async (_, thunkAPI) => {
  const { data, status, } = await api.listCompanySectors(thunkAPI.getState().company.company._id);
  if (200 === status) {
    return data.sectors;
  } else {
    Notification['warning']({
      placement: 'bottomEnd',
      title: 'Falha ao carregar setores!',
      description: 'Tente novamente dentro de alguns instantes.'
    });
  }
});

export const createCompanySector = createAsyncThunk('companySectors/create', async (companySector, thunkAPI) => {
  const { data, } = await api.createCompanySector(thunkAPI.getState().company.company._id, companySector);
  return data;
});


export const updateCompanySector = createAsyncThunk('companySectors/update', async (companySector, thunkAPI) => {
  const { data, } = await api.updateCompanySector(thunkAPI.getState().company.company._id, companySector.id, companySector);
  return data;
});


export const deleteCompanySector = createAsyncThunk('companySectors/delete', async (companySectorId, thunkAPI) => {
  try {
    await api.deleteCompanySector(thunkAPI.getState().company.company._id, companySectorId);
    return companySectorId;
  } catch (err) {
    return {
      error: err.response.data.error,
      code: err.response.data.code,
      status: err.response.status,
    };
  }
});


export const companySectorsSlice = createSlice({
  name: 'companySectors',
  initialState,
  reducers: {
    setStatus: (state, { payload: status, }) => {
      if (status != null) {
        state.status = status;
      } else {
        state.status = initialState.status;
      }
    },
    setCompanySectorsModal(state, { payload, }) {
      state.managementModalOpen = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(listCompanySectors.pending, (state) => {
      state.status = CRUD_STATUSES.LOADING;
    }).addCase(listCompanySectors.fulfilled, (state, { payload: companySectors, }) => {
      state.status = CRUD_STATUSES.FETCH_SUCCESSFUL;
      state.companySectors = companySectors;
      state.companySectorsMap = Object.fromEntries(companySectors.map((companySector) => {
        return [companySector.id, companySector];
      }));
    }).addCase(listCompanySectors.rejected, (state) => {
      state.status = CRUD_STATUSES.FETCH_FAILED;
      state.companySectors = initialState.companySectors;
      state.companySectorsMap = initialState.companySectorsMap;
    });

    builder.addCase(createCompanySector.fulfilled, (state, { payload: companySector, }) => {
      state.companySectors.push(companySector);
      state.companySectorsMap[companySector.id] = companySector;
    });

    builder.addCase(updateCompanySector.fulfilled, (state, { payload: companySector, }) => {
      const index = state.companySectors.findIndex(({ id, }) => id === companySector.id);
      if (index > -1) {
        state.companySectors[index] = companySector;
        state.companySectorsMap[companySector.id] = companySector;
      }
    });

    builder.addCase(deleteCompanySector.fulfilled, (state, { payload: companySectorId, }) => {
      const index = state.companySectors.findIndex(({ id, }) => id === companySectorId);
      if (index > -1) {
        state.companySectors.splice(index, 1);
        delete state.companySectorsMap[companySectorId];
      }
    });
  }
});

/**
 * Custom select to get status
 *
 * @param {object} state - company sectors slice state
 *
 * @returns the feature status
 */
export const selectFeatureStatus = (state) => {
  return state.companySectors.status;
}

/**
 * Custom select to get company sectors
 *
 * @param {object} state - company sectors slice state
 *
 * @returns companySectors
 */
export const selectCompanySectors = (state) => {
  return state.companySectors.companySectors;
}

/**
 * Custom select to get company sectors by ID
 *
 * @param {object} state - company sectors slice state
 * @param {number} value - sector ID
 *
 * @returns companySectors
 */
export const selectCompanySectorById = (state, value) => {
  return state.companySectors.companySectorsMap[value];
}

/**
 * Custom select to get company sectors management modal state
 *
 * @param {object} state - company sectors slice state
 *
 * @returns company sectors management modal state
 */
export const selectCompanySectorModalState = (state) => {
  return state.companySectors.managementModalOpen;
}

export const {
  setStatus,
  setCompanySectorsModal,
} = companySectorsSlice.actions;
export default companySectorsSlice.reducer;
