import React from 'react';

import { makeStyles, Grid, Typography, IconButton, } from '@material-ui/core';
import { ArrowBackIos, ArrowForwardIos, } from '@material-ui/icons';

import useDebounce from '../../../hooks/useDebounce';


const useStyles = makeStyles({
  defaultColor: {
    color: '#54708C',
  },
});


function PrintModeReportPaginator({ page, totalPages, onChangePage, style, }) {
  const classes = useStyles();
  const [value, setValue] = React.useState(page);
  const _onChangePage = useDebounce(onChangePage, 300);


  React.useEffect(() => {
    setValue(page);
  }, [page]);

  React.useEffect(() => {
    _onChangePage(value);
  }, [value]);

  function onPrevious() {
    setValue((value) => value - 1);
  }

  function onNext() {
    setValue((value) => value + 1);
  }


  return (
    <Grid
      container
      justifyContent="flex-end"
      alignItems="center"
      style={style}
    >
      <Typography
        variant="body1"
        className={classes.defaultColor}
        style={{
          marginRight: 8,
        }}
      >
        { `página ${value + 1} de ${totalPages}` }
      </Typography>

      <IconButton
        aria-label="previous"
        onClick={onPrevious}
        disabled={value <= 0}
        size="small"
      >
        <ArrowBackIos className={classes.defaultColor} />
      </IconButton>

      <IconButton
        aria-label="next"
        onClick={onNext}
        disabled={value >= (totalPages - 1)}
        size="small"
      >
        <ArrowForwardIos className={classes.defaultColor} />
      </IconButton>
    </Grid>
  );
}


export default PrintModeReportPaginator;
