import React, { useEffect, useState } from "react";
import Tab from "./Tab";
import { makeStyles, Typography, ButtonBase, Breadcrumbs, Link, Badge } from "@material-ui/core";
import CustomBreadcrumbs from "../CustomBreadcrumbs";

export default function Sheet({ children, pages, topChildrenComponent, bootomChildrenComponent, outsideTopComponent, outsideBottomComponent, outsideLeftComponent, outsideRightComponent, calbackItemMenu, callbackSubMenu, bgColor = '#fff' }) {

  const [componentSelect, setComponentSelect] = useState(pages[pages.length - 1].component ? pages[pages.length - 1] : pages[pages.length - 1].subMenu)
  const [componentSelectSubMenu, setComponentSelectSubMenu] = useState(null);
  const [saveStateSubMenus, setSaveStateSubMenus] = useState({});

  function setHistorySubMenus(itemSubMenu) {
    setSaveStateSubMenus({
      ...saveStateSubMenus,
      [componentSelect.id]: {
        itemSubMenuId: itemSubMenu,
      }
    })

    setComponentSelectSubMenu(componentSelect.subMenu[itemSubMenu]);
  }

  useEffect(() => {

    if (!componentSelect?.subMenu) {
      return;
    }

    if (!!Object.keys(saveStateSubMenus)?.length && saveStateSubMenus[componentSelect.id]?.hasOwnProperty('itemSubMenuId')) {
      return;
    }

    setHistorySubMenus(Object.values(componentSelect.subMenu)[0].id);
    setComponentSelectSubMenu(Object.values(componentSelect.subMenu)[0]);

  }, [componentSelect]);

  function handllerSetComponentSelect(page) {
    setComponentSelect(page);
    if (calbackItemMenu && typeof calbackItemMenu === 'function') {
      calbackItemMenu(page);
    }
  }

  function handlerSetComponentSelectSubMenu(page) {
    if (page?.component) {
      setComponentSelectSubMenu(null);
      return;
    }

    if (!page?.component) {
      setComponentSelectSubMenu(page.subMenu[Object.keys(page.subMenu)[0]]);

    }
  }

  const className = styles({ bgColor });

  const MapMenu = () => {
    return (
      <div className={className.divMenu}>
        {pages.map((page, index) => {
          return (
            <div key={index+''} style={{ position: "relative" }}>
              {page?.badge ?
                <Badge badgeContent={page.badge} color="primary">
                  <Tab
                    title={page.titleTab}
                    isActive={componentSelect.id === page.id}
                    setSelectedObjectMenu={() => { handllerSetComponentSelect(page), handlerSetComponentSelectSubMenu(page) }}
                  />
                </Badge>
                :
                <Tab
                  title={page.titleTab}
                  isActive={componentSelect.id === page.id}
                  setSelectedObjectMenu={() => { handllerSetComponentSelect(page), handlerSetComponentSelectSubMenu(page) }}
                />
              }
              {componentSelect.id === page.id && componentSelect?.subMenu &&
                <div className={className.divSubMenu}>
                  <div className={className.internalSubMenu}>
                    <div style={{ display: "flex", flexDirection: "row" }}>
                      {Object.values(componentSelect?.subMenu).map(({ id, titleTab }) => {
                        return (
                          <ButtonBase onClick={() => {
                            setHistorySubMenus(id), callbackSubMenu(id)
                          }}>
                            <Typography className={className.textBtn} style={{ textDecoration: componentSelectSubMenu?.id == id ? 'underline' : 'none' }}>
                              {titleTab}
                            </Typography>
                          </ButtonBase>
                        )
                      })
                      }
                    </div>
                  </div>
                </div>
              }
            </div>
          )
        })}
      </div>
    )
  }

  return (
    <div className={className.containerMain}>
      <CustomBreadcrumbs />
      {outsideTopComponent && outsideTopComponent}
      <div className={className.containerRow}>
        {outsideLeftComponent && outsideLeftComponent}

        <div className={className.containerCenter}>
          {pages && pages.length > 1 && <MapMenu />}
          <div className={className.sheet}>
            <div className={className.internalSheet}>
              {topChildrenComponent && topChildrenComponent}

              {!pages && children}
              {(componentSelect?.component && !componentSelectSubMenu?.component) && componentSelect.component}
              {(componentSelect?.component && componentSelectSubMenu?.component) && componentSelectSubMenu?.component}
              {(!componentSelect?.component && componentSelectSubMenu?.component) && componentSelectSubMenu?.component}

              {bootomChildrenComponent && bootomChildrenComponent}
            </div>
          </div>
        </div>

        {outsideRightComponent && outsideRightComponent}

      </div>
      {outsideBottomComponent && outsideBottomComponent}
    </div>
  )
}

const styles = makeStyles(() => ({
  containerMain: {
    display: "flex",
    flexDirection: "column",
    padding: '20px'
  },
  sheet: {
    background: props => props.bgColor,
    borderRadius: "10px"
  },
  internalSheet: {
    padding: "20px"
  },
  divMenu: {
    display: "flex",
    flexDirection: "row-reverse",
    width: "100%",
    justifyContent: "center"
  },
  divSubMenu: {
    position: 'absolute',
    right: 0,
    top: '100%'
  },
  internalSubMenu: {
    background: "#fff",
    borderBottomRightRadius: "10px",
    borderBottomLeftRadius: "10px",
    border: "1px solid rgba(59, 102, 255, 1)",
    boxShadow: '0 4px 2px -2px gray'
  },
  textBtn: {
    display: "flex",
    fontWeight: "500",
    color: "rgba(84, 112, 140, 1)",
    whiteSpace: "nowrap",
    padding: "10px 20px",
    fontWeight: "800",
    textUnderlineOffset: '5px'
  },
  containerCenter: {
    width: "100%"
  },
  containerRow: {
    display: "flex",
    position: "relative"
  }
}));
