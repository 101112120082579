import React, { useState, } from 'react';
import {
  Container,
  Paper,
  makeStyles,
  List,
  ListItem,
  ListItemText,
  Collapse,
  Divider,
  Typography,
} from '@material-ui/core';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import { useSelector, } from 'react-redux';

import CollaboratorsCourseProgress from './CollaboratorsCourseProgress';
import CourseLevelChip from '../../components/DPO/CourseLevelChip';


const useStyles = makeStyles({
  pageActions: {
    marginBottom: '1rem',
  },
  contentContainer: {
    paddingTop: '1rem',
    paddingBottom: '1rem',
  },
  selectedListItem: {
    backgroundColor: '#1C265A !important',
    color: 'white !important',
  },
});


export default function TrainingsDashboard() {
  const classes = useStyles();
  const [openedCourse, setOpenedCourse] = useState(null);
  const { courses, } = useSelector((state) => state.courses);


  function handleCourseClick(courseId) {
    setOpenedCourse(courseId === openedCourse ? null : courseId)
  }


  return (
    <Container
    className={classes.contentContainer}
    fullWidth
    maxWidth="xl"
    component={Paper}
    >
      {
        courses.length === 0 ?
        <Typography
          variant="body1"
          display="block"
          align="center"
        >
          Você ainda não possui cursos
        </Typography> :
        <List>
          {
            courses.map((course) => {
              const open = openedCourse === course.id;

              return (
                <div key={course.id}>
                  <ListItem
                    button
                    classes={{
                      selected: classes.selectedListItem,
                    }}
                    selected={course.id === openedCourse}
                    onClick={() => handleCourseClick(course.id)}
                  >
                    <ListItemText primary={course.title} />
                    <CourseLevelChip
                      level={course.level}
                      style={{
                        marginRight: '2rem',
                      }}
                    />
                    {open ? <ExpandLess /> : <ExpandMore />}
                  </ListItem>

                  <Collapse
                    in={open}
                    timeout="auto"
                    unmountOnExit
                  >
                    <CollaboratorsCourseProgress course={course} />
                  </Collapse>
                  <Divider />
                </div>
                );
            })
          }
        </List>
      }
    </Container>
  );
}
