import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import Title from '../../components/Global/PageTitle';
import Docs from '../../components/MonitoramentoComponents/Docs';

import { getDocs } from '../../services/api';
import { setDocs } from '../../features/docsSlice';


export default function Documents() {
  const dispatch = useDispatch();


  const getData = async () => {
    await getDocs().then(({ data }) => {
      dispatch(setDocs(data));
    });
  }

  useEffect(() => {
    getData();
  }, []);


  return (
    <>
      <Title description="Documentos" />
      <Docs workshop={true}/>
    </>
  );
}
