import React from 'react';

import { WidthProvider, Responsive as RRGL, } from 'react-grid-layout';

import Widget from './Widget';


const GridLayout = WidthProvider(RRGL);


function Dashboard({ layout, }) {
  return (
    <GridLayout
      className="layout"
      layouts={{
        lg: layout,
      }}
      breakpoints={{
        lg: 1200,
        md: 996,
        sm: 768,
        xs: 480,
        xxs: 0,
      }}
      cols={{
        lg: 12,
        md: 12,
        sm: 12,
        xs: 6,
        xxs: 6,
      }}
      rowHeight={130}
      isResizable={false}
      isDraggable={false}
      isDroppable={false}
      style={{
        overflowX: 'auto',
        overflowY: 'hidden'
      }}
      resizeHandles={['se']}
    >
      {
        layout.map((item) => {
          return (
            <Widget
              key={item.type}
              type={item.type}
            />
          );
        })
      }
    </GridLayout>
  );
}

export default Dashboard;
