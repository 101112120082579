export const DashboardWidgetType = Object.freeze({
  COMPANY_OBJECTIVE: 'COMPANY_OBJECTIVE',
  COMPANY_PROGRESS: 'COMPANY_PROGRESS',
  RISK_REPORT: 'RISK_REPORT',
  DATA_MAPPING_REPORT: 'DATA_MAPPING_REPORT',
  PURPOSE_REPORT: 'PURPOSE_REPORT',
  PROCESSING_TYPE_REPORT: 'PROCESSING_TYPE_REPORT',
  PERSONAL_DATA_TYPE_REPORT: 'PERSONAL_DATA_TYPE_REPORT',
  LEGAL_BASES_REPORT: 'LEGAL_BASES_REPORT',
  SENSITIVE_DATA_CATEGORY_REPORT: 'SENSITIVE_DATA_CATEGORY_REPORT',
  SENSITIVE_DATA_REPORT: 'SENSITIVE_DATA_REPORT',
  HEAT_MAP: 'HEAT_MAP',
  CATEGORY_MATURITY_LEVEL_REPORT: 'CATEGORY_MATURITY_LEVEL_REPORT',
  ACTION_PLAN_REPORT: 'ACTION_PLAN_REPORT',
  DPO_CONTACT_REQUEST_REPORT: 'DPO_CONTACT_REQUEST_REPORT',
  PRIVACY_CULTURE: 'PRIVACY_CULTURE',
});
