import React, { useEffect, useState, memo, } from 'react';
import { useSelector, } from 'react-redux';
import moment from 'moment';
import _ from 'lodash';
import Container from '@material-ui/core/Container';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import { Notification, } from 'rsuite';
import Table from '../../components/Table';
import api, { getUsers, } from '../../services/api';


const HEADERS = Object.freeze([
  {
    label: 'Nome',
    align: 'center',
  },
  {
    label: 'E-mail',
    align: 'center',
  },
  {
    label: 'Número de telefone',
    align: 'center',
  },
  {
    label: 'Grupo',
    align: 'center',
  },
  {
    label: 'Data da solicitação',
    align: 'center',
  },
  {
    label: 'Consultor/Empresa',
    align: 'center',
  },
  {
    //! column use to show buttons to approve or reject user account
    label: '',
    align: 'center',
  },
]);

/**
 * Table to show users waiting for approval to use the system
 *
 * @returns Rendered component
 */
function UsersApprove() {
  const [loading, setLoading] = useState(true);
  const [showError, setShowError] = useState(false);
  const [users, setUsers] = useState([]);

  const { groups, } = useSelector(state => state.groups);


  /**
   * Fetch required data from the API
   */
  const loadData = async () => {
    setLoading(true);

    try {
      const { data, } = await getUsers();
      if (_.isArray(data)) {
        setUsers(data.filter((user) => !user.autorizado && user.ativo).reverse());
      }
    } catch {
      Notification['error']({
        placement: 'bottomEnd',
        title: 'Falha ao carregar usuários aguardando aprovação do servidor do servidor!',
      });
      setShowError(true);
      setUsers([]);
    }

    setLoading(false);
  }

  /**
   * Send request to API to approve user account
   *
   * @param {object} user - user to approve
   */
  const approveUserAccount = async (user) => {
    setLoading(true);

    try {
      await api.post(`/accept/user?id=${user._id}`);

      Notification.success({
        placement: 'bottomEnd',
        title: 'Conta de usuário aprovada com sucesso!',
      });

      setUsers(users.filter(({_id,}) => _id !== user._id));
    } catch {
      Notification['error']({
        placement: 'bottomEnd',
        title: 'Falha ao aprovar conta do usuário',
        description: 'Tente novamente em alguns instantes.',
      });
    }

    setLoading(false);
  }

  /**
   * Send request to API to approve user account
   *
   * @param {object} user - user to approve
   */
  const rejectUserAccount = async (user) => {
    setLoading(true);

    try {
      await api.post(`user?id=${user._id}`);

      Notification['success']({
        placement: 'bottomEnd',
        title: 'Conta de usuário recusada com sucesso!',
      });
      setUsers(users.filter(({_id,}) => _id !== user._id));
    } catch {
      Notification['error']({
        placement: 'bottomEnd',
        title: 'Falha ao recusar conta do usuário!',
        description: 'Tente novamente em alguns instantes.',
      });
    }

    setLoading(false);
  }

  /**
   * Use effect to initialize required data
   */
  useEffect(loadData, []);


  return (
    <Container
      maxWidth="xl"
      component={Paper}
      style={{
        paddingTop: 10,
      }}
    >
      <Table
        headers={HEADERS}
        data={users}
        loading={loading}
        noDataMessage="Nenhum usuário aguardando aprovação encontrado"
        errorMessage="Falha ao carregar usuários aguardando aprovação"
        showError={showError}
      >
        {
          (user) => {
            const group = groups.find(({id}) => id === user.group);

            return (
              <TableRow key={user._id}>
                <TableCell align="center">
                  { user.name }
                </TableCell>
                <TableCell align="center">
                  { user.email }
                </TableCell>
                <TableCell align="center">
                  { user.tel }
                </TableCell>
                <TableCell align="center">
                  { ((group != undefined) && (group.name != undefined)) ? group.name : '' }
                </TableCell>
                <TableCell align="center">
                  { moment(user.createdAt).fromNow() }
                </TableCell>
                <TableCell align="center">
                  { user.admin ? 'Consultor' : 'Empresa' }
                </TableCell>
                <TableCell align="center">
                  <Button
                    onClick={() => approveUserAccount(user)}
                    color="primary"
                    variant="outlined"
                    disableElevation
                    size="small"
                    style={{
                      marginRight: 10,
                    }}
                  >
                    Aprovar
                  </Button>

                  <Button
                    onClick={() => rejectUserAccount(user)}
                    color="secondary"
                    variant="outlined"
                    disableElevation
                    size="small"
                  >
                    Recusar
                  </Button>
                </TableCell>
              </TableRow>
            )
          }
        }
      </Table>
    </Container>
  );
}

export default memo(UsersApprove);
