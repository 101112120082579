import { useState, useEffect, memo, } from 'react';
import { useSelector, } from 'react-redux';

import { Grid, Divider, makeStyles, } from '@material-ui/core';
import { Progress, } from 'rsuite';
const { Line, } = Progress;

import 'rsuite/dist/styles/rsuite-default.css';

import { getCompanyProgress, } from '../../services/api';
import { selectCompany } from '../../features/companySlice';


const useStyles = makeStyles({
  paragraph: {
    color: '#3B66FF',
    fontWeight: 'bold',
    fontFamily: 'Inter',
  },
  subparagraph: {
    color: '#54708C',
    fontWeight: 'normal',
  },
});


function CompanyProgress() {
  const classes = useStyles();
  const statusColor = '#3B66FF';
  const company = useSelector(selectCompany);
  const [companyProgress, setCompanyProgress] = useState({
    progress: 0,
    maturity: 0,
  });


  async function getData() {
    try {
      const { data: companyProgress, } = await getCompanyProgress(company._id);
      setCompanyProgress(companyProgress);
    } catch {
      //? Drop error
    }
  }

  useEffect(getData, []);


  return (
    <Grid
      container
      style={{
        backgroundColor: 'white',
        borderRadius: '5px',
        padding: 0,
        marginBottom: '1rem',
      }}
      justifyContent="space-around"
      spacing={2}
    >
      <Grid
        item
        xs={12}
        md={true}
        style={{
          padding: '0 0.5rem',
        }}
      >
        <p className={classes.paragraph}>
          Progresso{' '}
          <span className={classes.subparagraph}>
            (Orientações dadas)
          </span>
        </p>
        <Line
          percent={companyProgress.progress || 0}
          strokeColor={statusColor}
          style={{
            padding: '1px 0',
          }}
        />
      </Grid>

      <Divider
        flexItem
        variant="middle"
        orientation="vertical"
      />

      <Grid
        item
        xs={12}
        md={true}
        style={{
          padding: '0 0.5rem',
        }}
      >
        <p className={classes.paragraph}>
          Aderência{' '}
          <span className={classes.subparagraph}>
            (Atitudes tomadas pela empresa)
          </span>
        </p>
        <Line
          percent={companyProgress.maturity || 0}
          strokeColor={statusColor}
          style={{
            padding: '1px 0',
          }}
        />
      </Grid>
    </Grid>
  );
}


export default memo(CompanyProgress);
