import { useState, useEffect } from "react";
import { useLocation, useHistory } from "react-router-dom";
import queryString from "query-string";
import ToastAnimated, { showToast } from "../../ui-lib/toast";
import "react-toastify/dist/ReactToastify.css";
import "./styles.css";
import api from "../../services/api";

import history from "../../history";
import logo from "../../assets/logo-blue.svg";
import { Container, Paper, Grid } from "@material-ui/core";
import { Button } from "rsuite";

function RecoverPass() {
  const [passwordmain, setPasswordmain] = useState("");
  const [password, setPassword] = useState("");
  const { search } = useLocation();
  const { token } = queryString.parse(search);
  const { email } = queryString.parse(search);

  function handleBack() {
    history.push("/login");
  }

  async function passChange(event) {
    event.preventDefault();
    if (!passwordmain)
      return showToast({
        type: "error",
        message: "Preencha sua senha",
        pauseOnFocusLoss: true,
      });
    if (passwordmain != password)
      return showToast({
        type: "error",
        message: "As senhas são diferentes, tente novamente",
        pauseOnFocusLoss: true,
      });
    try {
      await api.post("/recoverpass", {
        password: passwordmain,
        token: token,
        email: email,
      });

      showToast({
        type: "success",
        message: "Senha alterada",
        pauseOnFocusLoss: true,
      });

      return setTimeout(() => {
        history.push("/login");
      }, 2000);

    } catch (err) {
      return showToast({
        type: "error",
        message:
          "Não foi possivel trocar sua senha, contate seu administrador do site.",
        pauseOnFocusLoss: true,
      });
    }
  }
  return (
    <Container>
      <Container component={Paper} style={{ marginTop: "20%" }}>
        <br />
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Container>
              <img
                src={logo}
                alt="octo"
                width="350px"
                style={{ marginLeft: "35%" }}
              />
              <br />
              <br />
            </Container>
          </Grid>
          <Container maxWidth="sm">
            <strong>Digite uma nova senha </strong>
            <br />

            <input
              type="password"
              className="form-control"
              placeholder="Digite uma senha"
              value={passwordmain}
              onChange={(event) => {
                setPasswordmain(event.target.value);
              }}
            />
            <br />
            <strong>Digite novamente a senha </strong>
            <br />
            <input
              type="password"
              className="form-control"
              placeholder="Repita a senha digitada"
              value={password}
              onChange={(event) => {
                setPassword(event.target.value);
              }}
            />
            <br />
            <br />

            <ToastAnimated />
            <Container style={{ marginLeft: "79%" }}>
              <Button appearance="primary" active onClick={passChange}>
                Alterar a senha
              </Button>
            </Container>
          </Container>
        </Grid>
        <br />
      </Container>
    </Container>
  );
}

export default RecoverPass;
