import React, { useState, useEffect, } from 'react';
import { useSelector, } from 'react-redux';
import fileDownload from 'js-file-download';
import _ from 'lodash';
import { useTranslation, } from 'react-i18next';

import { Container, Button, makeStyles, Grid, Typography, } from '@material-ui/core';
import { Notification, } from 'rsuite';
import DownloadIcon from '@material-ui/icons/CloudDownload';

import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { CKEditor, } from '@ckeditor/ckeditor5-react';

import RegisterImpactReportTemplateDialog from
  '../../../../components/RegisterImpactReportTemplateDialog/RegisterImpactReportTemplateDialog';

import api, {
  downloadImpactReportAsPDF,
  getGlobalImpactReportTemplateContent,
  getImpactReportContent,
  saveGlobalImpactReportTemplateContent,
  saveImpactReportContent,
  uploadGlobalImpactReportTemplateImage,
  uploadImpactReportImage,
} from '../../../../services/api';

import {
  selectCurrentReportId,
  selectCurrentVersionId,
  selectIsGlobal,
} from '../../../../features/impactReportsSlice';
import store from '../../../../redux/store';
import { selectCompany } from '../../../../features/companySlice';



const useStyles = makeStyles({
  buttonContainer: {
    marginBottom: '1rem',
    marginTop: '1rem',
  },
});


function uploadAdapter(loader) {
  return {
    async upload() {
      const file = await loader.file;
      const reportId = store.getState().impactReports.currReportId;
      const isGlobal = store.getState().impactReports.isGlobal;
      const companyId = store.getState().company.company._id;

      const { data, } = isGlobal ? await uploadGlobalImpactReportTemplateImage(reportId, file) :
        await uploadImpactReportImage(companyId, reportId, file);
      return {
        default: new URL(data.imageUrl, api.defaults.baseURL).href,
      };
    },
  };
}

function uploadPlugin(editor) {
  editor.plugins.get('FileRepository').createUploadAdapter = (loader) => {
    return uploadAdapter(loader);
  };
}


export default function ImpactReportEditorV1({ readOnly, }) {
  const { t, } = useTranslation();
  const classes = useStyles();
  const reportId = useSelector(selectCurrentReportId);
  const versionId = useSelector(selectCurrentVersionId);
  const isGlobal = useSelector(selectIsGlobal);
  const company = useSelector(selectCompany);
  const [isDownloading, setIsDownloading] = useState(false);
  const [text, setText] = useState('');
  const [open, setOpen] = useState(false);


  /**
   * Send request to the API to save impact report
   */
  async function saveReport() {
    try {
      isGlobal ? await saveGlobalImpactReportTemplateContent(reportId, text) :
        await saveImpactReportContent(company._id, reportId, text);

      Notification['success']({
        placement: 'bottomEnd',
        title: 'Alterações salvas!',
      });
    } catch {
      Notification['error']({
        placement: 'bottomEnd',
        title: _.capitalize(t('ERRORS.IMPACT_REPORTS.failed_to_save_report')),
        description: _.capitalize(t('ERRORS.try_again_later')),
      });
    }
  }

  async function downloadReport() {
    setIsDownloading(true);

    try {
      Notification['success']({
        placement: 'bottomEnd',
        title: _.capitalize(t('PAGES.IMPACT_REPORTS.download_in_progress')),
        description: _.capitalize(t('PAGES.IMPACT_REPORTS.report_will_be_available_soon')),
      });
      const { data, } = await downloadImpactReportAsPDF(company._id, reportId, versionId);
      fileDownload(data, 'relatório.pdf');
    } catch {
      Notification['error']({
        placement: 'bottomEnd',
        title: _.capitalize(t('ERRORS.IMPACT_REPORTS.failed_to_download_report')),
        description: _.capitalize(t('ERRORS.try_again_later')),
      });
    }

    setIsDownloading(false);
  }

  /**
   * Open modal to register impact report template
   */
  function openRegisterTemplateModal() {
    setOpen(true);
  }

  /**
   * Close modal to register impact report template
   */
  function closeRegisterTemplateModal() {
    setOpen(false);
  }

  /**
   * Handle click in save as template button
   */
  async function onSaveAsTemplate() {
    await saveReport();
    openRegisterTemplateModal();
  }

  /**
   * Get impact report content from the API
   */
  async function getContent() {
    try {
      const { data, } = isGlobal ? await getGlobalImpactReportTemplateContent(reportId) :
        await getImpactReportContent(company._id, reportId, versionId);
      setText(data.content);
    } catch {
      Notification['error']({
        placement: 'bottomEnd',
        title: 'Falha ao carregar relatório de impacto!',
        description: 'Tente novamente dentro de alguns instantes',
      });
    }
  }

  useEffect(() => {
    if (reportId != null) {
      getContent();
    }
  }, [reportId, versionId]);


  return (
    <Container
      disableGutters
      maxWidth="xl"
    >
      <RegisterImpactReportTemplateDialog
        open={open}
        reportId={reportId}
        onClose={closeRegisterTemplateModal}
      />

      <Grid
        container
        alignContent="center"
        justifyContent="flex-end"
        style={{
          marginBottom: '1rem',
        }}
      >
        <Button
          color="primary"
          disableElevation
          onClick={downloadReport}
          variant="contained"
          startIcon={<DownloadIcon />}
          disabled={isDownloading}
        >
          { t('PAGES.IMPACT_REPORTS.download_report') }
        </Button>
      </Grid>

      {
        !readOnly && (versionId != null) ?
        <Typography
          variant="caption"
          display="block"
          align="right"
          color="error"
        >
          Edição desabilitada pois uma revisão foi selecionada!
        </Typography> :
        null
      }

      <CKEditor
        editor={ClassicEditor}
        data={text}
        onChange={(event, editor) => {
          const data = editor.getData();
          setText(data);
        }}
        disabled={readOnly || (versionId != null)}
        config={{
          extraPlugins: [uploadPlugin],
          toolbar: [
            'heading',
            '|',
            'bold',
            'italic',
            'link',
            'bulletedList',
            'numberedList',
            '|',
            'indent',
            'outdent',
            '|',
            'uploadImage',
            'blockQuote',
            'insertTable',
            'undo',
            'redo',
          ],
        }}
      />

      {
        !readOnly && (versionId == null) ?
        <Grid
          container
          alignContent="center"
          justifyContent="flex-end"
          className={classes.buttonContainer}
        >
          {
            !isGlobal ?
            <Button
              onClick={onSaveAsTemplate}
              variant="contained"
              color="primary"
              disableElevation
              disabled={versionId != null}
              style={{
                marginRight: '1rem',
              }}
            >
              Salvar como template
            </Button> :
            null
          }

          <Button
            onClick={saveReport}
            variant="contained"
            color="primary"
            disableElevation
            disabled={versionId != null}
          >
            Salvar
          </Button>
        </Grid> :
        null
      }

    </Container>
  );
}
