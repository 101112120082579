import React from 'react';
import { useTranslation, } from 'react-i18next';

import { ProgressBar, } from 'react-bootstrap';
import { Grid, makeStyles, } from '@material-ui/core';

import ChartLegend from '../ChartLegend';
import ChartByRisc from '../../components/ChartComponent/ByRisc';

import { RISK_STATUSES, } from '../../core/entities/risk';

import { byStatus, } from '../../services/api';


const useStyles = makeStyles({
  titleInnerItem: {
    color: '#3B66FF',
    display: 'inline',
    margin: '0',
    fontFamily: 'Inter',
    fontWeight: 'bolder',
    marginRight: '0.5rem',
  },
  subtitleInnerItem: {
    color: '#54708C',
    display: 'inline',
    fontFamily: 'Inter',
    margin: '0',
  },
});


function RiskOverviewCard() {
  const { t, } = useTranslation();
  const classes = useStyles();
  const [riskReport, setRiskReport] = React.useState({
    [RISK_STATUSES.UNKNOWN.key]: 0,
    [RISK_STATUSES.HIGH.key]: 0,
    [RISK_STATUSES.MEDIUM.key]: 0,
    [RISK_STATUSES.LOW.key]: 0,
  });
  const total = React.useMemo(() => {
    return Object.values(riskReport).reduce((prev, curr) => prev + curr, 0);
  }, [riskReport]);


  async function initialize() {
    try {
      const { data, } = await byStatus('all', 'all');
      setRiskReport({
        [RISK_STATUSES.UNKNOWN.key]: data.semStatus.length,
        [RISK_STATUSES.HIGH.key]: data.high.length,
        [RISK_STATUSES.MEDIUM.key]: data.medium.length,
        [RISK_STATUSES.LOW.key]: data.low.length,
      })
    } catch (err) {
      console.error(err);
    }
  }

  React.useEffect(() => {
    initialize();
  }, []);


  return (
    <div
      style={{
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
      }}
    >
      <Grid
        container
        justifyContent="space-between"
        alignContent="center"
        style={{
          flexGrow: 1,
        }}
        direction="column"
      >
        <Grid item >
          <p className={classes.titleInnerItem}>
            Riscos
          </p>

          <p className={classes.subtitleInnerItem} >
            (Quantidade de itens que estão
            classificados de acordo com níveis de
            risco)
          </p>
        </Grid>

        <Grid
          item
          container
          style={{
            flexGrow: 1,
          }}
        >
          <Grid
            item
            xs={6}
            container
          >
            <ChartByRisc
              semStatus={riskReport.UNKNOWN}
              baixo={riskReport.LOW}
              medio={riskReport.MEDIUM}
              alto={riskReport.HIGH}
            />
          </Grid>

          <Grid
            item
            xs={6}
            container
            alignItems="center"
            justifyContent="center"
          >
            <ProgressBar
              style={{
                borderRadius: 500,
                width: '100%',
              }}
            >
              {
                Object.entries(riskReport).map(([status, count]) => {
                  const { color, } = RISK_STATUSES[status];

                  return (
                    <ProgressBar
                      style={{
                        background: color,
                      }}
                      now={(count / total) * 100}
                      key={status}
                    />
                  );
                })
              }
            </ProgressBar>
          </Grid>
        </Grid>

        <Grid
          item
          container
          alignItems="center"
          justifyContent="space-around"
        >
          {
            Object.entries(riskReport).map(([status, count]) => {
              const { color, } = RISK_STATUSES[status];
              const label = t(`RISK_STATUSES.${status}`);

              return (
                <ChartLegend
                  key={status}
                  color={color}
                  label={`${label} (${count})`}
                />
              );
            })
          }
        </Grid>
      </Grid>
    </div>
  );
}

export default RiskOverviewCard;
