import { createSlice } from "@reduxjs/toolkit";

export const reunionsSlice = createSlice({
  name: 'participants',
  initialState: {
    value: [],
    reunions: [],
  },
  reducers: {
    resetParticipants: (state) => {
      state.value = []
    },
    setParticipants: (state, action) => {
      state.value = action.payload
    },
    updateReunion: (state, action) => {
      state.reunions = action.payload
    },
  },
})

export const {
  setParticipants,
  resetParticipants,
  updateReunion,
} = reunionsSlice.actions

export default reunionsSlice.reducer;
