import React, { useState, memo, useEffect, } from 'react';
import { useDispatch, } from 'react-redux';
import _ from 'lodash';
import {
  Container,
  TextField,
  makeStyles,
  Grid,
} from '@material-ui/core';
import { useFormik, } from 'formik';
import * as Yup from 'yup';
import { Notification, Button, } from 'rsuite';

import {
  createCompanySector,
  updateCompanySector,
} from '../../features/companySectorsSlice';


const INITIAL_VALUES = Object.freeze({
  name: '',
});

const MESSAGES = Object.freeze({
  UPDATE: {
    success: {
      title: 'Setor atualizado com sucesso!',
      description: '',
    },
    error: {
      title: 'Falha ao atualizar setor',
      description: 'Tente novamente dentro de alguns instantes',
    },
  },
  CREATE: {
    success: {
      title: 'Setor criado com sucesso!',
      description: '',
    },
    error: {
      title: 'Falha ao criar setor',
      description: 'Tente novamente dentro de alguns instantes',
    },
  },
});

const useStyles = makeStyles({
  buttonContainer: {
    marginTop: '0.5rem',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  cancelBtn: {
    marginLeft: '1rem',
    borderColor: '#3B66FF',
    color: '#3B66FF !important',
    background: 'transparent !important',
    boxShadow: 'none',
    borderWidth: 2,
    border: '2px solid #3B66FF !important',
    '&:hover': {
      background: 'transparent !important',
      border: '2px solid #3B66FF !important',
    },
  },
});

const InputProps = {
  disableUnderline: true,
};


/**
 * Form to create/edit new company sectors
 *
 * @param {object} data - company sector data to edit
 * @param {function} onUpdateFinished - callback called when company sector is updated
 *
 * @returns rendered component
 */
function CompanySectorForm({ data, onUpdateFinished, }) {
  const classes = useStyles();
  const isUpdate = undefined != data && undefined != data.id;
  const dispatch = useDispatch();
  const formik = useFormik({
    initialValues: INITIAL_VALUES,
    onSubmit: submitCompanySector,
    validationSchema: Yup.object({
      name: Yup.string().required('Obrigatório'),
    }),
  });
  const [loading, setLoading] = useState(false);


  /**
   * Save company sector in API
   */
  async function submitCompanySector(values) {
    setLoading(true);

    const msgs = isUpdate ? MESSAGES.UPDATE : MESSAGES.CREATE;

    const __data = {
      ...data,
      ...values,
    }

    try {
      await dispatch(isUpdate ? updateCompanySector(__data) :
        createCompanySector(__data)).unwrap();

      Notification['success']({
        placement: 'bottomEnd',
        title: msgs.success.title,
      });

      formik.resetForm({
        values: INITIAL_VALUES,
      });

      if (isUpdate) {
        onUpdateFinished();
      }
    } catch {
      Notification['error']({
        placement: 'bottomEnd',
        title: msgs.error.title,
        description: msgs.error.description,
      });
    } finally {
      setLoading(false);
    }
  }

  function onCancel() {
    formik.resetForm({
      values: INITIAL_VALUES,
    });

    onUpdateFinished();
  }

  useEffect(() => {
    if (isUpdate) {
      formik.setValues(data);
    } else {
      formik.setValues(INITIAL_VALUES);
    }
  }, [data]);


  return (
    <Container
      disableGutters
      fullWidth
      maxWidth="xl"
    >
      <TextField
        margin="dense"
        label="Nome"
        fullWidth
        id="name"
        variant="filled"
        InputProps={InputProps}
        type="text"
        error={formik.errors.name && formik.touched.name}
        helperText={formik.touched.name && formik.errors.name}
        {...formik.getFieldProps('name')}
      />

      <Grid
        className={classes.buttonContainer}
      >
        <Button
          className={classes.saveButton}
          appearance="primary"
          loading={loading}
          onClick={formik.handleSubmit}
        >
          Salvar
        </Button>

        {
          isUpdate ?
          <Button
            appearance="primary"
            className={classes.cancelBtn}
            onClick={onCancel}
            loading={loading}
          >
            Cancelar
          </Button> :
          null
        }
      </Grid>
    </Container>
  );
}

export default memo(CompanySectorForm);
