import React from 'react';
import {
  Container,
  Grid,
  Typography,
  Paper,
  makeStyles,
  CircularProgress,
} from '@material-ui/core';

import CourseTestAnswerForm from '../CourseTestAnswerForm';


const useStyles = makeStyles({
  testContainer: {
    paddingTop: '1rem',
    paddingBottom: '1rem',
  },
});


/**
 * Component used to show a course
 *
 * @param {object} course - course data
 * @param {object} author - course author data
 *
 * @returns rendered component
 */
export default function CourseTest({ course, }) {
  const classes = useStyles();


  return (
    <Container
      disableGutters
      maxWidth="xl"
    >
      <Typography
        variant="h5"
        display="block"
        gutterBottom
      >
        Exame final
      </Typography>

      <Container
        maxWidth="xl"
        component={Paper}
      >
        {
          (undefined != course) ?
          <Container
            className={classes.testContainer}
            disableGutters
          >
            <CourseTestAnswerForm
              test={course.test}
              course={course}
            />
          </Container> :
          <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
          >
            <CircularProgress />
            <Typography
              variant="body1"
              display="block"
            >
              Carregando
            </Typography>
          </Grid>
        }
      </Container>
    </Container>
  );
}
