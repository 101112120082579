import React, { useEffect, useState } from 'react'
import { Button } from 'react-bootstrap'
import {
  DragDropContext,
  Droppable,
  Draggable,
} from 'react-beautiful-dnd'
import {
  Container,
  Paper,
  Grid,
  Dialog,
  AppBar,
  Toolbar,
  Typography,
} from '@material-ui/core'
import { Icon } from 'rsuite'
import { Alert, AlertTitle } from '@material-ui/lab'

import CreateTemplateQuestion from '../../pages/Perguntas/TemplateAdd'
import EditPergunta from '../EditPergunta/edit_template'
import { getPerguntasTemplate } from '../../services/api'


export default function TemplatePerguntas() {
  const [template, setTemplate] = useState([]);
  const [cadastroPergunta, setCadastroPergunta] = useState(false);
  const [open, setOpen] = useState(false);


  function HandleClose() {
    setOpen(false);
  }

  async function getData() {
    try {
      const { data, } = await getPerguntasTemplate();
      setTemplate(data)
    } catch {
      //TODO: handle error
    }
  }

  useEffect(getData, []);

  async function handleOnDragEnd(result) {
    if (!result.destination) {
      return;
    }
    const items = Array.from(template)
    const [reorderedItem] = items.splice(result.source.index, 1)
    items.splice(result.destination.index, 0, reorderedItem)
    setTemplate(items)
  }


  return (
    <Grid item xs={12}>
      <div className="boxHeaderPoliticas">
        <h3>Template de Tarefas</h3>
        <button onClick={() => setOpen(true)}>Visualizar</button>
      </div>

      <Dialog
        open={open}
        onClose={HandleClose}
        fullScreen
      >
        <AppBar sx={{ position: 'relative' }}>
          <Toolbar className="HeaderTempĺate">
            <div className="boxLeftHeader">
              <Icon
                icon="close"
                onClick={HandleClose}
              />
              <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                Template de Tarefas
              </Typography>
            </div>
            <Button
            color="inherit"
            onClick={() =>
                setCadastroPergunta(!cadastroPergunta)
              }>
              Cadastrar tarefa
            </Button>
          </Toolbar>
        </AppBar>
      <Dialog
        open={cadastroPergunta}
        onClose={() =>
          setCadastroPergunta(!cadastroPergunta)
        }
        PaperProps={{
          style: {
            width: '700px',
            maxWidth: '90%',
          },
        }}
      >
        <Alert
          severity="info"
          action={
            <Icon
              icon="close"
              onClick={() =>
                setCadastroPergunta(!cadastroPergunta)
              }
            />
          }
        >
          <AlertTitle>
            Adicionar uma pergunta ao template
          </AlertTitle>
        </Alert>

        <CreateTemplateQuestion />
      </Dialog>

      <Container component={Paper} fullScreen style={{paddingTop: 65, maxWidth: 1920}}>

        <DragDropContext onDragEnd={handleOnDragEnd}>
          <Droppable droppableId="problema">
            {(provided) => (
              <ul
                className="problema"
                {...provided.droppableProps}
                ref={provided.innerRef}
              >
                {template.map((body, index) => {
                  return (
                    <Draggable
                      key={body.problema}
                      draggableId={body.problema}
                      index={index}
                    >
                      {(provided) => (
                        <EditPergunta
                          key={body.id}
                          data={body}
                          provided={provided}
                          index={index}
                        />
                      )}
                    </Draggable>
                  )
                })}
                {provided.placeholder}
              </ul>
            )}
          </Droppable>
        </DragDropContext>
      </Container>
      </Dialog>
    </Grid>
  );
}
