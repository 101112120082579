export const MAPPING_FIELD_AS_TYPE_OPTIONS = Object.freeze({
  INPUT: 'input',
  CHECKBOX: 'checkbox',
  SELECT: 'select',
  RISK: 'risk',
});

export const DataMappingFieldType = Object.freeze({
  TEXT: 'TEXT',
  CHECKBOX: 'CHECKBOX',
  PURPOSE: 'PURPOSE',
  SECTOR: 'SECTOR',
  PRESET: 'PRESET',
  LEGAL_BASES: 'LEGAL_BASES',
  PROCESSING_AGENTS: 'PROCESSING_AGENTS',
  COLLABORATORS: 'COLLABORATORS',
  RISK: 'RISK',
  SENSITIVE_DATA_CATEGORY: 'SENSITIVE_DATA_CATEGORY',
  PROCESSING_TYPE: 'PROCESSING_TYPE',
  DATA_MANAGEMENT_ROLE: 'DATA_MANAGEMENT_ROLE',
  PERSONAL_DATA_TYPE: 'PERSONAL_DATA_TYPE',
  SENSITIVE_DATA_OPTION: 'SENSITIVE_DATA_OPTION',
});

export const MAPPING_FIELD_TYPES = Object.freeze({
  TEXT: {
    value: DataMappingFieldType.TEXT,
    asType: MAPPING_FIELD_AS_TYPE_OPTIONS.INPUT,
    typePre: 'text',
    inputType: null,
  },
  CHECKBOX: {
    value: DataMappingFieldType.CHECKBOX,
    asType: MAPPING_FIELD_AS_TYPE_OPTIONS.CHECKBOX,
    typePre: 'checkbox',
    inputType: 'checkbox',
  },
  PURPOSE: {
    value: DataMappingFieldType.PURPOSE,
    asType: MAPPING_FIELD_AS_TYPE_OPTIONS.SELECT,
    typePre: 'finalidade',
    inputType: null,
  },
  SECTOR: {
    value: DataMappingFieldType.SECTOR,
    asType: MAPPING_FIELD_AS_TYPE_OPTIONS.SELECT,
    typePre: 'setor',
    inputType: null,
  },
  PRESET: {
    value: DataMappingFieldType.PRESET,
    asType: MAPPING_FIELD_AS_TYPE_OPTIONS.SELECT,
    typePre: 'preset',
    inputType: null,
  },
  LEGAL_BASES: {
    value: DataMappingFieldType.LEGAL_BASES,
    asType: MAPPING_FIELD_AS_TYPE_OPTIONS.SELECT,
    typePre: 'bases',
    inputType: null,
  },
  PROCESSING_AGENTS: {
    value: DataMappingFieldType.PROCESSING_AGENTS,
    asType: MAPPING_FIELD_AS_TYPE_OPTIONS.SELECT,
    typePre: 'processing-agents',
    inputType: null,
  },
  COLLABORATORS: {
    value: DataMappingFieldType.COLLABORATORS,
    asType: MAPPING_FIELD_AS_TYPE_OPTIONS.SELECT,
    typePre: 'collaborators',
    inputType: null,
  },
  RISK: {
    value: DataMappingFieldType.RISK,
    asType: MAPPING_FIELD_AS_TYPE_OPTIONS.RISK,
    typePre: 'null',
  },
  SENSITIVE_DATA_CATEGORY: {
    value: DataMappingFieldType.SENSITIVE_DATA_CATEGORY,
    asType: MAPPING_FIELD_AS_TYPE_OPTIONS.SELECT,
    typePre: 'sensitive-data-categories',
  },
  PROCESSING_TYPE: {
    value: DataMappingFieldType.PROCESSING_TYPE,
    asType: MAPPING_FIELD_AS_TYPE_OPTIONS.SELECT,
    typePre: 'processing-types',
  },
  DATA_MANAGEMENT_ROLE: {
    value: DataMappingFieldType.DATA_MANAGEMENT_ROLE,
    asType: MAPPING_FIELD_AS_TYPE_OPTIONS.SELECT,
    typePre: 'data-management-roles',
  },
  PERSONAL_DATA_TYPE: {
    value: DataMappingFieldType.PERSONAL_DATA_TYPE,
    asType: MAPPING_FIELD_AS_TYPE_OPTIONS.SELECT,
    typePre: 'personal-data-types',
  },
  SENSITIVE_DATA_OPTION: {
    value: DataMappingFieldType.SENSITIVE_DATA_OPTION,
    asType: MAPPING_FIELD_AS_TYPE_OPTIONS.SELECT,
    typePre: 'sensitive-data-options',
  },
});

export const MAPPING_FIELD_TYPES_ARRAY = Object.values(MAPPING_FIELD_TYPES);
