import { useState, } from "react";
import { v4 as uuidv4 } from "uuid";
import { useSelector, } from 'react-redux';

import { Notification } from "rsuite";
import Alert from "@material-ui/lab/Alert";
import { Form, Button, Col } from "react-bootstrap";
import { Container, Dialog, Paper } from "@material-ui/core";

import api from "../../services/api";

import "bootstrap/dist/css/bootstrap.min.css";
import "./styles.css";
import { selectCompany } from "../../features/companySlice";


export function ModalCreateUser({ open, setOpen, getUsers, }) {
  const { _id: empresa, group, } = useSelector(selectCompany);

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");


  function handleCreateUsers(event) {
    event.preventDefault();

    api
      .post("/singup/reunion", {
        name: name,
        email: email,
        password: uuidv4(),
        admin: false,
        empresa,
        group,
        autorizado: true,
        ativo: false,
      })
      .then(() => {
        Notification["success"]({
          title: "Success",
          placement: "bottomEnd",
          description: "Usuario criado",
        });
        getUsers();
        setOpen(false);
      })
      .catch((response) => {
        Notification["error"]({
          title: "Erro!",
          placement: "bottomEnd",
          description: "Erro ao criar Usuario",
        });
      });
  }


  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
    >
      <Container
        maxWidth="xl"
        disableGutters
      >
        <Alert
          severity="info"
          onClose={() => {
            setOpen(false);
          }}
        >
          Cadastre um usuario
        </Alert>
        <br />
        <br />
      </Container>

      <Container
        component={Paper}
        maxWidth="xl"
      >
        <Form>
          <Form.Row>
            <Form.Group as={Col}>
              <Form.Label>Nome </Form.Label>
              <Form.Control
                placeholder="Nome"
                value={name}
                onChange={(event) => {
                  setName(event.target.value);
                }}
              />
            </Form.Group>
            <Form.Group as={Col}>
              <Form.Label>E-mail</Form.Label>
              <Form.Control
                type="email"
                placeholder="E-mail"
                value={email}
                onChange={(event) => {
                  setEmail(event.target.value);
                }}
              />
            </Form.Group>
          </Form.Row>
          <br />
          <Button
            variant="secondary"
            onClick={() => {
              setOpen(false);
            }}
          >
            Cancelar
          </Button>{" "}
          <Button
            variant="primary"
            onClick={handleCreateUsers}
          >
            Cadastrar usuario
          </Button>
        </Form>
        <br />
        <br />
      </Container>
    </Dialog>
  );
}
