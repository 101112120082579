import React, { useState, memo, useEffect, } from 'react';
import { useDispatch, } from 'react-redux';
import { useTranslation, } from 'react-i18next';
import _ from 'lodash';
import {
  Container,
  TextField,
  makeStyles,
  Grid,
} from '@material-ui/core';
import { useFormik, } from 'formik';
import * as Yup from 'yup';
import { Notification, Button, } from 'rsuite';

import {
  createCompanyCategory,
  updateCompanyCategory,
} from '../../features/companyCategoriesSlice';


const INITIAL_VALUES = Object.freeze({
  name: '',
});

const useStyles = makeStyles({
  buttonContainer: {
    marginTop: '0.5rem',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  cancelBtn: {
    marginLeft: '1rem',
    borderColor: '#3B66FF',
    color: '#3B66FF !important',
    background: 'transparent !important',
    boxShadow: 'none',
    borderWidth: 2,
    border: '2px solid #3B66FF !important',
    '&:hover': {
      background: 'transparent !important',
      border: '2px solid #3B66FF !important',
    },
  },
});

const InputProps = {
  disableUnderline: true,
};


/**
 * Form to create/edit new company categories
 *
 * @param {object} data - company category data to edit
 * @param {function} onUpdateFinished - callback called when company category is updated
 *
 * @returns rendered component
 */
function CompanyCategoryForm({ data, onUpdateFinished, }) {
  const classes = useStyles();
  const { t, } = useTranslation();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const formik = useFormik({
    initialValues: INITIAL_VALUES,
    onSubmit: submitCompanyCategory,
    validationSchema: Yup.object({
      name: Yup.string().required(_.capitalize(t('VALIDATION_MESSAGES.required', {
        context: 'male',
      }))),
    }),
  });


  /**
   * Save company category in API
   */
  async function submitCompanyCategory(values) {
    setLoading(true);

    const isUpdate = null != data.id;

    const __data = {
      ...data,
      ...values,
    };

    try {
      await dispatch(isUpdate ? updateCompanyCategory(__data) :
        createCompanyCategory(__data)).unwrap();

      Notification['success']({
        placement: 'bottomEnd',
        title: _.capitalize(t(`PAGES.COMPANY_CATEGORIES.company_category_successfully_${isUpdate ? 'updated' : 'created'}`)),
      });

      formik.resetForm({
        values: INITIAL_VALUES,
      });

      if (isUpdate) {
        onUpdateFinished();
      }
    } catch {
      Notification['error']({
        placement: 'bottomEnd',
        title: _.capitalize(t(`ERRORS.COMPANY_CATEGORIES.failed_to_${isUpdate ? 'update' : 'create'}_company_category`)),
        description: _.capitalize(t('ERRORS.try_again_later')),
      });
    } finally {
      setLoading(false);
    }
  }

  function onCancel() {
    formik.resetForm({
      values: INITIAL_VALUES,
    });

    onUpdateFinished();
  }

  useEffect(() => {
    if (null != data.id) {
      formik.setValues(data);
    }
  }, [data]);


  return (
    <Container
      disableGutters
      maxWidth="xl"
    >
      <TextField
        margin="dense"
        label={_.capitalize(t('name'))}
        fullWidth
        id="name"
        variant="filled"
        InputProps={InputProps}
        type="text"
        error={formik.errors.name && formik.touched.name}
        helperText={formik.touched.name && formik.errors.name}
        {...formik.getFieldProps('name')}
      />

      <Grid
        className={classes.buttonContainer}
      >
        <Button
          className={classes.saveButton}
          appearance="primary"
          loading={loading}
          onClick={formik.handleSubmit}
        >
          { _.capitalize(t('save')) }
        </Button>

        {
          (null != data.id) ?
          <Button
            appearance="primary"
            className={classes.cancelBtn}
            onClick={onCancel}
            loading={loading}
          >
            { _.capitalize(t('cancel')) }
          </Button> :
          null
        }
      </Grid>
    </Container>
  );
}

export default memo(CompanyCategoryForm);
