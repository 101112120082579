import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import { selectCompany, } from '../../../features/companySlice';
import {
  getHistoricoObservacoes,
  setQuestionAsNotAnswered,
  getPerguntasOrdem,
  setAllQuestionAsNotAnswered
} from '../../../services/api';
import {
  setItens,
  loadingTrue,
  loadingFalse,
  setModalTasks
} from '../../../features/responderPerguntas';

import {CircularProgress} from "@material-ui/core";

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);


export default function UpdateTasks() {
  const { _id: companyId, } = useSelector(selectCompany);
  const dispatch = useDispatch();
  const stateResponderPergunta = useSelector((state) => state.ResponderPerguntas.modalTasks);
  const taskList = useSelector((state) => state.ResponderPerguntas.value);
  const [loading, setLoading] = useState(false);

  const handleClose = () => {
    dispatch(setModalTasks(false));
  };

  async function handleSubmitTasks() {
    await setTasksStatus();
    await updateStatus();
    handleClose();
  }

  async function createRequests(task) {
    const { id: questionId } = task;
    const { data: obs } = await getHistoricoObservacoes(questionId);
    await setQuestionAsNotAnswered(companyId, questionId, obs);
  }

  async function setTasksStatus() {
    try {
      setLoading(true);
      const questionsIds = taskList.filter(task => task.resposta || task.reg_resposta === 0).map((task) => task.id);
      await setAllQuestionAsNotAnswered(companyId, {questionsIds });
      setLoading(false);
    } catch (error) {
      //? drop error
      console.log(error);
    }
    updateStatus();
  }

  async function updateStatus() {
    const { data, } = await getPerguntasOrdem();
    dispatch(loadingTrue());
    dispatch(setItens(data));
    dispatch(loadingFalse());
  }

  return (
      <div>
        <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={stateResponderPergunta}>

          <DialogTitle id="customized-dialog-title" onClose={handleClose}>
            Atualizar tarefas
          </DialogTitle>

          <DialogContent dividers>
            <Typography gutterBottom>
              Todas as tarefas marcadas como não respondidas serão colocadas para revisão. Você tem certeza disso?
            </Typography>
          </DialogContent>

          <DialogActions>
            {loading ?
                <CircularProgress size={20}/> :
                <Button autoFocus onClick={handleSubmitTasks} color="primary">
                  sim
                </Button>
            }
          </DialogActions>

        </Dialog>
      </div>
  );
}
