import React from 'react';

import SelectField from './SelectField';
import SectorField from './SectorField';
import DefaultField from './DefaultField';
import LegalBasesField from './LegalBasesField';
import PreDefinedField from './PreDefinedField';
import CollaboratorsField from './CollaboratorsField';
import ProcessingAgentsField from './ProcessingAgentsField';
import SensitiveDataCategoriesField from './SensitiveDataCategoriesField';
import ProcessingTypeField from './ProcessingTypeField';
import DataManagementRoleField from './DataManagementRoleField';
import PersonalDataTypeField from './PersonalDataTypeField';
import SensitiveDataOptionsField from './SensitiveDataOptionField';

import { getFieldType, } from '../../../core/entities/data-mapping-field';
import { MAPPING_FIELD_TYPES, DataMappingFieldType, } from '../../../data/constants';
import CheckboxLineField from "./CheckBoxLineField";
import RiskFieldLine from "./RiskFieldLine";
import PurposeField from "./PurposeField";


const FIELD_COMPONENT_MAP = Object.freeze({
  [DataMappingFieldType.TEXT]: DefaultField,
  [DataMappingFieldType.CHECKBOX]: CheckboxLineField,
  [DataMappingFieldType.PURPOSE]: PurposeField,
  [DataMappingFieldType.SECTOR]: SectorField,
  [DataMappingFieldType.PRESET]: PreDefinedField,
  [DataMappingFieldType.LEGAL_BASES]: LegalBasesField,
  [DataMappingFieldType.PROCESSING_AGENTS]: ProcessingAgentsField,
  [DataMappingFieldType.COLLABORATORS]: CollaboratorsField,
  [DataMappingFieldType.RISK]: RiskFieldLine,
  [DataMappingFieldType.SENSITIVE_DATA_CATEGORY]: SensitiveDataCategoriesField,
  [DataMappingFieldType.PROCESSING_TYPE]: ProcessingTypeField,
  [DataMappingFieldType.DATA_MANAGEMENT_ROLE]: DataManagementRoleField,
  [DataMappingFieldType.PERSONAL_DATA_TYPE]: PersonalDataTypeField,
  [DataMappingFieldType.SENSITIVE_DATA_OPTION]: SensitiveDataOptionsField,
});


export default function DataMappingLineField({
   token,
   isPublic,
   answer,
   field,
   updateOptions,
   readOnly = false,
   previewOnly = false,
 }) {
  const type = getFieldType(field.asType, field.typePre, field.inputType) ||
    MAPPING_FIELD_TYPES.TEXT;
  const Field = FIELD_COMPONENT_MAP[type.value];

  return (
    <Field
      token={token}
      field={field}
      uid={previewOnly ? undefined : answer.uid}
      readOnly={(type.value == MAPPING_FIELD_TYPES.SECTOR.value) && isPublic}
      isPublic={isPublic}
      previewOnly={previewOnly}
      updateOptions={updateOptions}
    />
  );
}
