import { useEffect, useState, useContext, memo, } from 'react';
import { useCookies } from 'react-cookie'
import { useDispatch, useSelector, } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { useTranslation, } from 'react-i18next';
import _ from 'lodash';

import { Icon, Button } from 'rsuite'
import {
  ListItemIcon,
  useTheme,
  ThemeProvider,
  ListItem,
  List,
  Avatar,
  Typography,
  Toolbar,
  Drawer,
  ListItemText,
  Divider,
  Collapse,
  Dialog,
  DialogContent,
  makeStyles,
} from '@material-ui/core';
import CustomAppBar from '../CustomAppBar';
import { Image, ListGroup } from 'react-bootstrap';
import clsx from 'clsx';

import { getUniqueUser, } from '../../services/api';
import { Context } from '../../context/AuthContext'
import { useThemeContext } from '../../context/ThemeAppContext';
import { useStyles, StyledBadge } from './Styles'

import ModalUsers from '../../pages/CriarUsuarios/ModalCriarUser'
import Config from './Config'
import SingleItem from './Itens/SingleItem'
import SelectItem from './Itens/SelectItem'
import SelectSingleItem from './Itens/SelectSingleItem'
import { backToDefault } from '../../features/counterSlice'
import { resetParticipants } from '../../features/reunionsSlice'
import logo from '../../assets/logo.svg'
import iconProfile from '../../assets/iconesprofile.svg'
import iconUser from '../../assets/iconesuser.svg'
import '@fontsource/inter'
import './styles.css'
import ProcessingAgents from '../ProcessingAgents/ProcessingAgents';
import CompanySectorsModal from '../CompanySectorsModal/CompanySectorsModal';
import CollaboratorsModal from '../CollaboratorsModal';
import CompanyProgress from '../CompanyProgress';
import CompanyCategoriesModal from '../CompanyCategoriesModal';
import CompanyPersonalDataTypeModal from '../CompanyPersonalDataTypeModal';
import CompanySensitiveDataOptionModal from '../CompanySensitiveDataOptionModal';
import AppBar from '../CustomAppBar/V2/Mobile/AppBar';

import { selectCompany, } from '../../features/companySlice';
import { setCollaboratorsModal, } from '../../features/collaboratorsSlice';
import { setCompanySectorsModal, } from '../../features/companySectorsSlice';
import { setProcessingAgentsModal, } from '../../features/processingAgentsSlice';
import { setCompanyCategoriesModal, } from '../../features/companyCategoriesSlice';
import useModal from '../../hooks/useModal';


const useStyles2 = makeStyles({
  collapse: {
    display: 'block',
  },
});


const NESTED_TABS_OPTIONS = Object.freeze({
  MATURITY_CHECKLIST: 'MATURITY_CHECKLIST',
  DATA_MAPPING: 'DATA_MAPPING',
  DPO: 'DPO',
  CONFIG: 'CONFIG',
});


function NavbarCustom({ children }) {
  const { isMobile, layoutName, divSubMenu, divMenu, heightToobar } = useThemeContext();
  const history = useHistory();
  const classes2 = useStyles2();
  const { HandleLogout, adminauth, } = useContext(Context)
  const [cookie] = useCookies()
  const dispatch = useDispatch()
  const { t, } = useTranslation();
  const { _id: companyId, name: companyName, } = useSelector(selectCompany);
  const getContractInfo = localStorage.getItem('contract')
  const name = localStorage.getItem('name')
  const avatar = localStorage.getItem('avatar')
  const [menu, setMenu] = useState(false)
  const [config, setConfig] = useState(false)
  const [token, setToken] = useState('')
  const [open, setOpen] = useState(true)
  const [nestedTabs, setNestedTabs] = useState(Object.fromEntries(
    Object.values(NESTED_TABS_OPTIONS).map((item) => [item, false])));
  const [userModalIsOpen, setUserModalIsOpen] = useState(false);
  const [
    personalDataTypesModalOpen,
    onOpenPersonalDataTypesModal,
    onClosePersonalDataTypesModal
  ] = useModal();
  const [
    sensitiveDataOptionModalOpen,
    onOpenSensitiveDataOptionModal,
    onCloseSensitiveDataOptionModal
  ] = useModal();
  const classes = useStyles()
  const theme = useTheme()
  const location = useLocation()

  const PAGES_WITH_PROGRESS_BAR = [`/companies/${companyId}/Monitoramento`];


  const getContractStatus = async () => {
    if (getContractInfo !== 'true') {
      const { data, } = await getUniqueUser();
      if (data.accept_contract) {
        localStorage.setItem('contract', 'true');
      }
    }
  }

  function handleDashboard() {
    localStorage.removeItem('currentSearchMatriz');
    localStorage.removeItem('currentReunion');
    dispatch(backToDefault());
    dispatch(resetParticipants());
    return history.push('/Dashboard');
  }

  useEffect(() => {
    setOpen(!cookie.NavPref);
  }, [cookie.NavPref]);

  const handleUserModalIsOpen = () => {
    setUserModalIsOpen(!userModalIsOpen);
  }

  function openProcessingAgentsModal() {
    dispatch(setProcessingAgentsModal(true));
  }

  function openCollaboratorsModal() {
    dispatch(setCollaboratorsModal(true));
  }

  function openCompanySectorsModal() {
    dispatch(setCompanySectorsModal(true));
  }

  function openCompanyCategoriesModal() {
    dispatch(setCompanyCategoriesModal(true));
  }

  function toggleOption(optionKey) {
    setNestedTabs((_nestedTabs) => {
      const nestedTabs = {};
      for (const key of Object.keys(_nestedTabs)) {
        nestedTabs[key] = (optionKey == key) && !_nestedTabs[key];
      }
      return nestedTabs;
    });
  }

  useEffect(() => {
    document.title = `${companyName} | LGPD`;
    getContractStatus();
  }, []);

  return (
    <div className={classes.root}>
      <Dialog
        open={userModalIsOpen}
        onClose={handleUserModalIsOpen}
      >
        <DialogContent>
          <ModalUsers onClick={handleUserModalIsOpen} />
        </DialogContent>
      </Dialog>

      <ProcessingAgents />

      <CompanySectorsModal />

      <CollaboratorsModal />

      <CompanyCategoriesModal />

      <CompanyPersonalDataTypeModal
        open={personalDataTypesModalOpen}
        onClose={onClosePersonalDataTypesModal}
      />

      <CompanySensitiveDataOptionModal
        open={sensitiveDataOptionModalOpen}
        onClose={onCloseSensitiveDataOptionModal}
      />
      
      <CustomAppBar data={{
        nestedTabs,
        companyName,
        companyId,
        onOpenPersonalDataTypesModal,
        onOpenSensitiveDataOptionModal,
        adminauth,
        HandleLogout,
        handleUserModalIsOpen,
        openCollaboratorsModal,
        openProcessingAgentsModal,
        openCompanySectorsModal,
        openCompanyCategoriesModal,
      }} />

      <main className={classes.content}>
        <Config
          open={config}
          setOpen={setConfig}
        />
        <div style={{ marginTop: heightToobar }} />


        {isMobile &&
          <AppBar
            data={{
              nestedTabs,
              companyName,
              companyId,
              onOpenPersonalDataTypesModal,
              onOpenSensitiveDataOptionModal,
              adminauth,
              HandleLogout,
              handleUserModalIsOpen,
              openCollaboratorsModal,
              openProcessingAgentsModal,
              openCompanySectorsModal,
              openCompanyCategoriesModal,
            }}
          />
        }
        {/* {PAGES_WITH_PROGRESS_BAR.includes(location.pathname) &&
          <CompanyProgress />
        } */}
        {children}
      </main>
    </div>
  )
}

export default memo(NavbarCustom);
