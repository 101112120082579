import { useEffect, useState } from 'react';
import { useSelector, } from 'react-redux';

import { Container, Grid } from '@material-ui/core';
import { Notification, } from 'rsuite';

import Title from '../../components/Global/PageTitle'
import Label from '../../components/Global/Label'
import Loading from '../../components/Global/Loading'
import { CardsNumeros } from '../../components/Classificacao/CardsSeusNumeros.js'
import ResourcesClassification from '../../components/Classificacao/RankingAtividades';
import {
  getCompanySectorsClassification,
  getCompanyBasesClassification,
  getCompanyPurposeClassification,
} from '../../services/api';
import '../Classificacao/styles.css';

import { selectCompany, } from '../../features/companySlice';


function Classification() {
  const company = useSelector(selectCompany);
  const [basesClassification, setBasesClassification] = useState({
    classification: [],
    classifiedItems: 0,
    unclassifiedItems: 0,
  });
  const [purposeClassification, setPurposeClassification] = useState([]);
  const [sectorsClassification, setSectorsClassification] = useState([]);
  const [loading, setLoading] = useState(true);


  async function getSectorsClassification() {
    try {
      const { data, } = await getCompanySectorsClassification(company._id);
      setSectorsClassification(data.classification);
    } catch {
      Notification['error']({
        placement: 'bottomEnd',
        title: 'Falha ao carregar classificação dos setores da empresa',
        description: 'Tente novamente dentro de alguns instantes',
      });
    }
  }

  async function getBasesClassification() {
    try {
      const { data, } = await getCompanyBasesClassification(company._id);
      setBasesClassification(data);
    } catch {
      Notification['error']({
        placement: 'bottomEnd',
        title: 'Falha ao carregar classificação das bases legais da empresa',
        description: 'Tente novamente dentro de alguns instantes',
      });
    }
  }

  async function getPurposeClassification() {
    try {
      const { data, } = await getCompanyPurposeClassification(company._id);
      setPurposeClassification(data.classification);
    } catch {
      Notification['error']({
        placement: 'bottomEnd',
        title: 'Falha ao carregar classificação das finalidades de tratamento',
        description: 'Tente novamente dentro de alguns instantes',
      });
    }
  }


  async function initialize() {
    setLoading(true);
    await Promise.all([
      getPurposeClassification(),
      getSectorsClassification(),
      getBasesClassification()
    ]);
    setLoading(false);
  }

  useEffect(initialize, []);


  return (
    <>
      <Container maxWidth="xl" disableGutters>
        <Loading value={loading} />
        <Title description="Classificação" fontSize={20} />
        <br />
        <Label description="Seus números" fontSize={18} />
        <br />
        <Grid
          className="GridCardsNumeros"
          container
          spacing={1}
        >
          <CardsNumeros
            description="Itens sem classificação"
            value={basesClassification.unclassifiedItems}
          />
          <CardsNumeros
            description="Processos concluidos"
            value={basesClassification.classifiedItems}
          />
          <CardsNumeros
            description="Bases legais utilizadas"
            value={basesClassification.classification.filter((item) => item.amount > 0).length}
          />
        </Grid>
        <br />

        <ResourcesClassification
          basesClassification={basesClassification.classification}
          sectorsClassification={sectorsClassification}
          purposeClassification={purposeClassification}
        />
      </Container>
    </>
  );
}

export default Classification;
