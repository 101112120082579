import { useEffect, useState, useMemo, } from 'react';
import { useSelector, } from 'react-redux';
import history from '../../history'

import { Alert, Image } from 'react-bootstrap'
import { Divider, Icon, Input as InputRsuite, InputGroup, } from 'rsuite';
import { Container, Paper, Grid, Typography, Button, } from '@material-ui/core'

import api, { getCompanyContactRequests, } from '../../services/api'

import './dpostyles.css'
import styles from './styleIndex.module.scss'

import Table from '../../components/Table';
import GeraLinkTitular from './ComunicaTitular'
import RowSolicitacoes from '../NotificacoesPrivacidade/RowShowSolicitacoes'

import { selectCompany, } from '../../features/companySlice';

import pdcaCycleImage from '../../assets/pdca-cycle.png';


const HEADERS = Object.freeze([
  {
    label: 'Protocolo',
    align: 'center',
  },
  {
    label: 'Origem',
    align: 'center',
  },
  {
    label: 'Status',
    align: 'center',
  },
  {
    label: 'Solicitante',
    align: 'center',
  },
  {
    label: 'Solicitação',
    align: 'center',
  },
  {
    label: 'Tempo decorrido',
    align: 'center',
  },
  {
    label: 'Tempo para conclusão',
    align: 'center',
  },
  {
    label: 'Detalhes',
    align: 'center',
  },
]);


function DPOChannel() {
  const { _id: idEmpresa, emailContato, } = useSelector(selectCompany);
  const [search, setSearch] = useState('');
  const [loading, setLoading] = useState(true);
  const [contactRequests, setContactRequests] = useState([]);
  const [statusCount, setStatusCount] = useState({
    open: 0,
    inProgress: 0,
    closed: 0,
  });
  const [widthSearch, setWidthSearch] = useState('400px')
  const [emailComunication, setEmailComunication] = useState('')
  const [hideClosedRequests, setHideClosedRequests] = useState(true);

  const filteredContactRequests = useMemo(() => {
    let ret = contactRequests
      .filter((request) => !hideClosedRequests || request.status != 'Concluido')
      .reverse();

    if (search) {
      ret = ret
        .filter((r) => {
          return r.email
            .toLowerCase()
            .includes(search.toLocaleLowerCase())
        });
    }

    return ret;
  }, [contactRequests, hideClosedRequests, search]);


  async function getContactRequests() {
    setLoading(true);
    try {
      const { data, } = await getCompanyContactRequests(idEmpresa);
      setContactRequests(data.requests);
      setStatusCount(data.statusCount);
    } catch (err) {
      // Drop error
    }
    setLoading(false);
  }

  useEffect(() => {
    getContactRequests();
  }, []);

  async function postEmailcon() {
    try {
      await api.post('/empresa/emailcom', {
        idEmpresa,
        emailComunication,
      });

      alert('E-mail salvo');
    } catch (error) {
      alert('Erro ao salvar e-mail');
    }
  }

  function toggleClosedRequestsVisibility() {
    setHideClosedRequests(!hideClosedRequests);
  }

  useEffect(() => {
    if (window.screen.width <= 1370) {
      setWidthSearch('280px')
    } else if (window.screen.width <= 1200) {
      setWidthSearch('180px')
    } else {
      setWidthSearch('400px')
    }
  })

  const style = {
    display: 'flex',
    alignItems: 'center',
    width: `${widthSearch}`,
    height: 30,
    padding: '3px',
  };


  return (
    <Grid
      container
      spacing={3}
    >
      <div className="DobleCards" style={{ display: "flex", justifyContent: "center" }}>

        <Grid item xs={12} sm={4} style={{ marginInline: '10px' }}>
          <Container
            style={{
              minHeight: '10rem',
              marginLeft: '1.5%',
              display: "flex",
              alignItems: "center"
            }}
            component={Paper}
          >
            <Typography
              variant="body1"
              style={{ color: '#54708C' }}
            >
              <strong
                style={{
                  fontSize: 48,
                  color: '#3B66FF',
                  padding: 20,
                }}
              >
                {contactRequests.length}
              </strong>
              {'  '}Solicitações do titular
            </Typography>
          </Container>
        </Grid>

        <Grid item xs={12} sm={4} style={{ marginInline: '10px' }}>
          <Container
            style={{
              minHeight: '10rem',
              marginLeft: '1.5%',
              display: "flex",
              flexDirection: "column",
              justifyContent: "center"
            }}
            component={Paper}
          >
            <Typography variant="body1">
              <strong
                style={{
                  fontSize: 14,
                  color: '#31D9A6',
                  padding: 20,
                }}
              >
                {statusCount.closed}
              </strong>
              Concluidos
            </Typography>

            <Typography variant="body1">
              <strong
                style={{
                  fontSize: 14,
                  color: '#3B66FF',
                  padding: 20,
                }}
              >
                {statusCount.inProgress}
              </strong>{' '}
              Em andamento
            </Typography>
            <Typography variant="body1">
              <strong
                style={{
                  fontSize: 14,
                  color: '#FFB54D',
                  padding: 20,
                }}
              >
                {statusCount.open}
              </strong>{' '}
              Em aberto
            </Typography>
            <br />
          </Container>
        </Grid>

      </div>
      <GeraLinkTitular
        pushButtonEmailDPO={postEmailcon}
        emailComunication={emailComunication}
        setEmailComunication={setEmailComunication}
        emailCom={emailContato == undefined}
      />

      <Alert variant="warning">
        Todas as solicitações pendentes feitas através
        do formulario
      </Alert>

      <Grid item xs={12} component={Paper}>
        <Grid item xs={12}>
          <Grid
            item
            xs={12}
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <Typography
              variant="h6"
              className={styles.titleList}
            >
              Solicitações
            </Typography>
            <Grid item>
              <InputGroup
                inside
                style={style}
                onChange={(e) => {
                  setSearch(e.target.value)
                }}
              >
                <InputRsuite placeholder="Pesquisar por e-mail do solicitante" />
                <InputGroup.Button>
                  <Icon icon="search" />
                </InputGroup.Button>
              </InputGroup>
            </Grid>
          </Grid>

          <Grid
            item
            container
            xs={12}
            alignItems="center"
            justifyContent="flex-start"
          >
            <Button
              size="small"
              style={{
                color: '#808080',
              }}
              onClick={toggleClosedRequestsVisibility}
            >
              {`${hideClosedRequests ? 'Mostrar' : 'Ocultar'} solicitações concluídas`}
            </Button>
          </Grid>

          <Container
            xs={12}
            maxWidth
            style={{
              padding: '1rem',
            }}
          >
            <Table
              headers={HEADERS}
              data={filteredContactRequests}
              loading={loading}
              errorMessage="Falha ao carregar solicitações"
              noDataMessage="Nenhuma solicitação encontrada"
              disablePagination
              className={styles.bodyShowSolic}
            >
              {
                (request, props) => {
                  return (
                    <RowSolicitacoes
                      {...props}
                      dados={request}
                      companyId={idEmpresa}
                      getRequests={getContactRequests}
                    />
                  )
                }
              }
            </Table>
          </Container>
        </Grid>
        <br />
      </Grid>
    </Grid>
  );
}

export default DPOChannel;
