import axios from 'axios';
import history from '../history';
require('dotenv/config')

import { clearLocalStorage, } from '../utils';
import store from '../redux/store';
import { errorCodes } from '../errors/standard-error';

import { ACTION_STATUSES, } from '../core/entities/action-plans';
import { TASK_STATUSES, } from '../core/entities/checklist-tasks';


let url = `${window.location.host}`
let baseurl = ''

switch (url) {
  case 'localhost:3000':
    baseurl = process.env.REACT_APP_API_LOCAL
    break
  case 'dev.octo.legal':
    baseurl = 'https://apidev.octo.legal/'
    break
  case 'octo.legal':
    baseurl = process.env.REACT_APP_API_PROD
    break
  case 'homologacao.octo.legal':
    baseurl = 'https://apihomologacao.octo.legal/'
    break
  default:
    baseurl = process.env.REACT_APP_API_PROD
}

export const API_URL = baseurl;

const api = axios.create({
  headers: {
    'Access-Control-Allow-Origin': '*',
  },
  baseURL: baseurl,
});

api.interceptors.response.use(
  (response) => {
    return response
  },
  async function (error) {
    const access_token = localStorage.getItem('token');
    if (access_token && (error.response.status === 401 ||
      (error.response.status === 403 &&
        error.response.data.code === errorCodes.USER_IS_INACTIVE))) {
      clearLocalStorage();
      api.defaults.headers.Authorization = undefined;
      history.go(0);
    }
    return Promise.reject(error);
  }
);

api.interceptors.request.use(async function (config) {
  if (!(config.url.startsWith('/renew') || config.url.startsWith('/login'))) {
    if (store.getState().users.renewing) {
      await (new Promise((resolve) => {
        const intervalId = setInterval(() => {
          if (!store.getState().users.renewing) {
            clearInterval(intervalId);
            resolve();
          }
        }, 1000);
      }));
    }
  }

  return config;
}, function (error) {
  return Promise.reject(error);
});

export function setAuthHeader(token) {
  if (undefined != token) {
    api.defaults.headers.Authorization = `Bearer ${token}`;
  } else {
    api.defaults.headers.Authorization = undefined;
  }
}

export function renewSession(refreshToken) {
  return api.post('/renew', {
    refreshToken,
  });
}

export async function vericaLoginSession() {
  return await api.get('/verifica_tempo_login', {
    params: {
      last_endpoint: window.location.pathname,
      base: url,
    },
  });
}

export async function getPolitica(id) {
  return await api.get(`/politica${id}`)
}

//Requisiçoes de perguntas
export async function getPerguntasByEmpresa() {
  return await api.get(`/perguntas${store.getState().company.company._id}`);
}

export async function deleteAllQuestionsByCompany() {
  const companyId = store.getState().company.company._id;
  return await api.delete(`/companies/${companyId}/questions`);
}

export async function updatePerguntasOrdemByEmpresa() {
  const companyId = store.getState().company.company._id;
  return await api.post(`companies/${companyId}/questions/reorder`);
}

export async function perguntasByResults(value) {
  return await api.get(`/results?idEmpresa=${store.getState().company.company._id}&resposta=${value}`);
}

export async function getPerguntasBySetor(setor) {
  return await api.get(`/perguntas/setor?idEmpresa=${store.getState().company.company._id}&setor=${setor}`);
}

export async function updateImpactOrProbability(id, resp, step) {
  const companyId = store.getState().company.company._id;
  return await api.post(`/companies/${companyId}/questions/${id}/impact`, {
    value: resp,
    step,
  });
}

export async function updateQuestionMaturityLevel(companyId, questionId, maturityLevel) {
  return api.put(`/companies/${companyId}/questions/${questionId}/maturity`, {
    maturityLevel,
  });
}

export async function getPerguntasPorRespostaSemCalc(resp) {
  return await api.get(`/answered?resposta=${resp}&idEmpresa=${store.getState().company.company._id}`);
}

export async function getHistoricoPerguntas() {
  return await api.get(`/historico?idEmpresa=${store.getState().company.company._id}`);
}

export async function getCompanyProgress(companyId) {
  return api.get(`/companies/${companyId}/progress`);
}

export async function getPerguntasBySearch(value) {
  return await api.get(`/perguntas/search?idEmpresa=${store.getState().company.company._id}&value=${value}`);
}

export async function getPerguntasOrdem(page, limit) {
  return await api.get(`/perguntas/ordem/?idEmpresa=${store.getState().company.company._id}&page=${page}&limit=${limit}`);
}

/**
 * Create new maturity question for company
 *
 * @param {Object} data - question data
 *
 * @returns request response promise
 */
export async function createMaturityQuestion(companyId, data) {
  return api.post(`/companies/${companyId}/questions`, data);
}

/**
 * Update maturity question
 *
 * @param {string} companyId - company ID
 * @param {number} questionId - question ID
 * @param {Object} data - question data
 *
 * @returns request response promise
 */
export async function updateMaturityQuestion(companyId, questionId, data) {
  return api.put(`/companies/${companyId}/questions/${questionId}`, data);
}

/**
 * Delete maturity question
 *
 * @param {number} questionId - question ID
 *
 * @returns request response promise
 */
export async function deleteMaturityQuestion(questionId) {
  const companyId = store.getState().company.company._id;
  return api.delete(`/companies/${companyId}/questions/${questionId}`);
}

/**
 * Update maturity question position
 *
 * @param {number} questionId - question ID
 * @param {number} position - updated question position
 *
 * @returns request response promise
 */
export async function updateMaturityQuestionPosition(questionId, position) {
  const companyId = store.getState().company.company._id;
  return api.put(`companies/${companyId}/questions/${questionId}/order`, {
    value: position,
  });
}

/**
 * Fetch company compliant questions from the API
 *
 * @param {number} page - current page
 * @param {number} pageSize - page size
 *
 * @returns response promise
 */
export async function getPerguntasAdequadas(page, pageSize) {
  const companyId = store.getState().company.company._id;
  return api.get(`/companies/${companyId}/questions/compliant`, {
    params: {
      page,
      limit: pageSize,
    },
  });
}

/**
 * Fetch company questions in review from the API
 *
 * @param {number} page - current page
 * @param {number} pageSize - page size
 *
 * @returns response promise
 */
export async function getPerguntasNaoAdequadas(page, pageSize) {
  const companyId = store.getState().company.company._id;
  return api.get(`/companies/${companyId}/questions/in-review`, {
    params: {
      page,
      limit: pageSize,
    },
  });
}

/**
 * Fetch company unanswered questions from the API
 *
 * @param {number} page - current page
 * @param {number} pageSize - page size
 *
 * @returns response promise
 */
export async function getPerguntaSemReposta(page, pageSize) {
  const companyId = store.getState().company.company._id;
  return api.get(`/companies/${companyId}/questions/unanswered`, {
    params: {
      page,
      limit: pageSize,
    },
  });
}

export async function getPerguntasTemplate() {
  return await api.get('/perguntas/template')
}

export async function setQuestionAsAnswered(companyId, questionId, obs) {
  return api.post(`/companies/${companyId}/questions/${questionId}/compliant`, {
    obs: obs,
  });
}

export async function setQuestionAsNotAnswered(companyId, questionId, obs) {
  return api.post(`/companies/${companyId}/questions/${questionId}/in-review`, {
    obs: obs,
  });
}

export async function setAllQuestionAsNotAnswered(companyId, form) {
  const { questionsIds } = form
  return api.post(`/companies/${companyId}/questions/send-all-to-in-review`, {
    questionsIds
  });
}

export async function changeQuestionQuickActionFlag(companyId, questionId, value) {
  return api.post(`/companies/${companyId}/questions/${questionId}/quick`, {
    value,
  });
}

export async function addQuestionEvidence(companyId, questionId, file) {
  const form = new FormData();
  form.append('file', file);

  return api.post(`/companies/${companyId}/questions/${questionId}/add-evidence`, form, {
    headers: {
      'content-type': 'multipart/form-data',
    },
  });
}

/**
 * Fetch question observations from the API
 *
 * @param {number} questionId - question ID
 * @returns response
 */
export async function getHistoricoObservacoes(questionId) {
  const companyId = store.getState().company.company._id
  return await api.get(`/companies/${companyId}/questions/${questionId}/observations`);
}

export async function createQuestionObservation(companyId, questionId, obs) {
  return api.post(`/companies/${companyId}/questions/${questionId}/observations`, {
    obs,
  });
}

export async function deleteQuestionObservation(companyId, questionId, observationId) {
  return api
    .delete(`/companies/${companyId}/questions/${questionId}/observations/${observationId}`);
}

export async function getPerguntasPorResposta(resposta) {
  return await api.get(`/resposta?resposta=${resposta}&idEmpresa=${store.getState().company.company._id}`);
}

/**
 * Get questions summary by sector and response status (0 or 1)
 *
 * @param {string} companyId - company Id
 *
 * @returns request response
 */
export async function getQuestionsBySectorAndResponse(companyId) {
  return api.get(`/companies/${companyId}/questions/by-sector-and-response`);
}

export async function getQuestionsCategoriesReport(companyId) {
  return api.get(`/companies/${companyId}/questions/categories-maturity-level-report`);
}

export async function importChecklistFromCSVFile(companyId, file) {
  const form = new FormData();
  form.append('file', file);

  return api.post(`/companies/${companyId}/questions/import-from-csv`, form, {
    headers: {
      'content-type': 'multipart/form-data',
    },
  });
}

export async function exportCompanyChecklist(companyId, format = 'csv') {
  return api.get(`/companies/${companyId}/questions/export`, {
    params: {
      format,
    },
    responseType: 'blob',
  });
}

export async function downloadChecklistCSVFileTemplate() {
  return api.get(`/checklist-csv-template`, {
    responseType: 'blob',
  });
}

/**
 * Companies
 */

export async function getCompany(companyId) {
  return api.get(`/companies/${companyId}`);
}

export async function createCompany(data) {
  return api.post('/create/empresa', data, {
    withCredentials: true,
  });
}

export async function updateCompany(companyId, data) {
  return api.put(`/companies/${companyId}`, data);
}

export async function deleteCompany(companyId) {
  return api.delete(`/companies/${companyId}`);
}

/**
 * Fetch companies that the users has access to from API
 *
 * @returns {Promise} list of companies
 */
export async function getCompaines() {
  return await api.get(`/companies`);
}

/**
 * Fetch public company info from API by ID
 *
 * @param {string} companyId - company ID
 *
 * @returns {object} company data
 */
export async function getPublicCompany(companyId) {
  return api.get(`/companies/${companyId}/public`);
}

/**
 * Fetch user company info from API
 *
 * @returns promise
 */
export async function getUserCompany() {
  return api.get('/user/company');
}

/**
 * Fetch all companies to show in the admin console from API
 *
 * @returns {array} list of companies
 */
export async function getAdminConsoleCompanies() {
  return await api.get('/admin/companies');
}

/**
 * Fetch companies that a user has access to from API
 *
 * @param {string} userId - user ID
 *
 * @returns {Promise<CompanyData[]>} list of companies that the user has access to
 */
export async function getUserCompanies(userId) {
  return api.get(`/users/${userId}/companies`);
}

/**
 * Users
 */

/**
 * Simple user update to change: name, phone, email and group.
 *
 * @param {string} userId - user ID
 * @param {object} data - user update data
 *
 * @returns response promise
 */
export async function simpleUserUpdate(userId, data) {
  return api.post('/user/update', {
    ...data,
    id: userId,
  });
}

/**
 * Send request to the API to change the company active state
 *
 * @param {string} companyId - company ID
 * @param {boolean} newActiveState - updated active state
 *
 * @returns HTTP request response
 */
export async function changeCompanyActiveState(companyId, newActiveState) {
  return await api.post(`/companies/${companyId}/change-active-state`, {
    active: newActiveState,
  });
}

export async function listConsultants(data) {
  return api.get('/consultants', {
    params: data,
  });
}

/**
 * Risk analysis
 */

export async function getRisco(rapida) {
  return await api.get('/analiserisco', {
    params: {
      idEmpresa: store.getState().company.company._id,
      rapida,
    },
  });
}

export async function getRiscoBetweenReunions(companyId, meetingId) {
  return api.get(`/companies/${companyId}/meetings/${meetingId}/updated-items-between-meetings`, {
    params: {
      index: localStorage.getItem('reunion_index'),
    },
  });
}

export async function getRiscoNotResponsible(limit) {
  return await api.get('/analiserisco/notresponsible', {
    params: {
      limit,
      idEmpresa: store.getState().company.company._id,
    },
  });
}

export async function getRiscoByReunionID(value) {
  return await api.get('/analiserisco/reunion/id', {
    params: {
      idEmpresa: store.getState().company.company._id,
      value,
    },
  });
}

export async function notReunion() {
  return await api.get('/analiserisco/notreunion', {
    params: {
      idEmpresa: store.getState().company.company._id,
    },
  });
}

export async function byStatus(value, type) {
  return await api.get(`/analiserisco/status?idEmpresa=${store.getState().company.company._id}&type=${type}&value=${value}`);
}

export async function getRanking(userName) {
  const companyId = store.getState().company.company._id;
  return await api.get(`/companies/${companyId}/users/${userName}/actions`);
}

export async function getRankingSemUsuario() {
  const companyId = store.getState().company.company._id;
  return await api.get(`/companies/${companyId}/action-plan/actions`, {
    params: {
      assignee: 'null',
      taskStatus: TASK_STATUSES.IN_PROGRESS,
      actionStatus: ACTION_STATUSES.IN_PROGRESS,
    },
  });
}

/**
 * List company actions
 *
 * @param {string} companyId - company ID.
 * @param {string | null | undefined} filters.assignee - assignee filter, provide null if you want to
 * list only unsigned actions. Provide undefined to ignore this filter.
 * @param {TASK_STATUSES[] | undefined} filters.taskStatus - status of the task.
 * Provide undefined to ignore this filter.
 * @param {ACTION_STATUSES | undefined} filters.actionStatus - status of the action. Provide
 * undefined to ignore this filter.
 * @param {boolean | undefined} filters.isFast - fast action/task filter. Provide undefined to
 * ignore this filter.
 */
export async function listCompanyActions(companyId, filters = {
  assignee: undefined,
  taskStatus: undefined,
  actionStatus: undefined,
  isFast: undefined,
}) {
  return await api.get(`/companies/${companyId}/action-plan/actions`, {
    params: {
      ...filters,
      taskStatus: undefined != filters.taskStatus ? `${filters.taskStatus}` : undefined,
      assignee: undefined !== filters.assignee ? `${filters.assignee}` : undefined,
    },
  });
}

export async function updateCompanyActionStatus(companyId, actionId, status) {
  return api.post(`/companies/${companyId}/action-plan/actions/${actionId}/status`, {
    value: status,
  });
}

export async function updateCompanyActionWho(companyId, actionId, who) {
  return api.post(`/companies/${companyId}/action-plan/actions/${actionId}/who`, {
    value: who,
  });
}

export async function updateCompanyActionMeetings(companyId, actionId, meetings) {
  return api.post(`/companies/${companyId}/action-plan/actions/${actionId}/meetings`, {
    meetings,
  });
}

/**
 * @param {string} companyId
 * @param {number} actionId
 * @param {number[]} impactReportIds
 */
export async function updateCompanyActionImpactReports(companyId, actionId, impactReportIds) {
  return api.post(`/companies/${companyId}/action-plan/actions/${actionId}/impact-reports`, {
    impactReports: impactReportIds,
  });
}

export async function saveCompanyAction(companyId, actionId, data) {
  return api.put(`/companies/${companyId}/action-plan/actions/${actionId}`, data);
}

export async function searchRisco(value) {
  return await api.get(`/analiserisco/search?idEmpresa=${store.getState().company.company._id}&value=${value}`);
}

export async function getRespostaRisco() {
  return await api.get(`/showanaliserisco?idEmpresa=${store.getState().company.company._id}&rapida=${false}`);
}

/**
 * Action plan
 */

export async function getPlano(companyId, answer = 1) {
  return await api.get(`/companies/${companyId}/action-plan`, {
    params: {
      resposta: answer,
    },
  });
}

//Requisiçoes usuarios
export async function getUniqueUser() {
  return await api.get(
    `/user?id=${localStorage.getItem('user')}`
  )
}

export async function getUniqueUserParams(user) {
  return await api.get(`/user?id=${user}`)
}

export async function getUsers() {
  return await api.get('/users')
}

/**
 * Fetch user info from the API
 *
 * @param {string} userId - user ID
 *
 * @returns user data
 */
export async function getUser(userId) {
  return api.get('/user', {
    params: {
      id: userId,
    },
  });
}

export async function listCompanyUsers(companyId) {
  return api.get(`/companies/${companyId}/users`);
}

/**
 * Fetch public user info from the API
 *
 * @param {string} userId - user ID
 *
 * @returns public user data
 */
export async function getPublicUser(userId) {
  return api.get(`/users/${userId}/public`);
}

/**
 * Send request to API to send the password recovery email to the received email
 *
 * @param {string} email - email to send the password recovery link
 */
export async function sendPasswordRecoveryEmail(email) {
  return await api.post(`/forgot_password`, {
    email,
  });
}

/**
 * Send request to API to change user active state
 *
 * @param {string} userId - user to change active state
 * @param {boolean} activeState - updated active state
 */
export async function changeUserActiveState(userId, activeState) {
  return api.post(`/user/${userId}/change-active-state`, {
    active: activeState,
  });
}

export async function changeUserFollowupEnabled(userId, enabled) {
  return api.post(`/user/${userId}/change-follow-up-enabled`, {
    enabled,
  });
}

/**
 * Send request to API to delete user
 *
 * @param {string} userId - user to delete
 */
export async function deleteUser(userId) {
  return api.delete(`/user/${userId}`);
}

export async function getUserOnline(id) {
  return await api.get(`/user/online${id}`)
}
export async function updateUserContract() {
  return await api.post(`/user/contract?user=${localStorage.getItem('user')}`);
}

export async function getHistoricoLogins() {
  return await api.get('hist/all')
}

/**
 * Meetings
 */

export async function updateMeetingTitle(companyId, meetingId, title) {
  return api.put(`/companies/${companyId}/meetings/${meetingId}/title`, {
    title,
  });
}

export async function updateMeetingStart(companyId, meetingId, value) {
  return api.put(`/companies/${companyId}/meetings/${meetingId}/start`, {
    value,
  });
}

export async function updateMeetingDate(companyId, meetingId, value) {
  return api.put(`/companies/${companyId}/meetings/${meetingId}/date`, {
    value,
  });
}

export async function updateMeetingEnd(companyId, meetingId, value) {
  return api.put(`/companies/${companyId}/meetings/${meetingId}/end`, {
    value,
  });
}

export async function listCompanyMeetings(companyId) {
  const _companyId = companyId || store.getState().company.company._id;
  return await api.get(`/reunion${_companyId}`);
}

export async function searchReunions(value) {
  return await api.post('/reunion/search', {
    empresa: store.getState().company.company._id,
    value: value,
  })
}

export async function getItensForReunions(companyId, meetingId) {
  return await api.get(`/companies/${companyId}/meetings/${meetingId}/items`);
}

export async function getItensForReunionsByDate() {
  return await api.get(`/reunion/orderbydate?empresa=${store.getState().company.company._id}`);
}

export async function getReunionsUnique(companyId, meetingId) {
  return await api.get(`/companies/${companyId}/meetings/${meetingId}`);
}

export async function deleteCompanyMeeting(companyId, meetingId) {
  return await api.delete(`/companies/${companyId}/meetings/${meetingId}`);
}

export async function getReunionsParticipants(companyId, meetingId) {
  return await api.get(`/companies/${companyId}/meetings/${meetingId}/participants`);
}

export async function saveMinuteOfMeeting(companyId, meetingId, ata) {
  return await api.put(`/companies/${companyId}/meetings/${meetingId}/minute`, {
    ata,
  });
}

export async function insertReunionParticipant(companyId, meetingId, participants) {
  return await api.put(`/companies/${companyId}/meetings/${meetingId}/participants`, {
    participants,
  });
}

export async function removeMeetingParticipant(companyId, meetingId, participantId) {
  return await api.delete(`/companies/${companyId}/meetings/${meetingId}/participants/${participantId}`);
}

export async function createMeeting(data) {
  return api.post('/reunion', data);
}

export async function registerMeeting(companyId, meetingId) {
  return await api.post(`/companies/${companyId}/meetings/${meetingId}/register`);
}

/**
 * Documents
 */

export async function updateDocument(companyId, documentId, data) {
  return api.put(`/companies/${companyId}/documents/${documentId}`, data);
}

export async function postDocs(form, options) {
  const companyId = store.getState().company.company._id;
  return await api.post(`/companies/${companyId}/documents`, form, {
    ...options,
    headers: {
      'content-type': 'multipart/form-data',
    },
  });
}


export async function getDocs() {
  return await api.get(`/companies/${store.getState().company.company._id}/documents`);
}

export async function searchDocs(value) {
  return await api.get(`/companies/${store.getState().company.company._id}/documents/search`, {
    params: {
      value,
    },
  });
}

export async function getDocsWorkshop() {
  return await api.get(`companies/${store.getState().company.company._id}/workshop`);
}

export async function uploadWorkshop(companyId, workshopId, file) {
  const form = new FormData();
  form.append('doc', file);
  form.append('workshopId', workshopId);

  await api.post(`/companies/${companyId}/workshop`, form, {
    headers: {
      'content-type': 'multipart/form-data',
    },
  });
}

export async function downloadFile(companyId, documentId) {
  return api.get(`/companies/${companyId}/documents/${documentId}/download`, {
    responseType: 'blob',
  });
}

export async function getFileVisualizationLink(companyId, documentId) {
  return api.get(`/companies/${companyId}/documents/${documentId}/signed-link`);
}

export async function deleteDocument(companyId, documentId) {
  return api.delete(`/companies/${companyId}/documents/${documentId}`);
}

/**
 * Data mapping links
 */

export async function changeDataMappingRevocationState(id, value) {
  const companyId = store.getState().company.company._id;
  return await api.post(`/companies/${companyId}/data-mapping-links/${id}/revoke`, {
    value,
  });
}

export async function updateTokenValidity(id, setor, time) {
  const companyId = store.getState().company.company._id;
  return await api.patch(`/companies/${companyId}/data-mapping-links/${id}`, {
    setor,
    time,
  });
}

export async function getLinksPublico() {
  const companyId = store.getState().company.company._id;
  return await api.get(`/companies/${companyId}/data-mapping-links`);
}

export async function postLinkPublico(time, setor) {
  const companyId = store.getState().company.company._id;
  return await api.post(`/companies/${companyId}/data-mapping-links`, {
    setor,
    time,
  });
}

/**
 * Data mapping
 */

export async function exportCompanyDataMapping(companyId) {
  return api.get(`/companies/${companyId}/data-mapping/export`, {
    responseType: 'blob',
  });
}

export async function getDataMappingRiskAnalysis(companyId) {
  return api.get(`/companies/${companyId}/data-mapping/risk-analysis`);
}

/**
 * Send request to API to copy phase fields to another phase
 *
 * @param {string} companyId - company that the data mapping phases belong to
 * @param {number} from - phase ID that the fields are being copied from
 * @param {number} to - phase ID that the fields are being copied from
 */
export async function copyDataMappingPhase(companyId, from, to) {
  return api.post(`/companies/${companyId}/data-mapping/phases/${from}`, {
    to,
  });
}

export async function getEtapas() {
  return await api.get(`/mapeamento/etapa?idEmpresa=${store.getState().company.company._id}`);
}

export async function listCompanyDataMappingPhases(companyId) {
  return await api.get(`/mapeamento/etapa?idEmpresa=${companyId}`);
}

export async function getRespostaMapeamentoPublic(token) {
  return await api.get('/data-mapping-link/answers', {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export async function getRespostaMapeamento(orderByField, customReport) {
  const companyId = store.getState().company.company._id;
  return await api.get(`/companies/${companyId}/data-mapping/answers`, {
    params: {
      orderByField,
      customReport,
    },
  });
}

export async function cadastroEtapa(companyId, data) {
  return api.post('mapeamento', {
    idEmpresa: companyId,
    name: data.name,
  });
}

export async function updateDatMappingPhase(companyId, data) {
  return api.post(`/mapeamento/etapa?id=${data.id}&value=${data.name}`);
}

export async function etapaObrigatoria(name, id) {
  return await api.post('mapeamento', {
    idEmpresa: store.getState().company.company._id,
  })
}

export async function createDataMappingPhaseField(companyId, phaseId, data) {
  return await api.post(`/companies/${companyId}/data-mapping/phases/${phaseId}/fields`, data);
}

export async function updateDataMappingField(companyId, phaseId, data) {
  return api.put(`/companies/${companyId}/data-mapping/phases/${phaseId}/fields/${data.id}`, data);
}

export async function deleteDataMappingField(companyId, phaseId, fieldId) {
  return api.delete(`/companies/${companyId}/data-mapping/phases/${phaseId}/fields/${fieldId}`);
}

export async function deleteDataMappingPhase(companyId, phaseId) {
  return api.delete(`/companies/${companyId}/data-mapping/phases/${phaseId}`);
}

export async function listDataMappingPhaseFields(companyId, phaseId) {
  return api.get(`/companies/${companyId}/data-mapping/phases/${phaseId}/fields`);
}

/**
 * Create new option in mapping field
 *
 * @param {string} option - new option value
 * @param {number} fieldId - field ID
 * @param {number} phaseId - phase ID
 *
 * @returns {Promise<object>} request response
 */
export async function createMappingFieldOption(option, fieldId, phaseId) {
  const companyId = store.getState().company.company._id;
  const url = `companies/${companyId}/data-mapping/phases/${phaseId}/fields/${fieldId}/options`;
  return await api.post(url, {
    option,
  });
}

export async function getCompanyDataMappingCellResponse(companyId, answerUniqueId, fieldId) {
  return api.get(`/companies/${companyId}/data-mapping/answers/${answerUniqueId}/cell`, {
    params: {
      fieldId,
    },
  });
}

export async function getDataMappingLinkCellResponse(token, answerUniqueId, fieldId) {
  return api.get(`/data-mapping-link/answers/${answerUniqueId}/cell`, {
    params: {
      fieldId,
    },
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

/**
 * Send request to save field response to the API
 *
 * @param {object} fieldResponseData - object representing the field response data
 *
 * @returns response promise
 */
export async function saveMappingFieldResponse(fieldResponseData) {
  return api.post('/mapeamento/insertresponse', fieldResponseData);
}

/**
 * Update mapping field order
 *
 * @param {number} fieldId - field ID
 * @param {number} order - updated field order
 *
 * @returns {object} request promise
 */
export async function updateMappingFieldOrder(companyId, phaseId, fieldId, order) {
  return api.put(`/companies/${companyId}/data-mapping/phases/${phaseId}/fields/${fieldId}/order`, {
    order,
  });
}

export async function deleteDataMappingAnswer(companyId, answerUniqueId) {
  return api.delete('/mapeamento/resposta', {
    params: {
      uid: answerUniqueId,
      idEmpresa: companyId,
    },
  });
}

export async function createDataMappingAnswer(companyId, uid, token, order, positionType, sector) {
  return api.post('/mapeamento/mapa/respostas', {
    idEmpresa: companyId,
    name: uid,
    uid: uid,
    token,
    ordem: order,
    positionType,
    sector,
  });
}

export async function listCompanyDataMappingReport(companyId, page = 0, pageSize = 1,
  orderByField = undefined, customReport = undefined) {
  return api.get(`/companies/${companyId}/mapping-report`, {
    params: {
      page,
      pageSize,
      orderByField,
      customReport,
    },
  });
}

/**
 * Custom data mapping reports
 */

export async function listCustomDataMappingReports(companyId) {
  return api.get(`/companies/${companyId}/data-mapping/custom-reports`);
}

export async function createCustomDataMappingReport(companyId, data) {
  return api.post(`/companies/${companyId}/data-mapping/custom-reports`, data);
}

export async function deleteCustomDataMappingReport(companyId, reportId) {
  return api.delete(`/companies/${companyId}/data-mapping/custom-reports/${reportId}`);
}

/**
 * Workshop
 */

export async function GetOrPostWorkshop() {
  return await api.post(`/workshop?idEmpresa=${store.getState().company.company._id}`);
}

export async function GetConstituicaoByWorkShop() {
  return await api.get(`/workshop/constituicao?idEmpresa=${store.getState().company.company._id}`);
}

/**
 * Cookies management endpoints
 */

/**
 * Fetch cookies categories from API
 *
 * @returns response promise
 */
export async function getCookiesCategories() {
  return api.get('/cookieBot/cookiesCategories');
}

/**
 * Fetch company registed popups from the API
 *
 * @param {string} companyId - company ID
 *
 * @returns response promise
 */
export async function getCompanyRegisteredPopups(companyId) {
  return api.get(`cookieBot/${companyId}/cookiePopups`);
}

/**
 * Get popup config data from the API
 *
 * @param {string} popupId - popup ID
 *
 * @returns response promise
 */
export async function getCompanyPopup(popupId) {
  return api.get(`/cookieBot/cookiePopups/${popupId}`);
}

/**
 * Send request to API to create new company cookie popup for a site
 *
 * @param {string} companyId - company ID
 * @param {object} data - popup data
 *
 * @returns response promise
 */
export async function createCompanyPopup(companyId, data) {
  return api.post(`/cookieBot/${companyId}/cookiePopups`, data);
}

/**
 * Send request to API to update a company cookie popup
 *
 * @param {string} companyId - company ID
 * @param {string} popupId - popup ID
 * @param {object} data - popup data
 *
 * @returns response promise
 */
export async function updateCompanyPopup(companyId, popupId, data) {
  return api.put(`/cookieBot/${companyId}/cookiePopups/${popupId}`, data);
}

/**
 * Get popup consents
 *
 * @param {string} companyId - company ID
 * @param {string} popupId - popup ID
 * @param {number} page - current page
 * @param {number} pageSize - page size to be used
 *
 * @returns response promise
 */
export async function getPopupConsents(companyId, popupId, page = 0, pageSize = 5) {
  return api.get(`/cookieBot/${companyId}/cookiePopups/${popupId}/consents`, {
    params: {
      page,
      pageSize,
    },
  });
}

/**
 * Fetch identified cookies from API for a given popup
 *
 * @param {string} companyId - company ID
 * @param {string} popupId - popup ID
 *
 * @returns response promise
 */
export async function getPopupCookies(companyId, popupId) {
  return api.get(`/cookieBot/${companyId}/cookiePopups/${popupId}/cookies`);
}

/**
 * Send request to API to update popup cookie info
 * @param {string} companyId - Company ID
 * @param {string} popupId - Popup ID
 * @param {number} cookieId - cookie ID
 * @param {object} data - cookie data
 *
 * @returns response promise
 */
export async function updatePopupCookie(companyId, popupId, cookieId, data) {
  return api.put(`/cookieBot/${companyId}/cookiePopups/${popupId}/cookies/${cookieId}`, data);
}

/**
 * Send request to the API to delete a cookie
 *
 * @param {string} companyId - company ID
 * @param {string} popupConfigId - popup config ID
 * @param {number} cookieId - cookie ID
 *
 * @returns request response promise
 */
export async function deletePopupCookie(companyId, popupConfigId, cookieId) {
  return api.delete(`companies/${companyId}/cookie-popups/${popupConfigId}/cookies/${cookieId}`);
}

export async function createPopupCookie(companyId, popupConfigId, data) {
  return api.post(`companies/${companyId}/cookie-popups/${popupConfigId}/cookies`, data);
}

/**
 * DPO endpoints
 */

/**
 * Fetch activity records from the API
 *
 * @param {string} companyId - company ID
 * @param {number} page - page number
 * @param {number} pageSize - page size
 *
 * @returns {Promise<object>} response promise
 */
export async function getCompanyActivityRecords(companyId, page, pageSize) {
  return await api.get(`/companies/${companyId}/activity-records`, {
    params: {
      page,
      pageSize,
    },
  });
}

export async function InnerNotification(name, id, data, hora, local, problem, efeito, descoberto,
  resolucao, mitigar) {
  await api.post('/privacidade', {
    acoes_tomadas: resolucao,
    como_descoberto: descoberto,
    data: data,
    efeito_incidente: efeito,
    name: name,
    user_id: id,
    hora: hora,
    loc_incidente: local,
    problema: problem,
    acoes_preventivas: mitigar,
    empresa: store.getState().company.company._id,
  })
}

export async function getNotification(empresa) {
  return await api.get(`/privacidade${empresa}`)
}

export async function EditNotification(name, id, data, hora, local, problem, efeito, descoberto,
  resolucao, mitigar, idNotification) {
  await api.patch('/privacidade', {
    id: idNotification,
    acoes_tomadas: resolucao,
    como_descoberto: descoberto,
    data: data,
    efeito_incidente: efeito,
    name: name,
    user_id: id,
    hora: hora,
    loc_incidente: local,
    problema: problem,
    acoes_preventivas: mitigar,
    empresa: store.getState().company.company._id,
  });
}

/**
 * Send request to contact company DPO
 *
 * @param {object} data - request data
 */
export async function sendDPOContactRequest(data) {
  await api.post('/solicitacao', data);
}

/**
 * Send request to update DPO contact request
 *
 * @param {object} data - request data
 */
export async function updateDPOContactRequest(data) {
  return api.post('/solicitacao/update', {}, {
    params: {
      id: data.id,
      resp: data.response,
      status: data.status,
      email: data.email,
      name: data.name,
    },
  });
}

/**
 * List company contact requests
 *
 * @param {string} companyId - company ID
 * @param {boolean} pendingOnly - only lists pending contact requests
 */
export async function getCompanyContactRequests(companyId, pendingOnly) {
  return api.get(`/companies/${companyId}/contact-requests`, {
    params: {
      pendingOnly: Boolean(pendingOnly),
    },
  });
}

export async function sendContactRequestEmailVerification(companyId, email) {
  return api.post(`/companies/${companyId}/contact-requests/send-verification`, {
    email,
  });
}

export async function listCompanyContactRequestObservations(companyId, contactRequestId) {
  return api.get(`/companies/${companyId}/contact-requests/${contactRequestId}/observations`);
}

export async function listApplicantContactRequestsInCompany(companyId, token) {
  return api.get(`/applicant/companies/${companyId}/contact-requests`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export async function listApplicantContactRequestObservations(token, companyId, contactRequestId) {
  return api.get(`/applicant/companies/${companyId}/contact-requests/${contactRequestId}/observations`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export async function uploadContactRequestDocument(contactRequestId, file, message) {
  const form = new FormData();
  form.append('doc', file);
  form.append('message', message);

  return await api.post(`/contact-requests/${contactRequestId}/upload-file`, form, {
    headers: {
      'content-type': 'multipart/form-data',
    },
  });
}

/**
 * Courses
 */

/**
 * Fetch company courses
 *
 * @param {string} companyId - company ID
 *
 * @returns request promise
 */
export async function getCourses(companyId) {
  return api.get(`/companies/${companyId}/courses`);
}

/**
 * Create course
 *
 * @param {string} companyId - company ID
 * @param {object} courseData - course info
 *
 * @returns request promise
 */
export async function createCourse(companyId, courseData) {
  return api.post(`/companies/${companyId}/courses`, courseData);
}


/**
 * Update course
 *
 * @param {string} companyId - company ID
 * @param {number} courseId - course ID
 * @param {object} courseData - course info
 *
 * @returns request promise
 */
export async function updateCourse(companyId, courseId, courseData) {
  return api.put(`/companies/${companyId}/courses/${courseId}`, courseData);
}

/**
 * Get company course info from API
 *
 * @param {string} companyId - company ID
 * @param {number} courseId - course ID
 *
 * @returns request promise
 */
export async function getCompanyCourse(companyId, courseId) {
  return api.get(`/companies/${companyId}/courses/${courseId}`);
}

/**
 * Get course info from API
 *
 * @param {number} courseId - course ID
 *
 * @returns request promise
 */
export async function getCourse(courseId) {
  return api.get(`/courses/${courseId}`);
}

/**
 * Send request to API to share course with collaborators
 *
 * @param {string} companyId - company ID
 * @param {number} courseId - course ID
 * @param {array} collaborators - array of collaborator IDs
 *
 * @returns request promise
 */
export async function shareCourse(companyId, courseId, collaborators) {
  return api.post(`/companies/${companyId}/courses/${courseId}/send`, {
    collaborators,
  });
}


/**
 * Send request to API to delete course
 *
 * @param {string} companyId - company ID
 * @param {number} courseId - course ID
 *
 * @returns request promise
 */
export async function deleteCourse(companyId, courseId) {
  return api.delete(`/companies/${companyId}/courses/${courseId}`);
}

/**
 * Answer course test
 *
 * @param {string} companyId - company ID
 * @param {number} courseId - course ID
 * @param {number} testId - test ID
 * @param {object} data - answer data
 *
 * @returns request promise
 */
export async function answerCourseTest(companyId, courseId, testId, data) {
  return api.post(`/companies/${companyId}/courses/${courseId}/tests/${testId}/answer`, data);
}

/**
 * Collaborators
 */

/**
 * Fetch collaborators from API
 *
 * @param {string} companyId - company ID
 *
 * @returns request promise
 */
export async function getCollaborators(companyId) {
  return api.get(`/companies/${companyId}/collaborators`);
}

/**
 * Get collaborator data from API
 *
 * @param {string} companyId - company ID
 * @param {number} collaboratorId - collaborator ID
 *
 * @returns request promise
 */
export async function getCollaborator(companyId, collaboratorId) {
  return api.get(`/companies/${companyId}/collaborators/${collaboratorId}`);
}

/**
 * Send request to API to create new collaborator
 *
 * @param {string} companyId - company ID
 * @param {object} collaboratorData - collaborator data
 *
 * @returns request promise
 */
export async function createCollaborator(companyId, collaboratorData) {
  return api.post(`/companies/${companyId}/collaborators`, collaboratorData);
}

/**
 * Create collaborators from CSV file
 *
 * @param {string} companyId - company ID
 * @param {any} file - CSV file
 *
 * @returns {Promise<object>} response promise
 */
export async function createCollaboratorFromCSV(companyId, file) {
  const form = new FormData();
  form.append('file', file);

  return api.post(`/companies/${companyId}/collaborators/from-csv`, form, {
      headers: {
        'content-type': 'multipart/form-data',
      },
    }
  );
}

/**
 * Send request to API to update collaborator
 *
 * @param {string} companyId - company ID
 * @param {object} collaboratorData - collaborator data
 *
 * @returns request promise
 */
export async function updateCollaborator(companyId, collaboratorData) {
  return api.put(`/companies/${companyId}/collaborators/${collaboratorData.id}`, collaboratorData);
}

/**
 * Send request to API to delete collaborator
 *
 * @param {string} companyId - company ID
 * @param {number} collaboratorId - collaborator ID
 *
 * @returns request promise
 */
export async function deleteCollaborator(companyId, collaboratorId) {
  return api.delete(`/companies/${companyId}/collaborators/${collaboratorId}`);
}

/**
 * Get course collaborator progress tracking
 *
 * @param {string} companyId - company ID
 * @param {number} courseId - course ID
 *
 * @returns request promise
 */
export async function getCourseCollaboratorsTracking(companyId, courseId) {
  return api.get(`companies/${companyId}/courses/${courseId}/attendance-requests`);
}

/**
 * Register
 */

/**
 * Register new consultant
 *
 * @param {object} data - consultant data
 *
 * @returns promise
 */
export async function registerConsultant(data) {
  return api.post('/singup', data, {
    withCredentials: true,
  });
}

/**
 * Register new user
 *
 * @param {object} data - user data
 *
 * @returns promise
 */
export async function registerUser(data) {
  return api.post('/signup-entrepreneur', data, {
    withCredentials: true,
  });
}

/**
 * Send request to API to create a new consultant
 *
 * @param {object} data - new consultant data
 *
 * @returns {Promise<object>} response promise
 */
export async function createConsultant(data) {
  return api.post('/singup/consultor', data);
}

/**
 * Download risk report for a company
 *
 * @param {string} companyId - company ID
 *
 * @returns promise
 */
export async function downloadRiskReport(companyId) {
  return api.get(`/companies/${companyId}/risk-report/download`, {
    responseType: 'arraybuffer',
  });
}

/**
 * Company observations
 */

/**
 * Fetch company observations from the API
 *
 * @param {string} companyId - company ID
 *
 * @returns response promise
 */
export async function getCompanyObservations(companyId) {
  return api.get(`companies/${companyId}/observations`);
}

/**
 * Send request to create company observation
 *
 * @param {string} companyId - company ID
 * @param {object} data - observation data
 *
 * @returns response promise
 */
export async function createCompanyObservation(companyId, data) {
  return api.post(`companies/${companyId}/observations`, data);
}

/**
 * Send request to update company observation
 *
 * @param {string} companyId - company ID
 * @param {number} observationId - observation ID
 * @param {object} data - updated observation data
 *
 * @returns response promise
 */
export async function updateCompanyObservation(companyId, observationId, data) {
  return api.put(`companies/${companyId}/observations${observationId}`, data);
}

/**
 * Processing Agents
 */

/**
 * Create new processing agent
 *
 * @param {string} companyId - company ID
 * @param {object} data - processing agent data
 *
 * @returns {Promise<object>} response promise
 */
export async function createProcessingAgent(companyId, data) {
  return api.post(`/companies/${companyId}/processing-agents`, data);
}

export async function createManyProcessingAgentsFromCSVFile(companyId, file) {
  const form = new FormData();
  form.append('file', file);

  return await api.post(`/companies/${companyId}/processing-agents/import-from-csv`, form, {
    headers: {
      'content-type': 'multipart/form-data',
    },
  });
}


/**
 * Fetch company processing agents from the API
 * processing agent
 *
 * @param {string} companyId - company ID
 *
 * @returns {Promise<object>} response promise
 */
export async function listProcessingAgents(companyId) {
  return api.get(`/companies/${companyId}/processing-agents`);
}

/**
 * Get processing agent data from the API
 *
 * @param {string} companyId - company ID
 * @param {number} processingAgentId - processing agent ID
 *
 * @returns {Promise<object>} response promise
 */
export async function getProcessingAgent(companyId, processingAgentId) {
  return api.get(`/companies/${companyId}/processing-agents/${processingAgentId}`);
}

/**
 * Update processing agent data in the API
 *
 * @param {string} companyId - company ID
 * @param {number} processingAgentId - processing agent ID
 * @param {*} data
 *
 * @returns {Promise<object>} response promise
 */
export async function updateProcessingAgent(companyId, processingAgentId, data) {
  return api.put(`/companies/${companyId}/processing-agents/${processingAgentId}`, data);
}

/**
 * Delete processing agent
 *
 * @param {string} companyId - company ID
 * @param {number} processingAgentId - processing agent ID
 *
 * @returns {Promise<object>} response promise
 */
export async function deleteProcessingAgent(companyId, processingAgentId) {
  return api.delete(`/companies/${companyId}/processing-agents/${processingAgentId}`);
}

/**
 * Impact Reports
 */

/**
 * Create new impact report
 *
 * @param {string} companyId - company ID
 * @param {object} data - impact report data
 *
 * @returns {Promise<object>} response promise
 */
export async function createImpactReport(companyId, data) {
  return api.post(`/companies/${companyId}/impact-reports`, data);
}

/**
 * Create new app impact report template
 *
 * @param {object} data - impact report data
 *
 * @returns {Promise<object>} response promise
 */
export async function createAppImpactReportTemplate(data) {
  return api.post(`/admin/impact-report-templates`, data);
}

/**
 * Send request to API to save impact report content
 *
 * @param {string} companyId - company ID
 * @param {number} reportId - impact report ID
 * @param {string} content - impact report content
 *
 * @returns {Promise<object>} response promise
 */
export async function saveImpactReportContent(companyId, reportId, content) {
  return api.post(`/companies/${companyId}/impact-reports/${reportId}/content`, {
    content,
  });
}

/**
 * Send request to API to save global impact report template content
 *
 * @param {number} reportId - impact report ID
 * @param {string} content - impact report content
 *
 * @returns {Promise<object>} response promise
 */
export async function saveGlobalImpactReportTemplateContent(reportId, content) {
  return api.post(`/admin/impact-report-templates/${reportId}/content`, {
    content,
  });
}

/**
 * Get impact report content
 *
 * @param {string} companyId - company ID
 * @param {number} reportId - impact report ID
 * @param {number} versionId - impact report review version ID
 *
 * @returns {Promise<object>} response promise
 */
export async function getImpactReportContent(companyId, reportId, versionId) {
  const reportLocator = versionId != undefined ? `${reportId}/versions/${versionId}` :
    reportId;
  return api.get(`/companies/${companyId}/impact-reports/${reportLocator}/content`);
}

export async function downloadImpactReportAsPDF(companyId, reportId, versionId) {
  const reportLocator = versionId != undefined ? `${reportId}/versions/${versionId}` :
    reportId;
  return api.get(`/companies/${companyId}/impact-reports/${reportLocator}/convert-to-pdf`, {
    responseType: 'arraybuffer',
  });
}

/**
 * Get global impact report template content
 *
 * @param {number} reportId - impact report ID
 *
 * @returns {Promise<object>} response promise
 */
export async function getGlobalImpactReportTemplateContent(reportId) {
  return api.get(`/admin/impact-report-templates/${reportId}/content`);
}

/**
 * Fetch company impact reports from the API
 *
 * @param {string} companyId - company ID
 *
 * @returns {Promise<object>} response promise
 */
export async function listImpactReports(companyId) {
  return api.get(`/companies/${companyId}/impact-reports`);
}

/**
 * Fetch app/global impact reports templates from the API
 *
 * @returns {Promise<object>} response promise
 */
export async function listGlobalImpactReportTemplates() {
  return api.get(`/admin/impact-report-templates`);
}

/**
 * Fetch company impact report templates from the API
 *
 * @param {string} companyId - company ID
 *
 * @returns {Promise<object>} response promise
 */
export async function listImpactReportTemplates(companyId) {
  return api.get(`/companies/${companyId}/impact-reports/templates`);
}

/**
 * Get impact report data from the API
 *
 * @param {string} companyId - company ID
 * @param {number} impactReportId - impact report ID
 *
 * @returns {Promise<object>} response promise
 */
export async function getImpactReport(companyId, impactReportId) {
  return api.get(`/companies/${companyId}/impact-reports/${impactReportId}`);
}

/**
 * Upload impact report image
 *
 * @param {string} companyId - company ID
 * @param {number} impactReportId - impact report ID
 * @param {any} file - file image to be sent
 *
 * @returns {Promise<object>} response promise
 */
export async function uploadImpactReportImage(companyId, impactReportId, file) {
  const form = new FormData();
  form.append('file', file);

  return api.post(`/companies/${companyId}/impact-reports/${impactReportId}/images`, form, {
      headers: {
        'content-type': 'multipart/form-data',
      },
    }
  )
}

/**
 * Upload global impact report template image
 *
 * @param {number} impactReportId - impact report ID
 * @param {any} file - file image to be sent
 *
 * @returns {Promise<object>} response promise
 */
export async function uploadGlobalImpactReportTemplateImage(impactReportId, file) {
  const form = new FormData();
  form.append('file', file);

  return api.post(`/admin/impact-report-templates/${impactReportId}/images`, form, {
      headers: {
        'content-type': 'multipart/form-data',
      },
    }
  )
}

/**
 * Create new impact report review version
 *
 * @param {string} companyId - company ID
 * @param {number} impactReportId - impact report ID
 * @param {object} data - review version data
 *
 * @returns {Promise<object>} response promise
 */
export async function createImpactReportReview(companyId, impactReportId, data) {
  return api.post(`/companies/${companyId}/impact-reports/${impactReportId}/versions`, data);
}

/**
 * Send request to API to delete impact report
 *
 * @param {string} companyId - company ID
 * @param {number} impactReportId - impact report ID
 *
 * @returns {Promise<object>} response promise
 */
export async function deleteImpactReport(companyId, impactReportId) {
  return api.delete(`/companies/${companyId}/impact-reports/${impactReportId}`);
}

/**
 * Send request to API to delete global impact report template
 *
 * @param {number} impactReportId - impact report ID
 *
 * @returns {Promise<object>} response promise
 */
export async function deleteGlobalImpactReportTemplate(impactReportId) {
  return api.delete(`/admin/impact-report-templates/${impactReportId}`);
}

/**
 * @param {number} impactReportId
 */
export function listGlobalImpactReportTemplateWidgets(impactReportId) {
  return api.get(`/admin/impact-report-templates/${impactReportId}/widgets`);
}

export function createGlobalImpactReportTemplateWidget(impactReportId, widgetData) {
  return api.post(`/admin/impact-report-templates/${impactReportId}/widgets`, widgetData);
}

export function updateGlobalImpactReportTemplateWidget(impactReportId, widgetId, widgetData) {
  return api.put(`/admin/impact-report-templates/${impactReportId}/widgets/${widgetId}`,
    widgetData);
}

export function deleteGlobalImpactReportTemplateWidget(impactReportId, widgetId) {
  return api.delete(`/admin/impact-report-templates/${impactReportId}/widgets/${widgetId}`);
}

/**
 * @param {string} companyId
 * @param {number} impactReportId
 * @param {number | undefined} versionId
 */
export function listCompanyImpactReportWidgets(companyId, impactReportId, versionId) {
  const reportLocator = versionId != undefined ? `${impactReportId}/versions/${versionId}` :
    impactReportId;
  return api.get(`/companies/${companyId}/impact-reports/${reportLocator}/widgets`, {
    params: {
      versionId,
    },
  });
}

export function createCompanyImpactReportWidget(companyId, impactReportId, widgetData) {
  return api.post(`/companies/${companyId}/impact-reports/${impactReportId}/widgets`, widgetData);
}

export function updateCompanyImpactReportWidget(companyId, impactReportId, widgetId, widgetData) {
  return api.put(`/companies/${companyId}/impact-reports/${impactReportId}/widgets/${widgetId}`,
    widgetData);
}

export function deleteCompanyImpactReportWidget(companyId, impactReportId, widgetId) {
  return api.delete(`/companies/${companyId}/impact-reports/${impactReportId}/widgets/${widgetId}`);
}

/**
 * Get widget data not contained inside the model
 *
 * @param {string} companyId
 * @param {number} impactReportId
 * @param {string} widgetId
 */
export function getCompanyImpactReportWidgetData(companyId, impactReportId, widgetId) {
  return api.get(`/companies/${companyId}/impact-reports/${impactReportId}/widgets/${widgetId}`);
}

/**
 * Company sectors
 */

/**
 * Get sector info from the API
 *
 * @param {string} companyId - company ID
 * @param {number} companySectorId - company sector ID
 *
 * @returns {Promise<object>} response promise
 */
export async function getCompanySector(companyId, companySectorId) {
  return api.get(`/companies/${companyId}/sectors/${companySectorId}`);
}

/**
 * Fetch company sectors from the API
 *
 * @param {string} companyId - company ID
 *
 * @returns {Promise<object>} response promise
 */
export async function listCompanySectors(companyId) {
  return api.get(`/companies/${companyId}/sectors`);
}

/**
 * Send request to API to create new company sector
 *
 * @param {string} companyId - company ID
 * @param {object} data - company sector data
 *
 * @returns {Promise<object>} response promise
 */
export async function createCompanySector(companyId, data) {
  return api.post(`/companies/${companyId}/sectors`, data);
}

/**
 * Send request to API to delete company sector
 *
 * @param {string} companyId - company ID
 * @param {number} companySectorId - company sector ID
 *
 * @returns {Promise<object>} response promise
 */
export async function deleteCompanySector(companyId, companySectorId) {
  return api.delete(`/companies/${companyId}/sectors/${companySectorId}`);
}

/**
 * Send request to update company sector to the API
 *
 * @param {string} companyId - company ID
 * @param {number} companySectorId - company sector ID
 * @param {object} data - update sector data
 *
 * @returns {Promise<object>} response promise
 */
export async function updateCompanySector(companyId, companySectorId, data) {
  return api.put(`/companies/${companyId}/sectors/${companySectorId}`, data);
}

/**
 * Classification
 */

export async function getCompanySectorsClassification(companyId) {
  return api.get(`/companies/${companyId}/classification/sectors`);
}

export async function getCompanyBasesClassification(companyId) {
  return api.get(`/companies/${companyId}/classification/bases`);
}

export async function getCompanyPurposeClassification(companyId) {
  return api.get(`/companies/${companyId}/classification/purpose`);
}

export async function getCompanySensitiveDataCategoriesClassification(companyId) {
  return api.get(`/companies/${companyId}/classification/sensitive-data-categories`);
}

export async function getCompanyProcessingTypesClassification(companyId) {
  return api.get(`/companies/${companyId}/classification/processing-types`);
}

export async function getCompanyPersonalDataTypesClassification(companyId) {
  return api.get(`/companies/${companyId}/classification/personal-data-types`);
}

export async function getCompanyDataManagementRolesClassification(companyId) {
  return api.get(`/companies/${companyId}/classification/data-management-roles`);
}

/**
 * Questions templates
 */

/**
 * List questions template
 *
 * @param {string} userId - user ID
 *
 * @returns {Object} request response
 */
export async function listQuestionsTemplates(userId) {
  return api.get(`/users/${userId}/questions-templates`);
}

/**
 * Create questions template
 *
 * @param {string} userId - user ID
 * @param {object} data - new questions template data
 *
 * @returns {Object} request response
 */
export async function createQuestionsTemplate(userId, data) {
  return api.post(`/users/${userId}/questions-templates`, data);
}

/**
 * Create questions template from company questions
 *
 * @param {string} userId - user ID
 * @param {object} data - new questions template data
 *
 * @returns {Object} request response
 */
export async function createQuestionsTemplateFromCompanyQuestions(userId, data) {
  return api.post(`/users/${userId}/questions-templates/from-company-questions`, data);
}

/**
 * Overwrite questions template with company questions
 *
 * @param {string} userId - user ID
 * @param {number} templateId - template ID
 * @param {object} data - new questions template data
 *
 * @returns {Object} request response
 */
export async function overwriteQuestionsTemplateWithCompanyQuestions(userId, templateId, data) {
  return api.post(`/users/${userId}/questions-templates/${templateId}/overwrite-with-company-questions`, data);
}

/**
 * Update questions template
 *
 * @param {string} userId - user ID
 * @param {number} templateId - ID of the questions template that is going to be updated
 * @param {object} data - updated template data
 *
 * @returns {Object} request response
 */
export async function updateQuestionsTemplate(userId, templateId, data) {
  return api.put(`/users/${userId}/questions-templates/${templateId}`, data);
}

/**
 * Delete questions template
 *
 * @param {string} userId - user ID
 * @param {number} templateId - ID that identifies the template that is going to be deleted
 *
 * @returns {Object} request response
 */
export async function deleteQuestionsTemplate(userId, templateId) {
  return api.delete(`/users/${userId}/questions-templates/${templateId}`);
}

/**
 * List questions in questions template
 *
 * @param {string} userId - user ID
 * @param {number} templateId - ID of the template that the questions belong to
 */
export async function listQuestionsTemplateQuestions(userId, templateId) {
  return api.get(`/users/${userId}/questions-templates/${templateId}/questions`);
}

/**
 * Create new question in questions template
 *
 * @param {string} userId - user ID
 * @param {number} templateId - template taht the question belongs to
 */
export async function createQuestionsTemplateQuestion(userId, templateId, data) {
  return api.post(`/users/${userId}/questions-templates/${templateId}/questions`, data);
}

/**
 * Update question in questions template
 * @param {string} userId - user ID
 * @param {number} templateId - template taht the question belongs to
 * @param {number} questionId - ID of the question that is going to be deleted
 * @param {object} data - updated question data
 */
export async function updateQuestionsTemplateQuestion(userId, templateId, questionId, data) {
  return api.put(`/users/${userId}/questions-templates/${templateId}/questions/${questionId}`, data);
}

/**
 * Delete questions template question
 *
 * @param {string} userId - user ID
 * @param {number} templateId - template ID that the question belongs to
 * @param {number} questionId - ID of the question that is going to be deleted
 *
 */
export async function deleteQuestionsTemplateQuestion(userId, templateId, questionId) {
  return api.delete(`/users/${userId}/questions-templates/${templateId}/questions/${questionId}`);
}

export async function importTemplateQuestionsFromCSVFile(userId, templateId, file) {
  const form = new FormData();
  form.append('file', file);

  return api.post(`/users/${userId}/questions-templates/${templateId}/import-from-csv`, form, {
    headers: {
      'content-type': 'multipart/form-data',
    },
  });
}

export async function exportChecklistTemplate(userId, templateId, format = 'csv') {
  return api.get(`/users/${userId}/questions-templates/${templateId}/export`, {
    params: {
      format,
    },
    responseType: 'blob',
  });
}

/**
 * Affiliates
 */

/**
 * List the companies created with the affiliate link for a given user
 *
 * @param {string} userId - user ID
 */
export async function listAffiliateCompanies(userId) {
  return api.get(`/affiliates/${userId}`);
}

export async function getAffiliatesLink(userId) {
  return api.get(`/affiliates/me`);
}

/**
 * Company categories
 */


/**
 * Fetch company categories from the API
 *
 * @param {string} companyId - company ID
 *
 * @returns {Promise<object>} response promise
 */
export async function listCompanyCategories(companyId) {
  return api.get(`/companies/${companyId}/categories`);
}

/**
 * Send request to API to create new company category
 *
 * @param {string} companyId - company ID
 * @param {object} data - company category data
 *
 * @returns {Promise<object>} response promise
 */
export async function createCompanyCategory(companyId, data) {
  return api.post(`/companies/${companyId}/categories`, data);
}

/**
 * Send request to API to delete company category
 *
 * @param {string} companyId - company ID
 * @param {number} companyCategoryId - company category ID
 *
 * @returns {Promise<object>} response promise
 */
export async function deleteCompanyCategory(companyId, companyCategoryId) {
  return api.delete(`/companies/${companyId}/categories/${companyCategoryId}`);
}

/**
 * Send request to update company category to the API
 *
 * @param {string} companyId - company ID
 * @param {number} companyCategoryId - company category ID
 * @param {object} data - update category data
 *
 * @returns {Promise<object>} response promise
 */
export async function updateCompanyCategory(companyId, companyCategoryId, data) {
  return api.put(`/companies/${companyId}/categories/${companyCategoryId}`, data);
}

/**
 * Maturity checklist share links
 */

export async function listCompanyMaturityChecklistShareLinks(companyId) {
  return api.get(`/companies/${companyId}/maturity-checklist-share-links`);
}

export async function createCompanyMaturityChecklistShareLink(companyId, data) {
  return api.post(`/companies/${companyId}/maturity-checklist-share-links`, data);
}

export async function deleteCompanyMaturityChecklistShareLink(companyId, shareLinkId) {
  return api.delete(`/companies/${companyId}/maturity-checklist-share-links/${shareLinkId}`);
}

export async function updateCompanyMaturityChecklistShareLink(companyId, shareLinkId, data) {
  return api.put(`/companies/${companyId}/maturity-checklist-share-links/${shareLinkId}`, data);
}

export async function resendMaturityChecklistShareLinkToCollaborator(companyId, shareLinkId) {
  return api.post(`/companies/${companyId}/maturity-checklist-share-links/${shareLinkId}/resend`);
}

export async function getCompanyMaturityChecklistShareLink(shareLinkId) {
  return api.get(`/maturity-checklist-share-links/${shareLinkId}`);
}

export async function getPublicMaturityQuestions(shareLinkId) {
  return api.get(`/maturity-checklist-share-links/${shareLinkId}/questions`);
}

export async function getPublicMaturityQuestionObservations(shareLinkId, questionId) {
  return api.get(`/maturity-checklist-share-links/${shareLinkId}/questions/${questionId}/observations`);
}

export async function updatePublicMaturityQuestionImpact(shareLinkId, questionId, value) {
  return await api.put(`/maturity-checklist-share-links/${shareLinkId}/questions/${questionId}/impact`, {
    value,
  });
}

export async function updatePublicMaturityQuestionProbability(shareLinkId, questionId, value) {
  return await api.put(`/maturity-checklist-share-links/${shareLinkId}/questions/${questionId}/probability`, {
    value,
  });
}

export async function updatePublicMaturityQuestionMaturityLevel(shareLinkId, questionId, value) {
  return api.put(`/maturity-checklist-share-links/${shareLinkId}/questions/${questionId}/maturity-level`, {
    value,
  });
}

export async function updatePublicMaturityQuestionFastActionState(shareLinkId, questionId, value) {
  return api.put(`/maturity-checklist-share-links/${shareLinkId}/questions/${questionId}/fast-action`, {
    value,
  });
}

export async function createPublicMaturityQuestionObservation(shareLinkId, questionId, observation) {
  return api.post(`/maturity-checklist-share-links/${shareLinkId}/questions/${questionId}/observations`, {
    observation,
  });
}

export async function setPublicMaturityQuestionAsAnswered(shareLinkId, questionId) {
  return api.put(`/maturity-checklist-share-links/${shareLinkId}/questions/${questionId}/answered`, {
    value: true,
  });
}

export async function setPublicMaturityQuestionAsNotAnswered(shareLinkId, questionId) {
  return api.put(`/maturity-checklist-share-links/${shareLinkId}/questions/${questionId}/answered`, {
    value: false,
  });
}

/**
 * Due diligence
 */

export async function listCompanyDueDiligenceRequests(companyId) {
  return api.get(`/companies/${companyId}/due-diligence-requests`);
}

export async function resendCompanyDueDiligenceRequestNotification(companyId, requestId) {
  return api.post(`/companies/${companyId}/due-diligence-requests/${requestId}/resend`);
}

export async function listCompanyDueDiligenceTemplates(companyId) {
  return api.get(`/companies/${companyId}/due-diligence-templates`);
}

export async function createDueDiligenceRequest(companyId, data) {
  return api.post(`/companies/${companyId}/due-diligence-requests/send`, data);
}

export async function getCompanyDueDiligenceRequest(requestId) {
  return api.get(`/due-diligence-requests/${requestId}`);
}

export async function answerDueDiligenceRequestQuestion(requestId, questionId, data) {
  return api.put(`/due-diligence-requests/${requestId}/questions/${questionId}`, data);
}

export async function finishDueDiligenceRequest(requestId) {
  return api.post(`/due-diligence-requests/${requestId}/finish`);
}

export async function createDueDiligenceTemplate(companyId, data) {
  return api.post(`/companies/${companyId}/due-diligence-templates`, data);
}

export async function updateDueDiligenceTemplate(companyId, templateId, data) {
  return api.put(`/companies/${companyId}/due-diligence-templates/${templateId}`, data);
}

export async function deleteDueDiligenceTemplate(companyId, templateId) {
  return api.delete(`/companies/${companyId}/due-diligence-templates/${templateId}`);
}

export async function getDueDiligenceTemplate(companyId, templateId) {
  return api.get(`/companies/${companyId}/due-diligence-templates/${templateId}`);
}

export async function createDueDiligenceTemplateQuestion(companyId, templateId, data) {
  return api.post(`/companies/${companyId}/due-diligence-templates/${templateId}/questions`, data);
}

export async function updateDueDiligenceTemplateQuestion(companyId, templateId, questionId, data) {
  return api.put(`/companies/${companyId}/due-diligence-templates/${templateId}/questions/${questionId}`, data);
}


export async function updateDueDiligenceTemplateQuestionOrder(question) {
  const companyId = store.getState().company.company._id;
  return await api.put(`/companies/${companyId}/due-diligence-templates/${question.templateId}/questions/${question.id}/${question.order}`, question);
}


export async function deleteDueDiligenceTemplateQuestion(companyId, templateId, questionId) {
  return api.delete(`/companies/${companyId}/due-diligence-templates/${templateId}/questions/${questionId}`);
}

/**
 * Send request to API to create global due diligence template
 *
 * @param {string} data.name - template name
 * @param {string | undefined} data.description - template description
 */
export async function createGlobalDueDiligenceTemplate(data) {
  return api.post('/due-diligence-templates', data);
}

export async function listGlobalDueDiligenceTemplates() {
  return api.get('/due-diligence-templates');
}

/**
 * Send request to API to update global due diligence template
 *
 * @param {string} templateId - template ID
 * @param {string} data.name - template name
 * @param {string | undefined} data.description - template description
 */
export async function updateGlobalDueDiligenceTemplate(templateId, data) {
  return api.put(`/due-diligence-templates/${templateId}`, data);
}

export async function deleteGlobalDueDiligenceTemplate(templateId) {
  return api.delete(`/due-diligence-templates/${templateId}`);
}

export async function getGlobalDueDiligenceTemplate(templateId) {
  return api.get(`/due-diligence-templates/${templateId}`);
}

/**
 * Send request to API to create due diligence template question
 *
 * @param {string} templateId - template ID
 * @param {string} data.question - question
 * @param {number} data.order - question position in the template
 */
export async function createGlobalDueDiligenceTemplateQuestion(templateId, data) {
  return api.post(`/due-diligence-templates/${templateId}/questions`, data);
}

/**
 * Send request to API to update due diligence template question
 *
 * @param {string} templateId - template ID
 * @param {string} questionID - question ID
 * @param {string} data.question - question
 * @param {number} data.order - question position in the template
 */
export async function updateGlobalDueDiligenceTemplateQuestion(templateId, questionId, data) {
  return api.put(`/due-diligence-templates/${templateId}/questions/${questionId}`, data);
}

export async function deleteGlobalDueDiligenceTemplateQuestion(templateId, questionId) {
  return api.delete(`/due-diligence-templates/${templateId}/questions/${questionId}`);
}

/**
 * Document signature requests
 */

export async function listDocumentSignatureRequests(companyId, documentId) {
  return api.get(`/companies/${companyId}/documents/${documentId}/signature-requests`);
}

export async function resendDocumentSignatureRequest(companyId, documentId, requestId) {
  return api.post(`/companies/${companyId}/documents/${documentId}/signature-requests/${requestId}/resend`);
}

export async function sendDocumentSignatureRequests(companyId, documentId, collaborators) {
  return api.post(`/companies/${companyId}/documents/${documentId}/send-signature-requests`, {
    collaborators,
  });
}

export async function downloadDocumentSignatureRequestsReport(companyId, documentId) {
  return api.get(`/companies/${companyId}/documents/${documentId}/signature-requests-report`, {
    responseType: 'arraybuffer',
  });
}

export async function getPublicDocumentSignatureRequest(requestId) {
  return api.get(`/document-sign-requests/${requestId}`);
}

export async function signDocument(requestId) {
  return api.post(`/document-sign-requests/${requestId}/sign`);
}

/**
 * Groups requests
 */

export async function listGroupConsultants(groupId) {
  return api.get(`/groups/${groupId}/consultants`);
}

export async function listGroupPartnerships(groupId) {
  return api.get(`/groups/${groupId}/partnerships`);
}

/**
 * Company partners
 */

export async function deleteCompanyPartner(companyId, partnerId) {
  return api.delete(`/companies/${companyId}/partners/${partnerId}`);
}

export async function addCompanyPartner(companyId, data) {
  return await api.post(`/companies/${companyId}/partners`, data);
}

export async function listAllCompanyPartners(companyId) {
  return api.get(`/companies/${companyId}/partners`);
}

/**
 * Data mapping templates
 */

export async function listDataMappingTemplates() {
  return api.get(`/user/data-mapping-templates`);
}

export async function createDataMappingTemplate(data) {
  return api.post(`/user/data-mapping-templates`, data);
}

export async function updateDataMappingTemplate(data) {
  return api.put(`/user/data-mapping-templates/${data.id}`, data);
}

export async function deleteDataMappingTemplate(templateId) {
  return api.delete(`/user/data-mapping-templates/${templateId}`);
}

export async function listDataMappingTemplatePhases(templateId) {
  return api.get(`/user/data-mapping-templates/${templateId}/phases`);
}

export async function createDataMappingTemplatePhase(templateId, data) {
  return api.post(`/user/data-mapping-templates/${templateId}/phases`, data);
}

export async function updateDataMappingTemplatePhase(templateId, data) {
  return api.put(`/user/data-mapping-templates/${templateId}/phases/${data.id}`, data);
}

export async function deleteDataMappingTemplatePhase(templateId, tabId) {
  return api.delete(`/user/data-mapping-templates/${templateId}/phases/${tabId}`);
}

export async function listDataMappingTemplatePhaseFields(templateId, tabId) {
  return api.get(`/user/data-mapping-templates/${templateId}/phases/${tabId}/fields`);
}

export async function createDataMappingTemplatePhaseField(templateId, tabId, data) {
  return api.post(`/user/data-mapping-templates/${templateId}/phases/${tabId}/fields`, data);
}

export async function updateDataMappingTemplatePhaseField(templateId, tabId, data) {
  return api.put(`/user/data-mapping-templates/${templateId}/phases/${tabId}/fields/${data.id}`,
    data);
}

export async function deleteDataMappingTemplatePhaseField(templateId, tabId, fieldId) {
  return api.delete(`/user/data-mapping-templates/${templateId}/phases/${tabId}/fields/${fieldId}`);
}

export async function getUserShortcut() {
  return api.get(`user/shortcut`);
}

export async function postUserShortcut(data) {
  return api.post(`user/shortcut`, data);
}

export async function deletUserShortcut(data) {
  return api.delete(`user/shortcut`, {
    data: data,
  });
}

/**
 * Global data mapping templates
 */

export async function listGlobalDataMappingTemplates() {
  return api.get(`/data-mapping-templates`);
}

export async function createGlobalDataMappingTemplate(data) {
  return api.post(`/data-mapping-templates`, data);
}

export async function updateGlobalDataMappingTemplate(data) {
  return api.put(`/data-mapping-templates/${data.id}`, data);
}

export async function deleteGlobalDataMappingTemplate(templateId) {
  return api.delete(`/data-mapping-templates/${templateId}`);
}

export async function listGlobalDataMappingTemplatePhases(templateId) {
  return api.get(`/data-mapping-templates/${templateId}/phases`);
}

export async function createGlobalDataMappingTemplatePhase(templateId, data) {
  return api.post(`/data-mapping-templates/${templateId}/phases`, data);
}

export async function updateGlobalDataMappingTemplatePhase(templateId, data) {
  return api.put(`/data-mapping-templates/${templateId}/phases/${data.id}`, data);
}

export async function deleteGlobalDataMappingTemplatePhase(templateId, tabId) {
  return api.delete(`/data-mapping-templates/${templateId}/phases/${tabId}`);
}

export async function listGlobalDataMappingTemplatePhaseFields(templateId, tabId) {
  return api.get(`/data-mapping-templates/${templateId}/phases/${tabId}/fields`);
}

export async function createGlobalDataMappingTemplatePhaseField(templateId, tabId, data) {
  return api.post(`/data-mapping-templates/${templateId}/phases/${tabId}/fields`, data);
}

export async function updateGlobalDataMappingTemplatePhaseField(templateId, tabId, data) {
  return api.put(`/data-mapping-templates/${templateId}/phases/${tabId}/fields/${data.id}`, data);
}

export async function deleteGlobalDataMappingTemplatePhaseField(templateId, tabId, fieldId) {
  return api.delete(`/data-mapping-templates/${templateId}/phases/${tabId}/fields/${fieldId}`);
}

export async function saveDataMappingAsTemplate(companyId, data) {
  return api.post(`/companies/${companyId}/data-mapping/save-as-template`, data);
}

export async function importDataMappingTemplate(companyId, data) {
  return api.post(`/companies/${companyId}/data-mapping/import-template`, data);
}

/**
 * Company maturity diagnosis
 */

export async function createNewDiagnosis(data) {
  return api.post('/company-maturity-diagnosis', data);
}

/**
 * Evidences
 */

export async function downloadEvidence(evidenceId) {
  return api.get(`/evidences/${evidenceId}/download`, {
    responseType: 'blob',
  });
}

/**
 * Personal data types
 */

export async function listCompanyPersonalDataTypes(companyId) {
  return api.get(`/companies/${companyId}/personal-data-types`);
}

export async function deleteCompanyPersonalDataType(companyId, personalDataTypeId) {
  return api.delete(`/companies/${companyId}/personal-data-types/${personalDataTypeId}`);
}

export async function updateCompanyPersonalDataType(companyId, personalDataTypeId, data) {
  return api.put(`/companies/${companyId}/personal-data-types/${personalDataTypeId}`, data);
}

export async function createCompanyPersonalDataType(companyId, data) {
  return api.post(`/companies/${companyId}/personal-data-types`, data);
}


export async function listGlobalPersonalDataTypes() {
  return api.get(`/personal-data-types`);
}

export async function deleteGlobalPersonalDataType(personalDataTypeId) {
  return api.delete(`/personal-data-types/${personalDataTypeId}`);
}

export async function updateGlobalPersonalDataType(personalDataTypeId, data) {
  return api.put(`/personal-data-types/${personalDataTypeId}`, data);
}

export async function createGlobalPersonalDataType(data) {
  return api.post(`/personal-data-types`, data);
}

/**
 * Data management roles
 */

export async function listGlobalDataManagementRoles() {
  return api.get('/data-management-roles');
}

export async function deleteGlobalDataManagementRole(dataManagementRoleId) {
  return api.delete(`/data-management-roles/${dataManagementRoleId}`);
}

export async function updateGlobalDataManagementRole(dataManagementRoleId, data) {
  return api.put(`/data-management-roles/${dataManagementRoleId}`, data);
}

export async function createGlobalDataManagementRole(data) {
  return api.post('/data-management-roles', data);
}

/**
 * Data processing types
 */

export async function listGlobalDataProcessingTypes() {
  return api.get('/data-processing-types');
}

export async function deleteGlobalDataProcessingType(processingTypeId) {
  return api.delete(`/data-processing-types/${processingTypeId}`);
}

export async function updateGlobalDataProcessingType(processingTypeId, data) {
  return api.put(`/data-processing-types/${processingTypeId}`, data);
}

export async function createGlobalDataProcessingType(data) {
  return api.post('/data-processing-types', data);
}

/**
 * Company stats
 */

export async function getCompanyPrivacyCultureStats(companyId) {
  return api.get(`/companies/${companyId}/stats/privacy-culture`);
}

export async function getCompanyActionPlanStats(companyId) {
  return api.get(`/companies/${companyId}/stats/action-plan`);
}

export async function getCompanyDataMappingStats(companyId) {
  return api.get(`/companies/${companyId}/stats/data-mapping`);
}

/**
 * Sensitive data options
 */

export async function listCompanySensitiveDataOptions(companyId) {
  return api.get(`/companies/${companyId}/sensitive-data-options`);
}

export async function createCompanySensitiveDataOption(companyId, name) {
  return api.post(`/companies/${companyId}/sensitive-data-options`, {
    name,
  });
}

export async function updateCompanySensitiveDataOption(companyId, optionId, name) {
  return api.put(`/companies/${companyId}/sensitive-data-options/${optionId}`, {
    name,
  });
}

export async function deleteCompanySensitiveDataOption(companyId, optionId) {
  return api.delete(`/companies/${companyId}/sensitive-data-options/${optionId}`);
}

export async function listGlobalSensitiveDataOptions() {
  return api.get('/sensitive-data-options');
}

export async function createGlobalSensitiveDataOption(name) {
  return api.post('/sensitive-data-options', {
    name,
  });
}

export async function updateGlobalSensitiveDataOption(optionId, name) {
  return api.put(`/sensitive-data-options/${optionId}`, {
    name,
  });
}

export async function deleteGlobalSensitiveDataOption(optionId) {
  return api.delete(`/sensitive-data-options/${optionId}`);
}

export default api;
