import React, { useEffect, useState, memo, } from 'react';
import { useDispatch, } from 'react-redux';
import { useTranslation, } from 'react-i18next';

import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';

import './styles.css';

import history from '../../history';
import api from '../../services/api';

import Contrato from '../../components/Master/Contrato';
import NavBar from '../../components/NavBar/NavBarAdmin.js';
import Politica from '../../components/Master/Politica';
import UsersTable from '../../components/Master/UsersTable';
import LoginRankTable from '../../components/Master/LoginRankTable';
import CompaniesTable from '../../components/Master/CompaniesTable';
import GroupsTable from '../../components/Master/GroupsTable';
import UsersApprove from '../../components/Master/UsersApprove';
import TemplatePerguntas from '../../components/Master/TemplatePerguntas';
import GlobalImpactReportTemplates from '../../components/Master/GlobalImpactReportTemplates';
import GlobalDataMappingTemplates from '../../components/Master/GlobalDataMappingTemplates';
import GlobalDueDiligenceTemplates from './GlobalDueDiligenceTemplates';
import GlobalPersonalDataTypeModal from '../../components/GlobalPersonalDataTypeModal';
import GlobalDataManagementRoleModal from '../../components/GlobalDataManagementRoleModal';
import GlobalDataProcessingTypeModal from '../../components/GlobalProcessingDataTypeModal';
import ModalButton from './ModalButton';
import GlobalSensitiveDataOptionModal from '../../components/GlobalSensitiveDataOptionModal/index.jsx';

import { listUsers } from '../../features/usersSlice.js';


const TAB_OPTIONS_CONFIG = Object.freeze({
  GROUPS: {
    label: 'Grupos',
  },
  COMPANIES: {
    label: 'Empresas',
  },
  USERS: {
    label: 'Usuários',
  },
});


/**
 * Administrative console
 *
 * @returns Rendered component
 */
function AdminConsole() {
  const { t, } = useTranslation();
  const [currTab, setCurrTab] = useState('COMPANIES');
  const dispatch = useDispatch();


  /**
   * Checks if current user is in master group and should have access to this page
   */
  const initialize = async () => {
    try {
      dispatch(listUsers());
      const { isInMasterGroup, } = (await api.get('/user-in-master-group')).data;
      if (!isInMasterGroup) {
        history.push('/Dashboard');
      }
    } catch {
      history.push('/Dashboard');
    }
  }

  /**
   * Use effect to trigger the initialize function
   */
  useEffect(initialize, []);


  return (
    <div style={{ padding: '20px' }}>
      <h4
        variant="h4"
        style={{
          color: '#4D5884',
          fontSize: 25,
        }}
      >
        Console Master
      </h4>

      <Grid
        container
        xs={12}
        direction="column"
      >
        <Grid
          item
          xs={12}
        >
          <Typography
            variant="h6"
            align="center"
            display="block"
            color="primary"
            style={{
              paddingTop: 20,
              paddingBottom: 20,
              fontWeight: 'bold'
            }}
          >
            Usuários Aguardando Aprovação
          </Typography>
          <UsersApprove />
        </Grid>

        <Grid
          item
          xs={12}
        >
          <Typography
            variant="h6"
            align="center"
            display="block"
            color="primary"
            style={{
              paddingTop: 20,
              paddingBottom: 20,
              fontWeight: 'bold'
            }}
          >
            Ranking de Acessos
          </Typography>
          <LoginRankTable />
        </Grid>

        <Container
          disableGutters
          maxWidth="xl"
          style={{
            paddingTop: 30,
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'flex-start',
              justifyContent: 'center',
              marginBottom: 15,
            }}
          >
            {
              Object.keys(TAB_OPTIONS_CONFIG).map((optionKey, index) => {
                const isActive = optionKey === currTab;
                const { label, } = TAB_OPTIONS_CONFIG[optionKey];
                return (
                  <Button
                    key={index}
                    onClick={() => setCurrTab(optionKey)}
                    style={{
                      backgroundColor: '#FFFFFF',
                      color: isActive ? '#0000FF' : '#404040',
                      borderBottom: isActive ? '4px solid #0000FF' : '',
                      marginLeft: 10,
                      marginRight: 10,
                    }}
                  >
                    {label}
                  </Button>
                );
              })
            }
          </div>
          {currTab === 'GROUPS' && <GroupsTable />}
          {currTab === 'COMPANIES' && <CompaniesTable />}
          {currTab === 'USERS' && <UsersTable />}
        </Container>

        <Grid
          item
          container
          spacing={1}
        >
          <Grid
            item
            xs={12}
            sm={6}
          >
            <Politica />
          </Grid>

          <Grid
            item
            xs={12}
            sm={6}
          >
            <Contrato />
          </Grid>

          <Grid
            item
            xs={12}
            sm={6}
          >
            <TemplatePerguntas />
          </Grid>

          <Grid
            item
            xs={12}
            sm={6}
          >
            <GlobalImpactReportTemplates />
          </Grid>

          <Grid
            item
            xs={12}
            sm={6}
          >
            <GlobalDataMappingTemplates />
          </Grid>

          <Grid
            item
            xs={12}
            sm={6}
          >
            <GlobalDueDiligenceTemplates />
          </Grid>

          <Grid
            item
            xs={12}
            sm={6}
          >
            <ModalButton
              label={t('PAGES.ADMIN_CONSOLE.personal_data_types')}
              render={(props) => (
                <GlobalPersonalDataTypeModal {...props} />
              )}
            />
          </Grid>

          <Grid
            item
            xs={12}
            sm={6}
          >
            <ModalButton
              label={t('PAGES.ADMIN_CONSOLE.data_management_roles')}
              render={(props) => (
                <GlobalDataManagementRoleModal {...props} />
              )}
            />
          </Grid>

          <Grid
            item
            xs={12}
            sm={6}
          >
            <ModalButton
              label={t('PAGES.ADMIN_CONSOLE.data_processing_types')}
              render={(props) => (
                <GlobalDataProcessingTypeModal {...props} />
              )}
            />
          </Grid>

          <Grid
            item
            xs={12}
            sm={6}
          >
            <ModalButton
              label={t('PAGES.ADMIN_CONSOLE.sensitive_data_option')}
              render={(props) => (
                <GlobalSensitiveDataOptionModal {...props} />
              )}
            />
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}

export default memo(AdminConsole);
