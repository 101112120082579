import React from 'react';
import ReactDOM from 'react-dom';

import { ThemeProvider, createTheme, responsiveFontSizes, } from '@material-ui/core/styles';

import App from './App';

import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';

const theme = responsiveFontSizes(createTheme());


ReactDOM.render(
  <div className="App">
    <React.StrictMode>
      <ThemeProvider theme={theme} >
        <App />
      </ThemeProvider>
    </React.StrictMode>
  </div>,
  document.getElementById('root')
);
