import {
  MAPPING_FIELD_TYPES_ARRAY,
  MAPPING_FIELD_TYPES,
  DataMappingFieldType,
} from '../../data/constants';


/**
 * @typedef DataMappingFieldData
 * @type {object}
 * @property {number} id - field ID
 * @property {string} name - field name
 * @property {string | null} asType - property used to determine the field TYPE as defined in
 * MAPPING_FIELD_TYPES_ARRAY
 * @property {string | null} typePre - property used to determine the field TYPE as defined in
 * MAPPING_FIELD_TYPES_ARRAY
 * @property {string | null} inputType - property used to determine the field TYPE as defined in
 * MAPPING_FIELD_TYPES_ARRAY
 * @property {string | null} description - field description
 * @property {number} fk_etapa - the data mapping phase the field belongs to
 * @property {DataMappingFieldOptionData[] | undefined} options - the options of the field, if it
 * has any
 * @property {number} ordem - the position of the field in the phase
 */


/**
 *
 * @param asType {string | null}
 * @param typePre {string | null}
 * @param inputType {string | null}
 */
export function getFieldType(asType, typePre, inputType) {
  //TODO NEED REFACTOR FOR FERIFY asType
  // TODO remove to lowercase, its here because the data is not normalized
  return MAPPING_FIELD_TYPES_ARRAY.find((type) => {
    return (type.asType == asType?.toLowerCase() || null) &&
      (type.typePre == typePre?.toLowerCase() || null) &&
      ((null == type.inputType) || (type.inputType == inputType?.toLowerCase() || null));
  });
}

export function hasCustomOptions({ asType, typePre, inputType, } = {}) {
  const type = getFieldType(asType, typePre, inputType) || MAPPING_FIELD_TYPES.TEXT;
  return type.value == DataMappingFieldType.PURPOSE;
  // TODO criar um componenete especifico para PURPOSE e nao usar esse tipo de validacao
}
