import { useState, useEffect } from 'react'
import moment from 'moment'
import history from '../../history'
import { useSelector, } from 'react-redux';

import { Card, } from 'react-bootstrap';
import { Icon, Notification, } from 'rsuite';

import { deleteCompanyMeeting, getItensForReunions, } from '../../services/api';
import openNotification from '../../utils/Notification'
import styles from './styleIndexReunion.module.scss'

import { selectCompany, } from '../../features/companySlice';
import DeleteDialog from '../DeleteDialog';
import useModal from '../../hooks/useModal';


/**
 * Component used to render a meeting item
 * @param {object} data - object representing the meeting
 * @param {function} updateData - callback called when the update button is clicked
 *
 * @returns Rendered component
 */
export default function MeetingCard({ data, updateData, index, }) {
  const company = useSelector(selectCompany);
  const [reunion, setReunion] = useState([]);
  const [
    deleteConfirmModalOpen,
    onOpenDeleteConfirmModal,
    onCloseDeleteConfirmModal
  ] = useModal();


  function onGoToMeeting() {
    localStorage.setItem('reunion_index', index);
    history.push(`/companies/${company._id}/meetings/${data.id}`);
  }

  /**
   * Delete meeting
   */
  async function handleDelete() {
    onCloseDeleteConfirmModal();
    try {
      await deleteCompanyMeeting(company._id, data.id);
      openNotification('success', 'Reunião deletada com sucesso!');
      updateData();
    } catch {
      openNotification('error', 'Erro ao deletar reunião!');
    }
  }

  function onDeleteMeeting() {
    if (null != data.registration_id) {
      Notification['warning']({
        title: 'Reunião não pode ser excluída!',
        placement: 'bottomEnd',
        description: 'A reunião já foi registrada e não pode ser excluída.',
      });
      return;
    }

    onOpenDeleteConfirmModal();
  }

  const getData = async () => {
    const res = await getItensForReunions(company._id, data.id);
    if (res.status === 200) {
      setReunion(res.data)
    }
  }

  /**
   * Use effect to initialize data in component
   */
  useEffect(getData, []);

  return (
    <Card
      border="primary"
      style={{
        width: '100%',
        marginBottom: '1%',
      }}
    >
      <DeleteDialog
        open={deleteConfirmModalOpen}
        confirmationMessage={"Tem certeza que deseja excluir a reunião?"}
        onClose={onCloseDeleteConfirmModal}
        onConfirm={handleDelete}
      />
      <div className={styles.HeaderCardReunion}>
        {data.title ? (
          <div className={styles.boxTitleHeaderLeft}>
            <Icon icon="exclamation-circle" />
            {data.title}
          </div>
        ) : (
          <div></div>
        )}
        <div className={styles.boxHeaderRight}>
          <button onClick={onGoToMeeting}>Detalhes</button>
          <Icon icon="trash" onClick={onDeleteMeeting} />
        </div>
      </div>
      <div className={styles.bodyCardReunion}>
        <div className={styles.boxInfoUnic}>
          <Icon icon="calendar" />
          <div className={styles.BoxBlockData}>
            <label for="dateReunion">Data</label>
            <span id="dateReunion">
              {moment(data.date).format('DD/MM/YYYY')}
            </span>
          </div>
        </div>
        <div className={styles.boxInfoUnic}>
          <div className={styles.BoxBlock}>
            <label for="dateReunion">Horarío</label>
            <span id="dateReunion">{data.inicio}</span>
          </div>
        </div>
        <div className={styles.boxInfoUnic}>
          <div className={styles.BoxBlockDate}>
            <label for="dateReunion">Local</label>
            <span id="dateReunion">
              {data.local ? data.local : 'A definir'}
            </span>
          </div>
        </div>
        <div className={styles.boxInfoUnic}>
          <div className={styles.BoxBlockRequester}>
            <label for="dateReunion">Marcado por</label>
            <span id="dateReunion">{data.requester}</span>
          </div>
        </div>
      </div>
      <div className={styles.ObsCardReunions}>
        <div className={styles.boxObsBottom}>
          <p>Itens abordados</p>
          <div className={styles.overflowSpan}>
            {reunion.map((item, index) => {
              return (
                <span key={item.id}>
                  <br />
                  {index + 1} - {item.problema}
                  <br />
                </span>
              )
            })}
          </div>
        </div>
      </div>
    </Card>
  );
}
