import React, { useState, useRef, } from 'react';

import { Form, } from 'react-bootstrap';
import {makeStyles, Menu,} from '@material-ui/core';

import BaseField from './BaseField';
import {parseFieldResponse} from "./utils";
import MenuItem from "@material-ui/core/MenuItem";
import Tooltip from "../../Tooltip";


const useStyles = makeStyles(() => ({
  control: {
    position: 'relative',
  },
  popover: {
    zIndex: 100000000000000,
    backgroundColor: 'white',
    border: '1px solid #00000055',
    position: 'absolute',
    overflowY: 'auto',
    borderEndEndRadius: 5,
    borderEndStartRadius: 5,
    borderTop: '0px',
  },
  listItem: {
    paddingLeft: '0.6rem',
    paddingRight: '0.6rem',
    paddingBottom: '0.1rem',
    paddingTop: '0.1rem',
    cursor: 'pointer',
    backgroundColor: 'transparent',
    '&:hover': {
      backgroundColor: '#0e7fe1',
      color: 'white',
    },
  },
}));


/**
 * It uses the same props as BaseField component
 *
 * @param {function} props.updateOptions - callback to update field options
 */
export default function PurposeField(props) {
  const [ref, setRef] = useState();
  const [value, setValue] = useState();
  const classes = useStyles();
  const open = Boolean(ref);

  function closeOptions() {
    setRef(null);
  }

  function openOptions(event) {
    setRef(event.currentTarget);
  }

  return (
    <BaseField
      {...props}
      render={(_resp, onChangeResp) => {
        const resp = (props.field.options || []).find((item) => item.id === Number(_resp))?.title || _resp;
        return (
          <div className={classes.control} style={{width: '100%'}}>
            <Form.Control
              placeholder="Escolha um valor..."
              value={resp}
              aria-haspopup="true"
              onChange={(event => onChangeResp(event.target.value))}
              onClick={openOptions}
              disabled={props.disabled}
            />
            <Menu
              id="basic-menu"
              anchorEl={ref}
              open={open}
              onClose={closeOptions}
              getContentAnchorEl={null}
              autoFocus={false}
              disableAutoFocus={true}
              disableEnforceFocus={true}

              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              MenuListProps={{
                autoFocus: false,
                'aria-labelledby': 'basic-button',
                style: {
                  overflowX: 'auto'
                }
              }}

              PaperProps={{
                style: {
                  maxHeight: '400px',
                  width: '240px',
                },
              }}
              style={{
                overflowX: 'auto',
              }}
            >

              {
                (props.field.options || []).map(({ id, title, }) => {
                  return (
                    <MenuItem
                      key={id}
                      onClick={() => {
                        onChangeResp(String(id));
                        closeOptions();
                      }}
                    >
                      {title}
                    </MenuItem>
                  );
                })
              }
            </Menu>

            {/*{*/}
            {/*  showOptions ?*/}
            {/*    <div*/}
            {/*      className={classes.popover}*/}
            {/*      style={{*/}
            {/*        minWidth: boundingRect.width,*/}
            {/*      }}*/}
            {/*    >*/}
            {/*      {*/}
            {/*        (props.field.options || []).map(({ id, title, }) => {*/}
            {/*          return (*/}
            {/*            <div*/}
            {/*              key={id}*/}
            {/*              className={classes.listItem}*/}
            {/*              onMouseDown={() => {*/}
            {/*                onChangeResp(String(id));*/}
            {/*                closeOptions();*/}
            {/*              }}*/}
            {/*            >*/}
            {/*              { title }*/}
            {/*            </div>*/}
            {/*          );*/}
            {/*        })*/}
            {/*      }*/}
            {/*    </div> :*/}
            {/*    null*/}
            {/*}*/}
          </div>
        );
      }}
    />
  );
}
