import React from "react";
import { Typography, ButtonBase, makeStyles } from '@material-ui/core';
import { Icon } from 'rsuite';
import history from "../../../../../history";



export default function Tab({ icon, title, onClick, go, setSelectedObjectMenu, isActive, openSubMenu, }) {

  const className = styles({ isActive });

  return (
    <div onMouseOver={openSubMenu} className={className.containerBtn}>
      <ButtonBase onClick={onClick ? onClick : () => { history.push(go); setSelectedObjectMenu() }}>
        <div className={className.containerInternal}>
          <div className={className.containerIcon}>
            <Icon icon={icon || "flask"} />
          </div>
          <Typography className={className.textBtn}>
            {title || "Workshop"}
          </Typography>
        </div>
      </ButtonBase>
    </div>
  )
}

const styles = makeStyles(() => ({
  containerBtn: {
    borderTopLeftRadius: '10px',
    borderTopRightRadius: "10px",
    overflow: "hidden",
  },
  containerInternal: {
    display: "flex",
    flexDirection: "row",
    background: props => props.isActive ? "#dbdce1" : "#fff",
    padding: "10px 30px", borderTopLeftRadius: '10px',
    borderTopRightRadius: "10px",
    minWidth: "120px",
    color: "#0000FF"
  },
  containerIcon: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginRight: '5px',
    marginBottom: '2px'
  },
  textBtn: {
    display: "flex",
    fontWeight: "500",
    color: "#1C265A",
    whiteSpace: "nowrap",
  }
}));