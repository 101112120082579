import {useEffect, useRef, useState,} from 'react';
import {CircularProgress, Container,} from '@material-ui/core';
import LimitedBackdrop from '../LimitedBackdrop';


const BASE_INTERVAL = 3000;


export default function DocumentViewer({fileLink, fileType, height = '600px', googleRender = false}) {
  const viewerRef = useRef();
  const fileLoader = useRef(null);

  const [viewerLink, setViewerLink] = useState(' ');

  const [loadingViewer, setLoadingViewer] = useState(true);


  function clearFileLoader() {
    if ((null != fileLoader.current) && (null != fileLoader.current.timeoutId)) {
      clearTimeout(fileLoader.current.timeoutId);
    }
    fileLoader.current = null;
  }

  function setFileLoader() {
    if (null == fileLoader.current) {
      fileLoader.current = {
        tryNumber: 0,
      };
    }

    fileLoader.current.timeoutId = setTimeout(() => {
      if (viewerRef.current) {
        viewerRef.current.src = viewerLink;
        fileLoader.current.tryNumber++;
        setFileLoader();
      }
    }, BASE_INTERVAL * (fileLoader.current.tryNumber + 1));
  }

  function onFileLoaded() {
    setLoadingViewer(false);
    clearFileLoader();
  }

  useEffect(() => {
    if (undefined != fileLink) {
      const encodedLink = encodeURIComponent(fileLink);
      if ('application/pdf' === fileType) {
        setViewerLink(`https://docs.google.com/viewer?url=${encodedLink}&embedded=true`);
      } else {
        setViewerLink(`https://view.officeapps.live.com/op/embed.aspx?src=${encodedLink}`);
      }
    }
  }, [fileLink]);

  useEffect(() => {
    setFileLoader();
    return () => clearFileLoader();
  }, [viewerLink]);


  return (
    <Container
      maxWidth="xl"
      style={{
        height,
        position: 'relative',
      }}
      disableGutters
    >
      <LimitedBackdrop open={loadingViewer}>
        <CircularProgress/>
      </LimitedBackdrop>
      {
        googleRender ?
          <iframe
            ref={viewerRef}
            src={viewerLink}
            width="100%"
            height="100%"
            onLoad={onFileLoaded}
          />
          :
          <object data={fileLink} type="application/pdf" width="100%" height="100%" onLoad={onFileLoaded}>
            <p>Seu navegador não suporta a visualização de PDFs.
              <a href="caminho/para/seu-arquivo.pdf"/>clique aqui para baixar o PDF
            </p>
          </object>
      }
    </Container>
  )

}
