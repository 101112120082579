import { useState, useEffect, } from 'react';
import { CircularProgress, Container, Button, makeStyles } from '@material-ui/core';
import { Notification, } from 'rsuite';
import parse from 'html-react-parser';
import LogoOctoImg from '../../assets/logo.svg';
import { HeaderContent, LogoContainer, } from '../../components/Site/header/StyleHeader';
import { getPolitica, } from '../../services/api';


const useStyles = makeStyles({
  container: {
    padding: '10px',
    maxHeight: '40rem',
    fontFamily: 'Inter, sans-serif',
    fontSize: '13px',
    textAlign: 'flex-start',
  },
  title: {
    marginBottom: '2%',
    fontSize: '40px',
    textAlign: 'center',
    color: '#1c265a',
    fontWeight: 800,
  },
  logo: {
    cursor: 'pointer',
  },
  header: {
    width: '100%',
    height: '4rem',
    margin: 0,
    padding: '0 3rem',
    background: '#1C265A',
  },
});


export default function PrivacyPolicy() {
  const classes = useStyles();

  const [text, setText] = useState('');
  const [loading, setLoading] = useState(true);


  /**
   * Initialize component data
   */
  async function initialize() {
    try {
      const { data, } = await getPolitica('politica');
      setText(data[0].value);
    } catch {
      Notification['error']({
        placement: 'bottomEnd',
        title: 'Falha ao carregar política de privacidade, tente novamente dentro de alguns instantes',
      });
    }

    setLoading(false);
  }

  /**
   * Use effect to initialize component data
   */
  useEffect(initialize, []);


  return (
    <>
      <header className={classes.header}>
        <HeaderContent>
          <LogoContainer>
            <Button
              href="/"
            >
              <img
                className={classes.logo}
                src={LogoOctoImg}
                alt="Logo Octo"
              />
            </Button>
          </LogoContainer>
        </HeaderContent>
      </header>

      <Container>
        <div
          className={classes.container}
        >
          <h1
            className={classes.title}
          >
            Aviso de Privacidade
          </h1>
          {
            loading ?
            <CircularProgress /> :
            parse(text)
          }
        </div>
      </Container>
    </>
  );
}
