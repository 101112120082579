import api from "./api";


export async function getReportConfig(company) {
  return api.get(`/companies/${company._id}/report`);
}

export async function createReportHeader(company, header) {
  return api.post(`/companies/${company._id}/report/header`, {
    name: header.name,
    value: header.value
  });
}

export async function updateHeader(company, header) {
  return api.put(`/companies/${company._id}/report/header/${header.id}`, {
    name: header.name,
    value: header.value
  });
}

export async function removeHeader(company, header) {
  return api.delete(`/companies/${company._id}/report/header/${header.id}`);
}

