import { useEffect, useState, useRef, } from 'react';
import { useSelector, } from 'react-redux';

import { Timeline, Icon } from 'rsuite';
import {
  Button,
  Container,
  Paper,
  Grid,
  CircularProgress,
  TablePagination,
  TextField,
  InputAdornment,
} from '@material-ui/core';
import Paginator from '../../components/Paginator/Paginator';

import { getCompanyActivityRecords, } from '../../services/api';

import Title from '../../components/Global/PageTitle';
import TimeLineReg from '../../components/DPO/TimeLineReg';

import { selectCompany, } from '../../features/companySlice';


export default function ActivityRegistry() {
  const company = useSelector(selectCompany);
  const [logs, setLogs] = useState([]);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [disableLoading, setDisableLoading] = useState(false);
  const [loading, setLoading] = useState(true);
  const [currentPageComponent, setCurrentPageComponent] = useState([]);
  const [search, setSearch] = useState('');
  const [filterLogs, setFilterLogs] = useState([]);

  /**
   * Fetch activity records from the API
   */
  async function getData() {
    setLoading(true);

    try {
      const { data, } = await getCompanyActivityRecords(company._id);
      if (data.records.length > 0) {
        if (page == 1) {
          setLogs(data.records);
        } else {
          setLogs([...logs, ...data.records]);
        }
      }

      if (data?.records?.length) {
        setDisableLoading(true);
      }
    } catch {
      console.log(err);
    }

    setLoading(false);
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(1);
  };


  const _onChangePage = (event, newPage) => {
    setPage(newPage);
  }

  useEffect(async () => {
    await getData();
    return () => setLogs([]);
  }, []);

  function filterRecords() {
    return logs.filter(record => {
      console.log(record);
      return Object.keys(record).some(key => {
        if (['user', 'description', 'created_at'].includes(key) && record[key] != null) {
          return record[key].toString().toLowerCase().includes(search.toLowerCase());
        }
        return false;
      });
    });
  }

  function handlerPages(){
    const newArrayItens = search != '' ? filterRecords(logs) : logs;
    setFilterLogs(newArrayItens);
    setCurrentPageComponent(newArrayItens.slice((page - 1) * rowsPerPage, page * rowsPerPage));
  }

  useEffect(() => {
    handlerPages();
  }, [logs, page, rowsPerPage]);

  useEffect(() => {
    handlerPages();
    setPage(1);
  }, [search]);


  return (
    <>
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <Grid item>
          <Title description="Registros de atividade" />
        </Grid>
        <Grid item>
          <TextField
            label="Pesquisar"
            id="outlined-size-small"
            variant="outlined"
            size="small"
            value={search}
            onChange={(e) => {
              setSearch(e.target.value)
            }}
            style={{ background: "#fff", marginLeft: "10px" }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Icon icon="search" />
                </InputAdornment>
              ),
            }}
          />
        </Grid>
      </Grid>

      <Container
        // component={Paper}
        maxWidth="xl"
        style={{
          marginTop: '0.5rem',
          padding: '0.5rem',
        }}
      >
        <Timeline>
          {!loading &&
            currentPageComponent.map((log) => {
              return (
                <TimeLineReg
                  log={log}
                  key={log.id}
                />
              );
            })
          }
        </Timeline>

        <Grid
          container
          direction="column"
          alignItems="center"
          justifyContent="center"
          style={{
            paddingTop: '1rem',
            paddingBottom: '1rem',
          }}
        >
          {
            loading ?
              <CircularProgress
                indeterminate
                style={{
                  marginBottom: '1rem',
                }}
              /> :
              null
          }
        </Grid>

        <TablePagination
          rowsPerPageOptions={[5, 10, 25, { label: 'Todos', value: filterLogs.length }]}
          component="div"
          SelectProps={{
            inputProps: {
              'aria-label': 'Linhas',
            },
            native: true,
          }}
          labelRowsPerPage={`Linhas por página`}
          labelDisplayedRows={({ from, to, count }) => `${from}-${to} de ${count}`}
          count={filterLogs.length}
          rowsPerPage={rowsPerPage}
          page={page - 1}
          onPageChange={_onChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          ActionsComponent={() => <Paginator page={page} rowsPerPage={rowsPerPage} count={filterLogs.length} onChange={_onChangePage} />}
        />

      </Container>
    </>
  );
}
