import React, { useEffect, useState, memo, } from 'react';
import _ from 'lodash';
import { useSelector, } from 'react-redux';

import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import { Notification, } from 'rsuite';

import api, { getAdminConsoleCompanies, changeCompanyActiveState, } from '../../services/api';
import DeleteDialog from '../DeleteDialog';
import ModalConfig from './modalConfigUsers';
import SearchField from '../../components/SearchField';

import Table from '../CompaniesTable';


const INITIAL_DELETED_COMPANY = Object.freeze({
  _id: undefined,
  name: '',
  group: '',
});


/**
 * Pane used to show company data in the admin console
 *
 * @returns Rendered pane
 */
function CompaniesTable() {
  const [companies, setCompanies] = useState([]);
  const [loading, setLoading] = useState(true);
  const [openConfig, setOpenConfig] = useState(false);
  const [deleteConfirmationDialog, setDeleteConfirmationDialog] = useState(false);
  const [deletedCompany, setDeletedCompany] = useState(INITIAL_DELETED_COMPANY);
  const [showError, setShowError] = useState(false);
  const [currCompany, setCurrCompany] = useState(INITIAL_DELETED_COMPANY);

  const { groups, } = useSelector(state => state.groups);


  /**
   * Fetch companies from the API
   */
  const loadData = async () => {
    setLoading(true);
    try {
      const { data, } = await getAdminConsoleCompanies();
      setCompanies(data.group);
    } catch (err) {
      Notification['error']({
        placement: 'bottomEnd',
        title: 'Falha ao carregar empresas do servidor!',
      });
      setShowError(true);
      setCompanies([]);
    }
    setLoading(false);
  }

  /**
   * Search companies using the search string received
   *
   * @param {string} value - search string
   */
  async function searchCompanies(value) {
    setLoading(true);

    try {
      const { data, status, } = await api.get(`/empresas/procurar?value=${value}`);
      if (status === 200) {
        setCompanies(data);
      }
    } catch {
      Notification['error']({
        placement: 'bottomEnd',
        title: 'Falha ao pesquisar empresas!',
      });
    }

    setLoading(false);
  }

  /**
   * Callback for the delete company button
   *
   * @param {object} company - object representing the company to be deleted
   */
  const onDeleteCompany = (company) => {
    setDeleteConfirmationDialog(true);
    setDeletedCompany(company);
  }

  /**
 * Requests the API to delete the company
 */
  const deleteCompany = async () => {
    try {
      const companyId = deletedCompany._id;

      await api.delete(`/companies/${companyId}`);

      clearDeleteConfirmationDialog();
      setCompanies(companies.filter(({ _id, }) => _id != companyId));

      Notification['success']({
        placement: 'bottomEnd',
        title: 'Empresa excluída com sucesso!',
      });
    } catch (err) {
      Notification['error']({
        placement: 'bottomEnd',
        title: 'Falha ao excluir empresa!',
      });
    }
  }

  /**
   * Toggle company active status in API
   *
   * @param {object} company - company to toggle active state
   */
  const toggleCompanyActiveState = async (company) => {
    setLoading(true);

    const companyId = company._id;
    const newActiveState = !company.active;

    try {
      const { status, } = await changeCompanyActiveState(companyId, newActiveState);
      if (status === 204) {
        Notification['success']({
          placement: 'bottomEnd',
          title: `Empresa ${newActiveState ? 'ativada' : 'inativada'} com sucesso!`,
        });

        const updatedCompanies = _.cloneDeep(companies);
        const updatedCompany = updatedCompanies.find(({ _id, }) => _id === companyId);
        if (updatedCompany != undefined) {
          updatedCompany.active = newActiveState;
        }
        setCompanies(updatedCompanies);
      } else {
        Notification['warning']({
          placement: 'bottomEnd',
          title: `Não foi possível ${ newActiveState ? 'ativar' : 'inativar' } empresa!`,
        });
      }
    } catch {
      Notification['warning']({
        placement: 'bottomEnd',
        title: `Falha ao ${ newActiveState ? 'ativar' : 'inativar' } empresa!`,
        description: 'Tente novamente dentro de alguns instantes.',
      });
    }

    setLoading(false);
  }

  /**
   * Close config modal
   */
  function closeModalConfig() {
    setOpenConfig(false);
  }

  /**
   * handle open event in the config modal button
   *
   * @param {object} company - company that the modal should load
   */
  function onOpenConfigModal(company) {
    //todo: check if there is no loading problems
    setCurrCompany(company);
    setOpenConfig(true);
  }

  /**
   * Close delete confirmation dialog and clears company to be deleted data
   */
  const clearDeleteConfirmationDialog = () => {
    setDeleteConfirmationDialog(false);
    setDeletedCompany(INITIAL_DELETED_COMPANY);
  }

    /**
   * Use effect to initialize required data
   */
    useEffect(loadData, []);


  return (
    <Container
      maxWidth="xl"
      component={Paper}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'flex-start',
          justifyContent: 'flex-end',
          paddingBottom: 15,
          paddingTop: 15,
        }}
      >
        <SearchField onChange={searchCompanies} />
      </div>

      <Table
        companies={companies}
        onDelete={onDeleteCompany}
        groups={groups}
        onOpenConfigModal={onOpenConfigModal}
        loading={loading}
        toggleCompanyActiveState={toggleCompanyActiveState}
        showError={showError}
      />

      <DeleteDialog
        open={deleteConfirmationDialog}
        confirmationMessage={`Tem certeza que deseja excluir a empresa ${deletedCompany.name}?`}
        onClose={clearDeleteConfirmationDialog}
        onConfirm={deleteCompany}
      />

      <ModalConfig
        open={openConfig}
        onClose={closeModalConfig}
        infoUsers={currCompany}
        logs={[]}
      />
    </Container>
  );
}

export default memo(CompaniesTable);
