import { useState, } from 'react';
import { v4 as uuidv4 } from 'uuid';
import './styles.css';
import { Notification, } from 'rsuite';
import {
  Typography,
  makeStyles,
  Button,
  Grid,
  TextField,
  FormLabel,
  FormControl,
} from '@material-ui/core';
import { useFormik, } from 'formik';
import * as Yup from 'yup';

import SimpleUsersTable from '../../components/SimpleUsersTable';
import { createConsultant, } from '../../services/api';


const InputProps = {
  disableUnderline: true,
};

const useStyles = makeStyles({
  title: {
    color: '#1C265A',
    fontWeight: 'bolder',
    fontSize: '1.2rem',
    fontFamily: 'Inter',
    marginBottom: '2rem',
  },
  addBtnContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: '1rem',
  },
  addBtn: {
    textTransform: 'none',
    fontFamily: 'Inter',
    fontWeight: 'bold',
    background: '#3B66FF',
    paddingLeft: '2rem',
    paddingRight: '2rem',
    marginLeft: '1rem',
  },
  tableContainer: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  container: {
    padding: '1rem',
  },
  helperText: {
    color: 'gray',
    paddingTop: '1rem',
    paddingBottom: '1rem',
  },
});

const INITIAL_VALUES = Object.freeze({
  name: '',
  email: '',
});


function CreateUser({ users, getUsers, }) {
  const [loading, setLoading] = useState(false);
  const classes = useStyles();
  const formik = useFormik({
    initialValues: INITIAL_VALUES,
    onSubmit: handleCreateUser,
    validationSchema: Yup.object({
      name: Yup.string().required('Obrigatório'),
      email: Yup.string().email('Deve ser um e-mail válido').required('Obrigatório'),
    }),
  });


  async function handleCreateUser(values) {
    setLoading(true);

    try {
      const { status, } = await createConsultant({
        ...values,
        password: uuidv4(),
        group: localStorage.getItem('group'),
        admin: true,
        autorizado: true,
        whoCreated: localStorage.getItem('name'),
        whereCreated: window.location.pathname,
      });

      if (status === 201) {
        formik.resetForm({
          values: INITIAL_VALUES,
        });

        getUsers();

        Notification['success']({
          top: 70,
          placement: 'topEnd',
          title: 'Tudo certo!',
          description: 'Consultor criado com sucesso!',
        });
      } else if (status === 200) {
        Notification['info']({
          top: 70,
          placement: 'topEnd',
          title: 'Ops!',
          description: 'Consultor já cadastrado',
        });
      }
    } catch {
      Notification['error']({
        top: 70,
        placement: 'topEnd',
        title: 'Ops!',
        description: 'Erro ao criar consultor',
      });
    }

    setLoading(false);
  }

  function __onSubmit(event) {
    event.preventDefault();
    formik.handleSubmit();
  }


  return (
    <>
      <Typography
        display="block"
        className={classes.helperText}
      >
        Gerencie os usuários que terão acesso às mesmas empresas que você.
      </Typography>
      <div>
        <Grid
          container
          justifyContent="space-around"
          spacing={3}
          className={classes.container}
        >
          <Grid
            item
            md={5}
            sm={12}
          >
            <Typography
              variant="h6"
              className={classes.title}
            >
              Adicionar novo Consultor
            </Typography>

            <FormControl
              required
              fullWidth
              style={{
                marginBottom: '1rem',
              }}
            >
              <FormLabel>Nome</FormLabel>
              <TextField
                margin="dense"
                fullWidth
                id="name"
                variant="filled"
                InputProps={InputProps}
                type="text"
                error={formik.errors.name && formik.touched.name}
                helperText={formik.touched.name && formik.errors.name}
                {...formik.getFieldProps('name')}
              />
            </FormControl>

            <FormControl
              required
              fullWidth
            >
              <FormLabel>E-mail</FormLabel>
              <TextField
                margin="dense"
                fullWidth
                id="email"
                variant="filled"
                InputProps={InputProps}
                type="text"
                error={formik.errors.email && formik.touched.email}
                helperText={formik.touched.email && formik.errors.email}
                {...formik.getFieldProps('email')}
              />
            </FormControl>

            <div className={classes.addBtnContainer}>
              <Button
                onClick={__onSubmit}
                variant="contained"
                color="primary"
                loading={loading}
                className={classes.addBtn}
              >
                Adicionar
              </Button>
            </div>
          </Grid>

          <Grid
            item
            md={7}
            sm={12}
            className={classes.tableContainer}
          >
            <div>
              <Typography
                variant="h6"
                className={classes.title}
              >
                Todos os Consultores
              </Typography>

              <SimpleUsersTable
                users={users}
                getUsers={getUsers}
              />
            </div>
          </Grid>
        </Grid>
      </div>
    </>
  );
}

export default CreateUser;
