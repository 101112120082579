

import { useState, useEffect, } from 'react';
import _ from 'lodash';
import { useTranslation, } from 'react-i18next';
import { useParams, } from 'react-router-dom';

import { Notification, } from 'rsuite';

import Title from '../../components/Global/PageTitle';
import LimitedBackdropLoader from '../../components/LimitedBackdropLoader';

import { getCompanyDueDiligenceRequest, getProcessingAgent } from '../../services/api';
import DueDiligenceRequestQuestionList from '../../components/DueDiligenceRequestQuestionList';


export default function DueDiligenceRequestPage() {
  const { t, } = useTranslation();
  const { dueDiligenceRequestId, } = useParams();
  const [dueDiligenceRequest, setDueDiligenceRequest] = useState(undefined);
  const [loading, setLoading] = useState(true);
  const [agent, setAgent] = useState(null);


  async function getData() {
    try {
      const { data, } = await getCompanyDueDiligenceRequest(dueDiligenceRequestId);
      if (data?.companyId && data?.processingAgentId) {
        const response = await getProcessingAgent(data.companyId, data.processingAgentId);
        setAgent(response.data);
      }
      setDueDiligenceRequest(data);
    } catch (err) {
      Notification['error']({
        placement: 'bottomEnd',
        title: _.capitalize(t('ERRORS.DUE_DILIGENCE_REQUEST.failed_to_get_due_diligence_request')),
        description: _.capitalize(t('ERRORS.try_again_later')),
      });
    }
  }

  async function initialize() {
    setLoading(true);
    await getData();
    setLoading(false);
  }

  useEffect(() => {
    initialize();
  }, []);


  return (
    <>
      <Title description={_.capitalize(t('PAGES.DUE_DILIGENCE_REQUEST.due_diligence_request'))} />
      {
        undefined != dueDiligenceRequest ?
        <DueDiligenceRequestQuestionList
          agent={agent}
          questions={dueDiligenceRequest.questions || []}
          readOnly
        /> :
        null
      }

      <LimitedBackdropLoader open={loading} />
    </>
  );
}
