import { useEffect, useState, memo, } from 'react';
import { Grid, } from '@material-ui/core';
import { byStatus, } from '../services/api';
import TableActions from './RiscoAnaliseComponent/Relatorio/Tables';


/**
 * Component to show actions by risk status
 *
 * @returns Rendered component
 */
function ActionsByRisk() {
  const [semStatus, setSemStatus] = useState([]);
  const [baixo, setBaixo] = useState([]);
  const [medio, setMedio] = useState([]);
  const [alto, setAlto] = useState([]);


  const getRisc = async () => {
    const { data, } = await byStatus('all', 'all');
    const { semStatus, low, medium, high } = data;
    setSemStatus(semStatus);
    setBaixo(low);
    setMedio(medium);
    setAlto(high);
  }

  /**
   * Use effect to initialize data
   */
  useEffect(getRisc, []);

  return (
    <Grid
      item
      spacing={1}
      xs={12}
    >
      <TableActions
        title="Sem risco definido"
        body={semStatus}
        filter="Risco não definido"
        variant="secondary"
      />

      <TableActions
        title="Alto Risco"
        body={alto}
        filter="Alto"
        variant="danger"
      />

      <TableActions
        title="Risco Médio"
        body={medio}
        filter="Médio"
        variant="warning"
      />

      <TableActions
        title="Risco Baixo"
        body={baixo}
        filter="Baixo"
        variant="success"
      />
    </Grid>
  );
}

export default memo(ActionsByRisk);
