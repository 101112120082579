import React from 'react';
import moment from 'moment';
import _ from 'lodash';
import { useTranslation, } from 'react-i18next';

import { TableCell, TableRow, IconButton, } from '@material-ui/core';
import SendIcon from '@material-ui/icons/Send';
import VisibilityIcon from '@material-ui/icons/Visibility';

import Table from '../Table';
import Score from '../Score';
import DeleteDialog from '../DeleteDialog';

import useModal from '../../hooks/useModal';


export default function DueDiligenceRequestsTable({
  loading,
  data,
  showError,
  processingAgentsMap,
  onView,
  onResend,
}) {
  const { t, } = useTranslation();
  const [selected, setSelected] = React.useState(null);
  const [
    confirmModalOpen,
    onOpenConfirmModal,
    onCloseConfirmModal
  ] = useModal((id) => setSelected(id), () => setSelected(null));


  function _onResend() {
    if (null == selected) {
      return;
    }
    onResend(selected);
    onCloseConfirmModal();
  }


  return (
    <>
      <Table
        headers={[
          {
            label: _.capitalize(t('name')),
            align: 'center',
          },
          {
            label: _.capitalize(t('responsible')),
            align: 'center',
          },
          {
            label: _.capitalize(t('PAGES.DUE_DILIGENCE.sent_at')),
            align: 'center',
          },
          {
            label: _.capitalize(t('PAGES.DUE_DILIGENCE.opened_at')),
            align: 'center',
          },
          {
            label: _.capitalize(t('PAGES.DUE_DILIGENCE.finished_at')),
            align: 'center',
          },
          {
            label: _.capitalize(t('PAGES.DUE_DILIGENCE.score')),
            align: 'center',
          },
          {
            label: '',
            align: 'center',
          },
        ]}
        data={data}
        loading={loading}
        errorMessage={_.capitalize(t('ERRORS.DUE_DILIGENCE.failed_to_load_due_diligence_requests'))}
        noDataMessage={_.capitalize(t('PAGES.DUE_DILIGENCE.no_due_diligence_requests'))}
        disablePagination
        showError={showError}
      >
        {
          (item, props) => {
            const processingAgent = processingAgentsMap[item.processingAgentId] || {};

            return (
              <TableRow {...props}>
                <TableCell align="center" >
                  { processingAgent.name }
                </TableCell>
                <TableCell align="center" >
                  { processingAgent.dpoName }
                </TableCell>
                <TableCell align="center" >
                  { moment(item.createdAt).format('HH:mm, DD/MM/YYYY') }
                </TableCell>
                <TableCell align="center" >
                  { item.openedAt ? moment(item.openedAt).format('HH:mm, DD/MM/YYYY') : '-' }
                </TableCell>
                <TableCell align="center" >
                  { item.finishedAt ? moment(item.finishedAt).format('HH:mm, DD/MM/YYYY') : '-' }
                </TableCell>
                <TableCell align="center" >
                  <Score grade={item.score} />
                </TableCell>
                <TableCell align="center">
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                    }}
                  >
                    <IconButton
                      onClick={() => onOpenConfirmModal(item.id)}
                      size="small"
                      style={{
                        marginRight: '0.5rem',
                      }}
                    >
                      <SendIcon fontSize="small" />
                    </IconButton>

                    <IconButton
                      onClick={() => onView(item.id)}
                      size="small"
                    >
                      <VisibilityIcon fontSize="small" />
                    </IconButton>
                  </div>
                </TableCell>
              </TableRow>
            );
          }
        }
      </Table>

      <DeleteDialog
        onConfirm={_onResend}
        open={confirmModalOpen}
        onClose={onCloseConfirmModal}
        confirmationMessage={_.capitalize(t('PAGES.DUE_DILIGENCE.resend_confirmation'))}
      />
    </>
  );
}
