import React from "react";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, Switch } from "@material-ui/core";
import { Icon, } from 'rsuite';
import styles from '../../../components/FieldFormModal/styles.module.scss';
import { useSelector, useDispatch } from 'react-redux';
import { replaceMenus, setModalIsOpen } from "../../../features/fixedMenuPages";
import { postUserShortcut, deletUserShortcut, } from "../../../services/api";
import routesMenuFixed from "../../NavBar/constants/routesMenuFixed";
import { Toggle, } from 'rsuite';

export default function UpdateMenus({ open = false }) {

	const stateReduxMenu = useSelector((state) => state.fixedMenuPages);
	const companyId = useSelector((state) => state.company.company._id);
	const dispatch = useDispatch();
	const menuArray = Object.values(routesMenuFixed(companyId));

	const menuIsActive = (key) => {
		const menuIsActive = stateReduxMenu.rigthMenu.indexOf(key);
		return menuIsActive !== -1;
	}

	async function saveShortcut(key) {
		const postData = {
			type: key
		}
		const { data, } = await postUserShortcut(postData);
		dispatch(replaceMenus(data))
	}

	async function fetchDeletShortcut(key) {
		const postData = {
			type: key
		}
		const { data } = await deletUserShortcut(postData);
		dispatch(replaceMenus(data));
	}

	const handlerSaveNewShortcut = (id) => {
		saveShortcut(id);
	}

	const handlerRemoveShortcut = (id) => {
		fetchDeletShortcut(id);
	}

	return (
		<Dialog
			open={open}
			onClose={() => dispatch(setModalIsOpen(false))}
			maxWidth="sm"
			fullWidth
			aria-labelledby="alert-dialog-title"
			aria-describedby="alert-dialog-description"
			PaperProps={{
				style: {
					width: '500px',
					maxWidth: '95%',
					background: "#f2f4f8"
				},
			}}
		>
			<DialogTitle
				className={styles.boxTitle}
				id="alert-dialog-title"
			>
				Adicionar novo atalho
				<Icon onClick={() => dispatch(setModalIsOpen(false))} icon="close" />
			</DialogTitle>

			<DialogContent>
				<Box style={{ paddingTop: "20px" }}>
					{menuArray.map(({ id, title }) => {
						return (
							<Box key={id + ""} style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", background: "#fff", padding: "10px", marginBottom: "10px", alignItems: "center", borderRadius: "10px" }}>
								<p>{title}</p>
								<Box style={{ flexDirection: "row", display: "flex" }}>
									<Box style={{marginLeft:'20px'}}>
										<Toggle
											size="md"
											checked={menuIsActive(id)}
											onChange={() => { menuIsActive(id) ? handlerRemoveShortcut(id) : handlerSaveNewShortcut(id) }}
											checkedChildren={`Ativo${'\u00A0\u00A0'}`}
											unCheckedChildren="Inativo"
										/>
									</Box>
								</Box>
							</Box>
						)
					})}
				</Box>
			</DialogContent>

			<DialogActions>

			</DialogActions>
		</Dialog>
	)
}