import React, {useEffect, useState,} from 'react';
import _ from 'lodash';
import {useTranslation,} from 'react-i18next';

import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import Typography from '@material-ui/core/Typography';

import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';

import DeleteDialog from '../DeleteDialog';
import DueDiligenceTemplateQuestionForm from '../DueDiligenceTemplateQuestionForm';

import useModal from '../../hooks/useModal';
import LimitedBackdropLoader from '../LimitedBackdropLoader';
import {DragDropContext, Draggable, Droppable} from "react-beautiful-dnd";
import {Notification} from "rsuite";
import {getAffiliatesLink, updateDueDiligenceTemplateQuestionOrder} from "../../services/api";

export default function DueDiligenceTemplateDialog({
                                                     open,
                                                     template,
                                                     onClose,
                                                     questions,
                                                     loading = false,
                                                     readOnly = false,
                                                     onDelete = (questionId) => {
                                                     },
                                                     onUpdate = (data) => {
                                                     },
                                                     onCreate = (data) => {
                                                     },
                                                     onMoveItem = () => {},
                                                   }) {
  const [questionsList, setQuestionsList] = useState([]);
  const {t,} = useTranslation();
  const [selectedQuestionId, setSelectedQuestionId] = useState([]);
  const question = questions.find((item) => item.id === selectedQuestionId);
  const [formModalOpen, openFormModal, closeFormModal] = useModal(selectQuestionId,
    selectQuestionId);
  const [deleteModalOpen, openDeleteModal, closeDeleteModal] = useModal(selectQuestionId,
    selectQuestionId);


  useEffect(() => {
    setQuestionsList(questions);
  }, [questions]);

  function selectQuestionId(questionId) {
    setSelectedQuestionId(questionId);
  }

  function _onDeleteQuestion() {
    onDelete(selectedQuestionId);
    closeDeleteModal();
  }

  async function handleOnDragEnd(result) {
    const questionToUpdateOrder = questions.find((item) => item.id === result.draggableId);
    if (!questionToUpdateOrder) {
        Notification['error']({
            placement: 'bottomEnd',
            title: `Falha ao atualizar ordenção!`,
            description: 'Atualize a página e tente novamente dentro de alguns instantes.'
        });
    }
    if (!result.destination) return;
    const items = Array.from(questionsList)
    const [reorderedItem] = items.splice(
      result.source.index,
      1
    )
    items.splice(result.destination.index, 0, reorderedItem);
    setQuestionsList(items)
    try {
        await updateDueDiligenceTemplateQuestionOrder({...questionToUpdateOrder, order: result.destination.index + 1});
        onMoveItem()
    } catch (err) {
        Notification['error']({
            placement: 'bottomEnd',
            title: `Falha ao atualizar ordenção!`,
            description: 'Atualize a página e tente novamente dentro de alguns instantes.'
        });
    }
  }

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullScreen
    >
      <DialogTitle>
        {undefined != template ? template.name : ''}
      </DialogTitle>

      <DialogContent>
        <LimitedBackdropLoader open={loading}/>

        <Grid
          container
          alignItems="center"
          justifyContent="flex-end"
        >
          <Button
            disableElevation
            onClick={() => openFormModal()}
            variant="contained"
            color="primary"
            startIcon={<AddIcon/>}
            disabled={readOnly}
          >
            {_.capitalize(t('PAGES.DUE_DILIGENCE.new_question'))}
          </Button>
        </Grid>
        <div style={{marginTop: '1rem'}}>
          <DragDropContext onDragEnd={handleOnDragEnd}>
            <Droppable droppableId="body.id">
              {(provided) => (
                <div
                  className="body.id"
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                >
                  <div>
                    {questionsList.map((question, index) => {
                      return (
                        <Draggable
                          key={question.id}
                          draggableId={String(question.id)}
                          index={index}
                        >
                          {(provided) => (
                            <div
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              key={String(question.id)}
                              ref={provided.innerRef}
                              className="boxAllQuestions"
                            >
                              <div style={{
                                  display: 'flex',
                                  alignItems: 'center',
                                  height: '36px',
                                  marginBottom: '0.5rem',
                                  width: '100%',
                              }}>
                                <div style={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                    width: '100%',
                                    marginLeft: '1rem',
                                    marginTop: '0.5rem',
                                    fontSize: '0.875rem',
                                    fontFamily: "Roboto, Helvetica, Arial,  sans-serif",
                                    fontWeight: 400,
                                    lineHeight: 1.43,
                                    letterSpacing: "0.01071em",
                                }}>
                                    {`${question.order}. ${question.question}`}
                                  <div style={{
                                    display: 'flex',
                                    marginRight: '1rem',
                                    alignItems: 'center',
                                  }}>
                                    <IconButton
                                      edge="end"
                                      aria-label="open-question-edit-modal"
                                      onClick={() => openFormModal(question.id)}
                                      disabled={readOnly}
                                    >
                                      <EditIcon/>
                                    </IconButton>

                                    <IconButton
                                      edge="end"
                                      aria-label="open-question-delete-modal"
                                      onClick={() => openDeleteModal(question.id)}
                                      color="secondary"
                                      disabled={readOnly}
                                    >
                                      <DeleteIcon/>
                                    </IconButton>
                                  </div>
                                </div>

                              </div>
                            </div>
                          )}
                        </Draggable>
                      )
                    })}
                    {provided.placeholder}
                  </div>
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </div>

        {
          undefined != template && !loading && questions.length == 0 ?
            <Typography
              variant="body1"
              display="block"
              align="center"
              style={{
                color: '#808080',
              }}
            >
              {_.capitalize(t('PAGES.DUE_DILIGENCE.no_questions_found'))}
            </Typography> :
            null
        }

        <DeleteDialog
          open={deleteModalOpen}
          confirmationMessage={t('PAGES.DUE_DILIGENCE.are_you_sure_you_want_to_delete_question', {
            question: undefined != question ? question.question : '',
          })}
          onClose={() => closeDeleteModal()}
          onConfirm={_onDeleteQuestion}
        />

        <Dialog
          open={formModalOpen}
          onClose={() => closeFormModal()}
        >
          <DialogTitle>
            {_.capitalize(t(`PAGES.DUE_DILIGENCE.${undefined != question ? 'edit' : 'create'}_due_diligence_question`))}
          </DialogTitle>

          <DialogContent>
            <DueDiligenceTemplateQuestionForm
              loading={false}
              data={question}
              onSubmit={(data) => {
                (undefined != question ? onUpdate({...question, ...data,}) : onCreate(data));
                closeFormModal();
              }}
            />
          </DialogContent>
        </Dialog>
      </DialogContent>
    </Dialog>
  );
}
