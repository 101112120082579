import { useState, } from 'react';
import { Grid, TextField, makeStyles, } from '@material-ui/core';
import { useFormik, } from 'formik';
import { Button, Notification } from 'rsuite';
import * as Yup from 'yup';
import _ from 'lodash';
import clsx from 'clsx';
import { createCompanyObservation, } from '../../services/api';


const INITIAL_VALUES = Object.freeze({
  observation: '',
});

const useStyles = makeStyles({
  container: {
    height: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
  },
  title: {
    background: '#3B66FF',
    color: '#fff !important',
  },
  actions: {
    marginTop: '0.2rem',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  button: {
    textTransform: 'none',
    fontFamily: 'Inter',
    fontWeight: 'bold',
    paddingLeft: '3rem',
    paddingRight: '3rem',
  },
  addBtn: {
    background: '#3B66FF',
    color: '#fff !important',
    marginLeft: '1rem',
  },
  cancelBtn: {
    borderColor: '#3B66FF',
    color: '#3B66FF !important',
    background: 'transparent !important',
    boxShadow: 'none',
    borderWidth: 2,
    border: '2px solid #3B66FF !important',
    '&:hover': {
      background: 'transparent !important',
      border: '2px solid #3B66FF !important',
    },
  },
});


/**
 * Form to create observation
 *
 * @param {function} onNewObservation - callback called when new observations are created
 * @param {string} companyId - company ID
 *
 * @returns Rendered component
 */
export default function CreateObservationForm ({ onNewObservation, companyId, }) {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);

  const formik = useFormik({
    initialValues: INITIAL_VALUES,
    onSubmit: onSubmit,
    validationSchema: Yup.object({
      observation: Yup.string().required('Obrigatório').max(500),
    }),
  });

  /**
   * Confirmation event handler
   *
   * @param {object} values - form values
   */
  async function onSubmit(values) {
    setLoading(true);
    values.observation = values.observation.trim();
    try {
      const { data: observation, status, } = await createCompanyObservation(companyId, values);

      if (201 === status) {
        formik.resetForm({
          values: INITIAL_VALUES,
        });

        Notification['success']({
          placement: 'bottomEnd',
          title: 'Observação criada com sucesso!',
        });

        if (_.isFunction(onNewObservation)) {
          onNewObservation(observation);
        }
      }
    } catch {
      Notification['error']({
        placement: 'bottomEnd',
        title: 'Falha ao criar observação, tente novamente dentro de alguns instantes.',
      });
    }

    setLoading(false);
  }

  function __onSubmit(event) {
    event.preventDefault();
    formik.handleSubmit();
  }


  return (
    <form
      onSubmit={__onSubmit}
      className={classes.container}
    >
      <Grid
        item
        xs={12}
        container
        direction="row"
      >
        <TextField
          margin="dense"
          variant="outlined"
          label="Observação"
          multiline
          maxRows={5}
          fullWidth
          id="observation"
          type="text"
          error={formik.errors.observation && formik.touched.observation}
          helperText={formik.touched.observation && formik.errors.observation}
          {...formik.getFieldProps('observation')}
        />
      </Grid>

      <Grid
        className={classes.actions}
        container
      >
        <Button
          type="submit"
          appearance="primary"
          loading={loading}
          className={clsx(classes.button, classes.addBtn)}
        >
          Salvar
        </Button>
      </Grid>
    </form>
  );
}
