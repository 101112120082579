import consultantImage from '../assets/cadastro_consultor.png';
import entrepreneurImage from '../assets/cadastro_empresario.png';


export const COURSE_LEVELS = Object.freeze({
  BASIC: {
    value: 'BASIC',
    label: 'Básico',
    color: '#C0A1F2',
  },
  INTERMEDIATE: {
    value: 'INTERMEDIATE',
    label: 'Intermediário',
    color: '#97A0DE',
  },
  ADVANCED: {
    value: 'ADVANCED',
    label: 'Avançado',
    color: '#F0988E',
  },
});

export const COURSE_LEVELS_ARRAY = Object.freeze(Object.values(COURSE_LEVELS));

export const COLLABORATOR_COURSE_PROGRESS_STATES = Object.freeze({
  SENT: {
    value: 'SENT',
    label: 'Enviado',
    color: '#FFB54D',
  },
  OPENED: {
    value: 'OPENED',
    label: 'Aberto',
    color: '#97A0DE',
  },
});

export const PROCESSING_AGENTS_TYPES = Object.freeze({
  MANAGER: {
    value: 'MANAGER',
    label: 'Controlador',
    imageSrc: consultantImage,
  },
  OPERATOR: {
    value: 'OPERATOR',
    label: 'Operador',
    imageSrc: entrepreneurImage,
  },
  IN_CHARGE: {
    value: 'IN_CHARGE',
    label: 'Encarregado',
    imageSrc: entrepreneurImage,
  },
});

export const PROCESSING_AGENTS_TYPES_ARRAY = Object.freeze(Object.values(PROCESSING_AGENTS_TYPES));

export const CRUD_STATUSES = Object.freeze({
  IDLE: 'IDLE',
  LOADING: 'LOADING',
  FETCH_FAILED: 'FETCH_FAILED',
  FETCH_SUCCESSFUL: 'FETCH_SUCCESSFUL',
  CREATE_FAILED: 'CREATE_FAILED',
  CREATE_SUCCESSFUL: 'CREATE_SUCCESSFUL',
  UPDATE_FAILED: 'UPDATE_FAILED',
  UPDATE_SUCCESSFUL: 'UPDATE_SUCCESSFUL',
  DELETE_FAILED: 'DELETE_FAILED',
  DELETE_SUCCESSFUL: 'DELETE_SUCCESSFUL',
});

export const RISK_RESPONSES = Object.freeze({
  ACCEPT: 'Aceitar',
  MITIGATE: 'Mitigar',
  AVOID: 'Evitar',
  TRANSFER: 'Transferir',
});

export const ACTION_STATUSES = Object.freeze({
  PENDING: {
    label: 'Pendente',
    color: 'red',
  },
  IN_PROGRESS: {
    label: 'Em andamento',
    color: 'yellow',
  },
  FINISHED: {
    label: 'Concluido',
    color: 'green',
  },
});

export const QUESTION_STATUSES = Object.freeze({
  UNANSWERED: {
    label: 'Não respondida',
    status: 'info',
    reviewBtnColor: '',
    compliantBtnColor: '',
  },
  ANSWERED_NOT_COMPLIANT: {
    label: 'Respondida mas não adequada',
    status: 'warning',
    reviewBtnColor: 'yellow',
    compliantBtnColor: '',
  },
  COMPLIANT: {
    label: 'Respondida e adequada',
    status: 'success',
    reviewBtnColor: '',
    compliantBtnColor: 'green',
  },
});

export const MATURITY_OPTIONS = Object.freeze([
  {
    value: 1,
    key: 'MATURITY_OPTIONS.level_1',
  },
  {
    value: 2,
    key: 'MATURITY_OPTIONS.level_2',
  },
  {
    value: 3,
    key: 'MATURITY_OPTIONS.level_3',
  },
  {
    value: 4,
    key: 'MATURITY_OPTIONS.level_4',
  },
  {
    value: 5,
    key: 'MATURITY_OPTIONS.level_5',
  },
]);

export const DEFAULT_QUESTION_CATEGORIES = Object.freeze([
  'Controles da Organização',
  'Controles de Pessoas',
  'Controles Físicos',
  'Controles Tecnológicos',
]);

export const QUESTION_CHANGE_REASONS = Object.freeze({
  SET_AS_IN_REVIEW: 1,
  SET_AS_COMPLIANT: 2,
});
export const REVERSED_QUESTION_CHANGE_REASONS = Object.fromEntries(
  Object.entries(QUESTION_CHANGE_REASONS).map((item) => item.reverse()));
