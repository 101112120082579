import React, { useState, useEffect, memo } from 'react';

import { useSelector, useDispatch, } from 'react-redux';
import Sheet from '../../components/Sheet';
import ActionPlan from '.';
import ActivityRank from './ActivityRank';
import PDCA from '../PDCA';
import CardMenuLeft from '../../components/Menus/CardMenuLeft';
import Dropdown from '../../components/Dropdown';
import {
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Box
} from '@material-ui/core';
import { useStyles } from '../../components/NavBar/Styles';

import { setCurrentMeeting } from "../../features/planAction";
import { setItens, updateTitle, } from '../../features/matrizDeRisco';
import { selectCompany, } from '../../features/companySlice';

import { ACTION_STATUSES, } from '../../core/entities/action-plans';
import { TASK_STATUSES, } from '../../core/entities/checklist-tasks';

import {
  listCompanyMeetings,
  notReunion,
  getRiscoNotResponsible,
  getRiscoByReunionID,
  listCompanyActions,
  getRisco,
} from '../../services/api';

const ActionPlanPending = () => <ActionPlan progress="PENDING" />;
const ActionPlanInProgress = () => <ActionPlan progress="IN_PROGRESS" />;
const ActionPlanClossed = () => <ActionPlan progress="CLOSED" />;

const MenuLeft = memo(() => (
  <div style={{ margin: '0px 20px 0px 0px' }}>
    <ActivityRank />
  </div>
))

const INITIAL_VALUES = Object.freeze({
  [ACTION_STATUSES.PENDING]: {
    values: ['Pendente', null],
    total: 0,
  },
  [ACTION_STATUSES.IN_PROGRESS]: {
    values: ['Em andamento'],
    total: 0,
  },
  [ACTION_STATUSES.CLOSED]: {
    values: ['Concluido'],
    total: 0,
  },
});

export default function MapPlanoDeAcao() {

  const [actionsStatuses, setActionsStatuses] = useState(INITIAL_VALUES);
  const [itemMenu, setItemMenu] = useState(null);
  const [riskAcepted, setRiskAcepted] = useState(null);
  const actions = useSelector((state) => state.matriz.value);
  const meetings = useSelector((state) => state.reunions.reunions);
  const planAction = useSelector((state) => state.planAction);
  const company = useSelector(selectCompany);
  const dispatch = useDispatch();
  const classes = useStyles();

  function filterActions() {
    const updatedActionsStatuses = _.cloneDeep(actionsStatuses);
    for (const actionStatus of Object.values(updatedActionsStatuses)) {
      actionStatus.total = (actions.filter((item) => {
        return (item.resposta !== 'Aceitar') && actionStatus.values.includes(item.status);
      })).length;
    }
    setActionsStatuses(updatedActionsStatuses);
  }

  async function fetchRisckAcepted() {
    await getRisco(true, 1).then(({ data }) => {
      setRiskAcepted(data.pdcaAceite)
    })
  }

  useEffect(async () => {
    filterActions();
    await fetchRisckAcepted();
  }, [actions]);

  const PAGES = Object.freeze({
    PDCA: {
      id: 'PDCA',
      titleTab: "PDCA",
      titlePage: "PDCA",
      component: <PDCA />,
      badge: riskAcepted?.length || '0'
    },
    concluido: {
      id: 'concluido',
      titleTab: "Concluído",
      titlePage: "Concluído",
      component: <ActionPlanClossed />,
      badge: actionsStatuses?.['CLOSED']?.total || '0'
    },
    emandamento: {
      id: 'emandamento',
      titleTab: "Em andamento",
      titlePage: "Em andamento",
      component: <ActionPlanInProgress />,
      badge: actionsStatuses?.['IN_PROGRESS']?.total || '0'
    },
    pendente: {
      id: 'pendente',
      titleTab: "Pendente",
      titlePage: "Pendente",
      component: <ActionPlanPending />,
      badge: actionsStatuses?.['PENDING']?.total || '0'
    },
  });

  const ARRAY_PAGES = Object.values(PAGES);

  function calbackItemMenu(itemMenu) {
    setItemMenu(itemMenu);
  }

  /**
   * Fetch all actions from the API
   */
  async function getAllActions() {
    const { data, } = await listCompanyActions(company._id, {
      taskStatus: [TASK_STATUSES.IN_PROGRESS, TASK_STATUSES.CLOSED],
    });
    const filtered = data.actions;
    updateActions(filtered, 'Itens para revisão');
  }

  /**
 * Update actions array and other related states
 *
 * @param {array} actions - updated actions array
 * @param {string} newTitle - updated title
 * @param {number | null} meetingId - meeting ID
 */
  function updateActions(actions, newTitle, meetingId = null) {
    dispatch(updateTitle(newTitle));
    dispatch(setItens(actions));
    dispatch(setCurrentMeeting(meetingId));
  }

  /**
  * Handle the change of the current meeting
  *
  * @param {number} meetingId - meeting ID
  */
  const onCurrentMeetingChange = async (meetingId) => {
    const isValid = !(_.isString(meetingId) && meetingId.length === 0);
    if (isValid) {
      const { data, } = await getRiscoByReunionID(meetingId);
      updateActions(data, 'Itens de reunião', meetingId);
    } else {
      getAllActions();
    }
  }


  function SelectReunion() {
    return (
      <Box style={{ display: "flex", flexDirection: "row-reverse", position:"absolute", right:0 }}>
        <Dropdown
          defaultItem="Escolha uma reunião"
          items={meetings}
          value={planAction.currentMeeting}
          labelKey="title"
          setValue={onCurrentMeetingChange}
        />
      </Box>
    );
  }

  return (
    <Sheet pages={ARRAY_PAGES} outsideLeftComponent={<MenuLeft />} outsideRightComponent={<SelectReunion />} calbackItemMenu={calbackItemMenu} />
  );
}
