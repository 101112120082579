import React from 'react';

import { Button, } from 'rsuite';
import { Container, } from '@material-ui/core';
import { Row, Col, Form, } from 'react-bootstrap';


function TabSelect({ tabs, label, name, onChange, value, }) {
  return (
    <Form.Group>
      <Form.Label>
        { label }
      </Form.Label>
      <Form.Control
        as="select"
        name={name}
        onChange={onChange}
        value={value}
      >
        <option>Selecione uma aba...</option>
        {
          tabs.map((tab) => (
            <option
              key={tab.id}
              value={tab.id}
            >
              {tab.name}
            </option>
          ))
        }
      </Form.Control>
    </Form.Group>
  );
}

export default function CloneDataMappingTabForm({ tabs, onCancel, onSubmit, }) {
  const data = React.useState({
    provider: 0,
    receiver: 0,
  });

  function handleChange(ev) {
    setDefaultNamespace({
      ...data,
      [ev.target.name]: ev.target.value,
    });
  }


  return (
    <Container
      style={{
        marginBottom: '1rem',
      }}
    >
      <Row>
        <Col md={6}>
          <TabSelect
            tabs={tabs}
            label="Aba base (Copiar)"
            name="provider"
            onChange={handleChange}
          />
        </Col>

        <Col md={6}>
          <TabSelect
            tabs={tabs}
            label="Aba que irá recebr (Colar)"
            name="receiver"
            onChange={handleChange}
          />
        </Col>
      </Row>

      <Row>
        <Col md={10}>
          <Button
            appearance="subtle"
            onClick={onCancel}
          >
            Cancelar
          </Button>
        </Col>
        <Col md={2}>
          <Button
            appearance="primary"
            block
            onClick={onSubmit}
          >
            Ok
          </Button>
        </Col>
      </Row>
    </Container>
  );
}
