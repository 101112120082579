import { createSlice } from '@reduxjs/toolkit'

export const mapeamentoRelatorio = createSlice({
  name: 'value',
  initialState: {
    abas: [],
    campos: [],
    abaAtivaCadastro: '',
  },

  reducers: {
    getCampos: (state) => {
      state.campos = action.payload
    },
    getAbas: (state, action) => {
      state.abas = action.payload
    },
    setAbaAtivaCadastro: (state, action) => {
      state.abaAtivaCadastro = action.payload
    },
  },
})

export const { getAbas, getCampos, setAbaAtivaCadastro } =
  mapeamentoRelatorio.actions

export default mapeamentoRelatorio.reducer
