import { useEffect, useState } from "react";
import { getReportConfig, createReportHeader } from "../../services/report.api";

/**
 *
 * @param company {{_id: string}}
 * @returns {{companyId: string, headers: [{id: number, name: string, value: string}] }}
 */
export function useReportConfigGet(company) {

  const [reportConfig, setHeaders] = useState({ headers: [] });

  async function getData() {
    const response = await getReportConfig(company);
    setHeaders(response.data);
  }

  useEffect(async () => {
    await getData();
  }, []);

  return [reportConfig, setHeaders]
}
