import React from 'react';
import { makeStyles, IconButton, } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';


const useStyles = makeStyles({
  container: {
    backgroundColor: '#F2F4F8',
    borderRadius: '0.75rem',
  },
  input: {
    backgroundColor: '#F2F4F8',
    borderRadius: '0.75rem',
    borderWidth: 0,
    color: '#54708C',
    '&:focus': {
      outline: 'none',
    },
  },
});


export default function QuestionOptionInput (props) {
  const classes = useStyles();


  return (
    <div className={classes.container}>
      <input
        placeholder={`Opção ${props.idx + 1}`}
        type="text"
        className={classes.input}
        {...props}
      />
      <IconButton
        size="small"
        onClick={props.onRemoveOption}
      >
        <CloseIcon/>
      </IconButton>
    </div>
  );
}
