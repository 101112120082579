import React, { useEffect, useState, useMemo, } from 'react';
import {useDispatch, useSelector,} from 'react-redux';
import _ from 'lodash';
import moment from 'moment';

import { makeStyles, Grid, Typography, Card, CardHeader, CardContent, } from '@material-ui/core';

import imgLogo from '../../../assets/relatorio_logo.svg';

import { listCompanyDataMappingReport, } from '../../../services/api';

import { selectCompany, } from '../../../features/companySlice';

import SearchField from '../../SearchField';
import PrintModeReportPaginator from './PrintModeReportPaginator';
import LimitedBackdropLoader from '../../LimitedBackdropLoader';

import { checkSearch, } from '../../../utils';
import DataMappingLineReadOnlyField from "./components/ResponseComponents";
import DataMappingAnswerPanelContext from "../../DataMappingAnswerPanel/context";
import DataMappingLineField from "../../DataMappingAnswerPanel/Field/DataMappingLineField";

const COLORS = ['#FFB54D', '#54708C', '#B2DD7B', '#C0A1F2', '#F0988E'];
const defaultColor = '#54708C';

const useStyles = makeStyles({
  reportContainer: {
    padding: '2rem',
    background: '#fff',
  },
  title: {
    color: defaultColor,
    fontWeight: 600,
    fontSize: '1rem',
  },
  dateSpan: {
    fontWeight: 'normal',
  },
  reportHeader: {
    marginBottom: 15,
  },
  reportPhaseCard: {
    marginBottom: 20,
    borderRadius: 25,
  },
  reportFieldCard: {
    marginBottom: 10,
  },
  reportFieldName: {
    color: '#FFFFFF',
    fontWeight: 550,
  },
  reportFieldAnswer: {
    color: defaultColor,
    padding: 7,
    paddingLeft: 16,
    paddingRight: 16,
    backgroundColor: '#F2F4F8',
  },
  defaultColor: {
    color: defaultColor,
  },
});


function PrintModeReport({ orderByField, }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const company = useSelector(selectCompany);
  const [page, setPage] = useState(0);
  const [loading, setLoading] = useState(true);
  const [totalPages, setTotalPages] = useState(10);
  const [reportPage, setReportPage] = useState({
    phases: [],
  });
  const [search, setSearch] = useState('');
  const { report, } = useSelector((state) => state.customMappingReport);
  const customReport = useMemo(() => (report || {}).id, [report]);
  const [prevCustomReport, setPrevCustomReport] = useState(customReport);
  const context = React.useContext(DataMappingAnswerPanelContext);
  const { listPhaseFields } = context;
  const [phaseFields, setPhaseFields] = useState([]);

  if (prevCustomReport != customReport) {
    if (0 !== page) {
      setPage(0);
    }
    setPrevCustomReport(customReport);
  }

  const filteredReportPage = useMemo(() => {
    let currReportPage = _.cloneDeep(reportPage);

    if (report.id !== null) {
      currReportPage.phases = currReportPage.phases
        .map((phase) => {
          const fields = phase.fields
            .filter(({ fieldId, }) => report.items.includes(fieldId));
          return {
            ...phase,
            fields,
          };
        })
        .filter(({ fields, }) => fields.length > 0);
    }

    if (search.length > 0) {
      currReportPage = {
        phases: currReportPage.phases
          .map(({ name, fields, }) => {
            const filteredFields = fields
              .filter(({ campo, resp, }) => {
                return checkSearch(campo, search) || checkSearch(resp, search);
              });
            const phaseNameMatchesSearch = checkSearch(name, search);

            return {
              name,
              fields: (filteredFields.length === 0) && phaseNameMatchesSearch ?
                fields : filteredFields,
            };
          })
          .filter(({ fields, }) => fields.length > 0),
      };
    }

    return currReportPage;
  }, [search, reportPage, report]);


  async function getReportPage() {
    setLoading(true);

    try {
      const { data, } = await listCompanyDataMappingReport(company._id, page, 1, orderByField,
        customReport);
      const { report, total, } = data;
      setTotalPages(total);
      if (report.length > 0) {
        setReportPage({...report[0], phases: report[0].phases.map(phase => ({...phase, fields: phase.fields.sort((a, b) => a.ordem - b.ordem) }))});
      }
    } catch (err) {
      console.log(err);
    }

    setLoading(false);
  }

  useEffect(getReportPage, [customReport, orderByField, page]);


  useEffect(async () => {
    if (!context.phases.length) return;

    const phaseIds = [...new Set(context.phases.map((phase) => phase.id))];

    const request = phaseIds.map(id => listPhaseFields(id));
    const fields = await Promise.all(request);

    setPhaseFields(fields);
  }, [context.phases]);

  return (
    <Grid
      className={classes.reportContainer}
      style={{
        position: 'relative'
      }}
    >
      <Grid
        className={classes.reportHeader}
        container
        justifyContent="space-between"
        alignItems="center"
      >
        <p className={classes.title}>
          { report.name }
        </p>

        <p className={classes.title}>
          { `Data de emissão: ${moment().format('DD/MM/YYYY')}` }
        </p>

        <SearchField
          value={search}
          label="Pesquisar abas"
          onChange={setSearch}
        />

        <img
          src={imgLogo}
          style={{
            height: '50px',
          }}
        />
      </Grid>

      <PrintModeReportPaginator
        page={page}
        totalPages={totalPages}
        onChangePage={setPage}
        style={{
          marginBottom: 10,
        }}
      />

      {
        filteredReportPage.phases.map((phase, index) => {
          //? Phase card color
          const color = COLORS[index%COLORS.length];

          return (
            <Card
              className={classes.reportPhaseCard}
              key={phase.name}
            >
              <CardHeader
                style={{
                  borderTop: `8.65px solid ${color}`,
                  textAlign: 'center',
                  paddingBottom: 0,
                  paddingTop: 2,
                }}
                titleTypographyProps={{
                  style: {
                    fontWeight: 500,
                    color: color,
                  }
                }}
                title={phase.name}
              />

              <CardContent
                style={{
                  paddingBottom: 10,
                }}
              >
                {
                  phase.fields.map((field) => {
                    let fieldOptions = null;
                    if (phaseFields[index]){
                      const findField = phaseFields[index].find((phaseField) => phaseField.id === field.fieldId);
                      if(findField) fieldOptions = findField
                    }
                    return (
                    <Card
                      className={classes.reportFieldCard}
                      elevation={0}
                      key={field.campo}
                    >
                      <Grid
                        container
                        spacing={0}
                      >
                        {/* Do not change this component to item, it was configured this to
                          center the text inside it. Only change this if you find another way to
                          center the text.
                          */}

                        <Grid
                          xs={3}
                          lg={4}
                          container
                          alignItems="center"
                          style={{
                            backgroundColor: color,
                            padding: 7,
                          }}
                        >
                          <Typography
                            variant="body1"
                            className={classes.reportFieldName}
                          >
                            {field.campo}
                          </Typography>
                        </Grid>

                        <Grid
                          xs={9}
                          lg={8}
                          item
                          className={classes.reportFieldAnswer}
                        >
                          <DataMappingLineReadOnlyField
                            token={context.token}
                            answer={field}
                            field={fieldOptions}
                            updateOptions={() => {}}
                            readOnly
                            key={field.uid}
                            style={{
                              width: '100%',
                            }}
                          />
                        </Grid>
                      </Grid>
                    </Card>
                  )
                  })
                }
              </CardContent>
            </Card>
          );
        })
      }

      <PrintModeReportPaginator
        page={page}
        totalPages={totalPages}
        onChangePage={setPage}
      />

      <LimitedBackdropLoader
        open={loading}
        invisible
      />
    </Grid>
  );
}

export default PrintModeReport;
