import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';
import { useTranslation, } from 'react-i18next';

import { Button, Form, Col } from 'react-bootstrap';
import {
  Container,
  Paper,
  Grid,
  Toolbar,
  Typography,
  AppBar,
  Collapse,
} from '@material-ui/core';
import { Steps, Divider, Notification, } from 'rsuite';

import {
  getPublicCompany,
  sendContactRequestEmailVerification,
  listApplicantContactRequestsInCompany,
} from '../../services/api';

import ShowCode from './ShowCodigo';
import ShowSolicitacoes from './ShowSolicitacoes';
import { FeedbackEnvio, } from './FeedBackSoliDados';
import DPOContactForm from '../../components/DPOContactForm';


const CONTACT_STEPS = Object.freeze({
  SEND_CONTACT: {
    key: 'SEND_CONTACT',
    value: 0,
    title: 'Você envia uma solicitação',
  },
  DPO_ANALYSIS: {
    key: 'DPO_ANALYSIS',
    value: 1,
    title: 'DPO analisa sua solicitação',
  },
  DPO_ANSWER: {
    key: 'DPO_ANSWER',
    value: 2,
    title: 'DPO responde sua solicitação',
  },
  CONTACT_FINISHED: {
    key: 'CONTACT_FINISHED',
    value: 3,
    title: 'Solicitação finalizada',
  },
});
const CONTACT_STEPS_ARRAY = Object.freeze(Object.values(CONTACT_STEPS));


const SolicitacaoDados = () => {
  const { t, } = useTranslation();
  const { search } = useLocation();

  const companyId = queryString.parse(search).idEmpresa;
  const origin = document.referrer || queryString.parse(search).origin;
  const code = queryString.parse(search).codigo;
  const progressCheck = queryString.parse(search).progress;

  const [company, setCompany] = useState({});
  const [step, setStep] = useState(CONTACT_STEPS.SEND_CONTACT.value);

  const [email, setEmail] = useState('');
  const [codigo, setCodigo] = useState('');

  const [bodyObs, setBodyObs] = useState([]);
  const [sendEmail, setSendEmail] = useState(false);
  const [feedbackEnvioOk, setFeedbackEnvioOk] = useState(false);

  const styles = {
    width: '200px',
    display: 'inline-table',
    verticalAlign: 'top',
  }

  async function __getCompany() {
    try {
      const { data: companyData, } = await getPublicCompany(companyId);
      setCompany(companyData);
    } catch {
      Notification['error']({
        placement: 'bottomEnd',
        title: 'Falha ao carregar informações da empresa',
        description: 'Tente novamente dentro de alguns instantes',
      });
    }
  }

  /**
   * Use effect to load company data from the API
   */
  useEffect(() => {
    __getCompany();

    if (code === 'true') {
      setStep(CONTACT_STEPS.DPO_ANALYSIS.value);
      setSendEmail(false);
    }

    if (progressCheck === 'true') {
      setSendEmail(true);
      setStep(CONTACT_STEPS.DPO_ANALYSIS.value);
    }
  }, []);

  async function sendEmailVerification() {
    try {
      await sendContactRequestEmailVerification(companyId, email);

      setSendEmail(false);
      Notification['success']({
        placement: 'bottomEnd',
        title: t('PAGES.CONTACT_REQUEST.email_verification_successfully_sent'),
        description: t('PAGES.CONTACT_REQUEST.email_verification_successfully_sent_description'),
      });
    } catch {
      Notification['error']({
        placement: 'bottomEnd',
        title: t('ERRORS.CONTACT_REQUEST.failed_to_send_email_verification'),
        description: t('ERRORS.try_again_later'),
      });
    }
  }

  async function getSolicitaçãoAndVerification() {
    try {
      const { data, } = await listApplicantContactRequestsInCompany(companyId, Number(codigo));

      setSendEmail(false);
      setBodyObs(data.requests);
      setStep(CONTACT_STEPS.DPO_ANSWER.value);
    } catch {
      Notification['error']({
        placement: 'bottomEnd',
        title: t('ERRORS.CONTACT_REQUEST.access_not_authorized'),
      });
    }
  }

  function onContactSent() {
    setFeedbackEnvioOk(true);
  }


  return (
    <Grid container>
      <AppBar
        position="static"
        style={{
          backgroundColor: '#1C265A',
          marginBottom: '2%',
        }}
      >
        <Toolbar>
          <Typography
            variant="h6"
            style={{
              marginLeft: '47%',
              fontWeight: '800',
              fontSize: '1.5rem',
              fontFamily: 'Inter',
            }}
          >
            {company.name}
          </Typography>
        </Toolbar>
      </AppBar>
      <Typography
        variant="h5"
        style={{
          color: '#4D5884',
          marginLeft: '2%',
          marginBottom: '2%',
        }}
      >
        Fale com nosso DPO
      </Typography>

      <Container
        component={Paper}
        maxWidth="false"
        style={{
          marginLeft: '2%',
          marginRight: '2%',
        }}
      >
        <br />
        <Grid
          container
          justifyContent="center"
          alignItems="flex-start"
        >
          <Grid
            item
            xs={12}
            md={4}
          >
            <strong
              style={{
                color: '#3B66FF',
                fontSize: '20px',
              }}
            >
              Como podemos te ajudar?
            </strong>
            <br />
            <Typography>
              Caso você tenha alguma dúvida sobre como seus
              dados são tratados ou queira solicitar algum
              dos direitos que lhes são conferidos pela LGPD
              favor preecher o formulário.
            </Typography>
            <br />
            <strong
              style={{
                color: '#3B66FF',
                fontSize: '20px',
              }}
            >
              Como vamos atender sua solicitação
            </strong>
            <br />
            <Container maxWidth="xl">
              <Steps current={step} vertical style={styles}>
                {
                  CONTACT_STEPS_ARRAY.map((stepOption) => (
                    <Steps.Item {...stepOption}/>
                  ))
                }
              </Steps>
            </Container>
          </Grid>

          <Grid
            item
            xs={12}
            md={8}
          >
            <Container
              style={{
                backgroundColor: '#E6E9F1',
                minHeight: '28rem',
              }}
            >
              <Collapse
                in={step === CONTACT_STEPS.SEND_CONTACT.value}
                style={{
                  height: '100%',
                }}
              >
                <strong
                  style={{
                    color: '#3B66FF',
                    fontSize: '20px',
                  }}
                >
                  Solicitação
                </strong>

                {
                  feedbackEnvioOk ?
                  <FeedbackEnvio /> :
                  <>
                    <DPOContactForm
                      company={company}
                      onContactSent={onContactSent}
                      origin={origin}
                    />
                    <Divider>
                      Ou{' '}
                      <a
                        style={{
                          color: '#3B66FF',
                          fontSize: '12px',
                          cursor: 'pointer',
                        }}
                        onClick={() => {
                          setStep(CONTACT_STEPS.DPO_ANALYSIS.value);
                          setSendEmail(true);
                        }}
                      >
                        clique aqui
                      </a>{' '}
                      para consultar sua solicitação
                    </Divider>
                  </>
                }
              </Collapse>

              <Collapse
                in={step === CONTACT_STEPS.DPO_ANALYSIS.value}
              >
                {
                  sendEmail ?
                  <Container
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      justifyContent: 'center',
                      marginTop: '1rem',
                    }}
                  >
                    <strong
                      style={{
                        color: '#3B66FF',
                        fontSize: '20px',
                      }}
                    >
                      Para consultar uma solicitação é simples!
                    </strong>

                    <br/>

                    <Typography>
                      Precisamos apenas do seu email para checar em nossa base de dados.
                    </Typography>

                    <br/>

                    <Form>
                      <Form.Row>
                        <br />
                        <Col xs={8}>
                          <Form.Control
                            value={email}
                            onChange={(e) => {
                              setEmail(e.target.value)
                            }}
                            placeholder="Email"
                          />
                        </Col>
                        <Col xs={4}>
                          <Button onClick={sendEmailVerification} >
                            Consultar
                          </Button>
                          <Divider vertical />
                        </Col>
                      </Form.Row>
                    </Form>
                    <br />
                  </Container> :
                  <ShowCode
                    GetObs={getSolicitaçãoAndVerification}
                    setCodigo={setCodigo}
                  />
                }
              </Collapse>

              <Collapse in={step === CONTACT_STEPS.DPO_ANSWER.value}>
                <Container>
                  <br/>
                  <strong
                    style={{
                      color: '#3B66FF',
                      fontSize: '20px',
                    }}
                  >
                    Veja abaixo o status da sua solicitação
                  </strong>

                  <br/>

                  <ShowSolicitacoes
                    data={bodyObs}
                    token={codigo}
                    companyId={companyId}
                  />

                  <br />
                </Container>
              </Collapse>
            </Container>
          </Grid>
        </Grid>
      </Container>
    </Grid>
  )
}

export default SolicitacaoDados;
