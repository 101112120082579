import React from 'react';

import { DashboardWidgetType, } from '../../../core/entities/dashboard-widget';

import CompanyObjectiveWidget from './CompanyObjectiveWidget';
import CompanyProgressWidget from './CompanyProgressWidget';
import RiskReportWidget from './RiskReportWidget';
import DataMappingReportWidget from './DataMappingReportWidget';
import PurposeReportWidget from './PurposeReportWidget';
import ProcessingTypeReportWidget from './ProcessingTypeReportWidget';
import PersonalDataTypeReportWidget from './PersonalDataTypeReportWidget';
import LegalBasesReportWidget from './LegalBasesReportWidget';
import SensitiveDataCategoryReportWidget from './SensitiveDataCategoryReportWidget';
import HeatMapWidget from './HeatMapWidget';
import CategoryMaturityLevelReportWidget from './CategoryMaturityLevelReportWidget';
import ActionPlanReportWidget from './ActionPlanReportWidget';
import DpoContactRequestReportWidget from './DpoContactRequestReportWidget';
import PrivacyCultureWidget from './PrivacyCultureWidget';
import SensitiveDataTypeReportWidget from "./SensitiveDataTypeReportWidget";

const WIDGET_COMPONENT_MAP = Object.freeze({
  [DashboardWidgetType.COMPANY_OBJECTIVE]: CompanyObjectiveWidget,
  [DashboardWidgetType.COMPANY_PROGRESS]: CompanyProgressWidget,
  [DashboardWidgetType.RISK_REPORT]: RiskReportWidget,
  [DashboardWidgetType.DATA_MAPPING_REPORT]: DataMappingReportWidget,
  [DashboardWidgetType.PURPOSE_REPORT]: PurposeReportWidget,
  [DashboardWidgetType.PROCESSING_TYPE_REPORT]: ProcessingTypeReportWidget,
  [DashboardWidgetType.PERSONAL_DATA_TYPE_REPORT]: PersonalDataTypeReportWidget,
  [DashboardWidgetType.SENSITIVE_DATA_REPORT]: SensitiveDataTypeReportWidget,
  [DashboardWidgetType.LEGAL_BASES_REPORT]: LegalBasesReportWidget,
  [DashboardWidgetType.SENSITIVE_DATA_CATEGORY_REPORT]: SensitiveDataCategoryReportWidget,
  [DashboardWidgetType.HEAT_MAP]: HeatMapWidget,
  [DashboardWidgetType.CATEGORY_MATURITY_LEVEL_REPORT]: CategoryMaturityLevelReportWidget,
  [DashboardWidgetType.ACTION_PLAN_REPORT]: ActionPlanReportWidget,
  [DashboardWidgetType.DPO_CONTACT_REQUEST_REPORT]: DpoContactRequestReportWidget,
  [DashboardWidgetType.PRIVACY_CULTURE]: PrivacyCultureWidget,
});


const Widget = React.forwardRef(function Widget({
  style,
  className,
  onMouseDown,
  onMouseUp,
  onTouchEnd,
  type,
  ...props
}, ref) {
  const Component = WIDGET_COMPONENT_MAP[type];


  return (
    <div
      style={{
        ...style,
      }}
      className={className}
      ref={ref}
      onMouseDown={onMouseDown}
      onMouseUp={onMouseUp}
      onTouchEnd={onTouchEnd}
    >
      <Component
        type={type}
        {...props}
      />
    </div>
  );
});

export default React.memo(Widget);
