import React from 'react';
import {
  makeStyles,
  Grid,
  Typography,
  Button,
  IconButton,
} from '@material-ui/core';
import { Close } from '@material-ui/icons';

import background_error from '../../assets/background_error.svg';
import './styles.css';


const useStyles = makeStyles({
  container: {
    margin: 0,
    minWidth: '75%',
    background: '#8DA6FF',
    position: 'relative',
    height: '100%',
  },
  alertModal: {
    background: '#8DA6FF',
    zIndex: 1,
    padding: '6rem 14rem 9rem',
    boxShadow: '0px 5px 10px rgba(0,0,0,0.1)',
  },
  closeButton: {
    position: 'absolute',
    top: '0.5rem',
    right: '0.5rem',
    '& .MuiIconButton-label': {
      color: '#fff !important',
    },
  },
  alertModalTitle: {
    color: '#fff',
    fontFamily: 'Sora',
    fontWeight: 600,
    fontSize: '1.5rem',
    lineHeight: '1.3',
    textAlign: 'center',
  },
  divider: {
    background: '#fff',
    width: '50%',
    margin: '1rem auto 2rem ',
    height: '2px',
  },
  alertModalMessageItem: {
    color: '#fff',
    fontFamily: 'Sora',
    fontWeight: 400,
    fontSize: '1rem',
    lineHeight: '1.8',
    textAlign: 'center',
    marginBottom: '1rem',
  },
  alertModalButton: {
    color: '#fff',
    display: 'block',
    background: 'transparent',
    border: '1px solid #fff',
    paddingInline: '2rem',
    margin: 'auto',
    marginTop: '3rem',
    '&:hover': {
      color: '#fff ',
      background: '#54708C',
      border: '1px solid #54708C',
    },
  },
});


const ModalError = ({ openNewLogin }) => {
  const classes = useStyles();


  return (
    <Grid
      container
      className={classes.alertModal}
      id="alertModal"
    >
      <Grid item>
        <div
          className={classes.closeButton}
          onClick={openNewLogin}
        >
          <IconButton>
            <Close />
          </IconButton>
        </div>
        <img
          src={background_error}
          style={{
            position: 'absolute',
            bottom: 0,
            left: 0,
            zIndex: -1,
            width: '100%',
          }}
        />
        <Typography
          variant="body1"
          className={classes.alertModalTitle}
        >
          Ops! Algo deu errado.
        </Typography>
        <div className={classes.divider}></div>
        <Typography
          variant="body2"
          className={classes.alertModalMessageItem}
        >
          Infelizmente sua solicitação não pode ser
          processada.
        </Typography>

        <Typography
          variant="body2"
          className={classes.alertModalMessageItem}
        >
          Em caso de duvidas ntre em contato pelo <br />
          e-mail suporte@octo.legal <br /> ou diretamente no
          suporte via whatsapp
        </Typography>

        <a
          href="https://wa.me/5541988377401"
          target="_blank"
          style={{ textDecoration: 'none' }}
        >
          <Button
            variant="outlined"
            className={classes.alertModalButton}
            color="secondary"
          >
            Suporte Whatsapp
          </Button>
        </a>
      </Grid>
    </Grid>
  );
}

export default ModalError;
