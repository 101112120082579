import { useContext, useState } from 'react';
import { useDispatch, } from 'react-redux';
import { Form, } from 'react-bootstrap';
import {
  Button,
  IconButton,
  Icon,
  Input,
  InputGroup,
  Notification,
} from 'rsuite';
import { Container, Typography, } from '@material-ui/core';
import { makeStyles, } from '@material-ui/core/styles';
import ToastAnimated from '../../ui-lib/toast';

import './styles.css';
import { Context } from '../../context/AuthContext';
import { setCompany, } from '../../features/companySlice';
import { getUserCompany, } from '../../services/api';


const useStyles = makeStyles({
  title: {
    marginBottom: '2rem',
  },
  margin: {
    marginTop: '0.75rem',
    marginBottom: '0.75rem',
  },
});


export default function NewLogin({ openRegistration, openPasswordRecovery, }) {
  const dispatch = useDispatch();
  const classes = useStyles();

  const {
    HandleLogin,
    setEmail,
    setPassword,
    email,
    password,
    isLoadingButton,
  } = useContext(Context);

  const [showSenha, setShowSenha] = useState('password');
  const [iconPass, setIconPass] = useState('eye');


  function mostrarSenha() {
    if (showSenha === 'password') {
      setShowSenha('textarea')
      setIconPass('eye-slash')
    } else if (showSenha === 'textarea') {
      setShowSenha('password')
      setIconPass('eye')
    }
  }

  /**
   * Handle login event
   */
  async function doLogin(event) {
    await HandleLogin(event);

    try {
      const { data: userCompany, } = await getUserCompany();
      dispatch(setCompany(userCompany));
    } catch {
      //todo: handle error
    }
  }

  function onLogin(event) {
    let octoConsent = localStorage.getItem('octoConsent');
    octoConsent = octoConsent || JSON.stringify({acceptedCategories: "[1,0,0,0,0]"});

    const acceptedCategories = JSON.parse(JSON.parse(octoConsent).accepted_categories || "[1,0,0,0,0]");
    const canLogIn = acceptedCategories[0] && acceptedCategories[1]; // necessary and functional cookies

    if (canLogIn || (process.env.REACT_APP_OCTO_ENV !== 'dev' && process.env.REACT_APP_OCTO_ENV !== 'prod')) {
      doLogin(event);
    } else {
      Notification['warning']({
        placement: 'topEnd',
        title: 'Atenção!',
        description: 'Para acessar o sistema você tem que concordar com o uso de cookies necessários e funcionais através do banner no canto inferior esquerdo.',
      });
    }
  }

  return (
    <Container>
      <Typography
        variant="h4"
        align="center"
        display="block"
        className={classes.title}
      >
        Entrar em sua conta
      </Typography>
      <Form>
        <Container>
          <Form.Control
            type="email"
            className={classes.margin}
            placeholder="Email"
            value={email}
            onChange={(event) => {
              setEmail(event.target.value)
            }}
          />

          <InputGroup
            inside
            className={classes.margin}
            value={password}
            onChange={(event) => {
              setPassword(event.target.value)
            }}
          >
            <Input
              type={showSenha}
              placeholder="Senha"
            />
            <InputGroup.Button>
              <Icon
                icon={iconPass}
                onClick={mostrarSenha}
              />
            </InputGroup.Button>
          </InputGroup>

          <IconButton
            className="ButtonEntrarModalLogin"
            loading={isLoadingButton}
            onClick={onLogin}
          >
            <strong>Entrar</strong>
          </IconButton>

          <Button
            block
            appearance="link"
            active
            onClick={openPasswordRecovery}
          >
            Esqueceu a senha?
          </Button>

          <Button
            className="ButtonSemCadastro"
            onClick={openRegistration}
          >
            Não tenho cadastro
          </Button>
        </Container>
        <ToastAnimated />
      </Form>
    </Container>
  )
}
