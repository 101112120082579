import React from 'react';
import { useTranslation, } from 'react-i18next';

import {
  Grid,
  Paper,
  IconButton,
  Menu,
  MenuItem,
  Typography,
  makeStyles,
} from '@material-ui/core';

import MoreVertIcon from '@material-ui/icons/MoreVert';

import LimitedBackdropLoader from '../../LimitedBackdropLoader';


const useStyles = makeStyles({
  contentContainer: {
    '&::-webkit-scrollbar': {
      width: '0.3rem',
      height: '0.5rem',
    },
    '&::-webkit-scrollbar-track': {
      '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.00)',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'rgba(0,0,0, 0.2)',
      borderRadius: 2,
    },
  },
});


function BaseWidget({
  children,
  menuActions = [],
  type,
  color = '#FFFFFF',
  header,
  loading = false,
}) {
  const classes = useStyles();
  const { t, } = useTranslation();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const title = t(`PAGES.DASHBOARD.WIDGET.${type}.title`);


  function onOpen(event) {
    setAnchorEl(event.currentTarget);
  }

  function onClose() {
    setAnchorEl(null);
  }


  return (
    <Paper
      style={{
        maxHeight: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: color,
      }}
    >
      <Grid
        container
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        style={{
          minHeight: '3rem',
          padding: '0 0.5rem',
        }}
      >
        <Grid
          item
          xs={menuActions.length > 0 ? 10 : 12}
          container
        >
          <Typography
            variant="h6"
            noWrap
            style={{
              color: '#3B66FF',
              fontWeight: 'bold',
              fontFamily: 'Inter',
            }}
          >
            { title }
          </Typography>

          {
            undefined != header ?
            <Grid
              item
              xs={true}
              container
              direction="row"
              alignItems="center"
            >
              { header }
            </Grid> :
            null
          }
        </Grid>

        {
          menuActions.length > 0 ?
          <Grid
            item
            xs={2}
            container
            alignItems="center"
            justifyContent="flex-end"
          >
            <IconButton onClick={onOpen} >
              <MoreVertIcon />
            </IconButton>

            <Menu
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={onClose}
              getContentAnchorEl={null}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
            >
              {
                menuActions
                  .map(({ label, onClick, }, idx) => {
                    return (
                      <MenuItem
                        key={idx}
                        onClick={() => {
                          onClick();
                          onClose();
                        }}
                      >
                        { label }
                      </MenuItem>
                    );
                  })
              }
            </Menu>
          </Grid> :
          null
        }
      </Grid>

      <div
        style={{
          display: 'flex',
          flexGrow: '1',
          overflow: 'auto',
          marginBottom: '0.5rem',
          flexDirection: 'column',
          padding: '0 1.5rem',
        }}
        className={classes.contentContainer}
      >
        { children }
      </div>

      <LimitedBackdropLoader
        open={loading}
        invisible
      />
    </Paper>
  );
}

export default BaseWidget;
