import { useState, useEffect } from 'react';
import api from '../../services/api';
import 'bootstrap/dist/css/bootstrap.min.css';
import CriaUsuario from './index';
import 'react-toastify/dist/ReactToastify.css';
import './styles.css';

function CreateUser() {
  const owner = localStorage.getItem('name');
  const [body, setBody] = useState([]);

  const initialize = async () => {
    try {
      const { data, } = await api.get('/user-in-master-group');
      const url = data.isInMasterGroup ? `/empresas/owner?owner=${owner}` : 'empresaAdmin';
      const response = await api.get(url);
      const total = response.data
      setBody(total);
    } catch {
      console.log('Failed to get companies');
    }
  }

  useEffect(initialize, []);


  return (
    <div>
      <CriaUsuario data={body} />
    </div>
  )
}

export default CreateUser;
