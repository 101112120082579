import React from 'react';

import Dashboard from '../../components/Dashboard';

import { DashboardWidgetType, } from '../../core/entities/dashboard-widget';


const layout = [
  {
    type: DashboardWidgetType.COMPANY_OBJECTIVE,
    i: DashboardWidgetType.COMPANY_OBJECTIVE,
    x: 0,
    y: 0,
    w: 12,
    h: 1.5,
  },
  {
    type: DashboardWidgetType.COMPANY_PROGRESS,
    i: DashboardWidgetType.COMPANY_PROGRESS,
    x: 0,
    y: 1.5,
    w: 6,
    h: 2,
  },
  {
    type: DashboardWidgetType.RISK_REPORT,
    i: DashboardWidgetType.RISK_REPORT,
    x: 6,
    y: 1.5,
    w: 6,
    h: 2,
  },
  {
    type: DashboardWidgetType.DATA_MAPPING_REPORT,
    i: DashboardWidgetType.DATA_MAPPING_REPORT,
    x: 0,
    y: 3.5,
    w: 12,
    h: 2.5,
  },
  {
    type: DashboardWidgetType.PURPOSE_REPORT,
    i: DashboardWidgetType.PURPOSE_REPORT,
    x: 0,
    y: 6,
    w: 6,
    h: 2,
  },
  {
    type: DashboardWidgetType.PROCESSING_TYPE_REPORT,
    i: DashboardWidgetType.PROCESSING_TYPE_REPORT,
    x: 0,
    y: 8,
    w: 6,
    h: 1,
  },
  {
    type: DashboardWidgetType.PERSONAL_DATA_TYPE_REPORT,
    i: DashboardWidgetType.PERSONAL_DATA_TYPE_REPORT,
    x: 6,
    y: 6,
    w: 6,
    h: 1.5,
  },
  {
    type: DashboardWidgetType.SENSITIVE_DATA_REPORT,
    i: DashboardWidgetType.SENSITIVE_DATA_REPORT,
    x: 6,
    y: 6,
    w: 6,
    h: 1.5,
  },
  {
    type: DashboardWidgetType.LEGAL_BASES_REPORT,
    i: DashboardWidgetType.LEGAL_BASES_REPORT,
    x: 0,
    y: 9,
    w: 12,
    h: 2,
  },
  {
    type: DashboardWidgetType.SENSITIVE_DATA_CATEGORY_REPORT,
    i: DashboardWidgetType.SENSITIVE_DATA_CATEGORY_REPORT,
    x: 0,
    y: 11,
    w: 12,
    h: 2,
  },
  {
    type: DashboardWidgetType.HEAT_MAP,
    i: DashboardWidgetType.HEAT_MAP,
    x: 0,
    y: 13,
    w: 6,
    h: 4,
  },
  {
    type: DashboardWidgetType.CATEGORY_MATURITY_LEVEL_REPORT,
    i: DashboardWidgetType.CATEGORY_MATURITY_LEVEL_REPORT,
    x: 6,
    y: 13,
    w: 6,
    h: 4,
  },
  {
    type: DashboardWidgetType.ACTION_PLAN_REPORT,
    i: DashboardWidgetType.ACTION_PLAN_REPORT,
    x: 0,
    y: 17,
    w: 12,
    h: 1.5,
  },
  {
    type: DashboardWidgetType.DPO_CONTACT_REQUEST_REPORT,
    i: DashboardWidgetType.DPO_CONTACT_REQUEST_REPORT,
    x: 0,
    y: 18.5,
    w: 12,
    h: 1.5,
  },
  {
    type: DashboardWidgetType.PRIVACY_CULTURE,
    i: DashboardWidgetType.PRIVACY_CULTURE,
    x: 0,
    y: 20,
    w: 12,
    h: 1.5,
  },
];


function MainDashboard({}) {
  return (
    <Dashboard layout={layout} />
  );
}

export default MainDashboard;
