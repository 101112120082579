import { useState, useEffect, } from 'react';
import { useDispatch, } from 'react-redux';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  makeStyles,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { useFormik, } from 'formik';
import * as Yup from 'yup';
import { Notification } from 'rsuite';
import _ from 'lodash';

import Button from '../Button';
import { getUserId, } from '../../utils';
import {
  createQuestionsTemplate, updateQuestionsTemplate,
} from '../../features/questionsTemplatesSlice';


const INITIAL_VALUES = Object.freeze({
  name: '',
});


const useStyles = makeStyles({
  actions: {
    marginTop: '1rem',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  title: {
    color: 'white',
    backgroundColor: '#3B66FF',
  },
});


/**
 * Dialog used to create/edit questions template
 *
 * @param {boolean} open - whether the dialog is open or not
 * @param {function} onClose - callback called when dialog is closed
 * @param {object} templateData - template data if editing template
 *
 * @returns Rendered dialog
 */
function QuestionsTemplateFormDialog({ open, onClose, templateData, }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const formik = useFormik({
    initialValues: INITIAL_VALUES,
    onSubmit: handleRegister,
    validationSchema: Yup.object({
      name: Yup.string().required('Obrigatório'),
    }),
  });

  const editing = templateData != null;


  /**
   * Handle register
   *
   * @param {object} values - new template info
   */
  async function handleRegister(values) {
    setLoading(true);

    try {
      const data = _.cloneDeep(values);

      if (editing) {
        await dispatch(updateQuestionsTemplate({
          userId: getUserId(),
          templateId: templateData.id,
          templateData: data,
        })).unwrap();
      } else {
        await dispatch(createQuestionsTemplate({
          userId: getUserId(),
          templateData: data,
        })).unwrap();
      }

      Notification['success']({
        placement: 'bottomEnd',
        title: `Template de tarefas ${editing ? 'atualizado' : 'criado'} com sucesso`,
      });

      __onClose();
    } catch {
      Notification['error']({
        placement: 'bottomEnd',
        title: `Falha ao ${editing ? 'editar': 'criar'} template de tarefas!`,
        description: 'Tente novamente dentro de alguns instantes.'
      });
    }

    setLoading(false);
  }

  /**
   * Submit event handler
   *
   * @param {object} event - event that triggered the handler
   */
  function onSubmit(event) {
    event.preventDefault();
    formik.handleSubmit();
  }

  /**
   * Handle cancel
   */
  function __onClose() {
    formik.resetForm();
    onClose();
  }

  useEffect(() => {
    formik.resetForm({
      values: !editing ? INITIAL_VALUES : templateData,
    });
  }, [templateData]);


  return (
    <Dialog
      open={open}
      onClose={__onClose}
      maxWidth="md"
    >
      <DialogTitle className={classes.title}>
        {editing ? 'Editar template de tarefas' : 'Criar template de tarefas' }
      </DialogTitle>

      <DialogContent>
        <form
          onSubmit={onSubmit}
        >
          <Grid
            item
            xs={12}
            container
            direction="row"
          >
            <TextField
              margin="dense"
              variant="filled"
              label="Nome"
              InputProps={{
                disableUnderline: true,
              }}
              fullWidth
              id="name"
              type="text"
              error={formik.errors.name && formik.touched.name}
              helperText={formik.touched.name && formik.errors.name}
              {...formik.getFieldProps('name')}
            />
          </Grid>

          <Grid
            className={classes.actions}
          >
            <Button
              variant="contained"
              loading={loading}
              onClick={__onClose}
            >
              Cancelar
            </Button>

            <Button
              type="submit"
              variant="contained"
              color="primary"
              loading={loading}
            >
              Cadastrar
            </Button>
          </Grid>
        </form>
      </DialogContent>
    </Dialog>
  );
}

QuestionsTemplateFormDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default QuestionsTemplateFormDialog;
