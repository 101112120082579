import _ from 'lodash';
import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import queryString from 'query-string';
import { useSelector, } from 'react-redux';
import { useLocation, } from 'react-router-dom';

import { Notification, } from 'rsuite';

import { selectCompany, } from '../features/companySlice';
import {
  getRespostaMapeamento,
  createDataMappingAnswer,
  deleteDataMappingAnswer,
  listDataMappingPhaseFields,
  getRespostaMapeamentoPublic,
  listCompanyDataMappingPhases,
} from '../services/api';


export default function useBaseDataMappingPanel(init = true, isPublic = false) {
  const { search } = useLocation();
  const [page, setPage] = React.useState(0);
  const company = useSelector(selectCompany);
  const [phases, setPhases] = React.useState([]);
  const [answers, setAnswers] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const { token, } = queryString.parse(search);
  const [error, setError] = React.useState(null);
  const [selectedPhaseIdx, setSelectedPhaseIdx] = React.useState(0);


  const listPhases = React.useCallback(async function listPhases() {
    try {
      const { data, } = await listCompanyDataMappingPhases(company._id);
      setPhases(data);
    } catch {
      setPhases([]);
    }
  }, [company]);

  async function listAnswers() {
    try {
      const { data, } = await (isPublic ? getRespostaMapeamentoPublic(token) :
        getRespostaMapeamento());
      setAnswers(data);
    } catch(error) {
      setError(error);
      setAnswers([]);
    }
  }

  function initialize() {
    setLoading(true);
    listPhases();
    listAnswers();
    setLoading(false);
  }

  React.useEffect(() => {
    if (init) {
      initialize();
    }
  }, []);

  async function listPhaseFields(phaseId) {
    let ret = [];
    try {
      const { data, } = await listDataMappingPhaseFields(company._id, phaseId);
      ret = data;
    } catch (error) {
    }
    return ret;
  }

  async function onDeleteAnswer(answerUniqueId) {
    try {
      await deleteDataMappingAnswer(company._id, answerUniqueId);
      Notification['success']({
        title: 'Resposta deletada com sucesso',
        placement: 'bottomEnd',
      });
      listAnswers();
    } catch {
      Notification['error']({
        title: 'Erro ao deletar resposta',
        placement: 'bottomEnd',
      });
    }
  }

  /**
   * Create new answer
   *
   * @param {number | null} position - base position of the new answer, if null it will be put at
   * the end of the answers
   * @param {'up' | 'down' | null} positionType - new answer put before or after the base position
   */
  async function onCreateAnswer(position, positionType, sector) {
    try {
      const uid = uuidv4();

      if (null == position) {
        await createDataMappingAnswer(company._id, uid, token, answers.length,
          undefined, sector);
        setPage(Math.floor((answers.length) / rowsPerPage));
      } else {
        await createDataMappingAnswer(company._id, uid, token, position,
          positionType || 'down', sector);

        const idx = answers.find(({ ordem, }) => ordem == position - 1);
        if (idx > -1) {
          setPage(Math.floor((idx + 1) / rowsPerPage));
        }
      }

      listAnswers();
      Notification['success']({
        title: 'Resposta registrada com sucesso',
        placement: 'bottomEnd',
      });
    } catch {
      Notification['error']({
        placement: 'bottomEnd',
        title: 'Falha ao registrar resposta!',
      });
    }
  }

  function onChangeRowsPerPage(newRowsPerPage) {
    const newPage = Math.floor((page * rowsPerPage)/newRowsPerPage);
    setPage(newPage);
    setRowsPerPage(newRowsPerPage);
  }


  return {
    page,
    phases,
    answers,
    loading,
    company,
    isPublic,
    token,
    rowsPerPage,
    selectedPhaseIdx,
    setPhases,
    initialize,
    setLoading,
    setAnswers,
    onCreateAnswer,
    setRowsPerPage,
    onDeleteAnswer,
    listPhaseFields,
    setSelectedPhaseIdx,
    onChangePage: setPage,
    onChangeRowsPerPage,
    onChangeSelectedPhaseIdx: setSelectedPhaseIdx,
    error,
  };
}
