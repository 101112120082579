import { useState, } from 'react';
import { Button, Notification, } from 'rsuite';
import { Container, Grid, Typography, TextField, } from '@material-ui/core';
import { makeStyles, } from '@material-ui/core/styles';
import { useFormik, } from 'formik';
import * as Yup from 'yup';

import { sendPasswordRecoveryEmail, } from '../../services/api';


const INITIAL_VALUES = Object.freeze({
  email: '',
});

const useStyles = makeStyles({
  margin: {
    marginTop: '0.75rem',
    marginBottom: '0.75rem',
  },
  backButton: {
    marginTop: '2rem',
  },
});


function PasswordRecovery({back}) {
  const [loading, setLoading] = useState(false);

  const classes = useStyles();

  const formik = useFormik({
    initialValues: INITIAL_VALUES,
    onSubmit: sendRecoveryEmail,
    validationSchema: Yup.object({
      email: Yup.string().email('Deve ser um e-mail válido').required('Obrigatório'),
    }),
  });


  async function sendRecoveryEmail(data) {
    setLoading(true);

    try {
      await sendPasswordRecoveryEmail(data.email);
      Notification['success']({
        placement: 'bottomEnd',
        title: 'Verifique seu e-mail e siga as instruções para alterar sua senha!',
      });
      back();
    } catch {
      Notification['error']({
        placement: 'bottomEnd',
        title: 'Verifique seu e-mail e tente novamente!',
      });
    }

    setLoading(false);
  }

  async function handleRecover(event) {
    event.preventDefault();
    formik.handleSubmit();
  }


  return (
    <Container>
      <Grid
        container
        direction="column"
      >
        <Typography
          variant="h4"
          align="center"
          display="block"
          className={classes.margin}
        >
          Esqueceu sua senha?
        </Typography>

        <Typography
          variant="body2"
          align="center"
          display="block"
          className={classes.margin}
        >
          Digite seu endereço de e-mail para que você possa trocar sua senha.
        </Typography>

        <TextField
          InputProps={{
            disableUnderline: true,
            autoComplete: "email"
          }}
          margin="dense"
          variant="filled"
          label="E-mail"
          fullWidth
          id="email"
          type="text"
          error={formik.errors.email && formik.touched.email}
          helperText={formik.touched.email && formik.errors.email}
          {...formik.getFieldProps('email')}
        />

        <Button
          appearance="primary"
          active
          onClick={handleRecover}
          loading={loading}
          className={classes.margin}
        >
          Obter uma nova senha
        </Button>

        <Button
          appearance="default"
          active
          onClick={back}
          className={classes.backButton}
        >
          Voltar
        </Button>
      </Grid>
    </Container>
  );
}

export default PasswordRecovery;
