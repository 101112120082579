import React, { useEffect, useState, useMemo, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation, } from 'react-i18next';
import _ from 'lodash';

import { Table, } from 'react-bootstrap';
import styled from 'styled-components'
//teste build
import {
  Paper,
  Grid,
  Typography,
  Dialog,
  Select,
  MenuItem,
  TextField,
  InputAdornment,
  FormControl,
  InputLabel,
  Box,
  TablePagination,
  Container
} from '@material-ui/core';
import Paginator from '../../components/Paginator/Paginator.jsx';

import { IconButton, Icon, Notification, } from 'rsuite';

import Companies from '../../components/CompaniesComponent';
import CreateCompany from '../CriarEmpresas/index';
import './styles.css';
import useModal from '../../hooks/useModal';
import TimeLineItem from '../../components/TimeLine/TimeLineItem/index.jsx';

import { getUserSession } from '../../utils';
import { useStyles } from '../../components/NavBar/Styles.js';

import {
  partnershipsFetch,
  companiesFetch,
  setLoading,
  setListCompanies,
  setCurrentPaginator,
  setItensPerPage,
  setSelectPartnerId,
} from '../../features/dashboard.js';

import InitialWorkshopImg from '../../assets/newImageWorkshop.svg';

const CustomTablePagination = styled(TablePagination)(({ theme }) => ({
  '.MuiTablePagination-input': {
    width: 'auto'
  },
}));

/**
 * User dashboard page component
 *
 * @returns rendered page component
 */
function MainDashboard() {
  const { t, } = useTranslation();
  const user = getUserSession();
  const [search, setSearch] = useState('');
  const [open, handleOpenModal, handleCloseModal] = useModal();
  const [currPartnerIdx, setCurrPartnerIdx] = useState(null);
  const [totalItensList, setTotalItens] = useState(null);
  const classes = useStyles();

  const dispatch = useDispatch();

  const partnershipsStatus = useSelector((state) => state.dashboard.partnerships.status);
  const partnershipsData = useSelector((state) => state.dashboard.partnerships.data);
  const companiesStatus = useSelector((state) => state.dashboard.companies.status);
  const companiesData = useSelector((state) => state.dashboard.companies.data);
  const itensPerPage = useSelector((state) => state.dashboard.itensPerPage);
  const listConpanies = useSelector((state) => state.dashboard.listConpanies);
  const indexPaginator = useSelector((state) => state.dashboard.indexPaginator);
  const arrayItensPerPage = useSelector((state) => state.dashboard.listItensPerPage);
  const selectPartnerId = useSelector((state) => state.dashboard.selectedPartnerId);
  const loading = useSelector((state) => state.dashboard.isLoading);

  const partners = useSelector((state) => {
    const ids = new Set([
      ...partnershipsData.map((item) => item.groupId),
      ...companiesData.map((item) => item.group)
    ]);
    ids.delete(user.group);
    return state.groups.groups.filter((group) => ids.has(group.id));
  });

  const checkStatusFetch = useCallback(status => status === 'succeeded', []);
  const checkIsLoading = useCallback((status => status === 'loading' || status === 'idle'), []);

  const init = useCallback(() => {
    if (partnershipsStatus === 'idle') {
      dispatch(partnershipsFetch(user.group));
    }
    if (companiesStatus === 'idle') {
      dispatch(companiesFetch());
    }

    if (checkIsLoading(partnershipsStatus) || checkIsLoading(companiesStatus)) {
      dispatch(setLoading(true));
    } else {
      dispatch(setLoading(false));
    }
  }, [partnershipsStatus, companiesStatus, dispatch]);

  useEffect(init, [init]);

  useMemo(() => {
    if (checkStatusFetch(partnershipsStatus) && checkStatusFetch(companiesStatus)) {
      const selectedPartner = partners[currPartnerIdx];
      const filteredCompanies = companiesData.filter(company => {
        let keepItem = company.name.toLowerCase().includes(search.toLowerCase());
        if (selectedPartner) {
          const partnership = partnershipsData.find(item => item.companyId === company._id);
          keepItem = keepItem && (selectedPartner.id === company.group || (partnership && partnership.groupId === selectedPartner.id));
        }
        return keepItem;
      }).sort((a, b) => a.name.localeCompare(b.name));

      dispatch(setCurrentPaginator(0));
      dispatch(setListCompanies(filteredCompanies));
    }
  }, [itensPerPage, search, currPartnerIdx, partnershipsStatus, companiesStatus]);


  useMemo(() => {
    setTotalItens(listConpanies.reduce((somaAcumulada, arrayInterno) => somaAcumulada + arrayInterno.length, 0));
  }, [listConpanies])


  /**
   * Callback that handles the creation of a new company
   */
  const handleCompanyCreated = useCallback(() => {
    dispatch(companiesFetch());
    handleCloseModal();
  }, [dispatch, handleCloseModal]);

  /**
   * Callback that handles the deletion of a new company
   */
  const handleCompanyDeleted = useCallback(() => {
    dispatch(companiesFetch());
    handleCloseModal();
  }, [dispatch, handleCloseModal]);

  /**
   * Handle change in selected partner
   *
   * @param {string} selectedPartnerId - selected partner ID
   */
  function onSelectedConsultantChange(selectedPartnerId) {
    const idx = partners.findIndex(({ id, }) => id === selectedPartnerId);
    dispatch(setSelectPartnerId(selectedPartnerId));
    setCurrPartnerIdx(idx < 0 ? null : idx);
  }

  return (
    <>
      <main className={classes.content}>
        <Grid
          item
          xs={12}
          style={{ justifyContent: "center", display: "flex" }}
        >
          <Typography variant="h5">
            Seja bem-vindo{' '}
            <strong
              style={{
                color: '#3B66FF',
              }}
            >
              {user.name}
            </strong>
          </Typography>
        </Grid>
        <Grid
          container
          spacing={3}
          justifyContent="center"
        >
          <Dialog
            open={open}
            onClose={handleCloseModal}
          >
            <CreateCompany
              onClose={handleCloseModal}
              onCreated={handleCompanyCreated}
            />
          </Dialog>

          {!loading && !companiesData[0] && (
            <Grid
              item
              xs={12}
            >
              {/* <Grid
                item
                xs={12}
                style={{ textAlign: "center", margin: "1rem 0px" }}
              >
                <Typography variant="h5">
                  Seja bem-vindo{' '}
                  <strong
                    style={{
                      color: '#3B66FF',

                    }}
                  >
                    {user.name}
                  </strong>
                </Typography>
              </Grid> */}
              <Grid row container>
                <Grid
                  item
                  md={5}
                  style={{ height: "70vh" }}
                >
                  <img src={InitialWorkshopImg} alt="Executivo de terno Ilustração" height={"100%"} style={{ objectFit: "contain" }} />
                </Grid>
                <Grid
                  item
                  xs
                >
                  <Box
                    sx={{
                      height: '100%',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      paddingTop: '150px',
                      marginRight: '60px'
                    }}
                  >
                    <TimeLineItem handleOpenModal={handleOpenModal} />
                  </Box>
                  {/* <Grid container style={{ display: "flex", height: "100%", justifyContent: "center", marginTop: "60px" }}>
                    <TimeLineItem handleOpenModal={handleOpenModal} />
                  </Grid> */}
                </Grid>
              </Grid>
            </Grid>
          )}

          {!loading && companiesData[0] && (
            <Grid
              item
              xs={12}
            >
              <Grid
                item
                xs={12}
                sm={12}
              >
                <Grid
                  container
                  direction="row"
                  alignItems="center"
                  style={{
                    marginBottom: '1rem',
                  }}
                >
                  <Grid
                    item
                    container
                    direction="row-reverse"
                    alignItems="center"
                  >
                    <FormControl variant="outlined" className={classes.formControl} size="small" style={{ marginLeft: "10px", minWidth: "200px" }}>
                      <InputLabel id="demo-simple-select-outlined-label">Selecione um parceiro</InputLabel>
                      <Select
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        value={selectPartnerId || 'myConpany'}
                        onChange={(ev) => onSelectedConsultantChange(ev.target.value)}
                        label="Selecione um parceiro"
                        style={{ background: "#fff" }}
                        disabled={!partners?.length}
                      >
                        <MenuItem value={'myConpany'}>
                          <em>Selecionar parceiro</em>
                        </MenuItem>
                        {partners.map((value, index) => (
                          <MenuItem key={index + ""} value={value?.id}>{value?.name}</MenuItem>
                        ))
                        }
                      </Select>
                    </FormControl>
                    <TextField
                      label="Pesquisar"
                      id="outlined-size-small"
                      variant="outlined"
                      size="small"
                      value={search}
                      onChange={(e) => {
                        setSearch(e.target.value)
                      }}
                      style={{ background: "#fff", marginLeft: "10px" }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <Icon icon="search" />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                </Grid>

                <Paper className="ContainerGeral">
                  <Table hover responsive>
                    <thead>
                      <tr>
                        <th>Empresa</th>
                        <th>Itens para revisão</th>
                        <th>Solicitações não atendidas</th>
                        <th>Progresso</th>
                        <th>Aderência</th>
                        <th>Ultima reunião</th>
                        <th>Data da ultima reunião</th>
                        <th>Tempo decorrido</th>
                        <th style={{ width: '1rem' }}></th>
                        <th style={{ width: '1rem' }}></th>
                        <th style={{ width: '1rem' }}></th>
                      </tr>
                    </thead>
                    {
                      listConpanies[indexPaginator]?.map((company) => {
                        return (
                          <Companies
                            key={company._id}
                            data={company}
                            onDeleted={handleCompanyDeleted}
                          />
                        )
                      })
                    }
                  </Table>
                  <CustomTablePagination
                    rowsPerPageOptions={[
                      5,
                      10,
                      25,
                      { label: 'Todos', value: totalItensList },
                    ]}
                    component="div"
                    SelectProps={{
                      inputProps: { 'aria-label': 'Linhas' },
                      native: true,
                    }}
                    count={totalItensList}
                    rowsPerPage={itensPerPage}
                    page={indexPaginator}
                    labelRowsPerPage={`Linhas por página`}
                    labelDisplayedRows={({ from, to, count }) => `${from}-${to} de ${count}`}
                    onPageChange={(_, newPage) => dispatch(setCurrentPaginator(newPage - 1))}
                    onRowsPerPageChange={(event) => dispatch(setItensPerPage(parseInt(event.target.value, 10)))}
                    ActionsComponent={() => <Paginator page={(indexPaginator + 1)} rowsPerPage={itensPerPage} count={totalItensList} onChange={(_, value) => dispatch(setCurrentPaginator(value-1))} />}
                  />

                  <div style={{ display: "flex", flexDirection: "row", justifyContent: "center", width: "100%", paddingBottom: "20px" }}>
                    <IconButton
                      icon={open ? <Icon icon="close" /> : <Icon icon="plus" />}
                      color={open ? "red" : "green"}
                      size="lg"
                      onClick={handleOpenModal}
                    >
                      Criar empresa
                    </IconButton>
                  </div>
                </Paper>
              </Grid>
            </Grid>
          )}
        </Grid>
      </main>
    </>
  )
}

export default MainDashboard;
