import { useEffect, useState, } from 'react';
import { useTranslation, } from 'react-i18next';
import _ from 'lodash';
import queryString from 'query-string';
import { useLocation, } from 'react-router-dom';

import {
  Container,
  Grid,
  Button,
  LinearProgress as MuiLinearProgress,
  Typography,
} from '@material-ui/core';
import { Notification, } from 'rsuite';

import { withStyles, } from '@material-ui/core/styles';

import Loading from '../../components/Global/Loading';
import PublicPageLayout from '../../components/PublicPageLayout';
import DueDiligenceRequestQuestionList from '../../components/DueDiligenceRequestQuestionList';

import {
  getCompanyDueDiligenceRequest,
  answerDueDiligenceRequestQuestion,
  finishDueDiligenceRequest,
  getPublicCompany,
  getProcessingAgent
} from '../../services/api';
import useModal from '../../hooks/useModal';
import ConfirmDialog from '../../components/ConfirmDialog';


const LinearProgress = withStyles((theme) => ({
  root: {
    height: '1rem',
    borderRadius: 10,
    marginTop: '1rem',
  },
  bar: {
    borderRadius: 5,
    backgroundColor: '#1a90ff',
  },
}))(MuiLinearProgress);


export default function PublicDueDiligencePage() {
  const { t, } = useTranslation();
  const { search, } = useLocation();
  const { rid: dueDiligenceRequestId, } = queryString.parse(search);
  const [modalOpen, openModal, closeModal] = useModal();
  const [loading, setLoading] = useState(true);
  const [dueDiligenceRequest, setDueDiligenceRequest] = useState(undefined);
  const [company, setCompany] = useState(undefined);
  const [agent, setAgent] = useState(null);

  const questions = undefined != dueDiligenceRequest ? dueDiligenceRequest.questions || [] : [];


  async function getData() {
    try {
      const { data, } = await getCompanyDueDiligenceRequest(dueDiligenceRequestId);
      // if (data?.companyId && data?.processingAgentId) {
      //   const response = await getProcessingAgent(data.companyId, data.processingAgentId);
      //   setAgent(response.data);
      // }
      setDueDiligenceRequest(data);
    } catch {
      Notification['error']({
        placement: 'bottomEnd',
        title: _.capitalize(t('ERRORS.PUBLIC_DUE_DILIGENCE_REQUEST.failed_to_get_due_diligence_request')),
        description: _.capitalize(t('ERRORS.try_again_later')),
      });
    }
  }

  async function sendQuestionAnswer(questionId, data) {
    try {
      const { data: answeredQuestion, } = await answerDueDiligenceRequestQuestion(
        dueDiligenceRequestId, questionId, data);
      setDueDiligenceRequest((request) => {
        const ret = Object.assign({}, request);
        if ((undefined != request) && (undefined != request.questions)) {
          const idx = request.questions.findIndex((question) => question.id === questionId);
          if (idx > -1) {
            request.questions[idx] = {
              ...(request.questions[idx]),
              ...answeredQuestion,
            };
          }
        }
        return ret;
      });

      Notification['success']({
        placement: 'bottomEnd',
        title: _.capitalize(t('PAGES.PUBLIC_DUE_DILIGENCE_REQUEST.due_diligence_request_question_successfully_answered')),
      });
    } catch {
      Notification['error']({
        placement: 'bottomEnd',
        title: _.capitalize(t('ERRORS.PUBLIC_DUE_DILIGENCE_REQUEST.failed_to_answer_question')),
        description: _.capitalize(t('ERRORS.try_again_later')),
      });
    }
  }

  async function onFinishDueDiligenceRequest() {
    try {
      closeModal();
      if (questions.length === 0) {
        return;
      }

      if (null != dueDiligenceRequest.finishedAt) {
        Notification['warning']({
          placement: 'bottomEnd',
          title: _.capitalize(t('ERRORS.PUBLIC_DUE_DILIGENCE_REQUEST.already_finished')),
        });
        return;
      }

      for (const question of questions) {
        if (null == question.answeredAt) {
          Notification['warning']({
            placement: 'bottomEnd',
            title: _.capitalize(t('ERRORS.PUBLIC_DUE_DILIGENCE_REQUEST.unanswered_questions')),
            description: t('ERRORS.PUBLIC_DUE_DILIGENCE_REQUEST.answer_the_question', {
              question: question.question,
              position: question.order,
            }),
          });
          return;
        }
      }

      await finishDueDiligenceRequest(dueDiligenceRequestId);
      Notification['success']({
        placement: 'bottomEnd',
        title: _.capitalize(t('PAGES.PUBLIC_DUE_DILIGENCE_REQUEST.due_diligence_request_successfully_finished')),
      });
      setDueDiligenceRequest({
        ...dueDiligenceRequest,
        finishedAt: (new Date()).toISOString(),
      });
    } catch {
      Notification['error']({
        placement: 'bottomEnd',
        title: _.capitalize(t('ERRORS.PUBLIC_DUE_DILIGENCE_REQUEST.failed_to_finish_due_diligence_request')),
        description: _.capitalize(t('ERRORS.try_again_later')),
      });
    }
  }

  async function initialize() {
    setLoading(true);
    await Promise.all([getData()]);
    setLoading(false);
  }

  async function getCompanyData(companyId) {
    try {
      const { data, } = await getPublicCompany(companyId);
      setCompany(data);
    } catch {
      //? Drop error
    }
  }

  useEffect(initialize, []);

  useEffect(() => {
    if (undefined != dueDiligenceRequest && undefined != dueDiligenceRequest.companyId) {
      getCompanyData(dueDiligenceRequest.companyId);
    }
  }, [dueDiligenceRequest]);


  const percentageAnsweredQuestions = undefined != questions ?
    100 * (questions.filter((question) => null != question.answeredAt).length / questions.length) : 0;


  return (
    <PublicPageLayout
      title={_.capitalize(t('PAGES.PUBLIC_DUE_DILIGENCE_REQUEST.due_diligence_request'))}
      companyName={undefined != company ? company.name : ''}
    >
      {
        loading ?
          <Loading value={loading} /> :
          <Container maxWidth="xl" >
            {
              undefined != dueDiligenceRequest && questions.length > 0 ?
                <>
                  <LinearProgress
                    variant="determinate"
                    value={percentageAnsweredQuestions}
                  />
                  <Typography
                    display="block"
                    align="center"
                    variant="h6"
                    paragraph
                    color="primary"
                    style={{
                      fontWeight: 'bold',
                    }}
                  >
                    {`${percentageAnsweredQuestions.toFixed(0)} %`}
                  </Typography>

                  <DueDiligenceRequestQuestionList
                    questions={questions}
                    onSubmit={sendQuestionAnswer}
                    agent={agent}
                  />
                  <Grid
                    container
                    alignItems="center"
                    justifyContent="flex-end"
                  >
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={openModal}
                    >
                      {_.capitalize(t('PAGES.PUBLIC_DUE_DILIGENCE_REQUEST.finish'))}
                    </Button>
                  </Grid>
                  <ConfirmDialog
                    open={modalOpen}
                    confirmationMessage={t('PAGES.PUBLIC_DUE_DILIGENCE_REQUEST.are_you_sure_you_want_to_finish_due_diligence_request')}
                    onClose={closeModal}
                    onConfirm={onFinishDueDiligenceRequest}
                  />
                </> :
                null
            }
          </Container>
      }
    </PublicPageLayout>
  );
}
