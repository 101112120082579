import { useState, useEffect, } from 'react';
import _ from 'lodash';
import { useTranslation, } from 'react-i18next';
import { useDispatch, useSelector, } from 'react-redux';
import { useHistory, } from 'react-router-dom';

import Container from '@material-ui/core/Container';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import { Notification, } from 'rsuite';
import { listCompanyDueDiligenceTemplates, } from '../../services/api';

import AddIcon from '@material-ui/icons/Add';

import DueDiligenceRequestsTable from '../../components/DueDiligenceRequestsTable';
import DueDiligenceRequestForm from '../../components/DueDiligenceRequestForm';

import useModal from '../../hooks/useModal';

import {
  createDueDiligenceRequest,
  listCompanyDueDiligenceRequests,
  resendCompanyDueDiligenceRequestNotification,
} from '../../services/api';

import {
  selectProcessingAgents,
  selectProcessingAgentsMap,
  setProcessingAgentsModal,
} from '../../features/processingAgentsSlice';
import { selectCompany, } from '../../features/companySlice';


export default function DueDiligenceRequests() {
  const { t, } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const company = useSelector(selectCompany);
  const [loading, setLoading] = useState(true);
  const [showError, setShowError] = useState(false);
  const [formModalOpen, openFormModal, closeFormModal] = useModal();
  const [dueDiligenceRequests, setDueDiligenceRequests] = useState([]);
  const processingAgentsMap = useSelector(selectProcessingAgentsMap);
  const processingAgents = useSelector(selectProcessingAgents);
  const [templates, setTemplates] = useState([]);


  async function getTemplates() {
    try {
      const { data, } = await listCompanyDueDiligenceTemplates(company._id);
      setTemplates(data.templates);
    } catch (err) {
      Notification['error']({
        placement: 'bottomEnd',
        title: _.capitalize(t('ERRORS.DUE_DILIGENCE.failed_to_load_company_due_diligence_templates')),
        description: _.capitalize(t('ERRORS.try_again_later')),
      });
    }
  }

  useEffect(async () => {
    await getTemplates();
  },[]);


  function onView(dueDiligenceRequestId) {
    history.push(`/companies/${company._id}/due-diligence/${dueDiligenceRequestId}`);
  }

  async function onCreateRequest(data) {
    try {
      const response = await createDueDiligenceRequest(company._id, {
        templateId: data.template,
        processingAgents: data.processingAgents,
      });

      const newDueDiligenceRequests = response.data.requests || [];
      setDueDiligenceRequests([...newDueDiligenceRequests, ...dueDiligenceRequests]);

      Notification['success']({
        placement: 'bottomEnd',
        title: _.capitalize(t('PAGES.DUE_DILIGENCE.due_diligence_request_successfully_created')),
      });
      closeFormModal();
    } catch (err) {
      Notification['error']({
        placement: 'bottomEnd',
        title: _.capitalize(t('ERRORS.DUE_DILIGENCE.failed_to_create_due_diligence_request')),
        description: _.capitalize(t('ERRORS.try_again_later')),
      });
    }
  }

  async function onResend(dueDiligenceRequestId) {
    try {
      await resendCompanyDueDiligenceRequestNotification(company._id, dueDiligenceRequestId);
      Notification['success']({
        placement: 'bottomEnd',
        title: _.capitalize(t('PAGES.DUE_DILIGENCE.due_diligence_request_notification_successfully_resent')),
      });
    } catch {
      Notification['error']({
        placement: 'bottomEnd',
        title: _.capitalize(t('ERRORS.DUE_DILIGENCE.failed_to_resend_due_diligence_request_notification')),
        description: _.capitalize(t('ERRORS.try_again_later')),
      });
    }
  }

  function onCreateNewProcessingAgent() {
    dispatch(setProcessingAgentsModal(true));
  }

  async function initialize() {
    try {
      const { data, } = await listCompanyDueDiligenceRequests(company._id);
      setDueDiligenceRequests(data.requests);
      setShowError(false);
    } catch {
      Notification['error']({
        placement: 'bottomEnd',
        title: _.capitalize(t('ERRORS.DUE_DILIGENCE.failed_to_load_due_diligence_requests')),
        description: _.capitalize(t('ERRORS.try_again_later')),
      });
      setShowError(true);
    }

    setLoading(false);
  }

  useEffect(() => {
    initialize();
  }, []);


  return (
    <Grid container >
      <Grid
        item
        xs={12}
        container
        alignItems="center"
        justifyContent="flex-end"
        style={{
          marginBottom: '1rem',
        }}
      >
        <Button
          variant="contained"
          disableElevation
          color="primary"
          startIcon={<AddIcon />}
          onClick={openFormModal}
        >
          {_.capitalize(t('PAGES.DUE_DILIGENCE.send_due_diligence'))}
        </Button>
      </Grid>
      <Container
        maxWidth="xl"
        component={Paper}
      >
        <DueDiligenceRequestsTable
          loading={loading}
          showError={showError}
          data={dueDiligenceRequests}
          processingAgentsMap={processingAgentsMap}
          onView={onView}
          onResend={onResend}
        />
      </Container>

      <Dialog
        open={formModalOpen}
        onClose={closeFormModal}
        PaperProps={{
          style: {
            width: '650px',
            maxWidth: '90%',
          },
        }}
      >
        <DialogTitle>
          {_.capitalize(t('PAGES.DUE_DILIGENCE.send_due_diligence'))}
        </DialogTitle>

        <DialogContent>
          <DueDiligenceRequestForm
            onSubmit={onCreateRequest}
            processingAgents={processingAgents}
            templates={templates}
            onCreateNewProcessingAgent={onCreateNewProcessingAgent}
          />
        </DialogContent>
      </Dialog>
    </Grid>
  );
}
