import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import fileDownload from 'js-file-download';
import { useTranslation, } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';

import { DragDropContext, Droppable, Draggable, } from 'react-beautiful-dnd';
import {
  Container,
  Input,
  InputAdornment,
  Grid,
  Dialog,
  Link,
  Breadcrumbs,
} from '@material-ui/core'
import SearchIcon from '@material-ui/icons/Search'
import { Notification, } from 'rsuite'

import {
  getPerguntasByEmpresa,
  updatePerguntasOrdemByEmpresa,
  deleteAllQuestionsByCompany,
  importChecklistFromCSVFile,
  exportCompanyChecklist,
} from '../../services/api'

import 'bootstrap/dist/css/bootstrap.min.css'
import './styles.css'

import { getPerguntas } from '../../features/perguntasSlice'
import { selectCompany, } from '../../features/companySlice';
import { listCompanySectors, } from '../../features/companySectorsSlice';
import { listCompanyCategories } from '../../features/companyCategoriesSlice';

import EditPergunta from '../../components/EditPergunta'
import ActionButton from '../../components/EditPergunta/Components/ActionButton'
import CadPerguntas from '../Perguntas/Perguntas'
import Loading from '../../components/Global/Loading'
import { HeaderProgress, } from '../../components/HeaderProgress';
import SaveQuestionsAsTemplateFormDialog from '../../components/SaveQuestionsAsTemplateFormDialog';
import UploadChecklistDialog from '../../components/UploadChecklistDialog';

import useModal from '../../hooks/useModal';

import { errorCodes } from '../../errors/standard-error';
import PartialCustomMenuFixed from '../../components/CustomMenuFixed/PartialCustomMenuFixed';
import { setGlobalMenuFixed } from '../../features/fixedMenuPages';
import DeleteDialog from "../../components/DeleteDialog";


function EditMaturityChecklist() {
  const { t, } = useTranslation();
  const dispatch = useDispatch();
  const company = useSelector(selectCompany);
  const [saveAsTemplate, openSaveAsTemplate, closeSaveAsTemplate] = useModal();
  const [importChecklistDialog, openImportChecklistDialog, closeImportChecklistDialog] = useModal();
  const body = useSelector((state) => state.perguntas.value);
  const bottomRef = React.useRef();
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [search, setSearch] = useState('');
  const [openDeleteAllDialog, setOpenDeleteAllDialog] = useState(false);


  function handleCloseModalNewQuestion() {
    setOpen(false);
    getData();
  }

  const getData = async () => {
    setLoading(true);

    try {
      const { data, } = await getPerguntasByEmpresa();
      dispatch(getPerguntas(data));
    } catch {
      Notification['error']({
        placement: 'bottomEnd',
        title: 'Falha ao carregar tarefas!',
        description: _.capitalize(t('ERRORS.try_again_later')),
      });
    }

    setLoading(false);
  }

  useEffect(() => {
    getData();
    dispatch(listCompanySectors());
    dispatch(setGlobalMenuFixed(false))
    return () => {
      dispatch(setGlobalMenuFixed(true))
    }
  }, []);

  async function handleOnDragEnd(result) {
    if (!result.destination) return
    const items = Array.from(body)
    const [reorderedItem] = items.splice(
      result.source.index,
      1
    )
    items.splice(result.destination.index, 0, reorderedItem)
    dispatch(getPerguntas(items))
  }

  const handleOnChange = (event) => {
    setSearch(event.target.value)
  }

  const orderByOrdem = async () => {
    setLoading(true)
    await updatePerguntasOrdemByEmpresa().then(
      ({ data }) => {
        dispatch(getPerguntas(data))

        setLoading(false)
      }
    )
  }

  const deleteAllQuestions = async () => {
    try {
      await deleteAllQuestionsByCompany();
      setOpenDeleteAllDialog(false);
      Notification['success']({
        placement: 'bottomEnd',
        title: 'Todas as tarefas foram excluídas!',
      });

      getData();
    } catch {
      Notification['error']({
        placement: 'bottomEnd',
        title: 'Falha ao excluir todas as tarefas!',
        description: _.capitalize(t('ERRORS.try_again_later')),
      });
    }
  }

  async function importChecklistTemplate(file) {
    closeImportChecklistDialog();
    try {
      const scrollToBottom = (body || []).length > 0;
      await importChecklistFromCSVFile(company._id, file);
      Notification['success']({
        placement: 'bottomEnd',
        title: _.capitalize(t('PAGES.MATURITY_CHECKLIST.checklist_successfully_imported')),
      });
      await getData();
      dispatch(listCompanySectors());
      dispatch(listCompanyCategories());
      if (scrollToBottom && Boolean(bottomRef.current)) {
        bottomRef.current.scrollIntoView({
          behavior: 'smooth',
          block: 'end',
        });
      }
    } catch (err) {
      if ((undefined != err.response) && (undefined != err.response.data)) {
        const MESSAGE_MAP = {
          [errorCodes.INCOMPLETE_CHECKLIST_CSV_FILE_ERROR]: t('ERRORS.ERROR_CODES.INCOMPLETE_CHECKLIST_CSV_FILE_ERROR'),
          [errorCodes.EMPTY_FILE_ERROR]: t('ERRORS.ERROR_CODES.EMPTY_FILE_ERROR'),
        };

        const description = MESSAGE_MAP[err.response.data.code] ||
          _.capitalize(t('ERRORS.try_again_later'));
        Notification['error']({
          placement: 'bottomEnd',
          title: _.capitalize(t('ERRORS.MATURITY_CHECKLIST.failed_to_import_checklist_from_csv_file')),
          description,
        });
      } else {
        Notification['error']({
          placement: 'bottomEnd',
          title: _.capitalize(t('ERRORS.MATURITY_CHECKLIST.failed_to_import_checklist_from_csv_file')),
          description: _.capitalize(t('ERRORS.try_again_later')),
        });
      }
    }
  }

  async function onExportChecklist() {
    try {
      const response = await exportCompanyChecklist(company._id);
      fileDownload(response.data, `diagnóstico-${company.name}.csv`);
      Notification['success']({
        placement: 'bottomEnd',
        title: _.capitalize(t('PAGES.MATURITY_CHECKLIST.checklist_successfully_exported')),
      });
    } catch (err) {
      Notification['error']({
        placement: 'bottomEnd',
        title: _.capitalize(t('ERRORS.MATURITY_CHECKLIST.failed_to_export_checklist')),
        description: _.capitalize(t('ERRORS.try_again_later')),
      });
    }
  }

  const MENU_FIXED = Object.freeze({
    deletAll: {
      id: 'deletAll',
      title: 'Deletar todas as tarefas',
      icon: <></>,
      onClick: () => setOpenDeleteAllDialog(true),
    },
    import: {
      id: 'import',
      title: 'Importar tarefas',
      icon: <></>,
      onClick: () => openImportChecklistDialog()
    },
    export: {
      id: 'export',
      title: 'Exportar tarefa',
      icon: <></>,
      onClick: () => onExportChecklist()
    },
    saveTemplate: {
      id: 'saveTemplate',
      title: 'Salvar como template',
      icon: <></>,
      onClick: () => openSaveAsTemplate()
    },
    reOrder: {
      id: 'reOrder',
      title: 'Reordenar tarefas',
      icon: <></>,
      onClick: () => orderByOrdem()
    },
    newTask: {
      id: 'newTask',
      title: 'Nova tarefa',
      icon: <></>,
      onClick: () => setOpen(true)
    },
  });

  return (
    <>
      <Dialog
        open={open}
        onClose={handleCloseModalNewQuestion}
      >
        <CadPerguntas
          closeModal={handleCloseModalNewQuestion}
        />
      </Dialog>
      <br />
      {loading && <Loading value={loading} />}
      {!loading && (
        <Grid container spacing={3}>
          <div className="boxButtonsTitle">
            <Grid className="pesquisa" item xs={12} sm={3}>
              <Input
                disabled
                placeholder="Pesquisar por tarefa"
                value={search}
                onChange={handleOnChange}
                endAdornment={
                  <InputAdornment position="end">
                    <SearchIcon
                      style={{ cursor: 'pointer' }}
                    />
                  </InputAdornment>
                }
                aria-describedby="standard-weight-helper-text"
                inputProps={{
                  'aria-label': 'weight',
                }}
              />
            </Grid>
          </div>
          <Container maxWidth="xl" disableGutters>
            <DragDropContext onDragEnd={handleOnDragEnd}>
              <Droppable droppableId="body.id">
                {(provided) => (
                  <ul
                    style={{ padding: '1%' }}
                    className="body.id"
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                  >
                    <div>
                      {body.map((body, index) => {
                        return (
                          <Draggable
                            key={body.id}
                            draggableId={String(body.id)}
                            index={index}
                          >
                            {(provided) => (
                              <EditPergunta
                                key={String(body.id)}
                                data={body}
                                provided={provided}
                                index={index}
                                getData={getData}
                              />
                            )}
                          </Draggable>
                        )
                      })}
                      {provided.placeholder}
                    </div>
                  </ul>
                )}
              </Droppable>
            </DragDropContext>

            <br />
          </Container>
        </Grid>
      )}
      <PartialCustomMenuFixed menuFixed={MENU_FIXED} />

      {/* <ActionButton
        newQuestion={() => {
          setOpen(true)
        }}
        order={orderByOrdem}
        deleteAll={deleteAllQuestions}
        saveAsTemplate={openSaveAsTemplate}
        onImportChecklist={openImportChecklistDialog}
        onExportChecklist={onExportChecklist}
      /> */}

      <SaveQuestionsAsTemplateFormDialog
        open={saveAsTemplate}
        onClose={closeSaveAsTemplate}
      />

      <UploadChecklistDialog
        open={importChecklistDialog}
        onClose={closeImportChecklistDialog}
        onChange={importChecklistTemplate}
      />
      <DeleteDialog
        open={openDeleteAllDialog}
        confirmationMessage="Tem certeza que deseja deletar todas as Tarefas?"
        onClose={() => setOpenDeleteAllDialog(false)}
        onConfirm={deleteAllQuestions}
      />
      <div ref={bottomRef} />
    </>
  );
}

export default EditMaturityChecklist;
