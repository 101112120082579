import React, { useEffect, useState, } from 'react';
import { useSelector, } from 'react-redux';

import {
  Paper,
  Container,
  Grid,
  Select,
  FormControl,
  InputLabel,
  MenuItem,
  CircularProgress,
} from '@material-ui/core';
import { Notification, Icon, Input, InputGroup, } from 'rsuite';

import {
  searchReunions,
  getItensForReunionsByDate,
  listCompanyMeetings,
} from '../../services/api';

import './styles.css';
import styles from './styles.module.scss';

import Reunion from '../../components/Reunions';
import RegisterMeeting from '../../components/RegisterMeeting/RegisterMeeting';

import { selectCompany, } from '../../features/companySlice';


export default function CommitteeMeetings() {
  const company = useSelector(selectCompany);
  const searchFieldChangedRef = React.useRef(false);
  const [reunion, setReunion] = useState([]);
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState('');


  function onSearchChange(event) {
    searchFieldChangedRef.current = true;
    setSearch(event.target.value);
  }

  function handleSearch() {
    setLoading(true);
    searchReunions(search)
      .then(({ data, status }) => {
        setLoading(false);
        if (status === 200) {
          setReunion(data);
        } else if (status === 204) {
          setReunion([]);
          Notification['warning']({
            title: 'Ops! ',
            placement: 'bottomEnd',
            description:
              'Nenhum um resultado encontraddo',
          });
        }
      })
      .catch(() => {
        setLoading(false);
      });
  }

  useEffect(() => {
    if (searchFieldChangedRef.current) {
      const timer = setTimeout(handleSearch, 500);
      return () => clearTimeout(timer);
    }
  }, [search, searchFieldChangedRef]);

  useEffect(() => {
    getReunions();
  }, []);

  const getReunionsByDate = async () => {
    setLoading(true)

    await getItensForReunionsByDate()
      .then(({ data }) => {
        setReunion(data)
        setLoading(false)
      })
      .catch(() => {
        setLoading(false)
      })
  }

  function getReunions() {
    setLoading(true);
    listCompanyMeetings(company._id)
      .then((response) => {
        const { data } = response;
        setReunion(data);
        setLoading(false);
      })
      .catch(() => {
        //TODO: handle error
        setLoading(false);
      });
  }

  const style = {
    width: 210,
    height: 35,
    borderRadius: 29,
    borderColor: '#95ADC3',
    padding: '3px',
  };


  return (
    <Grid
      container
      spacing={3}
    >
      <Grid
        item
        xs={12}
        md={6}
        lg={5}
      >
        <RegisterMeeting onNew={getReunions} />
      </Grid>

      <Grid
        item
        xs={12}
        md={6}
        lg={7}
      >
        <Container component={Paper}>
          <div className={styles.HeaderReunionsCards}>
            <div className={styles.titleHeader}>
              <h5>Reuniões de adequação</h5>
            </div>
            <div className={styles.boxSearchHeader}>
              <InputGroup
                inside
                style={style}
                onChange={onSearchChange}
              >
                <Input value={search} />
                <InputGroup.Button loading={loading}>
                  <Icon icon="search" />
                </InputGroup.Button>
              </InputGroup>

              <div className={styles.boxSelect}>
                <FormControl
                  className={styles.InputSelect}
                  disabled={loading}
                >
                  <InputLabel id="demo-simple-select-autowidth-label">
                    Filtrar por
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-autowidth-label"
                    id="demo-simple-select-autowidth"
                    value={''}
                    label="Mais recentes"
                  >
                    <MenuItem value="">
                      <em>Limpar</em>
                    </MenuItem>
                    <MenuItem
                      value={10}
                      onClick={getReunions}
                    >
                      Mais recentes
                    </MenuItem>
                    <MenuItem
                      value={20}
                      onClick={getReunionsByDate}
                    >
                      Mais antigas
                    </MenuItem>
                  </Select>
                </FormControl>
              </div>
              <button
                onClick={getReunions}
                disabled={loading}
                className={styles.BTN_Atualizar}
              >
                {loading ? (
                  <CircularProgress
                    size={16}
                    sx={{
                      position: 'absolute',
                      top: '50%',
                      left: '50%',
                      marginTop: '-12px',
                      marginLeft: '-12px',
                    }}
                    color="#fff"
                  />
                ) : (
                  <Icon icon="reload" />
                )}
                Atualizar
              </button>
            </div>
          </div>
        </Container>

        <Container
          className={styles.ContainersOverflowAbas}
          component={Paper}
        >
          <div className={styles.OverflowCards}>
            {
              !loading && reunion.map((reunion, index) => {
                return (
                  <Reunion
                    data={reunion}
                    key={reunion.id}
                    index={index}
                    updateData={getReunions}
                  />
                )
              }).reverse()
            }
          </div>
          <br />
        </Container>
      </Grid>
    </Grid>
  );
}
