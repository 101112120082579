import { useState } from 'react';
import { useTranslation, } from 'react-i18next';
import { useSelector, useDispatch, } from 'react-redux';

import { Grid, Typography, Dialog } from '@material-ui/core'
import { Button, Form } from 'react-bootstrap'
import { Notification, } from 'rsuite';

import Label from '../Global/Label'
import Container from '../Global/ContainerWithPaper'
import CircularProgressWithLabel from '../Global/CircularProgressWithLabel'
import SearshComponent from '../Global/LupaSearch'
import FilesHandler from '../FilesComponent'
import ShareLinkModal from './ShareDocumentModal';

import { setDocs } from '../../features/docsSlice'
import { getDocs, postDocs } from '../../services/api'

import useModal from '../../hooks/useModal';

import { errorCodes } from '../../errors/standard-error';

import './MonitoraStyles.css'
import validateFile from '../utils/validate-file';


/**
 * Component used to show company documents
 *
 * @returns Rendered component
 */
export default function Docs() {
  const { t, } = useTranslation();
  const dispatch = useDispatch();
  const body = useSelector((state) => state.docs.value)
  const [shareModalOpen, openShareModal, closeShareModal] = useModal(selectDocument, () => selectDocument());
  const [file, setFile] = useState('');
  const [open, setOpen] = useState(false);
  const [progress, setProgress] = useState(0);
  const [clickSearsh, setClickSearsh] = useState(false);
  const [selectedDocument, setSelectedDocument] = useState(undefined);


  function selectDocument(documentId) {
    setSelectedDocument(documentId);
  }

  /**
   * Get docs and update the store
   */
  async function updateDocs() {
    try {
      const { data, } = await getDocs();
      dispatch(setDocs(data));
    } catch {
      Notification['error']({
        placement: 'bottomEnd',
        title: 'Falha ao carregar documentos!',
      });
    }
  }

  /**
   * Send user docs to be stored at the cloud
   */
  async function handleSendDoc() {
    const form = new FormData();
    form.append('doc', file);

    try {
      setOpen(true);

      await postDocs(form, {
        onUploadProgress: (progressEvent) => {
          setOpen(true);
          const { loaded, total } = progressEvent;
          const percent = Math.floor((loaded * 100) / total);
          setProgress(percent);
        },
      });

      updateDocs();

      Notification['success']({
        placement: 'bottomEnd',
        title: t('PAGES.DOCUMENT_MANAGEMENT.document_successfully_sent'),
      });
    } catch (err) {
      if ((undefined != err.response) && (undefined != err.response.data) && (
        errorCodes.INVALID_FILE_ERROR == err.response.data.code)) {
        Notification['error']({
          placement: 'bottomEnd',
          title: t('ERRORS.ERROR_CODES.INVALID_FILE_ERROR'),
          description: t('PAGES.DOCUMENT_MANAGEMENT.send_valid_document'),
      });
      } else {
        Notification['error']({
          placement: 'bottomEnd',
          title: t('ERRORS.DOCUMENT_MANAGEMENT.failed_to_send_document'),
          description: t('ERRORS.try_again_later'),
        });
      }
    }

    setOpen(false);
    setProgress(0);
  }

  /**
   * Handle click in the close button in the upload dialog
   */
  function onRequestClose() {
    setOpen(false);
  }

  const onSelectedDocumentChanged = (e) => {
    const file = e.target.files[0];

    if (validateFile(file)) {
      setFile(file);
      setOpen(true);
    }
  }


  return (
    <Grid
      item
      xs={12}
      style={{
        marginTop: 20,
      }}
    >
      <Label
        description="Gestão de documentos"
        fontSize={16}
      />

      <Container>
        <Grid
          container
          alignItems="center"
        >
          <Grid
            container
            alignItems="center"
            className="ContainerHeader"
            item
            xs={12}
          >
            <Typography>
              <strong
                style={{
                  fontSize: 18,
                  color: '#3B66FF',
                }}
              >
                Arquivos
              </strong>
            </Typography>
            <div className={clickSearsh ? 'contentHeaderRight' : 'contentHeaderRightUnClick'}>
              <div
                onClick={() => {
                  setClickSearsh(true)
                }}
              >
                <SearshComponent docsFilter={body} />
              </div>

              <Form.Label htmlFor="contained-button-file">
                <Button
                  className="BTN-SetDoc"
                  variant="primary"
                  as="span"
                >
                  Adicionar arquivo
                </Button>
              </Form.Label>
            </div>
          </Grid>

          <Grid
            item
            xs={3}
          >
            <input
              id="contained-button-file"
              type="file"
              style={{ display: 'none' }}
              onChange={onSelectedDocumentChanged}
              onClick={(ev) => {
                //! Ensures the current value of the input will be null so the onChange event is
                //! triggered
                ev.target.value = null;
              }}
            />
            <br />
          </Grid>
        </Grid>

        <Form>
          <Dialog
            open={open}
            onClose={onRequestClose}
          >
            {progress > 0 ?
              <div className="modalArchiveFeedBack">
                <Typography variant="body1">
                  Enviando arquivo...
                </Typography>
                <br />
                <CircularProgressWithLabel value={progress} />
              </div> :
              <div className="modalArchiveFeedBack">
                <Typography
                  variant="body1"
                  style={{
                    marginBottom: 5,
                  }}
                >
                  { `Arquivo Selecionado: ${file.name}` }
                </Typography>
                <button onClick={handleSendDoc}>
                  Enviar
                </button>
              </div>
            }
          </Dialog>
          <Grid
            container
            item
            className="ContainerArchives"
            xs={12}
            justifyContent="start"
          >
            <div className="boxOverflow">
              {body.filter((docs) => !docs.workshop).map((docs) => {
                return (
                  <FilesHandler
                    key={docs.id}
                    data={docs}
                    onShareClick={openShareModal}
                  />
                )
              })}
            </div>
          </Grid>
          <br />
        </Form>
        <br />
      </Container>

      <ShareLinkModal
        open={shareModalOpen}
        onClose={closeShareModal}
        documentId={selectedDocument}
      />
    </Grid>
  );
}
