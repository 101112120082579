import React from 'react';
import _ from 'lodash';
import { useTranslation, } from 'react-i18next';

import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';

import { FaPaperclip, } from 'react-icons/fa';
import FileUploaderImg from '../../assets/Vector.svg';

import Dialog from '../Dialog';
import UploadFile from '../UploadFile';
import DocumentViewer from '../DocumentViewer';

import useModal from '../../hooks/useModal';

import { getDownloadEvidenceEndpoint, } from '../../utils/api-endpoints';


export default function EvidenceButton({
  evidenceId,
  onAttach = () => {},
  onDownload = () => {},
}) {
  const { t, } = useTranslation();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [uploadModalOpen, openUploadModal, closeUploadModal] = useModal();
  const [viewModalOpen, openViewModal, closeViewModal] = useModal();
  const disable = React.useMemo(() => undefined == evidenceId, [evidenceId]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  function _onAttach(file) {
    closeUploadModal();
    handleClose();
    onAttach(file);
  }

  function _onDownload() {
    handleClose();
    onDownload();
  }


  return (
    <div>
      <Tooltip title={_.capitalize(t('evidence', { count: 1, }))}>
        <IconButton
          aria-haspopup="true"
          aria-controls="evidence-management-button"
          onClick={handleClick}
        >
          <FaPaperclip />
        </IconButton>
      </Tooltip>

      <Menu
        keepMounted
        id="evidence-management-button"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        disableScrollLock
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
      >
        <MenuItem onClick={openUploadModal}>
          { _.capitalize(t('PAGES.EVIDENCE_MANAGEMENT.attach')) }
        </MenuItem>
        <MenuItem
          onClick={openViewModal}
          disabled={disable}
        >
          { _.capitalize(t('PAGES.EVIDENCE_MANAGEMENT.view')) }
        </MenuItem>
        <MenuItem
          onClick={_onDownload}
          disabled={disable}
        >
          { _.capitalize(t('PAGES.EVIDENCE_MANAGEMENT.download')) }
        </MenuItem>
      </Menu>

      <Dialog
        open={uploadModalOpen}
        onClose={closeUploadModal}
        title={_.capitalize(t('PAGES.EVIDENCE_MANAGEMENT.attach_evidence'))}
      >
        <UploadFile
          onChange={_onAttach}
          title={_.capitalize(t('PAGES.EVIDENCE_MANAGEMENT.select_evidence'))}
          image={(
            <img
              src={FileUploaderImg}
              style={{
                marginBottom: '1rem',
              }}
            />
          )}
        />
      </Dialog>

      <Dialog
        open={viewModalOpen}
        onClose={closeViewModal}
        maxWidth="xl"
        PaperProps={{
          style: {
            width: '800px',
            maxWidth: '90%',
          },
        }}
      >
        <DocumentViewer
          fileLink={getDownloadEvidenceEndpoint(evidenceId)}
          fileType="application/pdf"
        />
      </Dialog>
    </div>
  );
}
