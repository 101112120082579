import { configureStore, combineReducers, } from '@reduxjs/toolkit';
import counterReducer from '../features/counterSlice';
import docsReducer from '../features/docsSlice';
import perguntasReducer from '../features/perguntasSlice';
import reunionsReducer from '../features/reunionsSlice';
import matrizReducer from '../features/matrizDeRisco';
import mapeamentoCadastroReducer from '../features/mapeamentoCadastro';
import mapeamentoCadastroAbaReducer from '../features/mapeamentoCadastroAba';
import mapeamentoRespostaReducer from '../features/mapeamentoRespostas';
import mensagensSolicitacaoReducer from '../features/mensagensSolicitacao';
import classificacaoMapaDadosReducer from '../features/classificacaoMapaDados';
import ResponderPerguntasReducer from '../features/responderPerguntas';
import mapeamentoRelatorioReducer from '../features/mapeamentoRelatorio';
import customMappingReportReducer from '../features/customMappingReportSlice';
import groupsReducer from '../features/groupsSlice';
import courses from '../features/courseSlice';
import collaborators from '../features/collaboratorsSlice';
import companyReducer from '../features/companySlice';
import processingAgents from '../features/processingAgentsSlice';
import impactReports from '../features/impactReportsSlice';
import companySectors from '../features/companySectorsSlice';
import questionsTemplates from '../features/questionsTemplatesSlice';
import companyCategories from '../features/companyCategoriesSlice';
import maturityChecklistShareLinks from '../features/maturityChecklistShareLinksSlice';
import users from '../features/usersSlice';
import dataMappingTemplates from '../features/dataMappingTemplatesSlice';
import gestaoDeCookies from '../features/gestaoDeCookies';
import dashboard from '../features/dashboard';
import planAction from '../features/planAction';
import fixedMenuPages from '../features/fixedMenuPages';

const combinedReducer = combineReducers({
  counter: counterReducer,
  docs: docsReducer,
  perguntas: perguntasReducer,
  reunions: reunionsReducer,
  matriz: matrizReducer,
  mapeamentoCadastro: mapeamentoCadastroReducer,
  mapeamentoCadastroAba: mapeamentoCadastroAbaReducer,
  mensagensSolicitacao: mensagensSolicitacaoReducer,
  classificacaoMapeamento: classificacaoMapaDadosReducer,
  ResponderPerguntas: ResponderPerguntasReducer,
  mapeamentoResposta: mapeamentoRespostaReducer,
  mapeamentoRelatorio: mapeamentoRelatorioReducer,
  customMappingReport: customMappingReportReducer,
  groups: groupsReducer,
  courses,
  collaborators,
  company: companyReducer,
  processingAgents,
  impactReports,
  companySectors,
  questionsTemplates,
  companyCategories,
  maturityChecklistShareLinks,
  users,
  dataMappingTemplates,
  //manage routes
  gestaoDeCookies,
  dashboard,
  planAction,
  fixedMenuPages
});

const rootReducer = (state, action) => {
  if (action.type === 'users/logout') {
    state = undefined;
  }
  return combinedReducer(state, action);
};

export default configureStore({
  reducer: rootReducer,
});
