import { useEffect, useState, memo, } from 'react';
import { useDispatch, useSelector, } from 'react-redux';
import { useTranslation, } from 'react-i18next';
import _ from 'lodash';
import {
  Typography,
  makeStyles,
  Grid,
  TableCell,
  TableRow,
  Dialog,
  DialogContent,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import Table from '../../components/Table';
import { Notification, } from 'rsuite';
import clsx from 'clsx';

import CompanyCategoriesForm from '../CompanyCategoriesForm';
import {
  deleteCompanyCategory,
  selectCompanyCategories,
  selectFeatureStatus,
  listCompanyCategories,
  selectCompanyCategoryModalState,
  setCompanyCategoriesModal,
} from '../../features/companyCategoriesSlice';
import { CRUD_STATUSES, } from '../../utils/constants';


const useStyles = makeStyles({
  title: {
    color: '#1C265A',
    fontWeight: 'bolder',
    fontSize: '1.2rem',
    fontFamily: 'Inter',
    marginBottom: '2rem',
  },
  companyCategoryForm: {
    paddingRight: '1.0rem',
  },
  action: {
    '&:hover': {
      cursor: 'pointer',
    },
  },
  deleteButton: {
    marginLeft: '0.5rem',
    color: 'red',
  },
  actions: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
});


function CompanyCategoriesModal() {
  const { t, } = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();
  const companyCategories = useSelector(selectCompanyCategories);
  const status = useSelector(selectFeatureStatus);
  const companyCategoriesModal = useSelector(selectCompanyCategoryModalState);

  const [currCompanyCategory, setCurrCompanyCategory] = useState({});


  /**
   * Handle user request to delete processing agent
   *
   * @param {number} companyCategoryId - processing agent ID
   */
  async function onDeleteCompanyCategory(companyCategoryId) {
    try {
      const result = await dispatch(deleteCompanyCategory(companyCategoryId)).unwrap();

      if (null != result) {
        Notification['success']({
          placement: 'bottomEnd',
          title: _.capitalize(t('PAGES.COMPANY_CATEGORIES.company_category_successfully_deleted')),
        });
      }
    } catch {
      Notification['error']({
        placement: 'bottomEnd',
        title: _.capitalize(t('ERRORS.COMPANY_CATEGORIES.failed_to_delete_company_category')),
        description: _.capitalize(t('ERRORS.try_again_later')),
      });
    }
  }

  /**
   * Handle click in button to update company category
   *
   * @param {object} companyCategory - company category data
   */
  function onEditCompanyCategory(companyCategory) {
    setCurrCompanyCategory(companyCategory);
  }

  /**
   * Handle update in company category
   */
  function onUpdateFinished() {
    setCurrCompanyCategory({});
  }

  useEffect(() => {
    if (companyCategoriesModal) {
      dispatch(listCompanyCategories());
    }
  }, [companyCategoriesModal]);

  function onClose() {
    dispatch(setCompanyCategoriesModal(false));
  }


  return (
    <Dialog
      open={companyCategoriesModal}
      onClose={onClose}
      maxWidth="lg"
    >
      <DialogContent>
        <Grid
          container
          justifyContent="space-around"
        >
          <Grid
            item
            md={5}
            xs={12}
            className={classes.companyCategoryForm}
          >
            <Typography
              variant="h6"
              className={classes.title}
            >
              { _.capitalize(t('PAGES.COMPANY_CATEGORIES.add_new_category')) }
            </Typography>
            <CompanyCategoriesForm
              data={currCompanyCategory}
              onUpdateFinished={onUpdateFinished}
            />
          </Grid>

          <Grid
            item
            md={7}
            xs={12}
          >
            <Typography
              variant="h6"
              className={classes.title}
            >
              { _.capitalize(t('PAGES.COMPANY_CATEGORIES.all_categories')) }
            </Typography>
            <Table
              headers={[
                {
                  label: _.capitalize(t('name')),
                  align: 'center',
                },
                {
                  label: '',
                  align: 'right',
                },
              ]}
              data={companyCategories}
              loading={(status == CRUD_STATUSES.IDLE) || (status == CRUD_STATUSES.LOADING)}
              disablePagination
              errorMessage={_.capitalize(t('ERRORS.COMPANY_CATEGORIES.failed_to_load_company_categories'))}
              noDataMessage={_.capitalize(t('PAGES.COMPANY_CATEGORIES.no_categories_found'))}
              maxHeight={300}
            >
              {
                (companyCategory, props) => {
                  return (
                    <TableRow {...props}>
                      <TableCell align="center">
                        { companyCategory.name }
                      </TableCell>
                      <TableCell
                        align="right"
                        size="small"
                      >
                        <div
                          className={classes.actions}
                        >
                          <EditIcon
                            fontSize="small"
                            className={classes.action}
                            onClick={() => onEditCompanyCategory(companyCategory)}
                          />

                          <DeleteIcon
                            fontSize="small"
                            className={clsx(classes.action, classes.deleteButton)}
                            onClick={() => onDeleteCompanyCategory(companyCategory.id)}
                          />
                        </div>
                      </TableCell>
                    </TableRow>
                  );
                }
              }
            </Table>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
}

export default memo(CompanyCategoriesModal);
