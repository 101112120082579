import React, { useEffect, useState, } from 'react';
import { Typography, Grid, Chip, makeStyles, IconButton, } from '@material-ui/core';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import { Notification, } from 'rsuite';
import moment from 'moment';
import clsx from 'clsx';
import { getCompanyObservations, getUsers, } from '../../services/api';
import CreateObservationForm from './CreateObservationForm';


const useStyles = makeStyles(() => ({
  addObsButtonContainer: {
    marginBottom: '0.5rem',
  },
  addObsButton: {
    margin: 0,
    padding: 0,
  },
  fieldContainer: {
    marginBottom: '0.9rem',
  },
  field: {
    color: '#1C265A',
  },
  observationField: {
    backgroundColor: '#F2F4F8',
    padding: '0.3rem',
    borderRadius: 10,
    flexGrow: 1,
  },
  actionsContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  loadMore: {
    backgroundColor: '#2196f3',
    color: '#FFFFFF',
    '&:focus': {
      backgroundColor: '#2196f3',
      color: '#FFFFFF',
    },
    '&:hover': {
      backgroundColor: '#2196f3',
      color: '#FFFFFF',
    },
  },
  title: {
    fontWeight: 'bold',
  },
  timestamp: {
    padding: 0,
    margin: '0px 1.2rem',
  },
}));


/**
 * Show company observations
 *
 * @param {string} companyId - company ID
 *
 * @returns rendered component
 */
export default function CompanyObservations({ companyId, }) {
  const classes = useStyles();

  const [observations, setObservations] = useState([]);
  const [users, setUsers] = useState([]);
  const [loaded, setLoaded] = useState(1);
  const [obsFormVisible, setObsFormVisible] = useState(false);

  /**
   * Initialize component
   */
  async function initialize() {
    try {
      const { data, } = await getCompanyObservations(companyId);
      if (undefined != data) {
        setObservations(data.observations);
      }

      const { data: users, } = await getUsers();
      if (undefined != users) {
        setUsers(users);
      }
    } catch {
      Notification['error']({
        placement: 'bottomEnd',
        title: 'Falha ao carregar observações, tente novamente dentro de alguns instantes',
      });
    }
  }

  /**
   * Load 5 more observations on the screen
   */
  function loadMoreObservations() {
    setLoaded(Math.min(observations.length, loaded + 3));
  }

  /**
   * Toggle observation form visibility
   */
  function toggleObsFormVisibility() {
    setObsFormVisible(!obsFormVisible);
  }

  /**
   * Hide observation form
   */
  function hideObsForm() {
    setObsFormVisible(false);
  }

  /**
   * Callback called when new observations are created
   *
   * @param {object} observation - observation data
   */
  function onNewObservation(observation) {
    hideObsForm();
    setObservations([
      observation,
      ...observations,
    ]);
    setLoaded(loaded + 1);
  }

  /**
   * Use effect to trigger component initialization
   */
  useEffect(() => {
    initialize();
  }, []);


  return (
    <Grid
      container
      direction="column"
      justifyContent="space-between"
      alignItems="center"
    >
      <Grid
        item
        xs={12}
        className={classes.addObsButtonContainer}
      >
        <Grid
          item
          container
          direction="row"
          justifyContent="flex-end"
          alignItems="center"
        >
          <IconButton
            aria-label="show-add-obs-form"
            onClick={toggleObsFormVisibility}
            className={classes.addObsButton}
          >
            <AddCircleIcon />
          </IconButton>
        </Grid>
      </Grid>
      {
        (observations.length > 0) ?
        observations.slice(0, loaded).map((observation, idx) => {
          const user = users.find(({ _id, }) => observation.author_id === _id);

          return (
            <div
              key={observation.id}
            >
              <Grid
                item
                xs={12}
              >
                <Grid
                  item
                  container
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                  className={classes.fieldContainer}
                >
                  <Typography
                    variant="body2"
                    className={classes.field}
                  >
                    { undefined != user ? user.name : '' }
                  </Typography>

                  <Typography
                    variant="body2"
                    className={clsx(classes.field, classes.timestamp)}
                  >
                    { moment(observation.created_at).format('HH:mm - DD/MM/YY') }
                  </Typography>

                  <Typography
                    variant="body2"
                    className={clsx(classes.field, classes.observationField)}
                  >
                    { observation.observation }
                  </Typography>

                </Grid>
              </Grid>
            </div>
          );
        }) :
        <Grid
          item
          xs={12}
        >
          <Typography
            variant="subtitle1"
            display="block"
            align="center"
          >
            Nenhuma observação encontrada
          </Typography>
        </Grid>
      }

      <Grid
        item
        xs={12}
      >
        {
          (observations.length > 0) && (
            <Grid
              item
              container
              justifyContent="flex-end"
              alignItems="center"
              direction="row"
            >
              <Grid
                item
                xs={2}
              >
                <Chip
                  size="small"
                  label="carregar mais"
                  clickable
                  onClick={loadMoreObservations}
                  disabled={observations.length <= loaded}
                  className={classes.loadMore}
                />
              </Grid>
            </Grid>
          )
        }

        {
          obsFormVisible ?
          <>
            <Typography
              className={classes.title}
              variant="subtitle1"
              display="block"
            >
              Nova observação
            </Typography>
            <CreateObservationForm
              onNewObservation={onNewObservation}
              companyId={companyId}
            />
          </> :
          null
        }
      </Grid>
    </Grid>
  );
}
