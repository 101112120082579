import React, { useState, memo, useEffect, } from 'react';
import { useDispatch, } from 'react-redux';
import _ from 'lodash';
import {
  Container,
  TextField,
  makeStyles,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  Chip,
  FormHelperText,
  Grid,
} from '@material-ui/core';
import { useFormik, } from 'formik';
import * as Yup from 'yup';
import { Notification, Button, } from 'rsuite';

import {
  createProcessingAgent,
  updateProcessingAgent,
} from '../../features/processingAgentsSlice';
import { PROCESSING_AGENTS_TYPES, PROCESSING_AGENTS_TYPES_ARRAY, } from '../../utils/constants';


const INITIAL_VALUES = Object.freeze({
  name: '',
  email: '',
  types: [],
  dpoName: '',
  phoneNumber: '',
  companyIdentification: '',
});

const MESSAGES = Object.freeze({
  UPDATE: {
    success: {
      title: 'Terceiro atualizado com sucesso!',
      description: '',
    },
    error: {
      title: 'Falha ao atualizar terceiro',
      description: 'Tente novamente dentro de alguns instantes',
    },
  },
  CREATE: {
    success: {
      title: 'Terceiro criado com sucesso!',
      description: '',
    },
    error: {
      title: 'Falha ao criar terceiro"',
      description: 'Tente novamente dentro de alguns instantes',
    },
  },
});

const useStyles = makeStyles({
  buttonContainer: {
    marginTop: '0.5rem',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: '0.1rem',
  },
  cancelBtn: {
    marginLeft: '1rem',
    borderColor: '#3B66FF',
    color: '#3B66FF !important',
    background: 'transparent !important',
    boxShadow: 'none',
    borderWidth: 2,
    border: '2px solid #3B66FF !important',
    '&:hover': {
      background: 'transparent !important',
      border: '2px solid #3B66FF !important',
    },
  },
});

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const InputProps = {
  disableUnderline: true,
};


/**
 * Form to create/edit new processing agents
 *
 * @param {object} data - processing agent data to edit
 * @param {function} onUpdateFinished - callback called when processing agent is updated
 *
 * @returns rendered component
 */
function ProcessingAgentForm({ data, onUpdateFinished, }) {
  const classes = useStyles();

  const dispatch = useDispatch();

  const formik = useFormik({
    initialValues: INITIAL_VALUES,
    onSubmit: submitProcessingAgent,
    validationSchema: Yup.object({
      name: Yup.string().required('Obrigatório'),
      email: Yup.string().email('Deve ser um e-mail válido').required('Obrigatório'),
      phoneNumber: Yup.string(),
      dpoName: Yup.string(),
      companyIdentification: Yup.string(),
      // types: Yup.array().of(Yup.string()).required('Obrigatório').min(1, 'Selecione pelo menos um tipo'),
    }),
  });

  const [loading, setLoading] = useState(false);


  /**
   * Save processing agent in API
   */
  async function submitProcessingAgent(values) {
    setLoading(true);

    const isUpdate = null != data.id;
    const msgs = isUpdate ? MESSAGES.UPDATE : MESSAGES.CREATE;

    const __data = {
      ...data,
      ...values,
    }

    try {
      await dispatch(isUpdate ? updateProcessingAgent(__data) :
        createProcessingAgent(__data)).unwrap();

      Notification['success']({
        placement: 'bottomEnd',
        title: msgs.success.title,
      });

      formik.resetForm({
        values: INITIAL_VALUES,
      });

      if (isUpdate) {
        onUpdateFinished();
      }
    } catch {
      Notification['error']({
        placement: 'bottomEnd',
        title: msgs.error.title,
        description: msgs.error.description,
      });
    } finally {
      setLoading(false);
    }
  }

  function onCancel() {
    formik.resetForm({
      values: INITIAL_VALUES,
    });

    onUpdateFinished();
  }

  useEffect(() => {
    if (null != data.id) {
      formik.setValues(data);
    }
  }, [data]);


  return (
    <Container
      disableGutters
      fullWidth
      maxWidth="xl"
    >
      <TextField
        margin="dense"
        label="Nome"
        fullWidth
        id="name"
        variant="filled"
        InputProps={InputProps}
        type="text"
        error={formik.errors.name && formik.touched.name}
        helperText={formik.touched.name && formik.errors.name}
        {...formik.getFieldProps('name')}
      />

      <TextField
        margin="dense"
        label="CNPJ"
        fullWidth
        id="companyIdentification"
        variant="filled"
        InputProps={InputProps}
        type="text"
        error={formik.errors.companyIdentification && formik.touched.companyIdentification}
        helperText={formik.touched.companyIdentification && formik.errors.companyIdentification}
        {...formik.getFieldProps('companyIdentification')}
      />

      <TextField
        margin="dense"
        label="Nome do DPO"
        fullWidth
        id="dpoName"
        variant="filled"
        InputProps={InputProps}
        type="text"
        error={formik.errors.dpoName && formik.touched.dpoName}
        helperText={formik.touched.dpoName && formik.errors.dpoName}
        {...formik.getFieldProps('dpoName')}
      />

      <TextField
        margin="dense"
        label="E-mail"
        fullWidth
        id="email"
        variant="filled"
        InputProps={InputProps}
        type="text"
        error={formik.errors.email && formik.touched.email}
        helperText={formik.touched.email && formik.errors.email}
        {...formik.getFieldProps('email')}
      />

      <TextField
        margin="dense"
        label="Telefone"
        fullWidth
        variant="filled"
        InputProps={InputProps}
        id="phoneNumber"
        type="text"
        error={formik.errors.phoneNumber && formik.touched.phoneNumber}
        helperText={formik.touched.phoneNumber && formik.errors.phoneNumber}
        {...formik.getFieldProps('phoneNumber')}
      />

      {/* <FormControl
        fullWidth
        variant="filled"
        margin="dense"
        error={formik.errors.types && formik.touched.types}
      >
        <InputLabel
          id="processing-agent-types-label"
        >
          Tipos
        </InputLabel>
        <Select
          labelId="processing-agent-types-label"
          id="processing-agent-types"
          multiple
          disableUnderline
          {...formik.getFieldProps('types')}
          renderValue={(selected) => (
            <div className={classes.chips}>
              {selected.map((value) => (
                <Chip
                  size="small"
                  color="primary"
                  key={value}
                  label={PROCESSING_AGENTS_TYPES[value].label}
                  className={classes.chip}
                />
              ))}
            </div>
          )}
          MenuProps={MenuProps}
        >
          {PROCESSING_AGENTS_TYPES_ARRAY.map((type, index) => (
            <MenuItem
              key={type.value}
              value={type.value}
            >
              {type.label}
            </MenuItem>
          ))}
        </Select>
        <FormHelperText>
          {formik.touched.types && formik.errors.types}
        </FormHelperText>
      </FormControl> */}

      <Grid
        className={classes.buttonContainer}
      >
        <Button
          className={classes.saveButton}
          appearance="primary"
          loading={loading}
          onClick={formik.handleSubmit}
        >
          Salvar
        </Button>

        {
          (null != data.id) ?
          <Button
            appearance="primary"
            className={classes.cancelBtn}
            onClick={onCancel}
            loading={loading}
          >
            Cancelar
          </Button> :
          null
        }
      </Grid>
    </Container>
  );
}

export default memo(ProcessingAgentForm);
