import React, { useEffect, useState, useRef, useMemo, } from 'react';
import { useSelector, } from 'react-redux';

import { Loader, } from 'rsuite';
import { IconButton, Snackbar, makeStyles, } from '@material-ui/core';

import CloseIcon from '@material-ui/icons/Close';

import {
  saveMappingFieldResponse,
  createMappingFieldOption,
  getDataMappingLinkCellResponse,
  getCompanyDataMappingCellResponse,
} from '../../../services/api';
import { selectCompany, } from '../../../features/companySlice';

import { hasCustomOptions, } from '../../../core/entities/data-mapping-field';


const useStyles = makeStyles({
  container: {
    marginBottom: '1.44px',
    height: '38px',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    border: (props) => `1px solid ${props.borderColor}`,
    borderRadius: '0.25rem',
    color: '#495057',
  },
});


/**
 * Base data mapping field
 *
 * @param {DataMappingFieldData} props.field - field data
 * @param {string} props.uid - data mapping row answer UID
 * @param {boolean} props.previewOnly - if the component should be rendered in preview mode
 * @param {function} props.render - function used to render custom field behavior
 * @param {string} props.borderColor - border color
 * @param {function | undefined} props.updateOptions - callback to update field options (for
 * applicable fields only)
 * @param {boolean} props.isPublic - if the component is being used in authenticated mode (false)
 * or data mapping link mode (true)
 * @param {string} props.token - data mapping link token, only needed if loading component in
 * public mode
 */
export default function BaseField({
  uid,
  field,
  token,
  isPublic = false,
  previewOnly = false,
  borderColor = '#CED4DA',
  render,
  updateOptions,
}) {
  const classes = useStyles({
    borderColor,
  });
  const fieldDidChange = useRef(false);
  const [resp, setResp] = useState('');
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(`Salvando resposta...`);
  const company = useSelector(selectCompany);
  const fieldHasCustomOptions = useMemo(() => {
    return hasCustomOptions(field);
  }, [field]);


  function handleClose(_, reason) {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  }

  function onChange(value) {
    fieldDidChange.current = true;
    setResp(value);
  }

  function onShowMessage(message) {
    setMessage(message);
    setOpen(true);
  }

  async function saveFieldResponse() {
    if (previewOnly) {
      return;
    }
    let value = resp;


    // TODO criar um componenete especifico para PURPOSE e enviar essa logica para o proprio componente
    if ((undefined != updateOptions) && (undefined != field.options) && fieldHasCustomOptions) {
      value = value.trim();

      const option = field.options.find(({ title, }) =>  title === value);
      if ((value.length > 0) && (undefined === option)) {
        try {
          const { data: newOption, } = await createMappingFieldOption(value, field.id, field.fk_etapa);
          updateOptions(newOption);
          value = String(newOption.id);
          onShowMessage('Opção cadastrada com sucesso');
        } catch {
          onShowMessage('Erro ao salvar opção');
        }
      }
    }

    try {
      await saveMappingFieldResponse({
        fieldId: field.id,
        etapa: field.fk_etapa,
        resp: value,
        uid,
      });
      onShowMessage(`${field.name} respondido`);
    } catch {
      onShowMessage('Erro ao salvar resposta');
    }
  }

  async function getResponse() {
    if (previewOnly) {
      return;
    }

    setLoading(true);

    try {
      const { data, } = await (isPublic ?
        getDataMappingLinkCellResponse(token, uid, field.id) :
        getCompanyDataMappingCellResponse(company._id, uid, field.id));
      if (data[0]) {
        setResp(data[0].resp);
      }
    } catch {
      //! Drop error
    }

    setLoading(false);
  }

  useEffect(() => {
    if (fieldDidChange.current) {
      const timeoutId = setTimeout(saveFieldResponse, 800);
      return () => clearTimeout(timeoutId);
    }
  }, [resp]);

  /**
   * Use effect to initialize component
   */
  useEffect(() => {
    getResponse();
  }, []);


  return (
    <div className={classes.container}>

      {
        loading ?
        <Loader vertical /> :
        render(resp, onChange)
      }

      <Snackbar
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        open={open}
        autoHideDuration={2000}
        onClose={handleClose}
        message={message}
        action={
          <React.Fragment>
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={handleClose}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          </React.Fragment>
        }
      />
    </div>
  );
}
