import { Provider, } from 'react-redux';
import TagManager from 'react-gtm-module';
import { Router, } from 'react-router-dom';

import Routes from './routes';
import history from './history';
import { AuthProvider } from './context/AuthContext';
import { ThemeProvider } from './context/ThemeAppContext';
import store from './redux/store';
import './i18n';

import './App.css'


if (process.env.REACT_APP_GTM_ID) {
  TagManager.initialize({
    gtmId: process.env.REACT_APP_GTM_ID,
  });
}


function App() {
  return (

    <Provider store={store}>
      <AuthProvider>
        <Router history={history}>
          <ThemeProvider>
            <Routes />
          </ThemeProvider>
        </Router>
      </AuthProvider>
    </Provider>
  );
}

export default App;
