import React from 'react';

import { Container, Grid, } from '@material-ui/core';
import { makeStyles, } from '@material-ui/core/styles';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';

import SignaturesWidgetHeader from './SignaturesWidgetHeader';
import SignerCard from './SignerCard';


const DEFAULT_SIGNER_DATA = {
  agentTitle: '',
  name: '',
  registrationId: '',
};

const useStyles = makeStyles({
  addButtonContainer: {
    '&:hover': {
      backgroundColor: '#EEE',
    },
    cursor: 'pointer',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '5px',
  },
});


export default function SignaturesWidget({ data, readOnly, onChange, }) {
  const classes = useStyles();
  const refs = React.useRef([]);


  function _onChangeModel(name, value) {
    onChange({
      ...data,
      model: {
        ...data.model,
        [name]: value,
      },
    });
  }

  function onChangeSigner(updatedSigner, idx) {
    const updatedSigners = [...data.model.signers];
    updatedSigners[idx] = updatedSigner;
    _onChangeModel('signers', updatedSigners);
  }

  function onAddSigner() {
    _onChangeModel('signers', [
      ...data.model.signers,
      {
        ...DEFAULT_SIGNER_DATA,
      },
    ]);
  }

  function onRemoveSigner(removedSignerIdx) {
    const updatedSigners = data.model.signers.filter((signer, idx) => {
      return idx != removedSignerIdx;
    });
    _onChangeModel('signers', updatedSigners);
  }

  /**
   * Keep heights of cards and its headers synced to keep them aligned
   */
  React.useLayoutEffect(() => {
    const reduceMaxHeight = (prev, ref) => {
      return (null != ref) ? Math.max(prev, ref.clientHeight) : prev;
    };

    const syncRefHeight = (items) => {
      const filteredItems = items.filter((ref) => null != ref);

      if (filteredItems.length > 1) {
        const maxContainerHeight = filteredItems.reduce(reduceMaxHeight, 0);

        filteredItems.forEach((ref) => {
          ref.style.minHeight = `${maxContainerHeight}px`;
        });
      }
    }

    syncRefHeight(refs.current);
    syncRefHeight(refs.current
      .map((ref) => {
        return null != ref ? ref.querySelector('.signerCardHeader') : null;
      }));
  });


  return (
    <>
      <SignaturesWidgetHeader />

      <Grid
        container
        direction="row"
        justifyContent="space-between"
        spacing={3}
      >
        {
          data.model.signers
            .map((signer, idx) => {
              return (
                <Grid
                  key={idx}
                  item
                  xs={12}
                  md={6}
                >
                  <SignerCard
                    signer={signer}
                    readOnly={readOnly}
                    ref={(el) => refs.current[idx] = el}
                    onChange={(updateSigner) => onChangeSigner(updateSigner, idx)}
                    onRemoveSigner={() => onRemoveSigner(idx)}
                  />
                </Grid>
              );
            })
        }

        {
          !readOnly ?
          <Grid
            item
            xs={12}
            md={6}
          >
            <Container
              maxWidth="xl"
              className={classes.addButtonContainer}
              onClick={() => onAddSigner()}
              ref={(el) => refs.current[(data.model.signers || []).length] = el}
              style={{
                minHeight: '80px',
              }}
            >
              <AddCircleOutlineIcon fontSize="large" />
            </Container>
          </Grid> :
          null
        }

      </Grid>
    </>
  );
}
