import React from "react";

import CookieConsentLogTable from "./CookieConsentLogTable";
import CookieClassification from "./CookieClassification";
import { Container, Typography, Divider, makeStyles } from '@material-ui/core';
import { useSelector, useDispatch, } from 'react-redux';

import { useTranslation } from "react-i18next";

export default function Report() {

  const { t, } = useTranslation();

  const { sites, indexSelectSite } = useSelector(state => state.gestaoDeCookies);

  const className = useStyles();

  return (
    <Container maxWidth={false} style={{ width: "100%" }}>

      {!sites.length &&
        <Typography
          variant="h5"
          display="block"
          align="center"
          className={className.noItems}
        >
          Você ainda não possui domínios registrados
        </Typography>
      }

      {!!sites.length &&
        <>
          <CookieClassification
            popupConfig={sites[indexSelectSite]}
          />
          <Divider />
          <Typography
            variant="h5"
            display="block"
            color="primary"
            style={{
              paddingTop: 10,
              paddingBottom: 5,
            }}
          >
            {_.capitalize(t('PAGES.COOKIE_MANAGEMENT.consent_logs'))}
          </Typography>
          <CookieConsentLogTable
            popupId={sites[indexSelectSite].id}
          />
        </>
      }
    </Container>
  );
}

const useStyles = makeStyles({
  noItems: {
    paddingTop: '4rem',
    paddingBottom: '4rem',
    color: '#80808080',
  }
});