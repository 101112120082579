import React from 'react';

import { Loader, } from 'rsuite';
import { makeStyles, Paper, Typography, } from '@material-ui/core';

import Coluna from './Coluna';


const useStyles = makeStyles({
  container: {
    marginRight: '2rem',
    marginBottom: '2rem',
    borderTop: '#54708C 4px solid',
    minWidth: 'auto',
  },
  boxContainer: {
    padding: '2rem',
  },
  title: {
    color: '#54708C',
    fontFamily: 'Inter',
    fontWeight: 600,
  },
  titleContainer: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '1rem',
  },
  innerContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
  },
});


const DataContainer = ({ phase, fields, mapaDados, page, rowsPerPage, }) => {
  const classes = useStyles();


  return (
    <div className={classes.container}>
      <Paper className={classes.boxContainer}>
        {
          !fields ?
          <Loader content="Carregando" /> :
          <>
            <div className={classes.titleContainer}>
              <Typography
                variant="h6"
                className={classes.title}
              >
                { phase.name }
              </Typography>
            </div>
            <div className={classes.innerContainer}>
              {
                fields
                  .map((field, idx) => {
                    return (
                      <Coluna
                        key={field.id}
                        mapaDados={mapaDados}
                        page={page}
                        loading={false}
                        rowsPerPage={rowsPerPage}
                        index={idx}
                        data={field}
                      />
                    );
                  })
              }
            </div>
          </>
        }
      </Paper>
    </div>
  );
}

export default DataContainer;
