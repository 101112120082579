import React from 'react';

import { Dialog as MUIDialog, DialogContent, DialogTitle, DialogActions, makeStyles, } from '@material-ui/core';


const useStyles = makeStyles({
  actions: {
    marginTop: '1rem',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  title: {
    color: 'white',
    backgroundColor: '#3B66FF',
  },
});


function Dialog({ open, onClose, title, children, maxWidth = "md", PaperProps, actions, ...props }) {
  const classes = useStyles();


  return (
    <MUIDialog
      open={open}
      onClose={onClose}
      maxWidth={maxWidth}
      PaperProps={undefined != PaperProps ? PaperProps : {
        style: {
          width: '500px',
          maxWidth: '90%',
        },
      }}
      {...props}
    >
      {
        undefined != title ?
        <DialogTitle className={classes.title}>
          { title }
        </DialogTitle> :
        null
      }

      <DialogContent>
        { children }
      </DialogContent>

      {
        undefined != actions ?
        <DialogActions>
          { actions }
        </DialogActions> :
        null
      }
    </MUIDialog>
  );
}

export default React.memo(Dialog);
