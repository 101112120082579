import React, { useState, useEffect, } from 'react';
import { useSelector, } from 'react-redux';
import _ from 'lodash';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  makeStyles,
  Typography,
  Container,
  Divider,
  Grid,
} from '@material-ui/core';
import { Notification, Button, } from 'rsuite';

import CourseLevelChip from './CourseLevelChip';
import Image from '../Image';
import CollaboratorsTable from './CollaboratorsTable';

import { selectCourseById } from '../../features/courseSlice';
import { selectCompany } from '../../features/companySlice';

import { getUser, shareCourse, } from '../../services/api';

import { pluralizeString, } from '../../utils';


const useStyles = makeStyles({
  header: {
    backgroundColor: '#3B66FF',
  },
  headerTitle: {
    color: 'white',
  },
  divider: {
    marginTop: '0.5rem',
    marginBottom: '0.5rem',
  },
});


function CourseField({ label, children }) {
  return (
    <Grid
      container
      justifyContent="flex-start"
    >
      <Grid
        item
        xs={12}
      >
        <Typography
          variant="subtitle1"
          display="block"
          style={{
            fontWeight: 500,
          }}
        >
          { label }
        </Typography>
      </Grid>

      <Grid
        item
        xs={12}
      >
        { children }
      </Grid>
    </Grid>
  );
}


export default function ShareCourseModal({ courseId, handleClose, open, }) {
  const classes = useStyles();
  const company = useSelector(selectCompany);
  const course = useSelector((state) => selectCourseById(state, courseId));

  const [sharing, setSharing] = useState(false);
  const [author, setAuthor] = useState({});
  const [selected, setSelected] = useState([]);


  /**
   * Share course with collaborators
   */
  async function __shareCourse() {
    setSharing(true);

    try {
      await shareCourse(company._id, courseId, selected);

      Notification['success']({
        placement: 'bottomEnd',
        title: 'Curso compartilhado com sucesso',
      });

      clearModal();
    } catch {
      Notification['error']({
        placement: 'bottomEnd',
        title: 'Falha ao compartilhar curso!',
        description: 'Tente novamente dentro de alguns instantes',
      });
    } finally {
      setSharing(false);
    }
  }

  function clearModal() {
    setSelected([]);
    handleClose();
  }

  /**
   * Fetch author info from the API
   */
  const getCourseAuthor = async () => {
    try {
      const { data, } = await getUser(course.author_id);
      setAuthor(data);
    } catch (err) {
      console.log(err);
    }
  }

  /**
   * Use effect to update author info
   */
  useEffect(() => {
    if ((undefined != course) && (course.author_id != author._id)) {
      getCourseAuthor();
    }
  }, [course]);


  return (
    <Dialog
      onClose={clearModal}
      open={open}
      scroll="body"
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle
        className={classes.header}
        disableTypography
      >
        <Typography
          variant="h5"
          display="block"
          className={classes.headerTitle}
        >
          Compartilhar curso
        </Typography>
      </DialogTitle>

      <DialogContent>
        {
          (undefined != course) ?
          <Container
            disableGutters
            maxWidth="xl"
          >
            <Typography
              variant="h6"
              display="block"
              gutterBottom
            >
              { course.title }
            </Typography>

            <Grid
              container
              spacing={2}
            >
              <Grid
                item
                container
                xs={12}
                md={6}
                direction="column"
              >
                <Image
                  src={course.thumbnail_url}
                  alt="Thumbnail do curso"
                  style={{
                    borderRadius: 5,
                  }}
                />
              </Grid>

              <Grid
                item
                container
                xs={12}
                md={6}
                direction="column"
                justifyContent="space-between"
              >
                <CourseField label="Professor(a)" >
                  <Typography variant="body1" >
                    { author.name }
                  </Typography>
                </CourseField>

                <CourseField label="Duração total em horas" >
                  <Typography variant="body1" >
                    { `${course.duration} ${pluralizeString(course.duration, 'hora', 'horas')}` }
                  </Typography>
                </CourseField>

                <CourseField label="Dificuldade do curso" >
                  <CourseLevelChip level={course.level} />
                </CourseField>

                <CourseField label="Quantidade de aulas" >
                  <Typography variant="body1" >
                    1 aula
                  </Typography>
                </CourseField>
              </Grid>

              <Grid
                item
                container
                xs={12}
                direction="column"
              >
                <CourseField label="Descrição" >
                  <Typography variant="body1" >
                    { course.description }
                  </Typography>
                </CourseField>
              </Grid>
            </Grid>

            <Divider className={classes.divider} />

            <Typography
              variant="h6"
              display="block"
              gutterBottom
            >
              Selecione para quem gostaria de enviar
            </Typography>

            <CollaboratorsTable
              selected={selected}
              setSelected={setSelected}
            />
          </Container> :
          null
        }

        <DialogActions>
          <Button
            appearance="ghost"
            loading={sharing}
            onClick={clearModal}
          >
            Cancelar
          </Button>

          <Button
            appearance="primary"
            loading={sharing}
            onClick={__shareCourse}
            disabled={selected.length === 0}
          >
            Enviar
          </Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
}
