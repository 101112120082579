import { useState, useEffect, } from 'react';
import { useHistory, } from 'react-router-dom';
import moment from 'moment';
import 'moment/locale/pt-br';
import clsx from 'clsx';

import { Tooltip, Whisper, Icon, Loader, } from 'rsuite';
import { Dialog, IconButton, makeStyles, } from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import ImportContactsIcon from '@material-ui/icons/ImportContacts';
import ModalEmpresas from '../Master/EmpresasConsultor';
import ModalConfigUser from '../Master/modalConfigUsers.js'

import EditCompanyModal from '../EditarEmpresa/EditarEmpresaModal';
import Notifications from '../../utils/Notification';
import CircularProgressWithLabel from '../CircularProgressWithLabel';
import CompanyObservations from './CompanyObservations';

import api, { getPlano, getCompanyContactRequests, } from '../../services/api';

import './styles.css';


const useStyles = makeStyles(() => ({
  observations: {
    '&:focus': {
      backgroundColor: '#FFFFFF',
    },
    '&:hover': {
      backgroundColor: '#FFFFFF',
    },
  },
  enabledObservations: {
    color: '#2196f3',
  },
  disabledObservations: {
    color: '#808080',
  },
  editCompany: {
    color: '#1c265a',
  },
}));


/**
 * Component to show one row for a given company
 *
 * @param {object} data - company data
 * @param {function} onDeleted - callback used when company is deleted
 *
 * @returns rendered component
 */
const Companies = ({ data, onDeleted, }) => {
  const classes = useStyles();
  const history = useHistory();
  const idEmpresa = data._id;
  const [now, setNow] = useState(0);
  const [name, setName] = useState(data.name);
  const [peso, setPeso] = useState([]);
  const [results, setResults] = useState([]);
  const [responseNot, setResponseNot] = useState([]);
  const [solicitacao, setSolicitacao] = useState([]);
  const [reunions, setReunios] = useState([]);
  const [count, setCount] = useState(0);
  const [loading, setLoading] = useState(true);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [andamento, setAndamento] = useState(0);
  const [revisao, setRevisao] = useState(0);
  const [maturidade, setMaturidade] = useState(0);
  const [showCompanyObservations, setShowCompanyObservations] = useState(false);
  const [modalConfigIsVisible, setModalConfigIsVisible] = useState(false);
  const [hist, setHist] = useState([])

  moment.locale('pt');


  async function handlePushMain() {
    history.push(`/companies/${data._id}/Monitoramento`);
  }

  function handleCloseModal() {
    setOpenEditModal(false);
  }

  function HandleOpenModalEdit() {
    if (data.user)
      return Notifications(
        'warning',
        'Você não pode editar uma empresa que não pertença ao seu grupo',
        'Atenção!'
      )
    setOpenEditModal(true)
  }

  const getData = async () => {
    setLoading(true);

    const { data: pendingRequests, } = await getCompanyContactRequests(data._id, true);
    const { data: notAnswered, } = await api.get(`/resposta?resposta=0&idEmpresa=${idEmpresa}`);
    const { data: __results, } = await api.get(`/resposta?resposta=1&idEmpresa=${idEmpresa}`);
    const { data: weight, } = await api.get(`/historico?idEmpresa=${idEmpresa}`);
    const { data: meetings, } = await api.get(`/reunion${idEmpresa}`);
    const { data: plan, } = await getPlano(idEmpresa);
    const { andamento, } = plan;

    setSolicitacao(pendingRequests.requests);
    setResponseNot(notAnswered);
    setResults(__results);
    setPeso(weight);
    setReunios(meetings);
    setCount(meetings.length);
    setAndamento(andamento.length);

    setLoading(false);
  }

  useEffect(() => {
    setMaturidade(Math.floor(results * peso))
  }, [results, peso])

  useEffect(() => {
    setRevisao(responseNot - andamento)
  }, [responseNot, andamento])

  useEffect(() => {
    getData();

    if (name.length > 10) {
      setName(data.name.slice(0, 10) + '...');
    } else {
      setName(data.name);
    }
  }, []);

  useEffect(() => {
    const media = (results + andamento) * peso
    if (!media) {
      setNow(0)
    } else {
      setNow(Math.floor(media))
    }
  })


  return (
    <tbody>
      <Dialog
        open={openEditModal}
        onClose={handleCloseModal}
      >
        <EditCompanyModal
          onClose={handleCloseModal}
          data={data}
          onDeleted={onDeleted}
        />
      </Dialog>

      <ModalConfigUser
        open={modalConfigIsVisible}
        onClose={() => setModalConfigIsVisible(false)}
        infoUsers={data}
        logs={hist}
      />

      {
        loading ?
          <tr>
            <td
              colSpan="7"
              style={{
                alignItems: 'center',
              }}
            >
              <Loader content="loading" />
            </td>
          </tr> :
          <tr>
            <td
              onClick={handlePushMain}
              style={{
                cursor: 'pointer',
                alignItems: 'center',
              }}
            >
              <Whisper
                placement="top"
                trigger="hover"
                speaker={(
                  <Tooltip>
                    {data.name}
                  </Tooltip>
                )}
              >
                <strong className="Name">
                  {name}
                </strong>
              </Whisper>
            </td>

            <td
              onClick={handlePushMain}
              style={{
                cursor: 'pointer',
              }}
            >
              <strong className="Itens">
                {revisao}
              </strong>
            </td>

            <td
              onClick={handlePushMain}
              style={{
                cursor: 'pointer',
              }}
            >
              <strong className="AlertItem">
                {solicitacao.length}{' '}
                <Icon
                  icon="exclamation-triangle"
                  size="lg"
                />
              </strong>
            </td>

            <td
              onClick={handlePushMain}
              style={{
                cursor: 'pointer',
              }}
            >
              <CircularProgressWithLabel
                value={now}
              />
            </td>
            <td
              onClick={handlePushMain}
              style={{
                cursor: 'pointer',
              }}
            >
              <CircularProgressWithLabel
                value={maturidade}
              />
            </td>

            <td
              onClick={handlePushMain}
              style={{
                alignItems: 'center',
                cursor: 'pointer',
              }}
            >
              {
                !reunions[0] ?
                  <strong className="NoItens">
                    Sem reuniões registradas
                  </strong> :
                  reunions.filter((_, index) => index === count - 1).map((reunion) => {
                    return (
                      <strong
                        key={reunion.id}
                        className="NoItens"
                      >
                        {reunion.title}
                      </strong>
                    )
                  })
              }
            </td>

            <td
              onClick={handlePushMain}
              style={{
                alignItems: 'center',
                cursor: 'pointer',
              }}
            >
              {
                !reunions[0] ?
                  <strong className="NoItens">
                    Sem reuniões registradas
                  </strong> :
                  reunions.filter((_, index) => index === count - 1).map((reunion, index) => {
                    return (
                      <strong key={index+""} className="Itens">
                        {moment(reunion.date).format('DD/MM/YYYY')}
                      </strong>
                    );
                  })
              }
            </td>

            <td
              onClick={handlePushMain}
              style={{
                cursor: 'pointer',
              }}
            >
              <strong className="Itens">
                {moment(data.createdAt).fromNow()}
              </strong>
            </td>

            <td className="ColumnIcon">
              <IconButton
                aria-label="show-observations"
                size="small"
                className={clsx({
                  [classes.enabledObservations]: showCompanyObservations,
                  [classes.disabledObservations]: !showCompanyObservations,
                })}
                onClick={() => setShowCompanyObservations(!showCompanyObservations)}
              >
                <ImportContactsIcon />
              </IconButton>
            </td>

            <td className="ColumnIcon">
              <IconButton
                onClick={() => setModalConfigIsVisible(!modalConfigIsVisible)}
                aria-label="edit-company"
                size="small"
                className={classes.editCompany}
              >
                <Icon
                  icon="cog"
                  size="lg"
                  style={{ color: "#808080" }}
                />
              </IconButton>
            </td>

            <td className="ColumnIcon">
              <IconButton
                onClick={HandleOpenModalEdit}
                aria-label="edit-company"
                size="small"
                className={classes.editCompany}
              >
                <MoreVertIcon />
              </IconButton>
            </td>
          </tr>
      }
      {
        showCompanyObservations && (
          <tr className={classes.observations} >
            <td colSpan={100} className={classes.observations} >
              <CompanyObservations companyId={data._id} />
            </td>
          </tr>
        )
      }
    </tbody>
  );
}

export default Companies;
