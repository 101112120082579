import { useState, } from 'react';
import { Container, Grid, Typography, Dialog, } from '@material-ui/core';
import ModalError from './ModalError';
import ModalSucess from './ModalSucess';
import { registerUser, registerConsultant, } from '../../services/api';
import RegisterForm from './RegisterForm';
import useRegisterModalStyles from './useRegisterModalStyles';


export const REGISTRATION_TYPES = Object.freeze({
  CONSULTANT: 0,
  ENTREPRENEUR: 1,
});


export default function RegisterUser({ type, openLogin, onCancel, }) {
  const classes = useRegisterModalStyles();

  const [open, setOpen] = useState(false);
  const [openError, setOpenError] = useState(false);


  /**
   * Send request to create new user account to API
   *
   * @param {object} formData - user data
   */
  async function createEntrepreneur(formData) {
    try {
      const data = {
        name: formData.name,
        email: formData.email,
        password: formData.password,
        group: formData.companyName,
        empresa: formData.companyName,
        tel: formData.phoneNumber,
      };

      const { status, } = await registerUser(data);

      if (status === 201) {
        setOpen(true);
      } else {
        setOpen(false);
        setOpenError(true);
      }
    } catch {
      setOpen(false);
      setOpenError(true);
    }
  }

    /**
   * Handle register event
   *
   * @param {object} formData - form data
   */
    async function createConsultant(formData) {
      try {
        const data = {
          name: formData.name,
          email: formData.email,
          password: formData.password,
          group: formData.companyName,
          tel: formData.phoneNumber,
        };

        await registerConsultant(data);
        setOpen(true);
      } catch {
        setOpenError(true);
      }
    }

    async function onCreate(formData) {
      type === REGISTRATION_TYPES.CONSULTANT ? createConsultant(formData) :
        createEntrepreneur(formData);
    }


  return (
    <Container
      elevation={4}
      disableGutters
    >
      <Dialog
        open={open || openError}
      >
        { open && <ModalSucess openNewLogin={openLogin} /> }
        { openError && <ModalError openNewLogin={openLogin} /> }
      </Dialog>

      <Grid
        container
        spacing={0}
        className={classes.container}
        style={{
          display: open || openError ? 'none' : 'flex',
        }}
      >
        <Grid
          item
          xs={12}
        >
          <div className={classes.formContainer} >
            <Typography
              variant="body1"
              display="block"
              className={classes.inputTitle}
            >
              Você selecionou: { type === REGISTRATION_TYPES.CONSULTANT ? 'Consultor' : 'Empresário' }
            </Typography>
            <Typography
              variant="body1"
              display="block"
              className={classes.inputSubTitle}
            >
              Já possui uma conta?{' '}
              <span
                style={{
                  cursor: 'pointer',
                  color: '#3B66FF',
                }}
                onClick={openLogin}
              >
                Login
              </span>
            </Typography>

            <div
              style={{
                height: '100%',
              }}
            >
              <RegisterForm
                handleLogin={onCreate}
                handleCancel={onCancel}
              />
            </div>
          </div>
        </Grid>
      </Grid>
    </Container>
  );
}
