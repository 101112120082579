import React from 'react';;
import { Button, } from 'react-bootstrap';
import styles from './styleChatResp.module.scss';


export default function ShowCode({ GetObs, setCodigo, }) {
  return (
    <div className={styles.ShowCodeValidate}>
      <p>Insira abaixo o codigo de verificação que enviamos para o E-mail informado</p>
      <div className={styles.boxContentCode}>
        <input
          type="text"
          maxlength="4"
          onChange={(e) => setCodigo(e.target.value)}
        >
        </input>
      </div>
      <Button onClick={GetObs}>
        Consultar
      </Button>
    </div>
  )
}
