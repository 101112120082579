import React from "react";
import ShowUsers from "../Usuarios/showUsers";
import Sheet from "../../components/Sheet";
import DataMappingTemplate from "../../components/DataMappingTemplatePage";
import TemplateQuestionsPage from "../../components/TemplateQuestionsPage";
import AffiliatesPage from "../Affiliates/AffiliatesPage";

const PAGES = Object.freeze({
  linkparceiros: {
    id: "linkparceiros",
    titleTab: "Minhas indicações",
    component: <AffiliatesPage />,
  },
  templates: {
    id: 'templates',
    titleTab: "Templates",
    component: null,
    subMenu: {
      checkList: {
        id: 'checkList',
        titleTab: "Checklist",
        component: <TemplateQuestionsPage />,
      },
      mapeamento: {
        id: 'mapeamento',
        titleTab: "Mapeamento",
        component: <DataMappingTemplate />,
      },
    }
  },
  consultores: {
    id: "consultores",
    titleTab: "Consultores",
    component: <ShowUsers />,
  },
});

const ARRAY_PAGES = Object.values(PAGES);

export default function PagesConfigAdmin() {
  return (<Sheet pages={ARRAY_PAGES} />);
}