import React from 'react';
import { useTranslation, } from 'react-i18next';
import _ from 'lodash';

import {
  Radar,
  RadarChart,
  PolarGrid,
  PolarAngleAxis,
  PolarRadiusAxis,
  ResponsiveContainer, Tooltip,
} from 'recharts';


function QuestionsCategoriesMaturityLevelChart({data}) {
  const { t, } = useTranslation();

  return (
    <ResponsiveContainer
      width="100%"
      height="100%"
    >
      <RadarChart
        cx="50%"
        cy="50%"
        outerRadius="80%"
        data={data}
      >
        <PolarGrid />
        <PolarAngleAxis dataKey="name" />
        <PolarRadiusAxis
          domain={[0, 5]}
          scale="linear"
        />
        <Tooltip formatter={(v) => `${((v / 5) * 100).toFixed(2)}%`}/>
        <Radar
          name={_.capitalize(t('maturity'))}
          dataKey="value"
          stroke="#8884d8"
          fill="#8884d8"
          fillOpacity={0.6}
        />
      </RadarChart>
    </ResponsiveContainer>
  );
};

export default React.memo(QuestionsCategoriesMaturityLevelChart);
