import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector, } from 'react-redux';
import _ from 'lodash';
import {
  Grid,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  makeStyles,
  TableRow,
  TableCell,
  IconButton,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import { Loader, Notification, } from 'rsuite';
import styled from "styled-components";

import CollaboratorForm from '../CollaboratorForm';
import Table from '../Table';

import {
  deleteCollaborator,
  selectCollaborators,
  selectFeatureStatus,
  fetchCollaborators,
  setCollaboratorsModal,
  selectCollaboratorsModalState,
} from '../../features/collaboratorsSlice';
import { selectCompany } from '../../features/companySlice';

import { CRUD_STATUSES } from '../../utils/constants';
import useModal from '../../hooks/useModal';
import DeleteDialog from '../DeleteDialog';
import { createCollaboratorFromCSV, } from '../../services/api';


const useStyles = makeStyles({
  header: {
    backgroundColor: '#3B66FF',
  },
  headerTitle: {
    color: 'white',
  },
});

const DEFAULT_COLLABORATOR = Object.freeze({
  name: '',
  id: 0,
});

const HEADERS = Object.freeze([
  {
    label: 'Nome',
    align: 'left',
  },
  {
    //! column use to show buttons to approve or reject user account
    label: '',
    align: 'center',
  },
]);


export const CSVFileContainer = styled.div`
  width: 100%;
  height: 15%;
  border-radius: 0.75rem;
  background: transparent;
  align-items: center;
  justify-content: center;
  margin-top: 2rem;

  input {
    display: none;
    height: 100%;
    width: 100%;
  }

  label {
    height: 15%;
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: rgb(59, 102, 255);
    font-weight: bold;
    font-size: 13px;
    cursor: pointer;

    span {
      font-family: Inter;
      font-weight: normal;
      font-size: 11px;
      color: #54708C;
    }
}`;



const CollaboratorsModal = () => {
  const classes = useStyles();
  const company = useSelector(selectCompany);
  const dispatch = useDispatch();
  const collaborators = useSelector(selectCollaborators);
  const open = useSelector(selectCollaboratorsModalState);
  const status = useSelector(selectFeatureStatus);

  const [loading, setLoading] = useState(false);
  const [currCollaborator, setCurrCollaborator] = useState({
    name: '',
    id: 0,
  });

  const [deleteConfirmation, openDeleteConfirmation, closeDeleteConfirmation] = useModal(
    (collaborator) => setCurrCollaborator(_.cloneDeep(collaborator)),
    () => setCurrCollaborator(DEFAULT_COLLABORATOR)
  );


  /**
   * Send request to delete user to API
   */
  async function __deleteCollaborator() {
    try {
      await dispatch(deleteCollaborator(currCollaborator.id)).unwrap();

      Notification['success']({
        placement: 'bottomEnd',
        title: 'Colaborador deletado com sucesso!',
      });

      closeDeleteConfirmation();
    } catch {
      Notification['error']({
        placement: 'bottomEnd',
        title: 'Falha ao deletar colaborador!',
        description: 'Tente novamente dentro de alguns instantes.'
      });
    }
  }

  function handleClose() {
    dispatch(setCollaboratorsModal(false));
  }

  async function createFromCSV(event) {
    const file = event.target.files[0];
    setLoading(true);

    try {
      const { status, } = await createCollaboratorFromCSV(company._id, file);
      if (status === 200) {
        Notification['success']({
          placement: 'bottomEnd',
          title: 'Colaboradores criados com sucesso!',
        });

        dispatch(fetchCollaborators());
      } else {
        Notification['error']({
          placement: 'bottomEnd',
          title: 'Falha ao criar colaboradores',
        });
      }
    } catch {
      Notification['error']({
        placement: 'bottomEnd',
        title: 'Falha ao criar colaboradores',
      });
    }

    event.target.value = null;
    setLoading(false);
  }

  useEffect(() => {
    if (open) {
      dispatch(fetchCollaborators());
    }
  }, [open]);

  /**
   * Use effect to update loading state
   */
  useEffect(() => {
    setLoading((status !== CRUD_STATUSES.FETCH_FAILED) &&
      (status !== CRUD_STATUSES.FETCH_SUCCESSFUL));
  }, [status]);


  return (
    <Dialog
      onClose={handleClose}
      open={open}
      maxWidth="sm"
    >
      <DialogTitle
        className={classes.header}
        disableTypography
      >
        <Typography
          variant="h5"
          display="block"
          className={classes.headerTitle}
        >
          Gestão de colaboradores
        </Typography>
      </DialogTitle>

      <DialogContent>
          <Grid
            container
            spacing={2}
          >
            <Grid
              item
              container
              xs={12}
              md={6}
              justifyContent="center"
              alignItems="center"
            >
              <Typography
                variant="h6"
                display="block"
                style={{
                  width: '100%',
                }}
              >
                Adicionar colaboradores
              </Typography>

              <CollaboratorForm />

              <CSVFileContainer>
                <label for="create-from-csv-file-input">
                  Ou crie a partir de um arquivo CSV menor que 10MiB.
                  <span>
                    O arquivo deve possuir as colunas nome e e-mail, respectivamente.
                  </span>
                </label>

                <input
                  id="create-from-csv-file-input"
                  type="file"
                  onChange={(event) => {
                    if (event.target.files && event.target.files[0].size >= 102400000) {
                      Notification['warning']({
                        placement: 'bottomEnd',
                        title: 'Selecione um arquivo menor',
                      });
                    } else {
                      createFromCSV(event);
                    }
                  }}
                />
              </CSVFileContainer>
            </Grid>

            <Grid
              item
              xs={12}
              md={6}
            >
              <Typography
                variant="h6"
              >
                Colaboradores adicionados
              </Typography>

              {
                loading ?
                <Loader
                  content="Carregando"
                /> :
                <Table
                  headers={HEADERS}
                  data={collaborators}
                  loading={loading}
                  noDataMessage="Nenhum colaborador encontrado"
                  errorMessage="Falha ao carregar colaboradores"
                  disablePagination
                  maxHeight={250}
                >
                  {
                    (collaborator) => {
                      return (
                        <TableRow key={collaborator.id}>
                          <TableCell align="left">
                            <Typography>
                              { collaborator.name }
                            </Typography>
                            <Typography
                              variant="body2"
                              color="textSecondary"
                            >
                              { collaborator.email }
                            </Typography>
                          </TableCell>

                          <TableCell align="right">
                            <IconButton
                              onClick={() => openDeleteConfirmation(collaborator)}
                            >
                              <DeleteIcon color="error" />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      )
                    }
                  }
                </Table>
              }
            </Grid>
          </Grid>
      </DialogContent>

      <DeleteDialog
        open={deleteConfirmation}
        confirmationMessage={`Tem certeza que deseja excluir o colaborador ${currCollaborator.name}?`}
        onClose={closeDeleteConfirmation}
        onConfirm={__deleteCollaborator}
      />
    </Dialog>
  );
}

export default CollaboratorsModal;
