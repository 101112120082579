import { makeStyles, } from '@material-ui/core';


const useRegisterModalStyles = makeStyles((theme) => ({
  container: {
    margin: 0,
  },
  formContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    height: '100%',
  },
  inputTitle: {
    fontFamily: 'Inter',
    fontSize: '20px',
    color: '#1C265A',
    fontWeight: 500,
  },
  inputSubTitle: {
    fontFamily: 'Inter',
    fontSize: '15px',
    color: '#54708C',
    fontWeight: 400,
    marginBottom: '1.5rem',
  },
}));


export default useRegisterModalStyles;
