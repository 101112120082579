import React from 'react';

import { Icon, } from 'rsuite';
import styled, { css, } from 'styled-components';

import Tooltip from '../Tooltip';
import FieldPreview from '../FieldPreview';


const draggingActive = css`
  background: #b8ccc9;
  border-radius: 0.25rem;
  border: 1px solid #54708c;

  strong {
    color: #54708c;
  }
`;

const Container = styled.div.attrs({
  className: 'ActiveDrop',
})`
  max-width: 100%;
  height: 2.5rem;
  padding: 5px;
  margin: 0;
  margin-bottom: 2px;
  border: none;
  border-radius: 0.25rem;

  display: flex;
  align-items: center;
  justify-content: center;

  background: #54708c;

  strong {
    color: #fff;
    font-family: Inter;
    font-size: 15px;
    font-style: normal;
    text-align: center;
    justify-content: center;
  }
  ${props => props.isActive && draggingActive}
`;


/**
 * Component use as the title of the field
 *
 * @param {Object} data - field data
 * @param {boolean} isDragging - if the user is dragging the field
 * @param {function} onDelete - callback called when user wants to delete field
 * @param {function} onEdit - callback called when user wants to edit field
 *
 * @returns Rendered field component
 */
export default function MappingField({ data, isDragging, onDelete, onEdit, }) {
  const showTooltip = (data.description != undefined) && (data.description != '');
  const iconStyle = {
    color: isDragging ? '#54708c' : 'white',
  };


  return (
    <div
      style={{
        width: '100%',
      }}
    >
      <Container isActive={isDragging} >
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            width: '100%',
            padding: 10
          }}
        >
          <Icon
            icon="trash"
            style={iconStyle}
            onClick={onDelete}
          />

          {
            showTooltip ?
              <Tooltip
                arrow
                title={data.description}
                PopperProps={{
                  modifiers: {
                    offset: {
                      enabled: true,
                      offset: '0px, 0px'
                    }
                  }
                }}
                placement="top"
              >
                <strong>{data.name}</strong>
              </Tooltip> :
              <strong>{data.name}</strong>
          }{' '}

          <Icon
            icon="edit2"
            style={iconStyle}
            onClick={onEdit}
          />
        </div>
      </Container>

      <FieldPreview field={data} />
    </div>
  );
}
