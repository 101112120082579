import React, { useEffect, useState, useRef, useMemo, } from 'react';
import { Notification, } from 'rsuite';
import fileDownload from 'js-file-download';
import moment from 'moment';
import { useReactToPrint } from 'react-to-print';
import { useDispatch, useSelector, } from 'react-redux';
import _ from 'lodash';
import { useTranslation, } from 'react-i18next';

import { Grid, makeStyles, TablePagination, } from '@material-ui/core';

import ModalPersonalizedReport from '../../components/MapaDados/PersonalizedReport/ModalPersonalizedReport';
import DataContainer from '../../components/Relatorio/DataContainer';

import api, {
  getEtapas,
  getRespostaMapeamento,
  exportCompanyDataMapping,
  listDataMappingPhaseFields,
} from '../../services/api';
import { getAbas } from '../../features/mapeamentoRelatorio';
import './styles.css';

import ActionButton from './ActionButton';
import Dropdown from '../../components/Dropdown';
import PrintModeReport from '../../components/MapaDados/ComponentsRelatorio/PrintModeReport';
import DataMappingSortFieldSelector from '../../components/DataMappingSortFieldSelector';

import { CRUD_STATUSES as STATUSES, } from '../../utils/constants';

import { selectCompany } from '../../features/companySlice';
import { incrementByAmountRelatorio, } from '../../features/counterSlice';
import { fetchCustomReports, setReport, } from '../../features/customMappingReportSlice';
import DataMappingAnswerPanelContext from "../../components/DataMappingAnswerPanel/context";
import useBaseDataMappingPanel from "../../hooks/useBaseDataMappingPanel";
import queryString from "query-string";
import { useLocation } from "react-router-dom";
import { setGlobalMenuFixed } from '../../features/fixedMenuPages';
import PartialCustomMenuFixed from '../../components/CustomMenuFixed/PartialCustomMenuFixed';


const useStyles = makeStyles({
  container: {
    display: 'flex !important',
  },
  title: {
    color: '#54708C',
    fontFamily: 'Inter',
    fontWeight: 600,
    marginBottom: '1rem',
  },
})


function DataMappingReport({ mode = 'table' }) {
  const classes = useStyles();
  const componentRef = useRef();
  const exportingRef = useRef(false);
  const { t, } = useTranslation();
  const dispatch = useDispatch();
  const company = useSelector(selectCompany);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [page, setPage] = useState(0);
  const [etapas, setEtapas] = useState([]);
  const [phaseToFieldsMap, setPhaseToFieldsMap] = useState({});
  const fields = useMemo(() => Object.values(phaseToFieldsMap).flat(), [phaseToFieldsMap]);
  const [mapaDados, setMapaDados] = useState([]);
  const [openReport, setOpenReport] = useState(false);
  //! Can be: table || print
  const [viewMode, setViewMode] = useState(mode || '');
  const [downloading, setDownloading] = useState(false);
  const [orderByField, setOrderByField] = useState(undefined);
  const { status, customReports, report, } = useSelector(state => state.customMappingReport);
  const customReport = useMemo(() => (report || {}).id, [report]);
  const [prevCustomReport, setPrevCustomReport] = useState(customReport);
  const base = useBaseDataMappingPanel();

  useEffect(() => {
    dispatch(setGlobalMenuFixed(false))
    return () => {
      dispatch(setGlobalMenuFixed(true))
    }
  }, []);

  if (prevCustomReport != customReport) {
    if (0 !== page) {
      setPage(0);
    }
    setMapaDados([]);
    setPrevCustomReport(customReport);
  }

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const downloadPDFReport = async () => {
    if (!downloading) {
      setDownloading(true);
      const key = 'downloading-report';
      Notification['info']({
        placement: 'bottomEnd',
        title: 'O relatório está sendo gerado!',
        description: 'Não é necessário atualizar a página, você poderá encontrar o arquivo na pasta downloads em alguns instantes.',
        duration: 0,
        key,
      });

      const url = `/companies/${company._id}/mapping-report/download`;

      try {
        const { data, } = await api.get(url, {
          responseType: 'arraybuffer',
        });

        const reportName = `relatório-padrão-${company.name}-${moment().format('YYYYMMDD')}.pdf`;
        fileDownload(data, reportName);
      } catch {
        Notification['error']({
          placement: 'bottomEnd',
          title: 'Download do relatório falhou!',
          description: 'Um erro ocorreu durante o download do relatório solicitado, tente novamente mais tarde ou entre em contato com o suporte.',
        });
      }

      Notification.close(key);
      setDownloading(false);
    } else {
      Notification['warning']({
        placement: 'bottomEnd',
        title: 'O relatório já está sendo gerado!',
        description: 'O processamento da última requisição ainda foi finalizado.',
      });
    }
  }

  const handleViewMode = () => {
    if (viewMode === 'table') {
      setViewMode('print')
    }
    if (viewMode === 'print') {
      setViewMode('table')
    }
  }

  async function listPhases() {
    try {
      const { data: phases, } = await getEtapas();
      setEtapas(phases);
      dispatch(getAbas(phases));
    } catch (error) {
      //todo: handle error
    }
  }

  async function listFields() {
    if (0 === etapas.length) {
      return;
    }

    try {
      const responses = await Promise.all(etapas
        .map((p) => listDataMappingPhaseFields(company._id, p.id)));
      setPhaseToFieldsMap(Object.fromEntries(etapas.map((p, idx) => [p.id, responses[idx].data])));
    } catch (error) {
      //todo: handle error
    }
  }

  useEffect(listFields, [etapas]);

  async function listAnswers() {
    try {
      const { data: anwsers, } = await getRespostaMapeamento(orderByField, customReport);
      setMapaDados(anwsers);
    } catch (error) {
      //todo: handle error
    }
  }
  useEffect(listAnswers, [orderByField, customReport]);

  async function onExportDataMapping() {
    if (exportingRef.current) {
      return;
    }

    try {
      exportingRef.current = true;
      Notification['info']({
        placement: 'bottomEnd',
        title: _.capitalize(t('PAGES.DATA_MAPPING_REPORT.exporting_data_mapping')),
      });

      const { data, } = await exportCompanyDataMapping(company._id);
      fileDownload(data, 'mapeamento-de-dados.xlsx');

      Notification['success']({
        placement: 'bottomEnd',
        title: _.capitalize(t('PAGES.DATA_MAPPING_REPORT.successfully_exported_data_mapping')),
      });
    } catch (err) {
      Notification['error']({
        placement: 'bottomEnd',
        title: _.capitalize(t('ERRORS.DATA_MAPPING_REPORT.failed_to_export_data_mapping')),
        description: _.capitalize(t('ERRORS.try_again_later')),
      });
    }

    exportingRef.current = false;
  }

  const handleChangePage = (event, newPage) => {
    dispatch(incrementByAmountRelatorio(newPage));
    setPage(newPage);
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value);
  }

  const onCustomReportChange = (selectedReportId) => {
    const report = customReports.find(({ id, }) => id === selectedReportId);
    dispatch(setReport(report));
    return;
  }

  const initialize = async () => {
    listPhases();
    dispatch(fetchCustomReports());
    return;
  }

  useEffect(initialize, []);

  useEffect(() => {
    switch (status) {
      case STATUSES.FETCH_FAILED:
        Notification['error']({
          placement: 'bottomEnd',
          title: 'Falha ao carregar relatórios customizados!',
          description: 'Tente novamente mais tarde ou entre em contato com o suporte.',
        });
        break;
      default:
        break;
    }

    return;
  }, [status]);

  const zoom = componentRef.current != null ? Math.floor(100 * 1681 / componentRef.current.clientWidth) : 100;
  const printStyle = `
    @page {
      size: landscape;
    }

    body {
      zoom: ${zoom}%;
    }
  `;

  const MENU_FIXED = Object.freeze({
    export: {
      id: 'export',
      title: 'Exportar',
      icon: <></>,
      onClick: () => onExportDataMapping(),
    },
    dowload: {
      id: 'dowload',
      title: viewMode === 'table' ? 'PDF' : 'Dowload',
      icon: <></>,
      onClick: () => { viewMode === 'table' ? handlePrint() : downloadPDFReport() },
    },
    newReport: {
      id: 'newReport',
      title: 'Novo relatório',
      icon: <></>,
      onClick: () => setOpenReport(true),
    },
  });


  return (
    <>
      <Grid
        container
        direction="row"
        justifyContent="flex-end"
        alignItems="center"
        style={{
          paddingBottom: '1rem',
          marginTop: '40px'
        }}
      >
        <DataMappingSortFieldSelector
          value={orderByField}
          onChange={setOrderByField}
          fields={fields}
        />

        <Dropdown
          defaultItem="Relatório completo"
          items={customReports}
          value={report.id}
          setValue={onCustomReportChange}
        />

        <Grid
          style={{
            display: viewMode !== 'table' ? 'none' : undefined,
          }}
        >
          <TablePagination
            rowsPerPageOptions={[1, 3, 5, 10, 15, 20]}
            component="div"
            labelRowsPerPage={'Linhas por página'}
            className="Paginador"
            count={mapaDados.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </Grid>
      </Grid>

      {
        viewMode === 'table' ?
          <Grid
            container
            style={{
              overflow: 'hidden',
              overflowX: 'auto',
            }}
          >
            <Grid
              item
              className={classes.container}
              ref={componentRef}
            >
              {/* Set some print configurations */}
              {/* The width of the PDF page is 1681 pixels */}
              <style type="text/css" media="print">
                {printStyle}
              </style>

              {
                etapas
                  .map((tab) => {
                    const tabFields = (phaseToFieldsMap[tab.id] || [])
                      .filter(({ id, }) => {
                        return (report.id === null) || report.items.includes(id);
                      });

                    return 0 < tabFields.length ?
                      <DataContainer
                        key={tab.id}
                        phase={tab}
                        fields={tabFields}
                        page={page}
                        rowsPerPage={rowsPerPage}
                        mapaDados={mapaDados}
                      /> :
                      null;
                  })
              }
            </Grid>
          </Grid> :
          <DataMappingAnswerPanelContext.Provider
            value={base}
          >
            <PrintModeReport orderByField={orderByField} />
          </DataMappingAnswerPanelContext.Provider>
      }

      <PartialCustomMenuFixed menuFixed={MENU_FIXED} />

      {/* <ActionButton
        viewMode={viewMode}
        onChangeViewMode={handleViewMode}
        onNewReport={() => setOpenReport(true)}
        onExportDataMapping={onExportDataMapping}
        onDownload={viewMode === 'table' ? handlePrint : downloadPDFReport}
      /> */}

      <ModalPersonalizedReport
        open={openReport}
        phases={etapas}
        phaseToFieldsMap={phaseToFieldsMap}
        onClose={() => setOpenReport(false)}
      />
    </>
  )
}

export default DataMappingReport;
