import { useState, useEffect } from 'react'
import _ from 'lodash';
import { useSelector, } from 'react-redux';
import { useTranslation, } from 'react-i18next';
import fileDownload from 'js-file-download';

import { ListGroup, } from 'react-bootstrap';
import { Notification, } from 'rsuite';
import { Collapse, } from '@material-ui/core';

import {
  updatePublicMaturityQuestionImpact,
  updatePublicMaturityQuestionProbability,
  updatePublicMaturityQuestionFastActionState,
  createPublicMaturityQuestionObservation,
  updatePublicMaturityQuestionMaturityLevel,
  setPublicMaturityQuestionAsAnswered,
  setPublicMaturityQuestionAsNotAnswered,
  getPublicMaturityQuestionObservations,
  downloadEvidence,
  addQuestionEvidence,
} from '../../services/api';

import { showToast } from '../../ui-lib/toast'
import 'react-toastify/dist/ReactToastify.css'

import { QUESTION_STATUSES, } from '../../utils/constants';
import { selectCompanySectorById, } from '../../features/companySectorsSlice';
import { selectCompanyCategoryById, } from '../../features/companyCategoriesSlice';

import QuestionItemHeader from '../../components/CorpoResposta/QuestionItemHeader';
import QuestionItemCard from '../../components/CorpoResposta/QuestionItemCard';

import Risk, { RISK_STATUSES, } from '../../core/entities/risk';


function QuestionItem({ data: _data, shareLinkId, }) {
  const sector = useSelector((state) => selectCompanySectorById(state, _data.sector_id));
  const category = useSelector((state) => selectCompanyCategoryById(state, _data.category_id));
  const { t, } = useTranslation();
  const [observations, setObservations] = useState([]);
  const [openList, setOpenList] = useState(false);
  const [loadingButton, setLoadingButton] = useState(false);
  const [questionStatusData, setQuestionStatusData] = useState(QUESTION_STATUSES.UNANSWERED);
  const [questionRiskStatus, setQuestionRiskStatus] = useState(RISK_STATUSES.UNKNOWN);
  const [obs, setObs] = useState('');
  const [data, setData] = useState(_data);


  async function getData() {
    try {
      const resp = await getPublicMaturityQuestionObservations(shareLinkId, data.id);
      setObservations(resp.data);
    } catch (err) {
      //? drop error
      console.log(err);
    }
  }

  function updateData(key, value) {
    setData((data) => ({
      ...data,
      [key]: value,
    }));
  }

  async function updateImpact(_value) {
    const value = Number(_value);
    updateData('impacto', value);
    try {
      await updatePublicMaturityQuestionImpact(shareLinkId, data.id, value);
      Notification['success']({
        placement: 'bottomEnd',
        title: `Impacto alterado com sucesso!`,
      });
    } catch {
      Notification['error']({
        placement: 'bottomEnd',
        title: `Falha ao alterar impacto`,
        description: _.capitalize(t('ERRORS.try_again_later')),
      });
    }
  }

  async function updateProbability(_value) {
    const value = Number(_value);
    updateData('probabilidade', value);
    try {
      await updatePublicMaturityQuestionProbability(shareLinkId, data.id, value);
      Notification['success']({
        placement: 'bottomEnd',
        title: `Probabilidade alterada com sucesso!`,
      });
    } catch {
      Notification['error']({
        placement: 'bottomEnd',
        title: `Falha ao alterar probabilidade`,
        description: _.capitalize(t('ERRORS.try_again_later')),
      });
    }
  }

  /**
   * Use effect t update question status indicators
   */
  useEffect(() => {
    let statusData = null;
    if (!data.reg_resposta) {
      statusData = QUESTION_STATUSES.UNANSWERED;
    } else if (data.reg_resposta && data.resposta) {
      statusData = QUESTION_STATUSES.COMPLIANT;
    } else if (data.reg_resposta) {
      statusData = QUESTION_STATUSES.ANSWERED_NOT_COMPLIANT;
    }

    setQuestionStatusData(statusData);
  }, [data.reg_resposta, data.resposta]);

  useEffect(() => {
    const updatedData = { ...data, ..._data, };
    setData(updatedData);
  }, [_data]);

  useEffect(() => {
    if ((undefined != data.probabilidade) && (undefined != data.impacto)) {
      setQuestionRiskStatus(Risk.getRisk(data.probabilidade, data.impacto));
    }
  }, [data]);

  async function onToggleFastAction() {
    const value = !data.rapida;
    updateData('rapida', value);

    let messageType = 'success';
    let message = 'Tarefa marcada como rápida';

    try {
      await updatePublicMaturityQuestionFastActionState(shareLinkId, data.id, value);
    } catch {
      messageType = 'error';
      message = `Erro ao registrar ação rapida`;
    }

    return showToast({
      type: messageType,
      message,
      pauseOnFocusLoss: true,
    });
  }

  async function updateResult(apiCall, updatedStatus) {
    setLoadingButton(true);

    let messageType = 'success';
    let message = 'Tarefa respondida com sucesso';

    try {
      await apiCall(shareLinkId, data.id, obs);
      setQuestionStatusData(updatedStatus);
      getData();
    } catch (err) {
      messageType = 'error';
      message = 'Resposta não gravada';
    }

    showToast({
      type: messageType,
      message,
      pauseOnFocusLoss: true,
    });

    setLoadingButton(false);
  }

  async function insertObs() {
    try {
      await createPublicMaturityQuestionObservation(shareLinkId, data.id, obs);
      setObs('');
      Notification['success']({
        placement: 'bottomEnd',
        title: _.capitalize(t('PAGES.QUESTION_ITEM_CARD.observation_successfully_registered')),
      });
      getData();
    } catch {
      Notification['error']({
        placement: 'bottomEnd',
        title: _.capitalize(t('ERRORS.QUESTION_ITEM_CARD.failed_to_register_observation')),
        description: _.capitalize(t('ERRORS.try_again_later')),
      });
    }
  }

  function toggleOpenList() {
    const _openList = !openList;
    if (_openList) {
      getData();
    }
    setOpenList(_openList);
  }

  async function updateMaturityLevel(level) {
    try {
      updateData('category_maturity_level', Number(level));
      await updatePublicMaturityQuestionMaturityLevel(shareLinkId, data.id, Number(level));
      Notification['success']({
        placement: 'bottomEnd',
        title: _.capitalize(t('PAGES.QUESTION_ITEM_CARD.question_maturity_level_successfully_updated')),
      });
    } catch {
      Notification['error']({
        placement: 'bottomEnd',
        title: _.capitalize(t('ERRORS.QUESTION_ITEM_CARD.failed_to_update_question_maturity_level')),
        description: _.capitalize(t('ERRORS.try_again_later')),
      });
    }
  }

  async function onAttachEvidence(questionId, file) {
    try {
      const response = await addQuestionEvidence(data.idEmpresa, questionId, file);
      setData(response.data);

      Notification['success']({
        placement: 'bottomEnd',
        title: _.capitalize(t('PAGES.EVIDENCE_MANAGEMENT.evidence_successfully_attached')),
      });
    } catch {
      Notification['error']({
        placement: 'bottomEnd',
        title: _.capitalize(t('ERRORS.EVIDENCE_MANAGEMENT.failed_to_attach_evidence')),
        description: _.capitalize(t('ERRORS.try_again_later')),
      });
    }
  }

  async function onDownloadEvidence(evidenceId) {
    try {
      const response = await downloadEvidence(evidenceId);

      const headerLine = response.headers['content-disposition'];
      const startIdx = headerLine.indexOf('"') + 1;
      const endIdx = headerLine.lastIndexOf('"');
      const filename = headerLine.substring(startIdx, endIdx);

      fileDownload(response.data, filename);

      Notification['success']({
        placement: 'bottomEnd',
        title: _.capitalize(t('PAGES.EVIDENCE_MANAGEMENT.evidence_successfully_downloaded')),
      });
    } catch {
      Notification['error']({
        placement: 'bottomEnd',
        title: _.capitalize(t('ERRORS.EVIDENCE_MANAGEMENT.failed_to_download_evidence')),
        description: _.capitalize(t('ERRORS.try_again_later')),
      });
    }
  }


  return (
    <ListGroup>
      <ListGroup.Item
        onClick={toggleOpenList}
        active={openList}
      >
        <QuestionItemHeader
          data={data}
          open={openList}
          questionStatus={questionStatusData}
          sector={sector != undefined ? sector.name : 'N/A'}
        />
      </ListGroup.Item>

      <Collapse
        in={openList}
        unmountOnExit
      >
        <QuestionItemCard
          data={data}
          loading={loadingButton}
          observations={observations}
          questionStatus={questionStatusData}
          questionRiskStatus={questionRiskStatus}
          obs={obs}
          category={category != undefined ? category.name : 'N/A'}
          onUpdateProbability={updateProbability}
          onUpdateImpact={updateImpact}
          onUpdateMaturityLevel={updateMaturityLevel}
          onSubmitReview={() => updateResult(setPublicMaturityQuestionAsNotAnswered, QUESTION_STATUSES.ANSWERED_NOT_COMPLIANT)}
          onSubmitCompliant={() => updateResult(setPublicMaturityQuestionAsAnswered, QUESTION_STATUSES.COMPLIANT)}
          onInsertObs={insertObs}
          onToggleFastActionItem={onToggleFastAction}
          setObs={setObs}
          onAttachEvidence={onAttachEvidence}
          onDownloadEvidence={onDownloadEvidence}
        />
      </Collapse>
      <br />
    </ListGroup>
  );
}

export default QuestionItem;
