import React from 'react';
import _ from 'lodash';

import { DragDropContext, Droppable, Draggable, } from 'react-beautiful-dnd';
import { Container, Grid } from '@material-ui/core';
import { createStyles, makeStyles, } from '@material-ui/core/styles';

import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';

import ImpactReportWidget from '../../../../components/ImpactReportWidget';


const useStyles = makeStyles(() =>
  createStyles({
    widgetListItem: {
      marginBottom: '1rem',
    },
    addButtonContainer: {
      '&:hover': {
        backgroundColor: '#EEE',
      },
      cursor: 'pointer',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      borderRadius: '5px',
      minHeight: '80px',
    },
  }),
);


/**
 * @param {function} updateWidget - API call to update widget
 * @param {function} onChange - update local widgets state
 */
function WidgetList({
  readOnly = false,
  widgets = [],
  onPositionChanged = (widgetList) => {},
  onCreateWidget = (position) => {},
  onDeleteWidget = (widgetId) => {},
  updateWidget = (updatedWidget) => {},
  onChange = (updatedWidget) => {},
  isPrintMode
}) {
  const classes = useStyles();


  async function handleOnDragEnd(result) {
    if (!result.destination) {
      return;
    }

    const reorderedItem = widgets[result.source.index];
    onPositionChanged({
      widgetId: reorderedItem.id,
      position: result.destination.index + 1,
    });
  }


  return (
    <Container
      maxWidth="xl"
      disableGutters
    >
      <DragDropContext onDragEnd={handleOnDragEnd}>
        <Droppable
          droppableId="impactReportWidgetList"
          isDropDisabled={readOnly}
        >
          {
            (provided) => (
              <ul
                {...provided.droppableProps}
                ref={provided.innerRef}
              >
                <div>
                  {
                    widgets.map((widget, index) => {
                      return (
                        <Draggable
                          key={widget.id}
                          draggableId={widget.id}
                          index={index}
                          isDragDisabled={readOnly}
                        >
                          {(provided) => (
                            <div
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              ref={provided.innerRef}
                              className={classes.widgetListItem}
                            >
                              <ImpactReportWidget
                                data={widget}
                                readOnly={readOnly}
                                updateWidget={updateWidget}
                                onCreateWidget={onCreateWidget}
                                onDeleteWidget={onDeleteWidget}
                                onChange={onChange}
                                isPrintMode={isPrintMode}
                              />
                            </div>
                          )}
                        </Draggable>
                      )
                    })
                  }
                  {provided.placeholder}
                </div>
              </ul>
            )
          }
        </Droppable>
        {
          !readOnly ?
          <Grid
            item
            xs={12}
            style={{
              paddingLeft: '5rem',
            }}
          >
            <Container
              maxWidth="xl"
              className={classes.addButtonContainer}
              onClick={() => onCreateWidget()}
            >
              <AddCircleOutlineIcon fontSize="large" />
            </Container>
          </Grid> :
          null
        }
      </DragDropContext>

    </Container>
  );
}

export default WidgetList;
