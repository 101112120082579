import { useState, useEffect } from 'react'

import { Icon, Input, InputGroup } from 'rsuite'
import { useDispatch } from 'react-redux'
import { setDocs } from '../../features/docsSlice'
import { searchDocs } from '../../services/api'
export default function SearshComponent() {
  const dispatch = useDispatch()

  const [clickSearch, setClickSearch] = useState(false)
  const [loading, setLoading] = useState(false)
  const [search, setSearch] = useState('')

  const handleOnChange = (event) => {
    setLoading(true)
    setSearch(event.target.value)
  }

  async function searchFileByName() {
    if (loading) {
      await searchDocs(search).then(({ data }) => {
        dispatch(setDocs(data))
        setLoading(false)
      })
    }
  }

  useEffect(() => {
    const timeoutId = setTimeout(
      () => searchFileByName(),
      1300
    )
    return () => clearTimeout(timeoutId)
  }, [search])

  const styles = {
    width: 210,
    height: 35,
    borderRadius: 29,
    borderColor: '#95ADC3',
    padding: '3px',
  }

  return (
    <>
      {clickSearch ? (
        <InputGroup
          inside
          style={styles}
          onChange={handleOnChange}
        >
          <Input />
          <InputGroup.Button loading={loading}>
            <Icon icon="search" />
          </InputGroup.Button>
        </InputGroup>
      ) : (
        <Icon
          onClick={() => {
            setClickSearch(true)
          }}
          style={{
            color: '#95ADC3',
            cursor: 'pointer',
            width: 20,
            right: 0,
          }}
          icon="search"
        />
      )}
    </>
  )
}
