import React from 'react';
import _ from 'lodash';
import { useSelector, } from 'react-redux';
import { useTranslation, } from 'react-i18next';

import {
  Grid,
  Typography,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
} from '@material-ui/core';
import { Notification, } from 'rsuite';
import AddIcon from '@material-ui/icons/Add';

import CookieClassificationTable from './CookieClassificationTable';
import CookieForm from '../../components/CookieForm';

import {
  getCookiesCategories,
  createPopupCookie,
  getPopupCookies,
  updatePopupCookie,
  deletePopupCookie,
} from '../../services/api';

import useModal from '../../hooks/useModal';

import { selectCompany, } from '../../features/companySlice';


/**
 * Cookie consent management page
 *
 * @returns Rendered page
 */
function CookieClassification({ popupConfig, }) {
  const { t, } = useTranslation();
  const company = useSelector(selectCompany);
  const [formModalOpen, openFormModal, closeFormModal] = useModal();
  const [categories, setCategories] = React.useState([]);
  const [cookies, setCookies] = React.useState([]);
  const [loading, setLoading] = React.useState(false);


  /**
   * Fetch registered sites from API
   */
  async function getCategories() {
    try {
      const { data: cookiesCategories, } = await getCookiesCategories();
      setCategories(cookiesCategories);
    } catch {
      Notification['error']({
        placement: 'bottomEnd',
        title: _.capitalize(t('ERRORS.COOKIE_MANAGEMENT.failed_to_load_cookie_categories')),
      });
    }
  }

  async function getCookies() {
    const companyId = company._id;
    try {
      const { data, } = await getPopupCookies(companyId, popupConfig.id);
      setCookies(data);
    } catch (err) {
      console.log(err);
      Notification['error']({
        placement: 'bottomEnd',
        title: _.capitalize(t('ERRORS.COOKIE_MANAGEMENT.failed_to_load_cookies')),
      });
    }
  }

  async function initialize() {
    setLoading(true);
    await Promise.all([getCategories(), getCookies()]);
    setLoading(false);
  }

  async function updateCookies() {
    setLoading(true);
    await getCookies();
    setLoading(false);
  }

  async function createCookie(data) {
    try {
      const { status, } = await createPopupCookie(company._id, popupConfig.id, {
        name: data.name,
        categoryId: data.category,
      });

      if (201 === status) {
        Notification['success']({
          placement: 'bottomEnd',
          title: _.capitalize(t('PAGES.COOKIE_MANAGEMENT.cookie_successfully_created')),
        });
        closeFormModal();
        updateCookies();
      } else {
      Notification['warning']({
        placement: 'bottomEnd',
        title: _.capitalize(t('ERRORS.COOKIE_MANAGEMENT.failed_to_create_cookie')),
        description: _.capitalize(t('ERRORS.try_again_later')),
      });
      }
    } catch (err) {
      Notification['error']({
        placement: 'bottomEnd',
        title: _.capitalize(t('ERRORS.COOKIE_MANAGEMENT.failed_to_create_cookie')),
        description: _.capitalize(t('ERRORS.try_again_later')),
      });
    }
  }

  async function updateCookieCategory(cookie, newCategory) {
    setLoading(true);

    try {
      const companyId = company._id;
      const { status, } = await updatePopupCookie(companyId, popupConfig.id, cookie.id, {
        category: newCategory.name,
      });

      if (status === 200) {
        const updatedCookies = _.cloneDeep(cookies);
        const index = updatedCookies.findIndex(({ id, }) => id === cookie.id);
        if (index > -1) {
          updatedCookies[index].category = newCategory;
          updatedCookies[index].category_id = newCategory.id;
        }
        setCookies(updatedCookies);
        Notification['success']({
          placement: 'bottomEnd',
          title: `Categoria do cookie ${cookie.name} atualizada!`,
        });
      } else {
        Notification['error']({
          placement: 'bottomEnd',
          title: 'Não foi possível atualizar a categoria do cookie!',
        });
      }
    } catch (err) {
      Notification['error']({
        placement: 'bottomEnd',
        title: 'Erro ao atualizar categoria do cookie!',
        description: _.capitalize(t('ERRORS.try_again_later')),
      });
    }

    setLoading(false);
  }

  async function onDeleteCookie(cookie) {
    setLoading(true);

    try {
      const { status, } = await deletePopupCookie(cookie.company_id, cookie.popup_config_id, cookie.id);

      if (status === 200) {
        Notification['success']({
          placement: 'bottomEnd',
          title: `Cookie ${cookie.name} excluído!`,
        });

        setCookies(cookies.filter(({ id }) => id !== cookie.id));
      }
    } catch (err) {
      Notification['error']({
        placement: 'bottomEnd',
        title: 'Erro ao excluir o cookie!',
        description: _.capitalize(t('ERRORS.try_again_later')),
      });
    }

    setLoading(false);
  }

  React.useEffect(initialize, [popupConfig]);


  return (
    <>
      <Grid
        container
        alignItems="center"
        justifyContent="space-between"
        style={{
          marginTop: '1rem',
          marginBottom: '1rem',
        }}
      >
        <Typography
          variant="h6"
          display="block"
          color="primary"
          style={{
            paddingTop: 10,
            paddingBottom: 5,
          }}
        >
          { _.capitalize(t('PAGES.COOKIE_MANAGEMENT.classify_cookies')) }
        </Typography>

        <Button
          variant="contained"
          startIcon={<AddIcon />}
          color="primary"
          disableElevation
          onClick={openFormModal}
        >
          { _.capitalize(t('PAGES.COOKIE_MANAGEMENT.new_cookie')) }
        </Button>
      </Grid>
      <CookieClassificationTable
        cookies={cookies}
        popupId={popupConfig.id}
        categories={categories}
        onUpdate={updateCookieCategory}
        onDelete={onDeleteCookie}
        loading={loading}
        showError={false}
      />

      <Dialog
        open={formModalOpen}
        onClose={closeFormModal}
        PaperProps={{
          style: {
            width: '500px',
            maxWidth: '90%',
          },
        }}
      >
        <DialogTitle>
          { _.capitalize(t('PAGES.COOKIE_MANAGEMENT.create_cookie')) }
        </DialogTitle>
        <DialogContent>
          <CookieForm
            onSubmit={createCookie}
            categories={categories}
          />
        </DialogContent>
      </Dialog>
    </>
  );
}

export default React.memo(CookieClassification);
