import React, {useState, memo, useEffect,} from 'react';
import { useDispatch, } from 'react-redux';
import _ from 'lodash';
import {Container, TextField, makeStyles, Grid,} from '@material-ui/core';
import { useFormik, } from 'formik';
import * as Yup from 'yup';
import { Notification, Button, } from 'rsuite';
import { createCollaborator, updateCollaborator} from '../../features/collaboratorsSlice';


const INITIAL_VALUES = Object.freeze({
  name: '',
  email: '',
});

const useStyles = makeStyles({
  saveButton: {
    marginTop: '0.7rem',
  },
  buttonContainer: {
    marginTop: '0.5rem',
    display: 'flex',
    justifyContent: 'flex-start',
  },
  cancelBtn: {
    marginLeft: '1rem',
    marginTop: '0.7rem',
    borderColor: '#3B66FF',
    color: '#3B66FF !important',
    background: 'transparent !important',
    boxShadow: 'none',
    borderWidth: 2,
    border: '2px solid #3B66FF !important',
    '&:hover': {
      background: 'transparent !important',
      border: '2px solid #3B66FF !important',
    },
  },
});

const MESSAGES = Object.freeze({
  UPDATE: {
    success: {
      title: 'Colaborador atualizado com sucesso!',
      description: '',
    },
    error: {
      title: 'Falha ao atualizar colaborador',
      description: 'Tente novamente dentro de alguns instantes',
    },
  },
  CREATE: {
    success: {
      title: 'Colaborador criado com sucesso!',
      description: '',
    },
    error: {
      title: 'Falha ao criar colaborador',
      description: 'Tente novamente dentro de alguns instantes',
    },
  },
});


function CollaboratorForm({ data = {}, onUpdateFinished }) {

  const classes = useStyles();

  const dispatch = useDispatch();

  const formik = useFormik({
    initialValues: INITIAL_VALUES,
    onSubmit: (values) => saveCollaborator(values),
    validationSchema: Yup.object({
      name: Yup.string().required('Obrigatório'),
      email: Yup.string().email('Deve ser um e-mail válido').required('Obrigatório'),
    }),
  });

  const [loading, setLoading] = useState(false);


  /**
   * Save collaborator in API
   */
  const saveCollaborator = async (values) => {
    setLoading(true);

    const isUpdate = !!data.id;
    const msgs = isUpdate ? MESSAGES.UPDATE : MESSAGES.CREATE;

    const __data = {
      ...data,
      ...values,
    }

    try {
      await dispatch(isUpdate ?
        updateCollaborator(__data) :
        createCollaborator(__data)).unwrap();

      Notification['success']({
        placement: 'bottomEnd',
        title: msgs.success.title,
      });

      formik.resetForm({
        values: INITIAL_VALUES,
      });

      if (isUpdate) {
        onUpdateFinished();
      }

      formik.resetForm({
        values: INITIAL_VALUES,
      });
    } catch {
      Notification['error']({
        placement: 'bottomEnd',
        title: msgs.error.title,
        description: msgs.error.description,
      });
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    if (null != data.id) {
      formik.setValues(data);
    }
  }, [data]);

  function onCancel() {
    formik.resetForm({
      values: INITIAL_VALUES,
    });

    onUpdateFinished();
  }


  return (
    <Container
      disableGutters
      fullWidth
      maxWidth="xl"
    >
      <TextField
        margin="dense"
        variant="filled"
        label="Nome"
        fullWidth
        id="name"
        type="text"
        error={formik.errors.name && formik.touched.name}
        helperText={formik.touched.name && formik.errors.name}
        {...formik.getFieldProps('name')}
      />

      <TextField
        margin="dense"
        variant="filled"
        label="E-mail"
        fullWidth
        id="email"
        type="text"
        error={formik.errors.email && formik.touched.email}
        helperText={formik.touched.email && formik.errors.email}
        {...formik.getFieldProps('email')}
      />

      <Grid
        className={classes.buttonContainer}
      >
        <Button
          className={classes.saveButton}
          appearance="primary"
          loading={loading}
          onClick={formik.handleSubmit}
        >
          Salvar
        </Button>
        {
          (!!data.id) ?
            <Button
              appearance="primary"
              className={classes.cancelBtn}
              onClick={onCancel}
              loading={loading}
            >
              Cancelar
            </Button> :
            null
        }
      </Grid>

    </Container>
  );
}

export default memo(CollaboratorForm);
