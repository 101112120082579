import React, { useEffect, useState, memo, } from 'react';
import { useFormik, } from 'formik';
import * as Yup from 'yup';
import { useSelector, } from 'react-redux';

import { Button, Form, Row, Col, Badge, } from 'react-bootstrap';
import { Paper, Container, CircularProgress, } from '@material-ui/core';
import { Notification, Icon, } from 'rsuite';

import HighlightOffRoundedIcon from '@material-ui/icons/HighlightOffRounded';
import LibraryAddCheckRoundedIcon from '@material-ui/icons/LibraryAddCheckRounded';

import { createMeeting, getUsers, } from '../../services/api';
import styles from './styles.module.scss';

import { selectCompany } from '../../features/companySlice';


const INITIAL_VALUES = Object.freeze({
  title: '',
  date: '',
  local: '',
  resume: '',
  inicio: '',
  participants: [],
});


function RegisterMeeting({ onNew, hideTitle, }) {
  const { _id: companyId, } = useSelector(selectCompany);
  const name = localStorage.getItem('name');
  const avatar = localStorage.getItem('avatar');
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingUsers, setLoadingUsers] = useState(false);

  const formik = useFormik({
    initialValues: INITIAL_VALUES,
    onSubmit: onSubmit,
    validationSchema: Yup.object({
      title: Yup.string().required('Obrigatório'),
      date: Yup.string().min(10, 'Deve ser uma data válida').max(10,
        'Deve ser uma data válida').required('Obrigatório'),
      local: Yup.string().required('Obrigatório'),
      resume: Yup.string(),
      inicio: Yup.string().min(5, 'Deve ser um horário válido').max(5,
        'Deve ser um horário válido').required('Obrigatório'),
      participants: Yup.array().of(Yup.string()),
    }),
  });


  async function getParticipants() {
    setLoadingUsers(true);

    try {
      const { data, } = await getUsers();
      //todo: filter by group too
      setUsers(data.filter((user) => user.empresa === companyId));
    } catch {
      Notification['error']({
        placement: 'bottomEnd',
        title: 'Falha ao carregar usuários!',
        description: 'Tente novamente dentro de alguns instantes',
      });
    }

    setLoadingUsers(false);
  }

  /**
   * Use effect initialize component data
   */
  useEffect(() => {
    getParticipants();
  }, []);

  /**
   * Send request to API to create new meeting
   */
  async function onSubmit(values) {
    setLoading(true);

    try {
      await createMeeting({
        ...values,
        requester: name,
        idEmpresa: companyId,
        avatar: avatar,
      });

      Notification['success']({
        placement: 'bottomEnd',
        title: 'Reunião do comitê criada com sucesso!',
      });

      formik.resetForm({
        values: INITIAL_VALUES,
      });

      if (typeof onNew === 'function') {
        onNew();
      }
    } catch {
      Notification['error']({
        placement: 'bottomEnd',
        title: 'Falha ao criar reunião do comitê!',
        description: 'Tente novamente dentro de alguns instantes',
      });
    }

    setLoading(false);
  }

  function addMember(member) {
    if (!formik.values.participants.includes(member)) {
      formik.setFieldValue('participants', [...formik.values.participants, member]);
    }
  }

  function removeMember(memberRemove) {
    formik.setFieldValue('participants', formik.values.participants.filter((member) => member !== memberRemove));
  }

  async function resetParticipants(event) {
    event.preventDefault();
    await formik.setValues({
      ...formik.values,
      participants: [],
    }, false);
  }

  function __onSubmit(event) {
    event.preventDefault();
    formik.handleSubmit();
  }


  return (
    <Container
      maxWidth="xl"
      component={Paper}
    >
      {
        !hideTitle ?
        <h5 className={styles.title}>
          Registrar nova reunião
        </h5> :
        null
      }

      <Form
        noValidate
        className={styles.container}
      >
        <div
          style={{
            marginBottom: '3px',
          }}
        >
          <p
            className={styles.inputLabel}
            style={{
              display: 'inline',
            }}
          >
            Responsável:
          </p>
          {` `}
          <p
            style={{
              display: 'inline',
              fontWeight: 'bold'
            }}
          >
            { name }
          </p>
        </div>

        <Form.Group className="mb-2">
          <Form.Label className={styles.inputLabel} >
            Título:
          </Form.Label>
          <Form.Control
            placeholder="Título da reunião"
            id="title"
            type="text"
            isInvalid={formik.touched.title && formik.errors.title}
            {...formik.getFieldProps('title')}
          />

          <Form.Control.Feedback type="invalid">
            { formik.errors.title }
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group>
          <Form.Label className={styles.inputLabel} >
            Local:
          </Form.Label>

          <Form.Control
            id="local"
            type="text"
            isInvalid={formik.touched.local && formik.errors.local}
            {...formik.getFieldProps('local')}
          />

          <Form.Control.Feedback type="invalid">
            { formik.errors.local }
          </Form.Control.Feedback>
        </Form.Group>

        <Row>
          <Col
            xs={12}
            sm={6}
          >
            <Form.Group>
              <Form.Label className={styles.inputLabel} >
                Data:
              </Form.Label>
              <Form.Control
                id="date"
                type="date"
                isInvalid={formik.touched.date && formik.errors.date}
                {...formik.getFieldProps('date')}
              />

              <Form.Control.Feedback type="invalid">
                { formik.errors.date }
              </Form.Control.Feedback>
            </Form.Group>
          </Col>

          <Col
            xs={12}
            sm={6}
          >
            <Form.Group>
              <Form.Label className={styles.inputLabel} >
                Início:
              </Form.Label>

              <Form.Control
                id="time"
                type="time"
                isInvalid={formik.touched.inicio && formik.errors.inicio}
                {...formik.getFieldProps('inicio')}
              />

              <Form.Control.Feedback type="invalid">
                { formik.errors.inicio }
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
        </Row>

        <Form.Group>
          <Form.Label className={styles.inputLabel} >
            <div className={styles.inputLabelFunct}>
              <span>Participantes:</span>
              {
                loadingUsers ?
                <CircularProgress
                  size={16}
                  sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    marginTop: '-12px',
                    marginLeft: '-12px',
                  }}
                  color="#0059F7"
                /> :
                <Icon icon="reload" onClick={getParticipants} />
              }
            </div>
          </Form.Label>

          <Row>
            <Col xs={6}>
              <Form.Control
                className={styles.boxParticipantsSelect}
                as="select"
                multiple
                id="participants"
                defaultValue={[]}
                isInvalid={formik.touched.participants && formik.errors.participants}
                {...formik.getFieldProps('participants')}
                onChange={(ev) => addMember(ev.target.value)}
              >
                {
                  users.map((user) => (
                    <option key={user._id}>
                      { user.name }
                    </option>
                  ))
                }
              </Form.Control>

              <Form.Control.Feedback type="invalid">
                { formik.errors.participants }
              </Form.Control.Feedback>
            </Col>

            <Col xs={6}>
              <div className={styles.boxParticipantsSelect} >
                {
                  formik.values.participants.map((participant) => (
                    <Badge
                      className={styles.participantBadge}
                      key={participant}
                      pill
                      variant="primary"
                    >
                      <HighlightOffRoundedIcon
                        onClick={() => removeMember(participant)}
                        fontSize="small"
                        style={{
                          cursor: 'pointer',
                        }}
                      />{' '}
                      {participant}
                    </Badge>
                  ))
                }
              </div>
            </Col>
          </Row>
        </Form.Group>

        <button
          className={styles.cleanSelection}
          onClick={resetParticipants}
        >
          Limpar seleção
        </button>

        <Button
          variant="outline-info"
          size="sm"
          type="submit"
          disabled={loading}
          className={styles.submitButton}
          onClick={__onSubmit}
        >
          {
            !loading ?
            <>
              <LibraryAddCheckRoundedIcon fontSize="small" />{' '}
              Marcar Reunião
            </> :
            <CircularProgress
              size={16}
            />
          }
        </Button>
      </Form>
    </Container>
  );
}

export default memo(RegisterMeeting);
