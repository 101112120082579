import React from 'react';
import _ from 'lodash';
import * as Yup from 'yup';
import { useFormik, } from 'formik';
import { useTranslation, } from 'react-i18next';

import {
  Grid,
  TextField,
  makeStyles,
  Select,
  InputLabel,
  FormControl,
  MenuItem,
  FormHelperText,
  Container,
  InputAdornment,
  IconButton,
} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';

import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';

import Button from '../Button';


const INITIAL_VALUES = Object.freeze({
  password: '',
  template: '',
});

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};


const useStyles = makeStyles({
  actions: {
    marginTop: '0.5rem',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  title: {
    color: 'white',
    backgroundColor: '#3B66FF',
  },
});


function ImportTemplateForm({ items, onCancel, onSubmit, warning, }) {
  const classes = useStyles();
  const { t, } = useTranslation();
  const [showPassword, setShowPassword] = React.useState(false);
  const formik = useFormik({
    initialValues: INITIAL_VALUES,
    onSubmit: _onSubmit,
    validationSchema: Yup.object({
      password: Yup.string()
        .required(_.capitalize(t('VALIDATION_MESSAGES.required', {
          context: 'male',
        }))),
      template: Yup.number()
        .required(_.capitalize(t('VALIDATION_MESSAGES.required', {
          context: 'male',
        })))
        .oneOf(items.map((template) => template.id)),
    }),
  });


  function cleanForm(values = INITIAL_VALUES) {
    formik.resetForm({
      errors: {},
      touched: {},
      values,
    });
  }

  function _onSubmit(data) {
    onSubmit(data);
    cleanForm();
  }

  /**
   * Handle cancel
   */
  function _onCancel() {
    cleanForm();
    onCancel();
  }


  return (
    <Container>
      <Grid
        item
        xs={12}
        container
        direction="row"
      >
        {
          undefined != warning ?
          <Alert severity="warning" >
            <AlertTitle>
              { _.capitalize(t('PAGES.attention')) }
            </AlertTitle>
            { warning }
          </Alert> :
          null
        }
        <FormControl
          fullWidth
          variant="filled"
          margin="dense"
          error={formik.errors.template && formik.touched.template}
        >
          <InputLabel id="template-label">
            Template
          </InputLabel>
          <Select
            labelId="template-label"
            disableUnderline
            id="template"
            {...formik.getFieldProps('template')}
            MenuProps={MenuProps}
          >
            {
              items.map((template) => (
                <MenuItem
                  key={template.id}
                  value={template.id}
                >
                  { template.name }
                </MenuItem>
              ))
            }
          </Select>
          <FormHelperText>
            {formik.touched.template && formik.errors.template}
          </FormHelperText>
        </FormControl>

        <TextField
          margin="dense"
          variant="filled"
          label={_.capitalize(t('password'))}
          fullWidth
          id="password"
          type={showPassword ? 'text' : 'password'}
          error={formik.errors.password && formik.touched.password}
          helperText={formik.touched.password && formik.errors.password}
          InputProps={{
            disableUnderline: true,
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={() => setShowPassword((showPassword) => !showPassword)}
                  edge="end"
                >
                  {
                    showPassword ?
                    <VisibilityOffIcon /> :
                    <VisibilityIcon />
                  }
                </IconButton>
              </InputAdornment>
            )
          }}
          {...formik.getFieldProps('password')}
        />
      </Grid>

      <Grid className={classes.actions} >
        <Button
          variant="contained"
          onClick={_onCancel}
        >
          { _.capitalize(t('cancel')) }
        </Button>

        <Button
          color="primary"
          variant="contained"
          onClick={formik.handleSubmit}
        >
          { _.capitalize(t('save')) }
        </Button>
      </Grid>
    </Container>
  );
}

export default React.memo(ImportTemplateForm);
