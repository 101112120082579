import { makeStyles, } from '@material-ui/core';


const useAspectRatioStyles = makeStyles({
  outerWrapper: {
    position: 'relative',
    width: '100%',
    height: 0,
    paddingBottom: (props) => {
      return props.ratio ? `${(1 / props.ratio) * 100}%` : '50%';
    },
  },
  innerWrapper: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
  },
});


/**
 * Component used to show components with fixed aspect ratio
 *
 * @param {component} children - children component
 * @param {number} ratio - aspect ratio
 *
 * @returns rendered component
 */
export default function AspectRatio({ children, ...props }) {
  const classes = useAspectRatioStyles(props);


  return (
    <div className={classes.outerWrapper}>
      <div className={classes.innerWrapper}>
        { children }
      </div>
    </div>
  );
}
