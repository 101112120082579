import { useState, } from "react";
import { useSelector, } from 'react-redux';

import Chip from "@material-ui/core/Chip";
import { makeStyles, } from "@material-ui/core/styles";

import ModalNewReunionWorkshop from "./modalNewReunion";
import {
  BTNNewReunion,
  InputTitleWorckshopIntegra,
  MainWorkshopInputIntegra,
  TemplateWorkshopIntegra,
  BoxButtonReunion
} from "./style";

import { selectCompany, } from "../../../../features/companySlice";


const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "left",
    flexWrap: "wrap",
    "& > *": {
      borderRadius: "0.25rem",
      margin: theme.spacing(0.5),
    },
  },
}));


export function ContainerIntegrantesWorkshop({ user: users, }) {
  const classes = useStyles();
  const { _id: idEmpresa, } = useSelector(selectCompany);
  const [opemModalReunion, setOpemModalReunion] = useState(false);


  function HandleCloseModal() {
    setOpemModalReunion(false);
  }


  return (
    <MainWorkshopInputIntegra>
      <InputTitleWorckshopIntegra>
        <h1>Integrantes</h1>
        <p>
          Pessoas que participam do comitê de Privacidade e Proteção de Dados.
        </p>
      </InputTitleWorckshopIntegra>

      <TemplateWorkshopIntegra className={classes.root}>
        {
          users.filter((user) => user.empresa === idEmpresa).map((user) => {
            return (
              <Chip
                key={user.id}
                className="ChipIntegrante"
                avatar="img"
                label={user.name}
                size="medium"
              />
            );
          })
        }
      </TemplateWorkshopIntegra>

      <BoxButtonReunion>
        <BTNNewReunion
          type="button"
          onClick={() => setOpemModalReunion(true)}
          >
            Agendar reunião
        </BTNNewReunion>
      </BoxButtonReunion>

      <ModalNewReunionWorkshop
      openModal={opemModalReunion}
      closeModal={HandleCloseModal}
      />
    </MainWorkshopInputIntegra>
  );
}
