import React, { useEffect } from 'react';
import _ from 'lodash';

import { Notification } from 'rsuite';

import { createNewDiagnosis, } from '../../services/api';

import template from './questions.json';

export const COMPANY_MATURITY_DIAGNOSIS_FIELD_TYPES = Object.freeze({
  TEXT: 'TEXT',
  NUMBER: 'NUMBER',
  SELECT: 'SELECT',
});

export const CompanyMaturityDiagnosisContext = React.createContext();




export function CompanyMaturityDiagnosisContextProvider({ children, onFinished, }) {
  const [loading, setLoading] = React.useState(false);
  const [question, setQuestion] = React.useState(0);
  const [questions, setQuestions] = React.useState(() => {
    return _.cloneDeep(template).map((item) => {
      const values = {
        [COMPANY_MATURITY_DIAGNOSIS_FIELD_TYPES.TEXT]: () => '',
        [COMPANY_MATURITY_DIAGNOSIS_FIELD_TYPES.NUMBER]: () => 0,
        [COMPANY_MATURITY_DIAGNOSIS_FIELD_TYPES.SELECT]: (data) => data.multiple ? [] : null,
      };

      return {
        ...item,
        value: values[item.type](item),
      };
    });
  });
  const questionData = React.useMemo(() => {
    return questions[question] || {};
  }, [questions, question]);
  const previousEnabled = question > 0;
  const nextEnabled = React.useMemo(() => {
    const checkers = {
      [COMPANY_MATURITY_DIAGNOSIS_FIELD_TYPES.TEXT]: () => Boolean(questionData.value),
      [COMPANY_MATURITY_DIAGNOSIS_FIELD_TYPES.NUMBER]: () => Boolean(questionData.value),
      [COMPANY_MATURITY_DIAGNOSIS_FIELD_TYPES.SELECT]: () => {
        return questionData.multiple ? questionData.value.length > 0 : questionData.value != null;
      },
    };

    const checker = checkers[questionData.type];
    return undefined != checker ? checker() : false;
  }, [questionData]);


  function onPrevious() {
    setQuestion(Math.max(question - 1, 0));
  }

  function onNext() {
    const nextQuestion = question + 1;
    if (nextQuestion < questions.length) {
      setQuestion(nextQuestion);
    } else {
      _onFinished();
    }
  }

  async function _onFinished() {
    setLoading(true);

    try {
      await createNewDiagnosis(questions);
    } catch {
      Notification['error']({
        placement: 'bottomEnd',
        title: 'Falha ao enviar informações para diagnóstico!',
        description: 'Tente novamente dentro de alguns instantes.',
      });
    }

    try {
      window.dataLayer.push({
        event: 'diagnostico',
      });
    } catch {
      // Drop GTM error
    }

    setLoading(false);
    onFinished();
  }

  function onChange(value) {
    const _questions = _.cloneDeep(questions);
    const questionData = _questions[question];
    if (undefined != questionData) {
      if ((COMPANY_MATURITY_DIAGNOSIS_FIELD_TYPES.TEXT == questionData.type) ||
        questionData.multiple) {
        questionData.value = value;
      } else {
        questionData.value = Number(value);
      }
    }
    setQuestions(_questions);
  }


  return (
    <CompanyMaturityDiagnosisContext.Provider
      value={{
        loading,
        question,
        questions,
        nextEnabled,
        questionData,
        previousEnabled,
        onNext,
        onChange,
        onPrevious,
      }}
    >
      { children }
    </CompanyMaturityDiagnosisContext.Provider>
  );
}
